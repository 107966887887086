export default {
  sm: '415px',
  // => @media (min-width: 415px) { ... }

  md: '769px',
  // => @media (min-width: 769px) { ... }

  lg: '1281px',
  // => @media (min-width: 1281px) { ... }
};
