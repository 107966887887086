import {
  NODE_BR,
  NODE_CODEBLOCK,
  NODE_HEADING,
  NODE_HR,
  NODE_LI,
  NODE_OL,
  NODE_PARAGRAPH,
  NODE_QUOTE,
  NODE_UL,
  render,
} from 'storyblok-rich-text-react-renderer';

/**
 * Render Storyblok rich text document as react components
 * @param {StoryblokRichtext | unknown} doc
 * @param {RenderOptions} [options]
 * @return {*}
 */
export function renderRichTextReact(doc, options) {
  return render(doc, options);
}

/**
 * @param {any} node
 * @param {boolean} addNewlines
 * @return {any | null}
 */
function renderNode(node, addNewlines) {
  if (node.type === 'text') {
    return node.text;
  }

  if (
    [NODE_PARAGRAPH, NODE_HEADING, NODE_CODEBLOCK, NODE_QUOTE, NODE_OL, NODE_UL, NODE_LI, NODE_HR, NODE_BR].includes(
      node.type
    )
  ) {
    // eslint-disable-next-line no-use-before-define
    return node.content?.length ? `${renderNodes(node.content, addNewlines)}${addNewlines ? '\n\n' : ' '}` : '';
  }

  return null;
}

/**
 * @param {any} nodes
 * @param {boolean} addNewlines
 * @return {*}
 */
function renderNodes(nodes, addNewlines) {
  return (
    nodes
      .map((node) => renderNode(node, addNewlines))
      .filter((node) => node !== null)
      .join('')
      // Replace multiple spaces with one
      .replace(/[^\S\r\n]{2,}/g, ' ')
      .trim()
  );
}

/**
 * @typedef GetPlainTextOptions
 * @property {boolean} [addNewlines]
 */

/**
 * Render Storyblok rich text document as strings
 * @param {StoryblokRichtext} richText
 * @param {GetPlainTextOptions} options
 * @return {string}
 */
export function renderPlainText(richText, { addNewlines } = {}) {
  if (!richText?.content?.length) {
    return '';
  }

  return renderNodes(richText.content, addNewlines !== undefined ? addNewlines : true);
}

/**
 * @typedef GetExcerptOptions
 * @property {number} [maxLength]
 */

/**
 * After how many characters the text should be cut off. Default = 320
 * @param {StoryblokRichtext} richText
 * @param {GetExcerptOptions & GetPlainTextOptions} [options]
 * @return {string}
 */
export function getExcerpt(richText, options = { maxLength: 320 }) {
  const { maxLength, ...restOptions } = options;
  const text = renderPlainText(richText, { addNewlines: false, ...restOptions });

  if (!text || !maxLength || text?.length < maxLength) {
    return text;
  }

  return `${text?.substring(0, maxLength)}…`;
}
