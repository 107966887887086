import React from 'react';
import PropTypes from 'prop-types';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import { Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import { bpWidthTailwind } from '../../styles/Web3.0/variables';
import { bodyLRegularBrownStyles, bodySRegularBrownStyles } from '../../styles/Web3.0/typography';

const TwoColumnsBlokCol = styled(Col)`
  ${bodySRegularBrownStyles};

  @media (${bpWidthTailwind.tablet}) {
    ${bodyLRegularBrownStyles};
  }

  p {
    margin-bottom: 0.75rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  a.link-component {
    margin-top: 0.75rem;
    display: inline-flex;
    font-size: 14px;
    text-transform: uppercase;
  }

  &.business-events {
    .two-columns-blok-container {
      align-items: center;
      justify-content: space-between;
      row-gap: 55px;

      @media (${bpWidthTailwind.tablet}) {
        align-items: flex-end;

        .left-col {
          width: 52%;
        }

        .right-col {
          width: 44%;
        }
      }

      @media (${bpWidthTailwind.desktopSm}) {
        .left-col {
          width: 48%;
        }

        .right-col {
          width: 40%;
        }
      }

      @media (${bpWidthTailwind.desktopLg}) {
        .left-col {
          width: 33%;
        }

        .right-col {
          width: 50%;
          padding: 0 12%;
        }
      }
    }
  }

  &.business-case-study {
    .two-columns-blok-container {
      display: flex;
      row-gap: 2.875rem;

      .left-col {
        width: 100%;
      }

      .right-col {
        width: 100%;
      }

      @media (${bpWidthTailwind.tablet}) {
        row-gap: 4.875rem;

        .left-col {
          width: 100%;
        }

        .right-col {
          width: 100%;
        }
      }

      @media (${bpWidthTailwind.desktopSm}) {
        row-gap: 2.5rem;

        .left-col {
          width: 100%;
        }

        .right-col {
          width: 100%;
        }
      }

      @media (${bpWidthTailwind.desktopLg}) {
        justify-content: space-between;

        .left-col {
          width: 45%;
        }

        .right-col {
          width: 55%;
        }
      }
    }
  }
`;

const TwoColumnsBlok = ({ blok }) => {
  const { leftBloks, rightBloks, customCssClass } = blok;

  return (
    <TwoColumnsBlokCol {...storyblokEditable(blok)} xs={8} md={10} lg={12} className={customCssClass}>
      <div className="flex flex-wrap flex-col sm:flex-row two-columns-blok-container">
        <div className="w-full sm:w-1/2 left-col">
          {leftBloks?.map((nestedBlok) => (
            <Row key={nestedBlok._uid}>
              <StoryblokComponent blok={nestedBlok} />
            </Row>
          ))}
        </div>
        <div className="w-full sm:w-1/2 right-col">
          {rightBloks?.map((nestedBlok) => (
            <Row key={nestedBlok._uid}>
              <StoryblokComponent blok={nestedBlok} />
            </Row>
          ))}
        </div>
      </div>
    </TwoColumnsBlokCol>
  );
};

TwoColumnsBlok.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default TwoColumnsBlok;
