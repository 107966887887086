import React, { useMemo } from 'react';
import { object } from 'prop-types';
import styled from 'styled-components';
import { StoryblokComponent } from 'gatsby-source-storyblok';
import { Link } from 'gatsby';
import _ from 'lodash';
import {
  bodyMBoldBrownStyles,
  bodySBoldBrownStyles,
  bodySRegularBrownStyles,
  bodyXsBoldBrownStyles,
  bodyXsRegularBrownStyles,
} from '../../../styles/Web3.0/typography';
import { bpWidthTailwind, colors } from '../../../styles/Web3.0/variables';
import PiaggioGroupIcon from '../../../icons/Web3.0/piaggio-group.svg';
import LinkedInIcon from '../../../icons/Web3.0/b2b-linkedin.svg';
import TwitterIcon from '../../../icons/Web3.0/b2b-twitter.svg';
import YoutubeIcon from '../../../icons/Web3.0/b2b-youtube.svg';
import InstagramIcon from '../../../icons/Web3.0/b2b-instagram.svg';
import { useBusinessAppState } from '../../../context/business-app-state.context';
import { useIsomorphicLayoutEffect } from '../../../hooks';
import { updateStoryblokMediaUrl } from '../../../utils/helper';

const SecondaryLink = styled.div`
  ${bodyXsBoldBrownStyles};
  color: ${colors.beige100};

  @media (${bpWidthTailwind.tablet}) {
    line-height: 1.4;
  }

  @media (${bpWidthTailwind.desktopSm}) {
    ${bodySBoldBrownStyles};
    color: ${colors.beige100};
    line-height: 1.4;
  }

  @media (${bpWidthTailwind.desktopLg}) {
    ${bodyMBoldBrownStyles};
    color: ${colors.beige100};
    font-size: 18px;
  }

  a {
    ${bodyXsBoldBrownStyles};
    color: ${colors.beige100};

    @media (${bpWidthTailwind.tablet}) {
      line-height: 1.4;
    }

    @media (${bpWidthTailwind.desktopSm}) {
      ${bodySBoldBrownStyles};
      color: ${colors.beige100};
      line-height: 1.4;
    }

    @media (${bpWidthTailwind.desktopLg}) {
      ${bodyMBoldBrownStyles};
      color: ${colors.beige100};
      font-size: 18px;
    }

    text-decoration: none;

    &:hover {
      color: inherit;
    }
  }
`;

const SecondarySubLink = styled(SecondaryLink)`
  ${bodyXsRegularBrownStyles};
  color: ${colors.beige300};

  @media (${bpWidthTailwind.tablet}) {
    line-height: 1.7;
  }

  @media (${bpWidthTailwind.desktopLg}) {
    ${bodySRegularBrownStyles};
    color: ${colors.beige300};
  }

  a {
    ${bodyXsRegularBrownStyles};
    color: ${colors.beige300};

    @media (${bpWidthTailwind.tablet}) {
      line-height: 1.7;
    }

    @media (${bpWidthTailwind.desktopLg}) {
      ${bodySRegularBrownStyles};
      color: ${colors.beige300};
    }
  }
`;

const ImgSvg = styled.span`
  display: inline-block;
  mask: ${({ src }) => {
    return `url(${src})`;
  }};
  background: ${colors.beige100};
  width: 108px;
  height: ${`${(108 * 9) / 25}px`};

  @media (${bpWidthTailwind.tablet}) {
    width: 91px;
    height: ${`${(91 * 9) / 25}px`};
  }

  @media (${bpWidthTailwind.desktopSm}) {
    width: 123px;
    height: ${`${(123 * 9) / 25}px`};
  }

  @media (${bpWidthTailwind.desktopLg}) {
    width: 196px;
    height: ${`${(196 * 9) / 25}px`};
  }
`;

const BusinessFooter = ({ blok }) => {
  const { logo, navigationLinks, rightsText } = blok;
  const { setFooterBlok } = useBusinessAppState();
  const copyrightDate = new Date().getFullYear();
  const ungroup = 'ungroup';

  useIsomorphicLayoutEffect(() => {
    setFooterBlok(blok);
  }, [blok]);

  const desktopLgNavigationLinks = useMemo(() => {
    return _.groupBy(navigationLinks || [], (link) => link.group || ungroup);
  }, [navigationLinks]);

  const desktopSmNavigationLinks = useMemo(() => {
    const newLinks = navigationLinks
      .map((link) => {
        let orderedLink = { ...link };

        if (link.url.cached_url === '/about' || link.url.url === '/about') {
          orderedLink = {
            ...link,
            order: 0,
          };

          return orderedLink;
        }
        if (link.url.cached_url === '/careers' || link.url.url === '/careers') {
          orderedLink = {
            ...link,
            order: 1,
          };

          return orderedLink;
        }
        if (link.url.cached_url === '/business/support' || link.url.url === '/business/support') {
          orderedLink = {
            ...link,
            order: 2,
          };

          return orderedLink;
        }
        if (link.url.cached_url === '/privacy-policy' || link.url.url === '/privacy-policy') {
          orderedLink = {
            ...link,
            order: 3,
          };

          return orderedLink;
        }
        if (link.url.cached_url.includes('presskit') || link.url.url.includes('presskit')) {
          orderedLink = {
            ...link,
            order: 4,
          };

          return orderedLink;
        }

        return orderedLink;
      })
      .sort((a, b) => a.order - b.order);

    return _.groupBy(newLinks || [], (link) => link.group || ungroup);
  }, [navigationLinks]);

  const tabletNavigationLinks = useMemo(() => {
    const newLinks = navigationLinks
      .map((link) => {
        let orderedLink = { ...link };

        if (link.url.cached_url === '/privacy-policy' || link.url.url === '/privacy-policy') {
          orderedLink = {
            ...link,
            order: 0,
          };

          return orderedLink;
        }
        if (link.url.cached_url === '/business/support' || link.url.url === '/business/support') {
          orderedLink = {
            ...link,
            order: 1,
          };

          return orderedLink;
        }
        if (link.url.cached_url.includes('presskit') || link.url.url.includes('presskit')) {
          orderedLink = {
            ...link,
            order: 2,
          };

          return orderedLink;
        }
        if (link.url.cached_url === '/about' || link.url.url === '/about') {
          orderedLink = {
            ...link,
            order: 3,
          };

          return orderedLink;
        }
        if (link.url.cached_url === '/careers' || link.url.url === '/careers') {
          orderedLink = {
            ...link,
            order: 4,
          };

          return orderedLink;
        }

        return orderedLink;
      })
      .sort((a, b) => a.order - b.order);

    return _.groupBy(newLinks || [], (link) => link.group || ungroup);
  }, [navigationLinks]);

  const mobileNavigationLinks = useMemo(() => {
    return navigationLinks
      .map((link) => {
        let orderedLink = { ...link };

        if (link.url.cached_url === '/about' || link.url.url === '/about') {
          orderedLink = {
            ...link,
            order: 0,
          };

          return orderedLink;
        }
        if (link.url.cached_url === '/privacy-policy' || link.url.url === '/privacy-policy') {
          orderedLink = {
            ...link,
            order: 1,
          };

          return orderedLink;
        }
        if (link.url.cached_url.includes('/presskit') || link.url.url.includes('/presskit')) {
          orderedLink = {
            ...link,
            order: 2,
          };

          return orderedLink;
        }
        if (link.url.cached_url === '/careers' || link.url.url === '/careers') {
          orderedLink = {
            ...link,
            order: 3,
          };

          return orderedLink;
        }
        if (link.url.cached_url === '/business/support' || link.url.url === '/business/support') {
          orderedLink = {
            ...link,
            order: 4,
          };

          return orderedLink;
        }

        return orderedLink;
      })
      .sort((a, b) => a.order - b.order);
  }, [navigationLinks]);

  return (
    <footer
      className="bg-gradient-to-b from-navy-600 from-0% via-navy-400 via-45% to-navy-600 to-100% text-white
      pt-[70px] pb-[52px] px-[12.5%]
      sm:pt-[102px] sm:pb-[89px] sm:px-[10%]
      md:pt-[100px] md:pr-[88px] md:pb-[40px] md:pl-[108px]
      lg:pt-[110px] lg:pb-[95px] lg:px-[8.3%]"
    >
      <div
        className="grid grid-cols-2
        sm:grid-cols-[minmax(100px,_28.126vw)_auto_121px]
        md:grid-cols-[minmax(140px,_20vw)_auto_minmax(140px,_16.9535vw)]
        lg:grid-cols-[minmax(250px,_16.823vw)_auto_minmax(275px,_17.1875vw)]"
      >
        <div className="lg:mt-16 pt-1">
          {/* PPF Logo */}
          {logo?.filename && (
            <Link to="/business" className="inline-flex">
              <ImgSvg src={updateStoryblokMediaUrl(logo.filename)} alt="Piaggio Fast Forward Logo" />
            </Link>
          )}
        </div>
        <div className="hidden sm:flex md:hidden flex-wrap gap-y-5 gap-x-[4.81771vw]">
          <div className="flex flex-col gap-y-[1.125rem]">
            {tabletNavigationLinks.ungroup.map((nestedBlok) => (
              <SecondaryLink key={nestedBlok._uid}>
                <StoryblokComponent blok={nestedBlok} />
              </SecondaryLink>
            ))}
          </div>
          <div className="flex flex-col gap-y-1.5">
            <div className="font-brown font-bold text-xs leading-[1.4] capitalize">
              {tabletNavigationLinks.company?.[0]?.group}
            </div>
            {tabletNavigationLinks.company.map((nestedBlok) => (
              <SecondarySubLink key={nestedBlok._uid}>
                <StoryblokComponent blok={nestedBlok} />
              </SecondarySubLink>
            ))}
          </div>
        </div>
        <div
          className="hidden md:flex lg:hidden flex-wrap gap-y-6 gap-x-[6.25vw]
          text-[15px] font-brown font-bold"
        >
          <div className="flex flex-col gap-y-4">
            <div className="font-brown font-bold text-sm leading-[1.4] capitalize">
              {desktopSmNavigationLinks.company?.[0]?.group}
            </div>
            <div className="flex flex-col gap-y-1.5">
              {desktopSmNavigationLinks.company.map((nestedBlok) => (
                <SecondarySubLink key={nestedBlok._uid}>
                  <StoryblokComponent blok={nestedBlok} />
                </SecondarySubLink>
              ))}
            </div>
          </div>
          {desktopSmNavigationLinks.ungroup.map((nestedBlok) => (
            <SecondaryLink key={nestedBlok._uid}>
              <StoryblokComponent blok={nestedBlok} />
            </SecondaryLink>
          ))}
        </div>
        <div
          className="hidden lg:flex flex-wrap gap-y-6 gap-x-[5.20835vw] lg:mt-16
          text-[15px] font-brown font-bold"
        >
          <div className="flex flex-col gap-y-5">
            <div className="font-brown font-bold text-lg leading-[1.7] capitalize">
              {desktopLgNavigationLinks.company?.[0]?.group}
            </div>
            <div className="flex flex-col gap-y-1">
              {desktopLgNavigationLinks.company.map((nestedBlok) => (
                <SecondarySubLink key={nestedBlok._uid}>
                  <StoryblokComponent blok={nestedBlok} />
                </SecondarySubLink>
              ))}
            </div>
          </div>
          {desktopLgNavigationLinks.ungroup.map((nestedBlok) => (
            <SecondaryLink key={nestedBlok._uid}>
              <StoryblokComponent blok={nestedBlok} />
            </SecondaryLink>
          ))}
        </div>
        <div className="flex flex-col">
          <div className="flex-row hidden md:flex mb-1.5">
            <a href="https://www.linkedin.com/company/piaggiofastforward" className="md:mr-5 lg:mr-7">
              <LinkedInIcon className="w-auto md:h-[17px] lg:h-[24px]" />
            </a>
            <a href="https://www.instagram.com/piaggiofastforward/?hl=en" className="md:mr-5 g:mr-7">
              <InstagramIcon className="w-auto md:h-[17px] lg:h-[24px]" />
            </a>
            <a href="https://twitter.com/piaggiofastfwd?lang=en" className="md:mr-5 lg:mr-7">
              <TwitterIcon className="w-auto md:h-[17px] lg:h-[24px]" />
            </a>
            <a href="https://www.youtube.com/c/PiaggioFastForward">
              <YoutubeIcon className="w-auto md:h-[17px] lg:h-[24px]" />
            </a>
          </div>

          <Link
            to="/business/contact"
            className="bg-cerulean-400 rounded-[28px] sm:min-w-[119px] md:min-w-[164px] lg:min-w-[168px]
            ml-auto mr-0 sm:ml-0 sm:mr-auto md:mt-7 md:mb-4 lg:mt-10 lg:mb-5 px-[1.125rem] md:px-5 py-[5px]
            font-brown font-bold tracking-[2.16px] md:tracking-[2.88px] text-beige-100
            text-[12px] sm:text-[10px] md:text-[14px] lg:text-[16px]
            leading-[26px] md:leading-[30px] hover:brightness-75 uppercase text-center"
          >
            Contact Us
          </Link>

          <div className="flex-row hidden sm:flex md:hidden mt-4 ml-auto mr-0 sm:ml-0 sm:mr-auto">
            <a href="https://www.linkedin.com/company/piaggiofastforward" className="mx-2">
              <LinkedInIcon className="w-auto h-[20px] sm:h-[15px]" />
            </a>
            <a href="https://www.instagram.com/piaggiofastforward/?hl=en" className="mx-2">
              <InstagramIcon className="w-auto h-[20px] sm:h-[15px]" />
            </a>
            <a href="https://twitter.com/piaggiofastfwd?lang=en" className="mx-2">
              <TwitterIcon className="w-auto h-[20px] sm:h-[15px]" />
            </a>
            <a href="https://www.youtube.com/c/PiaggioFastForward" className="mx-2">
              <YoutubeIcon className="w-auto h-[20px] sm:h-[15px]" />
            </a>
          </div>

          {/* PG logo */}
          <div className="my-3 hidden md:block">
            <a href="https://www.piaggiogroup.com" target="_blank" rel="noreferrer">
              <PiaggioGroupIcon alt="Piaggio Group Logo" className="md:w-[58px] lg:w-[92px] h-auto mb-2" />
            </a>
          </div>

          {/* Copyright */}
          <div
            className="md:text-[10px] md:leading-[160%] md:tracking-[1.8px]
              lg:text-[13px] lg:leading-[150%] lg:tracking-[2.34px]
              hidden md:block font-brown font-bold text-beige-300 uppercase"
          >
            &copy; {`${copyrightDate}`} {`${rightsText}`}
          </div>
        </div>
      </div>
      <div className="flex sm:hidden my-12 justify-center">
        <a href="https://www.linkedin.com/company/piaggiofastforward" className="mx-2">
          <LinkedInIcon className="w-auto h-[20px]" />
        </a>
        <a href="https://www.instagram.com/piaggiofastforward/?hl=en" className="mx-2">
          <InstagramIcon className="w-auto h-[20px]" />
        </a>
        <a href="https://twitter.com/piaggiofastfwd?lang=en" className="mx-2">
          <TwitterIcon className="w-auto h-[20px]" />
        </a>
        <a href="https://www.youtube.com/c/PiaggioFastForward" className="mx-2">
          <YoutubeIcon className="w-auto h-[20px]" />
        </a>
      </div>
      <div className="flex flex-wrap sm:hidden gap-x-[10%] gap-y-6 justify-between my-4 font-brown font-bold">
        {/* Secondary Links */}
        {mobileNavigationLinks.map((nestedBlok) => (
          <SecondaryLink key={nestedBlok._uid}>
            <StoryblokComponent blok={nestedBlok} />
          </SecondaryLink>
        ))}
      </div>
      <div className="grid grid-cols-[1fr_auto] sm:grid-cols-[minmax(100px,_28.126vw)_auto_120px] md:hidden mt-[1.875rem]">
        {/* Copyright */}
        <div className="mr-[9vw] sm:mr-[3vw]">
          <div className="font-brown font-bold text-beige-300 uppercase text-[8px] leading-[160%] tracking-[1.8px]">
            &copy; {`${copyrightDate}`} {`${rightsText}`}
          </div>
        </div>
        {/* PG logo */}
        <div className="sm:col-start-3">
          <a href="https://www.piaggiogroup.com" target="_blank" rel="noreferrer">
            <PiaggioGroupIcon alt="Piaggio Group Logo" className="w-auto h-[34px] mb-2" />
          </a>
        </div>
      </div>
    </footer>
  );
};

BusinessFooter.propTypes = {
  blok: object.isRequired,
};

export default BusinessFooter;
