import { css } from 'styled-components';
import { buttonSStyles, buttonLStyles } from './typography.js';
import { colors } from './variables.js';

// ===== Default Common CSS
// Default Button Size
export const defaultButtonS = css`
  ${buttonSStyles};
  border: 0.75px solid;
  border-radius: 20px;
  height: 35px;
`;
export const defaultButtonL = css`
  ${buttonLStyles};
  border: 0.75px solid;
  border-radius: 20px;
  height: 40px;
`;
export const defaultDisabled = css`
  opacity: 0.35;
`;
export const defaultSecondaryDisabled = css`
  ${defaultDisabled};
  background-color: transparent;
  stroke: ${colors.orange400};
  stroke-width: 0.75px;
`;

// Default Oranges
export const defaultOrangeFilled = css`
  background-color: ${colors.orange400};
  border-color: ${colors.orange400};
  color: ${colors.beige100};
`;
export const defaultOrangeHover = css`
  &:hover {
    background-color: ${colors.orange700};
  }
`;
export const defaultOrangeBorderOnly = css`
  background-color: transparent;
  border-color: ${colors.orange400};
  color: ${colors.orange400};
  stroke: ${colors.orange400};
  stroke-width: 0.75px;
`;
export const defaultOrangeBorderHover = css`
  &:hover {
    background-color: ${colors.orange400};
    color: ${colors.beige100};
    stroke: none;
  }
`;

// Default Blues
export const defaultBlueFilled = css`
  background-color: ${colors.cerulean400};
  border-color: ${colors.cerulean400};
  color: ${colors.beige100};
`;
export const defaultBlueHover = css`
  &:hover {
    background-color: ${colors.cerulean700};
  }
`;
export const defaultBlueBorderOnly = css`
  background-color: transparent;
  border-color: ${colors.cerulean400};
  color: ${colors.cerulean400};
  stroke: ${colors.cerulean400};
  stroke-width: 0.75px;
`;
export const defaultBlueBorderHover = css`
  &:hover {
    background-color: ${colors.cerulean400};
    color: ${colors.beige100};
    stroke: none;
  }
`;

// Default Thin & Thin Disabled
export const defaultThinS = css`
  ${defaultButtonS};
  width: 96px;
`;
export const defaultThinDisabledS = css`
  ${defaultThinS};
  ${defaultDisabled};
`;

export const defaultThinL = css`
  ${defaultButtonL};
  width: 138px;
`;
export const defaultThinDisabledL = css`
  ${defaultThinL};
  ${defaultDisabled};
`;

// Default Medium & Medium Disabled
export const defaultMediumS = css`
  ${defaultButtonS};
  width: 149px;
`;
export const defaultMediumDisabledS = css`
  ${defaultMediumS};
  ${defaultDisabled};
`;

export const defaultMediumL = css`
  ${defaultButtonL};
  width: 220px;
`;
export const defaultMediumDisabledL = css`
  ${defaultMediumL};
  ${defaultDisabled};
`;

// Default Wide & Wide Disabled
export const defaultWideS = css`
  ${defaultButtonS};
  width: 202px;
`;
export const defaultWideDisabledS = css`
  ${defaultWideS};
  ${defaultDisabled};
`;

export const defaultWideL = css`
  ${defaultButtonL};
  width: 300px;
`;
export const defaultWideDisabledL = css`
  ${defaultWideL};
  ${defaultDisabled};
`;

// ===== Primary
// Thin
export const btnSmPrimaryThinOrangeStyles = css`
  ${defaultThinS};
  ${defaultOrangeFilled};
  ${defaultOrangeHover};
`;
export const btnSmPrimaryThinDisabledOrangeStyles = css`
  ${defaultThinDisabledS};
  ${defaultOrangeFilled};
`;
export const btnSmPrimaryThinBlueStyles = css`
  ${defaultThinS};
  ${defaultBlueFilled};
  ${defaultBlueHover};
`;
export const btnSmPrimaryThinDisabledBlueStyles = css`
  ${defaultThinDisabledS};
  ${defaultBlueFilled};
`;

export const btnLgPrimaryThinOrangeStyles = css`
  ${defaultThinL};
  ${defaultOrangeFilled};
  ${defaultOrangeHover};
`;
export const btnLgPrimaryThinDisabledOrangeStyles = css`
  ${defaultThinDisabledL};
  ${defaultOrangeFilled};
`;
export const btnLgPrimaryThinBlueStyles = css`
  ${defaultThinL};
  ${defaultBlueFilled};
  ${defaultBlueHover};
`;
export const btnLgPrimaryThinDisabledBlueStyles = css`
  ${defaultThinDisabledL};
  ${defaultBlueFilled};
`;

// Medium
export const btnSmPrimaryMediumOrangeStyles = css`
  ${defaultMediumS};
  ${defaultOrangeFilled};
  ${defaultOrangeHover};
`;
export const btnSmPrimaryMediumDisabledOrangeStyles = css`
  ${defaultMediumDisabledS};
  ${defaultOrangeFilled};
`;
export const btnSmPrimaryMediumBlueStyles = css`
  ${defaultMediumS};
  ${defaultBlueFilled};
  ${defaultBlueHover};
`;
export const btnSmPrimaryMediumDisabledBlueStyles = css`
  ${defaultMediumDisabledS};
  ${defaultBlueFilled};
`;

export const btnLgPrimaryMediumOrangeStyles = css`
  ${defaultMediumL};
  ${defaultOrangeFilled};
  ${defaultOrangeHover}
`;
export const btnLgPrimaryMediumDisabledOrangeStyles = css`
  ${defaultMediumDisabledL};
  ${defaultOrangeFilled};
`;
export const btnLgPrimaryMediumBlueStyles = css`
  ${defaultMediumL};
  ${defaultBlueFilled};
  ${defaultBlueHover};
`;
export const btnLgPrimaryMediumDisabledBlueStyles = css`
  ${defaultMediumDisabledL};
  ${defaultBlueFilled};
`;

// Wide
export const btnSmPrimaryWideOrangeStyles = css`
  ${defaultWideS};
  ${defaultOrangeFilled};
  ${defaultOrangeHover};
`;
export const btnSmPrimaryWideDisabledOrangeStyles = css`
  ${defaultWideDisabledS};
  ${defaultOrangeFilled};
`;
export const btnSmPrimaryWideBlueStyles = css`
  ${defaultWideS};
  ${defaultBlueFilled};
  ${defaultBlueHover};
`;
export const btnSmPrimaryWideDisabledBlueStyles = css`
  ${defaultWideDisabledS};
  ${defaultBlueFilled};
`;

export const btnLgPrimaryWideOrangeStyles = css`
  ${defaultWideL};
  ${defaultOrangeFilled};
  ${defaultOrangeHover};
`;
export const btnLgPrimaryWideDisabledOrangeStyles = css`
  ${defaultWideDisabledL};
  ${defaultOrangeFilled};
`;
export const btnLgPrimaryWideBlueStyles = css`
  ${defaultWideL};
  ${defaultBlueFilled};
  ${defaultBlueHover};
`;
export const btnLgPrimaryWideDisabledBlueStyles = css`
  ${defaultWideDisabledL};
  ${defaultBlueFilled};
`;

// ===== Secondary
// Thin
export const btnSmSecondaryThinOrangeStyles = css`
  ${defaultThinS};
  ${defaultOrangeBorderOnly};
  ${defaultOrangeBorderHover};
`;
export const btnSmSecondaryThinDisabledOrangeStyles = css`
  ${defaultThinDisabledS};
  ${defaultOrangeBorderOnly};
  ${defaultSecondaryDisabled};
`;
export const btnSmSecondaryThinBlueStyles = css`
  ${defaultThinS};
  ${defaultBlueBorderOnly};
  ${defaultBlueBorderHover};
`;
export const btnSmSecondaryThinDisabledBlueStyles = css`
  ${defaultThinDisabledS};
  ${defaultBlueBorderOnly};
  ${defaultSecondaryDisabled};
`;

export const btnLgSecondaryThinOrangeStyles = css`
  ${defaultThinL};
  ${defaultOrangeBorderOnly};
  ${defaultOrangeBorderHover};
`;
export const btnLgSecondaryThinDisabledOrangeStyles = css`
  ${defaultThinDisabledL};
  ${defaultOrangeBorderOnly};
  ${defaultSecondaryDisabled};
`;
export const btnLgSecondaryThinBlueStyles = css`
  ${defaultThinL};
  ${defaultBlueBorderOnly};
  ${defaultBlueBorderHover};
`;
export const btnLgSecondaryThinDisabledBlueStyles = css`
  ${defaultThinDisabledL};
  ${defaultBlueBorderOnly};
  ${defaultSecondaryDisabled};
`;

// Medium
export const btnSmSecondaryMediumOrangeStyles = css`
  ${defaultMediumS};
  ${defaultOrangeBorderOnly};
  ${defaultOrangeBorderHover};
`;
export const btnSmSecondaryMediumDisabledOrangeStyles = css`
  ${defaultMediumDisabledS};
  ${defaultOrangeBorderOnly};
  ${defaultSecondaryDisabled};
`;
export const btnSmSecondaryMediumBlueStyles = css`
  ${defaultMediumS};
  ${defaultBlueBorderOnly};
  ${defaultBlueBorderHover};
`;
export const btnSmSecondaryMediumDisabledBlueStyles = css`
  ${defaultMediumDisabledS};
  ${defaultBlueBorderOnly};
  ${defaultSecondaryDisabled};
`;

export const btnLgSecondaryMediumOrangeStyles = css`
  ${defaultMediumL};
  ${defaultOrangeBorderOnly};
  ${defaultOrangeBorderHover};
`;
export const btnLgSecondaryMediumDisabledOrangeStyles = css`
  ${defaultMediumDisabledL};
  ${defaultOrangeBorderOnly};
  ${defaultSecondaryDisabled};
`;
export const btnLgSecondaryMediumBlueStyles = css`
  ${defaultMediumL};
  ${defaultBlueBorderOnly};
  ${defaultBlueBorderHover};
`;
export const btnLgSecondaryMediumDisabledBlueStyles = css`
  ${defaultMediumDisabledL};
  ${defaultBlueBorderOnly};
  ${defaultSecondaryDisabled};
`;

// Wide
export const btnSmSecondaryWideOrangeStyles = css`
  ${defaultWideS};
  ${defaultOrangeBorderOnly};
  ${defaultOrangeBorderHover};
`;
export const btnSmSecondaryWideDisabledOrangeStyles = css`
  ${defaultWideDisabledS};
  ${defaultOrangeBorderOnly};
  ${defaultSecondaryDisabled};
`;
export const btnSmSecondaryWideBlueStyles = css`
  ${defaultWideS};
  ${defaultBlueBorderOnly};
  ${defaultBlueBorderHover};
`;
export const btnSmSecondaryWideDisabledBlueStyles = css`
  ${defaultWideDisabledS};
  ${defaultBlueBorderOnly};
  ${defaultSecondaryDisabled};
`;

export const btnLgSecondaryWideOrangeStyles = css`
  ${defaultWideL};
  ${defaultOrangeBorderOnly};
  ${defaultOrangeBorderHover};
`;
export const btnLgSecondaryWideDisabledOrangeStyles = css`
  ${defaultWideDisabledL};
  ${defaultOrangeBorderOnly};
  ${defaultSecondaryDisabled};
`;
export const btnLgSecondaryWideBlueStyles = css`
  ${defaultWideL};
  ${defaultBlueBorderOnly};
  ${defaultBlueBorderHover};
`;
export const btnLgSecondaryWideDisabledBlueStyles = css`
  ${defaultWideDisabledL};
  ${defaultBlueBorderOnly};
  ${defaultSecondaryDisabled};
`;

// ===== Tertiary
export const defaultTertiaryS = css`
  ${buttonSStyles};
  background-color: transparent;
  border: none;
  width: fit-content;
`;

export const defaultTertiaryL = css`
  ${defaultTertiaryS};
  ${buttonLStyles};
`;

export const btnSmTertiaryOrangeStyles = css`
  // side & down arrow not included

  ${defaultTertiaryS};
  color: ${colors.orange400};
  &:hover {
    color: ${colors.orange700};
  }
`;
export const btnSmTertiaryDisabledOrangeStyles = css`
  // side & down arrow not included

  ${defaultTertiaryS};
  ${defaultDisabled};
  color: ${colors.orange400};
`;
export const btnSmTertiaryBlueStyles = css`
  // side & down arrow not included

  ${defaultTertiaryS};
  font-size: 10px;
  color: ${colors.cerulean400};

  &:hover {
    color: ${colors.cerulean700};
  }
`;
export const btnSmTertiaryDisabledBlueStyles = css`
  // side & down arrow not included

  ${defaultTertiaryS};
  font-size: 10px;
  ${defaultDisabled};
  color: ${colors.cerulean400};
`;

export const btnLgTertiaryOrangeStyles = css`
  // side & down arrow not included

  ${defaultTertiaryL};
  color: ${colors.orange400};
  &:hover {
    color: ${colors.orange700};
  }
`;
export const btnLgTertiaryDisabledOrangeStyles = css`
  // side & down arrow not included

  ${defaultTertiaryL};
  ${defaultDisabled};
  color: ${colors.orange400};
`;
export const btnLgTertiaryBlueStyles = css`
  // side & down arrow not included

  ${defaultTertiaryL};
  color: ${colors.cerulean400};
  &:hover {
    color: ${colors.cerulean700};
  }
`;
export const btnLgTertiaryDisabledBlueStyles = css`
  // side & down arrow not included

  ${defaultTertiaryL};
  ${defaultDisabled};
  color: ${colors.cerulean400};
`;
