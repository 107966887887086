import React, { useEffect, useState } from 'react';
import { Link, withPrefix } from 'gatsby';
import { any } from 'prop-types';
import { StoryblokComponent } from 'gatsby-source-storyblok';

// Styles
import styled from 'styled-components';
import { Row, Col } from 'react-styled-flexboxgrid';
import {
  bodyLBoldBrownStyles,
  bodySBoldBrownStyles,
  bodyXxsBoldBrownStyles,
  bodyLRegularUtopiaStyles,
  bodySRegularUtopiaStyles,
  bodyLItalicUtopiaStyles,
  bodySItalicUtopiaStyles,
} from '../../../styles/Web3.0/typography.js';
import { bpWidth, colors } from '../../../styles/Web3.0/variables';

// Components
import { calculateReadTime } from '../../../utils/calculateReadTime';
import Pagination from '../Pagination/Pagination';
import { renderPlainText, renderRichTextReact } from '../../../utils/storyblokRichText';
import { BLOG_POST_SORT_BY, CATEGORY_POSTS_PER_PAGE } from '../../../constants/blog';
import { fetchStoryblokStories } from '../../../hooks';
import { useRootState } from '../../../context/root-state.context';

// Elements
const CategoryPostsRow = styled(Row)`
  padding-top: 95px;
  padding-bottom: 80px;

  @media (${bpWidth.desktopSm}) {
    padding-top: 80px;
  }

  @media (${bpWidth.tablet}) {
    padding-top: 70px;
  }
`;

const CategoryPostsCol = styled(Col)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;

  .innerPostCol {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  @media (${bpWidth.mobile}) {
    flex-direction: column;
  }
`;

const PostContainer = styled.div`
  margin-bottom: 80px;
  width: 22.2vw;

  &:hover {
    .gatsby-image-wrapper.gatsby-image-wrapper-constrained {
      opacity: 0.4;
      -webkit-transition: -webkit-opacity 0.3s ease-out;
    }
  }
  a {
    text-decoration: none;
    cursor: pointer;
  }

  @media (${bpWidth.desktopSm}) {
    width: 26.6vw;
  }

  @media (${bpWidth.tablet}) {
    margin-bottom: 70px;
    width: 37.5vw;
  }

  @media (${bpWidth.mobile}) {
    padding: 0;
    width: 100%;
  }
`;

const PostBlock = styled.div`
  padding: 0 12px;

  @media (${bpWidth.desktopSm}) {
    padding: 0 10px;
  }

  @media (${bpWidth.tablet}) {
    padding: 0 8px;
  }

  @media (${bpWidth.mobile}) {
    padding: 0;
  }
`;

const ImageContainer = styled.div`
  background: ${({ isEmpty }) => (isEmpty ? colors.navy700 : undefined)};
  height: fit-content;
  margin-bottom: 10px;

  .gatsby-image-wrapper.gatsby-image-wrapper-constrained {
    max-height: 408px;
    min-height: 408px;
    width: 100%;
  }

  @media (${bpWidth.desktopSm}) {
    .gatsby-image-wrapper.gatsby-image-wrapper-constrained {
      max-height: 326px;
      min-height: 326px;
    }
  }

  @media (${bpWidth.tablet}) {
    .gatsby-image-wrapper.gatsby-image-wrapper-constrained {
      max-height: 278px;
      min-height: 278px;
    }
  }

  @media (${bpWidth.mobile}) {
    .gatsby-image-wrapper.gatsby-image-wrapper-constrained {
      max-height: 308px;
      min-height: 308px;
    }
  }
`;

const Thumbnail = styled.div`
  max-height: 408px;
  min-height: 408px;

  @media (${bpWidth.desktopSm}) {
    max-height: 326px;
    min-height: 326px;
  }

  @media (${bpWidth.tablet}) {
    max-height: 278px;
    min-height: 278px;
  }

  @media (${bpWidth.mobile}) {
    max-height: 308px;
    min-height: 308px;
  }
`;

const Copy = styled.div`
  display: flex;
  flex-direction: column;

  span.read-time {
    ${bodySBoldBrownStyles};
    color: ${colors.gray300};
    margin-bottom: 15px;
  }
  h1.heading {
    ${bodyLBoldBrownStyles};
    color: ${colors.navy600};
    margin-bottom: 10px;
  }
  h2.sub-heading {
    ${bodyLRegularUtopiaStyles};
    color: ${colors.navy600};
    margin-bottom: 10px;

    a,
    a:hover {
      color: ${colors.navy600};
      text-decoration: underline !important;
    }
  }
  div.summary {
    ${bodyLItalicUtopiaStyles};
    color: ${colors.navy600};

    a,
    a:hover {
      color: ${colors.navy600};
      text-decoration: underline !important;
    }
  }

  @media (${bpWidth.desktopSm}) {
    span.read-time {
      ${bodyXxsBoldBrownStyles};
      color: ${colors.gray300};
    }
    h1.heading {
      ${bodySBoldBrownStyles};
      color: ${colors.navy600};
    }
    h2.sub-heading {
      ${bodySRegularUtopiaStyles};
      color: ${colors.navy600};
    }
    div.summary {
      ${bodySItalicUtopiaStyles};
      color: ${colors.navy600};
    }
  }
  @media (${bpWidth.mobile}) {
    span.read-time {
      padding-bottom: 5px;
    }
    h1.heading,
    h2.sub-heading {
      padding-bottom: 5px;
    }
  }
`;

const PaginationCol = styled(Col)`
  padding: 0 12px;

  @media (${bpWidth.desktopSm}) {
    padding: 0 10px;
  }
  @media (${bpWidth.tablet}) {
    padding: 0 8px;
  }
  @media (${bpWidth.mobile}) {
    padding: 0;
  }
`;

// Props
const propTypes = {
  sbStories: any,
};
const defaultProps = {
  sbStories: null,
};

/**
 * @param {ISbStories} sbStories
 * @return {JSX.Element}
 * @constructor
 */
function CategoryPosts({ sbStories }) {
  /** @type {ISbStoryData[]} */
  const initialStories = [];
  const { categoryFullSlug, categoryFeaturedPostFullSlug } = useRootState();

  const [paginationClass, setPaginationClass] = useState('');
  const [stories, setStories] = useState(initialStories);
  const [perPage, setPerPage] = useState(CATEGORY_POSTS_PER_PAGE);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);

  // Pagination
  const scrollOnPaginationClick = () => {
    const headerHeight = document.getElementById('headerContainerRow').getBoundingClientRect().height;
    const featuredPostHeight = document.getElementById('FeaturedPostCol').getBoundingClientRect().height;
    const tagHeight = document.getElementById('categoryTag').getBoundingClientRect().height;
    const calculatedScrollPosition = featuredPostHeight - headerHeight - tagHeight;

    if (typeof window !== 'undefined') {
      window.scrollTo(0, calculatedScrollPosition);
    }
  };

  const goPreviousPosts = () => {
    /** @type {*} */
    const categoryPostsRequestParams = {
      starts_with: categoryFullSlug,
      is_startpage: false,
      per_page: perPage,
      page: currentPage - 1,
      sort_by: BLOG_POST_SORT_BY,
      excluding_slugs: categoryFeaturedPostFullSlug,
    };
    fetchStoryblokStories(categoryPostsRequestParams).then((_sbStories) => {
      setTotal(_sbStories.total);
      setPerPage(_sbStories.perPage);
      setStories(_sbStories.data.stories);
      setCurrentPage(categoryPostsRequestParams.page);
      scrollOnPaginationClick();
    });
  };

  const gpNextPosts = () => {
    /** @type {*} */
    const categoryPostsRequestParams = {
      starts_with: categoryFullSlug,
      is_startpage: false,
      per_page: perPage,
      page: currentPage + 1,
      sort_by: BLOG_POST_SORT_BY,
      excluding_slugs: categoryFeaturedPostFullSlug,
    };
    fetchStoryblokStories(categoryPostsRequestParams).then((_sbStories) => {
      setTotal(_sbStories.total);
      setPerPage(_sbStories.perPage);
      setStories(_sbStories.data.stories);
      setCurrentPage(categoryPostsRequestParams.page);
      scrollOnPaginationClick();
    });
  };

  useEffect(() => {
    setTotal(sbStories?.total || 0);
    setPerPage(sbStories?.perPage || CATEGORY_POSTS_PER_PAGE);
    setStories(sbStories?.data?.stories || []);
  }, []);

  useEffect(() => {
    const totalLoaded = currentPage * perPage;

    if (totalLoaded > perPage && totalLoaded < total) {
      setPaginationClass('bothCtas');
    } else if (totalLoaded > perPage && totalLoaded >= total) {
      setPaginationClass('prevCtaOnly');
    } else if (totalLoaded <= perPage && totalLoaded < total) {
      setPaginationClass('nextCtaOnly');
    }
  }, [perPage, currentPage, total]);

  return (
    <CategoryPostsRow>
      {/* Posts */}
      <CategoryPostsCol xsOffset={1} lgOffset={2} xs={6} md={8}>
        <div className="innerPostCol">
          {stories.map((story) => {
            const content = typeof story.content === 'string' ? JSON.parse(story.content) : story.content;
            const post = content?.body?.find((item) => item.component === 'post');
            if (!post) {
              return undefined;
            }

            const article = renderPlainText(post.article);
            const heading = renderRichTextReact(post.heading);
            const subHeading = renderRichTextReact(post.subHeading);
            const summary = renderRichTextReact(post.summary);
            const hasThumbnail = post.thumbnail?.length > 0;

            return (
              <PostContainer key={story.id}>
                <PostBlock>
                  <Link to={withPrefix(story.path || story.full_slug)}>
                    <ImageContainer isEmpty={!hasThumbnail}>
                      <Thumbnail>
                        {post.thumbnail?.map((nestedBlok) => (
                          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
                        ))}
                      </Thumbnail>
                    </ImageContainer>
                    <Copy>
                      {article && <span className="read-time">{calculateReadTime({ raw: article })}</span>}
                      {heading && <h1 className="heading">{heading}</h1>}
                      {subHeading && <h2 className="sub-heading">{subHeading}</h2>}
                      {summary && <div className="summary">{summary}</div>}
                    </Copy>
                  </Link>
                </PostBlock>
              </PostContainer>
            );
          })}
        </div>
      </CategoryPostsCol>

      {/* Pagination */}
      <PaginationCol xsOffset={1} lgOffset={2} xs={6} md={8}>
        <Row>
          <Pagination
            paginationClass={paginationClass}
            currentPage={currentPage}
            perPage={perPage}
            total={total}
            goPrevious={goPreviousPosts}
            goNext={gpNextPosts}
          />
        </Row>
      </PaginationCol>
    </CategoryPostsRow>
  );
}

CategoryPosts.propTypes = propTypes;
CategoryPosts.defaultProps = defaultProps;
export default React.memo(CategoryPosts);
