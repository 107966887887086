import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { Col } from 'react-styled-flexboxgrid';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import { bool, number, object } from 'prop-types';
import styled, { css } from 'styled-components';
import { bpWidthTailwind, colors } from '../../../styles/Web3.0/variables';
import { bodyLRegularBrownStyles, bodyMRegularBrownStyles } from '../../../styles/Web3.0/typography';
import { renderRichTextReact } from '../../../utils/storyblokRichText';
import { btnSmPrimaryMediumBlueStyles, btnSmPrimaryMediumOrangeStyles } from '../../../styles/Web3.0/buttons';
import ArrowDiagonalUpRightIcon from '../../../img/cta-arrow-diagonal-up-right.svg';

const LogoImg = styled.div``;

const ProductImg = styled.div`
  ${({ index }) => {
    if (index === 0) {
      return css`
        --pff-b2b-product-img-object-position: 40%;
        --pff-b2b-product-img-scale: 1.15;
        --pff-b2b-product-img-transform-x: 13rem;
        --pff-b2b-product-img-transform-y: 27rem;
      `;
    }
    if (index === 1) {
      return css`
        --pff-b2b-product-img-scale: 1;
      `;
    }
    if (index === 2) {
      return css`
        --pff-b2b-product-img-object-position: 10%;
        --pff-b2b-product-img-scale: 1.2;
        --pff-b2b-product-img-transform-x: 60%;
        --pff-b2b-product-img-transform-y: 32rem;
      `;
    }

    return undefined;
  }};
  --pff-b2b-product-img-height: 32rem;
  user-select: none;

  .gatsby-image-wrapper {
    user-select: none;

    img {
      height: var(--pff-b2b-product-img-height);
      object-position: var(--pff-b2b-product-img-object-position);
      transform: translateZ(0) scale(var(--pff-b2b-product-img-scale));
      transform-origin: var(--pff-b2b-product-img-transform-x) var(--pff-b2b-product-img-transform-y);
      transition: object-position 0.5s ease-in-out, transform 0.5s ease-in-out, transform-origin 0.5s ease-in-out;
    }
  }

  @media (${bpWidthTailwind.tablet}) {
    ${({ index }) => {
      if (index === 0) {
        return css`
          --pff-b2b-product-img-transform-y: 27rem;
        `;
      }
      if (index === 2) {
        return css`
          --pff-b2b-product-img-transform-y: 20rem;
        `;
      }

      return undefined;
    }};
    --pff-b2b-product-img-height: 20rem;
  }

  @media (${bpWidthTailwind.desktopSm}) {
    ${({ index }) => {
      if (index === 0) {
        return css`
          --pff-b2b-product-img-scale: 1.1;
        `;
      }
      if (index === 2) {
        return css`
          --pff-b2b-product-img-object-position: 10%;
          --pff-b2b-product-img-scale: 1.2;
        `;
      }

      return undefined;
    }};
    --pff-b2b-product-img-height: 27rem;
  }

  @media (${bpWidthTailwind.desktopLg}) {
    ${({ index }) => {
      if (index === 0) {
        return css`
          --pff-b2b-product-img-transform-x: 22.5vw;
        `;
      }
      if (index === 2) {
        return css`
          --pff-b2b-product-img-scale: 1.17;
          --pff-b2b-product-img-transform-x: 13vw;
          --pff-b2b-product-img-transform-y: 100%;
        `;
      }

      return undefined;
    }};
    --pff-b2b-product-img-height: 35rem;
  }

  &:hover {
    ${({ index }) => {
      if (index === 0) {
        return css`
          --pff-b2b-product-img-object-position: 51%;
          --pff-b2b-product-img-scale: 1.4;
          --pff-b2b-product-img-transform-y: 19rem;
        `;
      }
      if (index === 1) {
        return css`
          --pff-b2b-product-img-object-position: 66%;
          --pff-b2b-product-img-scale: 1.4;
        `;
      }
      if (index === 2) {
        return css`
          --pff-b2b-product-img-scale: 1.5;
          --pff-b2b-product-img-transform-y: 85%;
        `;
      }

      return undefined;
    }};
  }
`;

const ProductImgCover = styled.div`
  background: linear-gradient(180deg, rgba(3, 37, 61, 0) 0%, ${colors.navy700} 123.62%);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
`;

const Copy = styled.div``;

const Body = styled.div`
  ${bodyMRegularBrownStyles};
  color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};

  @media (${bpWidthTailwind.desktopSm}) {
    ${bodyLRegularBrownStyles};
    color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
  }
`;

const Description = styled(Body)``;

const Specifications = styled(Body)``;

const CTALearnMore = styled.span`
  ${({ darkMode }) => (darkMode ? btnSmPrimaryMediumOrangeStyles : btnSmPrimaryMediumBlueStyles)};
  display: flex;
  gap: 7px;
  align-items: center;
  justify-content: center;
  width: 9.5rem;
  height: 2.25rem;
  border-radius: 1.125rem;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
`;

const BusinessProductListItem = ({ blok, darkMode = false, index = 0 }) => {
  const { logo, image, cta } = blok;
  const [richTextDescription, setRichTextDescription] = useState(null);
  const [richTextSpecifications, setRichTextSpecifications] = useState(null);

  useEffect(() => {
    setRichTextDescription(renderRichTextReact(blok.description));
  }, [blok.description]);

  useEffect(() => {
    setRichTextSpecifications(renderRichTextReact(blok.specifications));
  }, [blok.specifications]);

  return (
    <Col {...storyblokEditable(blok)} xs={8} md={10} lg={12}>
      <div className="hidden md:block">
        {/* Product Image */}
        {image?.length > 0 && (
          <ProductImg className="relative h-[27rem] lg:h-[35rem]" index={index}>
            <div className="absolute w-full h-full z-10 bg-[#D9D9D9]" />
            {image.map((nestedBlok) => (
              <div key={nestedBlok._uid} className="absolute w-full h-full z-10">
                <StoryblokComponent blok={nestedBlok} />
              </div>
            ))}
            <ProductImgCover />
            {/* Logo */}
            {logo?.map((nestedBlok) => (
              <LogoImg key={nestedBlok._uid} className="absolute bottom-6 left-5 max-w-[90%] z-20">
                <StoryblokComponent blok={nestedBlok} />
              </LogoImg>
            ))}
          </ProductImg>
        )}

        {cta?.url && (
          <div className="mt-9">
            <Link to={cta.url} className="inline-block">
              <CTALearnMore darkMode={darkMode}>
                <span>LEARN MORE</span>
                <span className="inline-flex mx-0 my-auto">
                  <ArrowDiagonalUpRightIcon />
                </span>
              </CTALearnMore>
            </Link>
          </div>
        )}
        <Copy className="mt-9 flex flex-col gap-6">
          {richTextDescription && <Description darkMode={darkMode}>{richTextDescription}</Description>}
          {richTextSpecifications && <Specifications darkMode={darkMode}>{richTextSpecifications}</Specifications>}
        </Copy>
      </div>
      <div className="hidden sm:flex flex-wrap md:hidden items-end gap-x-[3.5rem] gap-y-4">
        {/* Product Image */}
        {image?.length > 0 && (
          <ProductImg className="relative w-full max-w-[269px] h-[20rem] shrink-0" index={index}>
            <div className="absolute w-full h-[20rem] bottom-0 left-0 z-10 bg-[#D9D9D9]" />
            {image.map((nestedBlok) => (
              <div key={nestedBlok._uid} className="absolute w-full h-[20rem] top-0 right-0 bottom-0 left-0 z-10">
                <StoryblokComponent blok={nestedBlok} />
              </div>
            ))}
            <ProductImgCover className="absolute w-full h-[20rem] top-0 right-0 bottom-0 left-0 z-10" />
            {/* Logo */}
            {logo?.map((nestedBlok) => (
              <LogoImg key={nestedBlok._uid} className="absolute bottom-6 left-5 max-w-[85%] z-20">
                <StoryblokComponent blok={nestedBlok} />
              </LogoImg>
            ))}
          </ProductImg>
        )}

        <div className="flex-1 flex flex-col mb-7">
          <Copy className="flex flex-col gap-6">
            {richTextDescription && <Description darkMode={darkMode}>{richTextDescription}</Description>}
            {richTextSpecifications && <Specifications darkMode={darkMode}>{richTextSpecifications}</Specifications>}
          </Copy>
          {cta?.url && (
            <div className="mt-9">
              <Link to={cta.url} className="inline-block">
                <CTALearnMore darkMode={darkMode}>
                  <span>LEARN MORE</span>
                  <span className="inline-flex mx-0 my-auto">
                    <ArrowDiagonalUpRightIcon />
                  </span>
                </CTALearnMore>
              </Link>
            </div>
          )}
        </div>
      </div>
      <div className="sm:hidden">
        {image?.length > 0 && (
          <ProductImg className="relative h-[32rem]" index={index}>
            <div className="absolute w-full h-full bottom-0 left-0 z-10 bg-[#D9D9D9]" />
            {image.map((nestedBlok) => (
              <div key={nestedBlok._uid} className="absolute w-full h-full top-0 right-0 bottom-0 left-0 z-10">
                <StoryblokComponent blok={nestedBlok} />
              </div>
            ))}
            <ProductImgCover className="absolute w-full h-full top-0 right-0 bottom-0 left-0 z-10" />
            <div className="absolute flex flex-col items-center text-center right-9 bottom-10 left-9 z-20">
              {/* Logo */}
              {logo?.map((nestedBlok) => (
                <LogoImg key={nestedBlok._uid} className="">
                  <StoryblokComponent blok={nestedBlok} />
                </LogoImg>
              ))}
              <Copy className="mt-9 flex flex-col gap-6">
                {richTextDescription && <Description darkMode={darkMode}>{richTextDescription}</Description>}
                {richTextSpecifications && (
                  <Specifications darkMode={darkMode}>{richTextSpecifications}</Specifications>
                )}
              </Copy>
              {cta?.url && (
                <div className="mt-9">
                  <Link to={cta.url} className="inline-block">
                    <CTALearnMore darkMode={darkMode}>
                      <span>LEARN MORE</span>
                      <span>
                        <ArrowDiagonalUpRightIcon />
                      </span>
                    </CTALearnMore>
                  </Link>
                </div>
              )}
            </div>
          </ProductImg>
        )}
      </div>
    </Col>
  );
};

BusinessProductListItem.propTypes = {
  blok: object.isRequired,
  darkMode: bool,
  index: number,
};

export default BusinessProductListItem;
