import React from 'react';
import { Col } from 'react-styled-flexboxgrid';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import { object } from 'prop-types';
import styled from 'styled-components';
import { colors, sizes } from '../../../styles/Web3.0/variables';
import { bodyMSemiBoldUtopiaStyles, bodySSemiBoldUtopiaStyles } from '../../../styles/Web3.0/typography';
import { useViewport } from '../../../context/viewport.context';
import { renderRichTextReact } from '../../../utils/storyblokRichText';

const TableEl = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .thead,
  .tbody,
  .tr {
    flex: 1;
    display: flex;
  }

  .thead,
  .tbody {
    flex-direction: column;
  }

  .tr {
    justify-content: space-between;
  }

  .th,
  .td {
    flex: 1;
    border: 1px solid ${colors.gray200};
    border-right: none;
    padding: 12px;

    &:last-of-type {
      border-right: 1px solid ${colors.gray200};
    }
  }

  .thead {
    .th {
      ${bodyMSemiBoldUtopiaStyles};
    }
  }

  .tbody {
    .tr {
      .td {
        border-top: none;
      }
    }
  }

  p {
    margin-bottom: 30px;

    :last-of-type {
      margin-bottom: 0;
    }
  }
`;

const TabletTableEl = styled.div`
  border-top: 1px solid ${colors.gray200};
  padding-bottom: 30px;

  &:last-of-type {
    border-bottom: 1px solid ${colors.gray200};
  }

  h4 {
    ${bodySSemiBoldUtopiaStyles};
    font-style: italic;
    margin-top: 30px;
  }

  p {
    margin-bottom: 0 !important;
  }

  a {
    text-decoration: none;
    color: ${colors.cerulean400};
  }
`;

const TableData = ({ blok }) => {
  const { thead = [], tbody = [] } = blok;
  const { viewWidth } = useViewport();

  return (
    <Col {...storyblokEditable(blok)} xs={8} md={10} lg={12}>
      {(viewWidth >= sizes.containerMinWidth.desktopLg || viewWidth >= sizes.containerMinWidth.desktopSm) && (
        <TableEl>
          <div className="thead">
            <div className="tr">
              {thead.map((th) => (
                <StoryblokComponent blok={th} key={th._uid} />
              ))}
            </div>
          </div>
          <div className="tbody">
            {tbody.map((tr) => (
              <StoryblokComponent blok={tr} key={tr._uid} />
            ))}
          </div>
        </TableEl>
      )}
      {viewWidth < sizes.containerMinWidth.desktopSm &&
        (viewWidth >= sizes.containerMinWidth.tablet || viewWidth >= sizes.containerMinWidth.mobile) &&
        tbody.map((tr) => {
          return (
            <TabletTableEl key={tr._uid}>
              {tr.cells?.map((td, idx) => (
                <>
                  <h4>{renderRichTextReact(thead[idx]?.value)}</h4>
                  <p>{renderRichTextReact(td.value)}</p>
                </>
              ))}
            </TabletTableEl>
          );
        })}
    </Col>
  );
};

TableData.propTypes = {
  blok: object.isRequired,
};

export default TableData;
