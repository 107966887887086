// Calculate the post's average reading time
export const calculateReadTime = (content) => {
  const wpm = 60;
  const text = content.raw.toString();
  const words = text.trim().split(/\s+/).length;
  const time = Math.ceil(words / wpm);

  let averageTime = '';

  if (time > 60) {
    averageTime = averageTime + ' ' + Math.floor(time / 60);

    // Calculate hours
    if (Math.floor(time / 60) > 1) {
      averageTime += ' hrs';
    } else {
      averageTime += ' hr';
    }

    // Calculate minutes
    if (time % 60 !== 0) {
      averageTime = averageTime + ' ' + (time % 60) + ' min';
    }

    averageTime += ' read';
  } else {
    averageTime = time + ' min read';
  }

  return averageTime;
};
