import React, { useEffect, useState } from 'react';
import { object } from 'prop-types';
import { Col, Row } from 'react-styled-flexboxgrid';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import styled from 'styled-components';
import {
  bodyLItalicUtopiaStyles,
  bodyLRegularUtopiaStyles,
  bodyMRegularUtopiaStyles,
  bodySRegularUtopiaStyles,
  bodyXlItalicUtopiaStyles,
  bodyXxlItalicUtopiaStyles,
  eyebrowLStyles,
  eyebrowSStyles,
  headerSStyles,
  headerXsStyles,
} from '../../../styles/Web3.0/typography';
import { bpWidth, colors } from '../../../styles/Web3.0/variables';
import { renderRichTextReact } from '../../../utils/storyblokRichText';
import { useIsMounted } from '../../../hooks';

const HeadingRow = styled(Row)`
  margin-bottom: 50px;

  > ${Col} {
    flex: 1;
  }

  h4.heading {
    ${headerSStyles};
    margin-bottom: 15px;
  }

  div.description {
    ${bodyLRegularUtopiaStyles};
  }

  @media (${bpWidth.desktopSm}) {
    margin-bottom: 30px;

    h4.heading {
      ${headerXsStyles};
      margin-bottom: 10px;
    }

    div.description {
      ${bodyMRegularUtopiaStyles};
    }
  }

  @media (${bpWidth.mobile}) {
    div.description {
      ${bodySRegularUtopiaStyles};
    }
  }
`;

const PositionRow = styled(Row)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 30px;
  column-gap: 20px;
  margin-bottom: 70px;

  a {
    ${eyebrowLStyles};
    line-height: 1.18;
    background: ${colors.beige300};
    border-radius: 8px;
    display: flex;
    min-height: 45px;
    padding: 33px 48px 32px;
    text-decoration: none;
    align-items: center;

    &:hover {
      background: ${colors.beige500};
    }
  }

  @media (${bpWidth.desktopSm}) {
    a {
      ${eyebrowSStyles};
      line-height: 1.2;
      min-height: 32px;
      padding: 24px 22px;
    }
  }

  @media (${bpWidth.tablet}) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 15px;
  }

  @media (${bpWidth.mobile}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const ContactRow = styled(Row)`
  ${bodyMRegularUtopiaStyles};

  b {
    font-weight: 700;
  }

  a {
    color: ${colors.cerulean400};
    text-decoration: none;
  }

  @media (${bpWidth.desktopSm}) {
    ${bodySRegularUtopiaStyles};
  }
`;

const SocialRow = styled(Row)`
  margin-top: 100px;
  text-align: center;

  h3.title {
    ${bodyXxlItalicUtopiaStyles};
    margin-bottom: 10px;
  }

  div.description {
    ${bodyLRegularUtopiaStyles};
    margin-bottom: 40px;
  }

  div.btn-social {
    padding: 0 50px;
  }

  @media (${bpWidth.desktopSm}) {
    h3.title {
      ${bodyXlItalicUtopiaStyles};
    }

    div.description {
      ${bodySRegularUtopiaStyles};
    }
  }

  @media (${bpWidth.mobile}) {
    h3.title {
      ${bodyLItalicUtopiaStyles};
    }
  }
`;

const Vacancies = ({ blok }) => {
  const [positions, setPositions] = useState([]);
  const isMounted = useIsMounted();

  const fetchDataFromGreenhouse = () => {
    const url = 'https://api.greenhouse.io/v1/boards/piaggiofastforward/jobs?content=true';
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (isMounted()) {
          setPositions(() => data?.jobs || []);
        }
      })
      .catch((error) => new Error(error));
  };

  useEffect(() => {
    if (!blok.hideVacancies) {
      fetchDataFromGreenhouse();
    }
  }, [blok.hideVacancies, isMounted]);

  return (
    <Col {...storyblokEditable(blok)} xs={8} md={10} lg={12}>
      <HeadingRow>
        <Col>
          <h4 id="positions" className="heading">
            {blok.heading}
          </h4>
          <div className="description">{renderRichTextReact(blok.description)}</div>
        </Col>
      </HeadingRow>
      {!blok.hideVacancies && (
        <PositionRow>
          {positions.map((position) => {
            return position?.title && position?.absolute_url ? (
              <Col key={position.absolute_url}>
                <a href={position.absolute_url} target="_blank" rel="noreferrer">
                  {position.title}
                </a>
              </Col>
            ) : undefined;
          })}
        </PositionRow>
      )}
      <ContactRow>
        <Col xs={8} sm={5} md={4} lg={4}>
          {renderRichTextReact(blok.contactText)}
        </Col>
      </ContactRow>
      <SocialRow center="xs">
        <Col>
          <h3 className="title">{blok.socialHeading}</h3>
          <div className="description">{renderRichTextReact(blok.socialDescription)}</div>
          <div className="cta">
            {blok.socialCta?.map((nestedBlok) => (
              <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
          </div>
        </Col>
      </SocialRow>
    </Col>
  );
};

Vacancies.propTypes = {
  blok: object.isRequired,
};

export default Vacancies;
