import React, { useCallback } from 'react';
import { object } from 'prop-types';
import { NODE_IMAGE } from 'storyblok-rich-text-react-renderer';

// Styles
import styled from 'styled-components';
import { Row, Col } from 'react-styled-flexboxgrid';
import {
  headerXsStyles,
  headerXxsStyles,
  bodyMRegularUtopiaStyles,
  bodySRegularUtopiaStyles,
  bodyLSemiBoldUtopiaStyles,
  bodyMSemiBoldUtopiaStyles,
  bodySSemiBoldUtopiaStyles,
  bodyMItalicUtopiaStyles,
  bodySItalicUtopiaStyles,
} from '../../../styles/Web3.0/typography.js';
import { bpWidth, colors } from '../../../styles/Web3.0/variables.js';
import { renderRichTextReact } from '../../../utils/storyblokRichText';
import { useViewport } from '../../../context/viewport.context';
import { useIsClient } from '../../../hooks';
import EmbeddedVideo from '../singleElements/EmbeddedVideo';
import EmbeddedImage from '../singleElements/EmbeddedImage';

// Components

// Elements
const PostBodyRow = styled(Row)`
  margin-top: 62px;

  @media (${bpWidth.desktopSm}) {
    margin-top: 64px;
  }
`;
const PostBodyCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 32px;

  h2 {
    ${headerXsStyles};
    margin-bottom: 16px;
  }
  h3 {
    ${bodyLSemiBoldUtopiaStyles};
    margin-bottom: 13px;
  }
  h4 {
    ${bodyMSemiBoldUtopiaStyles};
    margin-bottom: 32px;
  }
  p {
    ${bodyMRegularUtopiaStyles};
    margin-bottom: 32px;

    &:last-of-type {
      margin-bottom: 32px;
    }
  }
  ul {
    list-style-type: disc;
    list-style-position: outside;
    margin-bottom: 0;
  }
  ol {
    list-style-type: number;
    list-style-position: outside;
    margin-bottom: 0;
  }
  ul,
  ol {
    li {
      color: ${colors.navy600};
    }
  }
  blockquote {
    margin-bottom: 16px;
    border-left: 2px solid ${colors.navy600};
    margin-left: -0.75em;
    padding-left: 0.5em;

    p {
      ${bodyMItalicUtopiaStyles};
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
    a:hover {
      font-style: normal;
    }
    code {
      color: ${colors.orange400};
    }
  }
  h2,
  h3,
  h4,
  p {
    color: ${colors.navy600};
  }
  a {
    color: ${colors.cerulean400};
    text-decoration: none;

    &:hover {
      font-style: italic;
    }
  }
  img {
    width: 100%;
  }
  p.gifs.standardWidth img {
    max-width: 100%;
  }
  & > *:last-child {
    margin-bottom: 0;
  }

  // Aligning elements to the grid
  & > * {
    width: 33.3%;
  }

  @media (${bpWidth.desktopSm}) {
    h2 {
      ${headerXxsStyles};
      margin-bottom: 20px;
    }
    h4 {
      ${bodySSemiBoldUtopiaStyles};
      margin-bottom: 15px;
    }
    p {
      ${bodySRegularUtopiaStyles};
    }
    blockquote {
      p {
        ${bodySItalicUtopiaStyles};
      }
    }

    // Aligning elements to the grid
    & > * {
      width: 40%;
    }
  }

  @media (${bpWidth.tablet}) {
    // Aligning elements to the grid
    & > * {
      width: 75%;
    }
  }
`;

// Props
const propTypes = {
  postBodyData: object,
};

const defaultProps = {};

function PostBodyContent(props) {
  const { postBodyData } = props;
  const { viewWidth } = useViewport();
  const isClient = useIsClient();

  const handleEmbeddedVideo = (embeddedVideoBlok) => <EmbeddedVideo blok={embeddedVideoBlok} />;
  const handleEmbeddedImage = (embeddedImageBlok) => <EmbeddedImage blok={embeddedImageBlok} />;

  const handleNodeImage = useCallback(
    /**
     * @param {ReactNode} children
     * @param {{ alt?: string; title?: string; src?: string; }} imageProps
     * @return {JSX.Element | null}
     */
    (children, imageProps) => {
      if (!isClient) {
        return undefined;
      }

      const { alt, title, src } = imageProps;
      const imageWidth = viewWidth > 800 ? 800 : viewWidth;
      const qualitySrc = `${src}/m/${imageWidth}x0/smart/filters:quality(50)`;
      const avifSrc = `${qualitySrc}:format(avif)`;
      const webpSrc = `${qualitySrc}:format(webp)`;

      return (
        <picture>
          <source type="image/avif" srcSet={avifSrc} />
          <source type="image/webp" srcSet={webpSrc} />
          <img alt={alt} title={title} loading="lazy" src={qualitySrc} />
        </picture>
      );
    },
    [isClient, viewWidth]
  );

  /** @type {RenderOptions} */
  const renderOptions = {
    blokResolvers: {
      embeddedVideo: handleEmbeddedVideo,
      embeddedImage: handleEmbeddedImage,
    },
    nodeResolvers: {
      [NODE_IMAGE]: handleNodeImage,
    },
  };

  return (
    <PostBodyRow>
      <PostBodyCol xs={8} md={10} lg={12}>
        {renderRichTextReact(postBodyData, renderOptions)}
      </PostBodyCol>
    </PostBodyRow>
  );
}

PostBodyContent.propTypes = propTypes;
PostBodyContent.defaultProps = defaultProps;
export default PostBodyContent;
