import React from 'react';
import PropTypes from 'prop-types';
import GlobalStyles from '../../styles/Web3.0/globalStyles';
import '../../styles/Web3.0/fonts/brown.css';
import '../../styles/Web3.0/utopia.css';
import BusinessAppStateProvider from '../../context/business-app-state.context';
import BusinessLayout from './layouts/BusinessLayout';

const BusinessPage = ({ blok }) => {
  return (
    <BusinessAppStateProvider>
      <GlobalStyles dark />
      <BusinessLayout blok={blok} />
    </BusinessAppStateProvider>
  );
};

BusinessPage.propTypes = {
  blok: PropTypes.any.isRequired,
};

BusinessPage.defaultProps = {};

export default BusinessPage;
