import React from 'react';
import PropTypes from 'prop-types';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import styled from 'styled-components';
import { Col, Row } from 'react-styled-flexboxgrid';
import Slider from 'react-slick';
import { bpWidthTailwind, colors } from '../../../styles/Web3.0/variables';
import {
  bodyLRegularBrownStyles,
  bodyMRegularBrownStyles,
  eyebrowLStyles,
  eyebrowMStyles,
  headerMStyles,
  headerSStyles,
  headerXlFillStyles,
  headerXsStyles,
  headerXxsStyles,
} from '../../../styles/Web3.0/typography';
import { renderRichTextReact } from '../../../utils/storyblokRichText';

const TilesCol = styled(Col)``;

const TileSlider = styled(Slider)`
  .slick-arrow {
    bottom: -60px;
    top: unset;
    transform: unset;
    left: unset;
    width: 34px;
    height: 34px;
    background: ${colors.beige700};
    border-radius: 50%;
  }

  .slick-prev {
    right: 50px;
  }

  .slick-next {
    right: 0;
  }

  .slick-prev.slick-disabled,
  .slick-next.slick-disabled {
    opacity: 0.5;
  }

  .slick-prev:before,
  .slick-next:before {
    font-family: ui-sans-serif, system-ui, sans-serif;
    font-size: 30px;
    color: #000;
    opacity: 1;
  }

  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    background: ${colors.beige700};
  }

  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 1;
  }

  .tile-slider-container {
    img {
      border-top-right-radius: 1rem;
      border-top-left-radius: 1rem;
    }
  }
`;

const Title = styled.div`
  ${headerXxsStyles};
  color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};

  @media (${bpWidthTailwind.desktopSm}) {
    ${headerSStyles};
    color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
  }
`;

const Content = styled.div`
  ${bodyMRegularBrownStyles};
  color: ${({ darkMode }) => (darkMode ? colors.orange200 : undefined)};

  @media (${bpWidthTailwind.desktopSm}) {
    ${bodyLRegularBrownStyles};
    color: ${({ darkMode }) => (darkMode ? colors.orange200 : undefined)};
  }
`;

const StatisticsTitle = styled.div`
  ${eyebrowMStyles};
  color: ${({ darkMode }) => (darkMode ? colors.cerulean100 : undefined)};

  @media (${bpWidthTailwind.desktopSm}) {
    ${eyebrowLStyles};
    color: ${({ darkMode }) => (darkMode ? colors.cerulean100 : undefined)};
    flex-basis: 100%;
  }
`;
const StatisticsData = styled.div`
  ${headerMStyles};
  color: ${({ darkMode }) => (darkMode ? colors.cerulean100 : undefined)};

  @media (${bpWidthTailwind.desktopSm}) {
    ${headerXlFillStyles};
    color: ${({ darkMode }) => (darkMode ? colors.cerulean100 : undefined)};
  }
`;
const StatisticsDescription = styled.div`
  ${headerXsStyles};
  color: ${({ darkMode }) => (darkMode ? colors.cerulean400 : undefined)};
  font-size: 16px;

  @media (${bpWidthTailwind.desktopSm}) {
    ${headerXsStyles};
    color: ${({ darkMode }) => (darkMode ? colors.cerulean400 : undefined)};
    flex: 1;
  }
`;

const Tiles = ({ blok }) => {
  const { tiles, enabledAnimation = false, darkMode = false } = blok;

  const settings = {
    dots: false,
    infinite: false,
    slidesToScroll: 1,
    arrows: true,
    fade: false,
    swipe: true,
    touchMove: true,
    swipeToSlide: true,
    focusOnSelect: true,
  };

  return (
    <TilesCol {...storyblokEditable(blok)} xs={8} md={10} lg={12}>
      <div className="hidden sm:block">
        {tiles?.map((nestedBlok) => (
          <Row key={nestedBlok._uid}>
            <StoryblokComponent blok={nestedBlok} enabledAnimation={enabledAnimation} darkMode={darkMode} />
          </Row>
        ))}
      </div>
      <div className="tiles-slider-container sm:hidden mt-12">
        <TileSlider {...settings}>
          {[...Array((tiles?.length || 0) * 2)].map((value, index) => {
            const isEven = index % 2 === 0;
            const tile = tiles[Math.floor(index / 2)];

            const titleRichText = renderRichTextReact(tile?.title);
            const contentRichText = renderRichTextReact(tile?.content);
            const statisticsTitleRichText = renderRichTextReact(tile?.statisticsTitle);
            const statisticsDataRichText = renderRichTextReact(tile?.statisticsData);
            const statisticsDescriptionRichText = renderRichTextReact(tile?.statisticsDescription);
            const key = `${tile._uid}-${index}`;

            return isEven ? (
              <div
                key={key}
                className="tile-slider-container sm:hidden w-full h-[23rem] sm:h-[36rem]
                bg-gradient-to-b from-[#0e598e] from-[7.3%] to-[#041928] to-[83.47%]
                rounded-2xl border-2 border-solid border-navy-100"
              >
                {tile?.image?.map((nestedBlok) => (
                  <Row key={nestedBlok._uid}>
                    <StoryblokComponent blok={nestedBlok} />
                  </Row>
                ))}
                <div className="flex flex-col gap-2 px-7 py-5">
                  <div className="flex">
                    {statisticsTitleRichText && (
                      <StatisticsTitle darkMode={darkMode}>{statisticsTitleRichText}</StatisticsTitle>
                    )}
                  </div>
                  <div className="flex gap-x-7 items-center">
                    {statisticsDataRichText && (
                      <StatisticsData darkMode={darkMode}>{statisticsDataRichText}</StatisticsData>
                    )}
                    {statisticsDescriptionRichText && (
                      <StatisticsDescription darkMode={darkMode}>{statisticsDescriptionRichText}</StatisticsDescription>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div
                key={key}
                className="sm:hidden w-full h-[23rem] sm:h-[36rem]
                bg-gradient-to-b from-[#0e598e] from-[7.3%] to-[#041928] to-[83.47%]
                rounded-2xl border-2 border-solid border-navy-100"
              >
                <div className="flex flex-col h-full gap-5 justify-end px-7 py-6">
                  {titleRichText && <Title darkMode={darkMode}>{titleRichText}</Title>}
                  {contentRichText && <Content darkMode={darkMode}>{contentRichText}</Content>}
                </div>
              </div>
            );
          })}
        </TileSlider>
      </div>
    </TilesCol>
  );
};

Tiles.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default Tiles;
