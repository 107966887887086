import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import { object } from 'prop-types';
import styled from 'styled-components';
import { Link, withPrefix } from 'gatsby';
import _ from 'lodash';
import AnimateHeight from 'react-animate-height';
import ArrowLNavy from '../../../icons/Web3.0/arrow-LNavy.svg';
import {
  btnLgPrimaryMediumDisabledOrangeStyles,
  btnLgPrimaryMediumOrangeStyles,
  btnSmPrimaryMediumDisabledOrangeStyles,
  btnSmPrimaryMediumOrangeStyles,
} from '../../../styles/Web3.0/buttons';
import { SHOP_URL } from '../../../constants';
import { bpWidth, colors } from '../../../styles/Web3.0/variables';
import { spacerSStyles } from '../../../styles/Web3.0/structureSpacing';
import {
  bodyMBoldBrownStyles,
  bodyMRegularUtopiaStyles,
  bodySRegularBrownStyles,
  bodySRegularUtopiaStyles,
  bodyXsBoldBrownStyles,
  bodyXsRegularBrownStyles,
  bodyXsRegularUtopiaStyles,
  bodyXxlBoldBrownStyles,
  bodyXxsBoldBrownStyles,
  bodyXxsItalicUtopiaStyles,
  bodyXxsRegularBrownStyles,
  bodyXxsRegularUtopiaStyles,
  bodyXxsSemiBoldUtopiaStyles,
  eyebrowMStyles,
  eyebrowSStyles,
  eyebrowXsStyles,
} from '../../../styles/Web3.0/typography';
import { useShopifyStore } from '../../../context/store-context';
import { useViewport } from '../../../context/viewport.context';
import { useRootState } from '../../../context/root-state.context';
import { useIsomorphicLayoutEffect } from '../../../hooks';
import { currencyFormatter } from '../../../utils/currencyFormatter';
import { renderRichTextReact } from '../../../utils/storyblokRichText';
import CollapseArrow from './CollapseArrow';
import LimitedEditionTag from './LimitedEditionTag';
import ProductVariantAffirm from './ProductVariantAffirm';
import ProductVariantDescription from './ProductVariantDescription';
import ProductVariantSelector from './ProductVariantSelector';
import LooxRating from './LooxRating';
import LooxReviews from './LooxReviews';
import ProductVariantCarousels from './ProductVariantCarousels';
import BottomCarousel from './BottomCarousel';

const ShopProductCol = styled(Col)`
  margin-top: ${({ sectionMarginTop = 100 }) => sectionMarginTop}px;
`;

const BackRow = styled(Row)`
  margin-bottom: 20px;
`;

const BackLink = styled(Link)`
  ${bodyXxsRegularBrownStyles};
  cursor: pointer;
  text-decoration: none;

  svg {
    margin-right: 10px;
    transform: rotate(180deg);
  }
`;

const ProductPricesCtaContainer = styled.div`
  display: flex;
  align-items: baseline;
  flex-direction: column;
  padding-bottom: 50px;

  img {
    height: 50px;
  }

  p {
    margin-bottom: 0;
  }

  @media (${bpWidth.desktopSm}) {
    margin-right: 55px;
    padding-bottom: 40px;

    img {
      height: 30px;
    }
  }

  @media (${bpWidth.tablet}) {
    margin-right: 0;
    margin-top: 65px;
  }

  @media (${bpWidth.mobile}) {
    margin-top: 75px;
  }

  .showfields-wrapper {
    margin-top: 40px;

    a {
      ${bodyMRegularUtopiaStyles};
      color: rgb(0, 180, 220);
    }

    a:visited {
      color: rgb(0, 180, 220);
      text-decoration: none;
    }

    @media (${bpWidth.desktopSm}) {
      a {
        ${bodySRegularUtopiaStyles};
        color: rgb(0, 180, 220);
      }
    }

    @media (${bpWidth.tablet}) {
      margin-top: 20px;
    }
  }
`;

const ProductPriceTotal = styled.p`
  ${bodyXxlBoldBrownStyles};
  padding-bottom: 10px;

  @media (${bpWidth.desktopSm}) {
    ${bodyMBoldBrownStyles};
  }
`;

const ColorsCtaContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (${bpWidth.tablet}) {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  @media (${bpWidth.mobile}) {
    flex-direction: column;
  }
`;

const CurrentColorCopy = styled.div`
  ${eyebrowSStyles};
  color: ${colors.gray200};
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  min-height: 40px;

  @media (${bpWidth.desktopSm}) {
    ${eyebrowXsStyles};
    color: ${colors.gray200};
  }
`;

const CartCtaContainer = styled.div`
  div {
    ${bodySRegularBrownStyles};
    color: ${colors.gray400};
    padding-top: 10px;
  }

  @media (${bpWidth.tablet}) {
    display: flex;
    flex-direction: column;
    align-items: end;
    text-align: right;
  }

  @media (${bpWidth.mobile}) {
    align-items: start;
    text-align: left;
  }
`;

const CartCta = styled.button`
  ${btnLgPrimaryMediumOrangeStyles};
  cursor: pointer;

  @media (${bpWidth.desktopSm}) {
    ${btnSmPrimaryMediumOrangeStyles};
  }

  &.disabled {
    ${btnLgPrimaryMediumDisabledOrangeStyles};

    @media (${bpWidth.desktopSm}) {
      ${btnSmPrimaryMediumDisabledOrangeStyles};
    }
  }
`;

const ProductScrollableInfo = styled.div`
  overflow-y: auto;
  max-height: 490px;
  padding-right: 10px;

  h2 {
    ${eyebrowMStyles}
  }

  p,
  span,
  p span {
    ${bodyMRegularUtopiaStyles};
  }

  @media (${bpWidth.desktopSm}) {
    max-height: 300px;
    margin-right: 55px;

    h2 {
      ${eyebrowSStyles};
    }

    p,
    span,
    p span {
      ${bodySRegularUtopiaStyles};
    }
  }
  @media (${bpWidth.tablet}) {
    max-height: unset;
    margin-right: 0;
    padding-right: 0;
  }
`;

const ScrollableSections = styled.div`
  ${spacerSStyles};
  border-top: 1px solid ${colors.gray100};

  button {
    ${eyebrowMStyles};
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 0 2px 0 0;
    width: 100%;
    margin: 0;

    .arrow {
      filter: none;
    }

    &:hover {
      ${eyebrowMStyles};

      .arrow {
        filter: none;
      }
    }
  }

  &:last-child {
    border-bottom: 1px solid ${colors.gray100};
  }

  div.description {
    padding-top: 20px;
  }

  @media (${bpWidth.desktopSm}) {
    button {
      ${eyebrowSStyles};
      padding: 0;

      &:hover {
        ${eyebrowSStyles};
      }
    }
  }
`;

const PropositionSection = styled.div`
  padding-top: 30px;

  h3 {
    ${bodyXsRegularBrownStyles};

    span {
      ${bodyXsRegularBrownStyles};
      color: ${colors.cerulean400};
      cursor: pointer;
    }
  }
  div {
    display: flex;
    flex-direction: column;

    h6 {
      ${bodyXsBoldBrownStyles};
      color: ${colors.orange700};
      display: flex;
      flex-direction: row;
    }

    span,
    p {
      ${bodyXsRegularUtopiaStyles};
      white-space: pre-wrap;

      a {
        text-decoration: none;
        color: ${colors.navy600};
      }
    }
  }

  @media (${bpWidth.desktopSm}) {
    padding-top: 20px;

    h3 {
      ${bodyXxsRegularBrownStyles};

      span {
        ${bodyXxsRegularBrownStyles};
        color: ${colors.cerulean400};
      }
    }

    div {
      h6 {
        ${bodyXxsBoldBrownStyles};
        color: ${colors.orange700};
      }

      span,
      p {
        ${bodyXxsRegularUtopiaStyles};
        i {
          ${bodyXxsItalicUtopiaStyles};
        }

        b {
          ${bodyXxsSemiBoldUtopiaStyles};
          font-weight: bold;
        }
      }
    }
  }

  b,
  strong {
    span {
      font-weight: bold;
    }
  }
`;

const BottomContainerRow = styled(Row)`
  display: flex;
  flex-shrink: 0;
`;

const ProductReviewsRow = styled(Row)`
  padding-bottom: 50px;
`;

const ShopProduct = ({ blok }) => {
  const { viewWidth } = useViewport();
  const { client: shopifyClient, addVariantToCart } = useShopifyStore();
  const { shopifyProduct, productColors } = useRootState();
  const expandText = 'Expand Proposition';
  const collapseText = 'Collapse Proposition';

  const [sectionMarginTop, setSectionMarginTop] = useState(100);
  const [productVariants, setProductVariants] = useState([]);
  const [currentVariant, setCurrentVariant] = useState(null);
  const [productVariant, setProductVariant] = useState(null);
  const [isAvailable, setIsAvailable] = useState(false);
  const [isExpandShipping, setIsExpandShipping] = useState(false);
  const [isExpandWhatsIncluded, setIsExpandWhatsIncluded] = useState(false);
  const [isExpandProductDetails, setIsExpandProductDetails] = useState(false);
  const [isExpandProposition, setIsExpandProposition] = useState(false);
  const [propositionTitle, setPropositionTitle] = useState(expandText);

  const calculateTopPosition = () => {
    const headerContainerRow = document.getElementById('headerContainerRow');
    if (headerContainerRow) {
      setSectionMarginTop(headerContainerRow.getBoundingClientRect().height + 40);
    }
  };

  useIsomorphicLayoutEffect(() => {
    setTimeout(calculateTopPosition, 1000);
  }, [viewWidth]);

  useEffect(() => {
    if (shopifyProduct) {
      const { variants: shopifyVariants = [] } = shopifyProduct;
      const { variants: blokVariants = [] } = blok;
      const filteredVariants = blokVariants.filter((v) => v.enable);
      const variants = _.intersectionWith(shopifyVariants, filteredVariants, (shopifyVar, blokVar) => {
        const productColor = productColors.find((pc) => pc.value === blokVar.color);
        return shopifyVar.title === productColor?.name && _.assign(shopifyVar, blokVar);
      });
      setProductVariants(
        variants.map((variant) => {
          return {
            ...variant,
            productTitle: shopifyProduct.title,
          };
        })
      );

      const [firstVariant] = variants.map((variant) => {
        return {
          ...variant,
          productTitle: shopifyProduct.title,
        };
      });
      if (!currentVariant) {
        setCurrentVariant(firstVariant);
      }
    }
  }, [shopifyProduct, blok, productColors]);

  // Price based on color variant selected
  const selectedVariantPrice = useMemo(() => {
    if (currentVariant) {
      const productPriceTotal = currencyFormatter(currentVariant.price, { minimumFractionDigits: 0 });

      return <ProductPriceTotal>{productPriceTotal}</ProductPriceTotal>;
    }

    return undefined;
  }, [currentVariant]);

  const handleVariantChanged = (item) => {
    setCurrentVariant(item);
  };

  // set product variant from client
  useEffect(() => {
    let selectedVariant =
      shopifyProduct &&
      shopifyClient.product.helpers.variantForOptions(shopifyProduct, { Color: currentVariant?.title });

    if (!selectedVariant) {
      selectedVariant = currentVariant;
    }

    setProductVariant(selectedVariant);
  }, [currentVariant]);

  const checkAvailability = useCallback(
    (productId) => {
      setIsAvailable(false);

      if (productId && productVariant) {
        shopifyClient.product.fetch(productId).then((fetchedProduct) => {
          const result = fetchedProduct?.variants?.find((_variant) => _variant.id === productVariant.storefrontId);
          setIsAvailable(result?.available ?? false);
        });
      }
    },
    [productVariant, shopifyClient]
  );

  useEffect(() => {
    setIsAvailable(false);

    if (shopifyProduct && productVariant) {
      checkAvailability(shopifyProduct.storefrontId);
    }
  }, [productVariant, checkAvailability]);

  const handleAddToCart = useCallback(
    (_variant) => {
      if (isAvailable && _variant) {
        addVariantToCart(_variant, 1);
      }
    },
    [isAvailable, addVariantToCart]
  );

  const CartCtaCopy = useMemo(() => {
    return (
      <CartCtaContainer>
        <CartCta
          disabled={!isAvailable}
          className={!isAvailable ? 'disabled' : undefined}
          onClick={() => {
            handleAddToCart(currentVariant);
          }}
        >
          {!isAvailable ? 'OUT OF STOCK' : 'ADD TO CART'}
        </CartCta>
      </CartCtaContainer>
    );
  }, [currentVariant, isAvailable, handleAddToCart]);

  // Expand product sections
  const onExpandShipping = () => {
    if (!isExpandShipping) {
      setIsExpandShipping(true);
    } else {
      setIsExpandShipping(false);
    }
    return isExpandShipping;
  };

  const onExpandWhatsIncluded = () => {
    if (!isExpandWhatsIncluded) {
      setIsExpandWhatsIncluded(true);
    } else {
      setIsExpandWhatsIncluded(false);
    }
    return isExpandWhatsIncluded;
  };

  const onExpandProductDetails = () => {
    if (!isExpandProductDetails) {
      setIsExpandProductDetails(true);
    } else {
      setIsExpandProductDetails(false);
    }
    return isExpandProductDetails;
  };

  const onPropositionClick = () => {
    if (!isExpandProposition) {
      setPropositionTitle(collapseText);
      setIsExpandProposition(true);
    } else {
      setPropositionTitle(expandText);
      setIsExpandProposition(false);
    }

    return propositionTitle;
  };

  return (
    <ShopProductCol {...storyblokEditable(blok)} xs={8} md={10} lg={12} sectionMarginTop={sectionMarginTop}>
      <Row>
        <Col xs={8} md={10} lg={12}>
          {/* Back navigation */}
          <BackRow>
            <Col xsOffset={1} xs={6} md={8} lg={10}>
              <BackLink to={withPrefix(SHOP_URL)}>
                <ArrowLNavy alt="Back arrow" /> Back
              </BackLink>
            </Col>
          </BackRow>

          {/* Product info */}
          <Row>
            {/*  /!* Top product carousel *!/*/}
            <Col xs={8} sm={8} mdOffset={1} md={6} lg={7}>
              {currentVariant?.topGallery?.length > 0 && (
                <ProductVariantCarousels
                  topCarousels={currentVariant?.topGallery}
                  activeVariant={currentVariant?.title}
                />
              )}
            </Col>

            {/* Product details */}
            <Col xsOffset={1} xs={5} mdOffset={0} md={3}>
              <ProductPricesCtaContainer>
                {/* Product logo */}
                {blok.logo?.length > 0 &&
                  blok.logo.map((nestedBlok) => <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />)}

                {/* Color variant price */}
                {selectedVariantPrice}

                {/* Affirm plugin */}
                {currentVariant && !currentVariant.hideAffirm && (
                  <ProductVariantAffirm currentVariant={currentVariant} />
                )}

                {/* Loox rating */}
                {shopifyProduct && !blok.hideRating ? (
                  <LooxRating link="#productReviews" shopifyId={shopifyProduct.shopifyId} />
                ) : undefined}

                {/* Product description */}
                {currentVariant && <ProductVariantDescription currentVariant={currentVariant} />}

                {/* Color variants */}
                <ColorsCtaContainer>
                  <div>
                    {productVariants.length > 0 && (
                      <ProductVariantSelector
                        productVariants={productVariants}
                        currentVariant={currentVariant}
                        onVariantChanged={handleVariantChanged}
                      />
                    )}
                    <CurrentColorCopy>
                      {currentVariant?.title}
                      {currentVariant?.limitedEdtion && <LimitedEditionTag />}
                    </CurrentColorCopy>
                  </div>

                  {/* Add to cart button & stock status */}
                  {CartCtaCopy}
                </ColorsCtaContainer>

                {/* Showfields demo */}
                {currentVariant?.ctaDemo?.map((nestedBlok) => (
                  <Col xs={8} md={8} lg={7} key={nestedBlok._uid}>
                    <div className="showfields-wrapper">
                      <StoryblokComponent blok={nestedBlok} />
                    </div>
                  </Col>
                ))}
              </ProductPricesCtaContainer>

              {/* Scrollable product info */}
              <ProductScrollableInfo>
                {/* Shipping */}
                {currentVariant?.isShowShipping && currentVariant?.shippingDescription && (
                  <ScrollableSections>
                    <CollapseArrow
                      onExpandChange={onExpandShipping}
                      buttonCopy="free shipping"
                      pageType="productTemplate"
                      color="navy600"
                    />
                    <AnimateHeight duration={250} height={isExpandShipping ? 'auto' : 0} animateOpacity>
                      <div className="description">{renderRichTextReact(currentVariant.shippingDescription)}</div>
                    </AnimateHeight>
                  </ScrollableSections>
                )}

                {/* What's included */}
                {currentVariant?.isShowWhatsIncluded && currentVariant?.whatsIncludedDescription && (
                  <ScrollableSections>
                    <CollapseArrow
                      onExpandChange={onExpandWhatsIncluded}
                      buttonCopy="what's included"
                      pageType="productTemplate"
                      color="navy600"
                    />
                    <AnimateHeight duration={250} height={isExpandWhatsIncluded ? 'auto' : 0} animateOpacity>
                      <div className="description">{renderRichTextReact(currentVariant.whatsIncludedDescription)}</div>
                    </AnimateHeight>
                  </ScrollableSections>
                )}

                {/* What's included */}
                {currentVariant?.isShowProductDetails && currentVariant?.productDetailsDescription && (
                  <ScrollableSections>
                    <CollapseArrow
                      onExpandChange={onExpandProductDetails}
                      buttonCopy="product details"
                      pageType="productTemplate"
                      color="navy600"
                    />
                    <AnimateHeight duration={250} height={isExpandProductDetails ? 'auto' : 0} animateOpacity>
                      <div className="description">{renderRichTextReact(currentVariant.productDetailsDescription)}</div>

                      {currentVariant?.isShowProposition && currentVariant?.propositionDescription && (
                        <PropositionSection>
                          <h3>
                            <span onClick={onPropositionClick} aria-hidden="true">
                              {propositionTitle}
                            </span>{' '}
                            65 warning for California residents.
                          </h3>
                          <AnimateHeight duration={250} height={isExpandProposition ? 'auto' : 0} animateOpacity>
                            <div>
                              <div className="description">
                                {renderRichTextReact(currentVariant?.propositionDescription)}
                              </div>
                            </div>
                          </AnimateHeight>
                        </PropositionSection>
                      )}
                    </AnimateHeight>
                  </ScrollableSections>
                )}
              </ProductScrollableInfo>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* Bottom carousel */}
      {currentVariant?.bottomGallery?.length > 0 && (
        <BottomContainerRow>
          <Col xs={8} sm={8} md={10} lg={12}>
            <BottomCarousel bottomCarouselData={currentVariant?.bottomGallery} activeVariant={currentVariant.title} />
          </Col>
        </BottomContainerRow>
      )}

      {/* Loox reviews */}
      {shopifyProduct && !blok.hideRating ? (
        <ProductReviewsRow id="productReviews">
          <Col xsOffset={1} xs={6} md={8} lg={10}>
            <LooxReviews shopifyId={shopifyProduct.shopifyId} />
          </Col>
        </ProductReviewsRow>
      ) : undefined}
    </ShopProductCol>
  );
};

ShopProduct.propTypes = {
  blok: object.isRequired,
};

export default ShopProduct;
