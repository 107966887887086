import React from 'react';
import PropTypes from 'prop-types';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import { Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import { bpWidth, colors } from '../../../styles/Web3.0/variables';
import { eyebrowMStyles, eyebrowSStyles, headerLFillStyles, headerSStyles } from '../../../styles/Web3.0/typography';

const ContainerCol = styled(Col)``;

const Title = styled.h3`
  ${eyebrowMStyles};
  color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
  text-align: center;
  margin-bottom: 50px;

  &.business-press {
    ${headerLFillStyles};
    color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
    text-align: left;
    text-transform: none;
  }

  @media (${bpWidth.desktopSm}) {
    ${eyebrowSStyles};
    color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
    text-align: center;
    margin-bottom: 45px;

    &.business-press {
      ${headerLFillStyles};
      color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
      text-align: left;
      text-transform: none;
    }
  }

  @media (${bpWidth.tablet}) {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 60px;

    &.business-press {
      text-align: left;
      text-transform: none;
    }
  }

  @media (${bpWidth.mobile}) {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 50px;

    &.business-press {
      ${headerSStyles};
      color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
      text-align: left;
      text-transform: none;
    }
  }
`;

const NewspapersRow = styled(Row)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Newspapers = ({ blok }) => {
  const { newspaper } = blok;

  return (
    <Col {...storyblokEditable(blok)} xs={8} md={10} lg={12}>
      <Row>
        <ContainerCol xs={8} md={10} lg={12}>
          <Title className={blok.cssClass} darkMode={blok.darkMode}>
            {blok.title}
          </Title>
          <NewspapersRow>
            {newspaper?.map((nestedBlok) => (
              <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} count={newspaper.length} />
            ))}
          </NewspapersRow>
        </ContainerCol>
      </Row>
    </Col>
  );
};

Newspapers.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default Newspapers;
