import React, { useEffect, useState } from 'react';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import { Col, Row } from 'react-styled-flexboxgrid';
import { bool, func, object } from 'prop-types';
import AnimateHeight from 'react-animate-height';
import styled from 'styled-components';
import { bpWidth, colors } from '../../../styles/Web3.0/variables';
import {
  bodyLSemiBoldUtopiaStyles,
  bodyMBoldBrownStyles,
  bodyMRegularUtopiaStyles,
  bodySBoldBrownStyles,
  bodySRegularUtopiaStyles,
  bodySSemiBoldUtopiaStyles,
  eyebrowLStyles,
  eyebrowMStyles,
  eyebrowXsStyles,
} from '../../../styles/Web3.0/typography';
import Chevron from '../../../icons/Web3.0/arrow-DCerulean.svg';
import { renderRichTextReact } from '../../../utils/storyblokRichText';

const ValueCol = styled(Col)`
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid ${colors.gray100};
  padding-top: 40px;
  padding-bottom: 40px;

  &:last-of-type {
    border-bottom: 1px solid ${colors.gray100};
  }

  @media (${bpWidth.desktopSm}) {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  @media (${bpWidth.tablet}) {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  @media (${bpWidth.mobile}) {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .value-heading-container {
    flex-grow: 1;
    display: flex;
    cursor: pointer;

    span.value-heading-icon {
      width: ${({ theme }) => `calc(100% * (1 / ${theme?.flexboxgrid?.gridSize || 12}))`};
      margin-right: ${({ theme }) => `calc(100% * (1 / ${theme?.flexboxgrid?.gridSize || 12}))`};
      text-align: center;
    }

    h4.value-heading {
      ${({ darkMode }) => (darkMode ? eyebrowLStyles : bodyMBoldBrownStyles)};
      color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
      flex: 1 0;
    }

    .value-cta-arrow {
      width: auto;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      ${eyebrowMStyles};
      color: ${({ darkMode }) => (darkMode ? colors.cerulean400 : undefined)};

      .chevron-label {
        margin-right: 15px;
      }

      svg {
        width: ${({ darkMode }) => (darkMode ? '9px' : '13px')};

        path {
          stroke: ${({ darkMode }) => (darkMode ? colors.cerulean400 : colors.navy600)};
        }
      }

      @media (${bpWidth.mobile}) {
        ${eyebrowXsStyles};
        color: ${({ darkMode }) => (darkMode ? colors.cerulean400 : undefined)};
      }
    }
  }

  .value-description {
    ${({ darkMode }) => (darkMode ? bodyLSemiBoldUtopiaStyles : bodyMRegularUtopiaStyles)};
    color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
    margin-left: ${({ theme, hasHeadingIcon, hasDescriptionImage }) => {
      let baseValue = 0;
      if (hasHeadingIcon && hasDescriptionImage) {
        baseValue = 3.3;
      } else if (hasHeadingIcon && !hasDescriptionImage) {
        baseValue = 1.65;
      }
      return `calc(100% * (${baseValue} / ${theme?.flexboxgrid?.gridSize || 12}))`;
    }};
    margin-right: ${({ hasDescriptionImage }) => (hasDescriptionImage ? `1.5rem` : undefined)};
    box-sizing: border-box;
  }

  @media (${bpWidth.tablet}) {
    .value-description {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: ${({ hasDescriptionImage }) => (hasDescriptionImage ? `1.5rem` : undefined)};
    }
  }

  @media (${bpWidth.mobile}) {
    .value-heading-container {
      h4.value-heading {
        ${({ darkMode }) => (darkMode ? eyebrowXsStyles : bodySBoldBrownStyles)};
        color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
      }
    }

    .value-description {
      ${({ darkMode }) => (darkMode ? bodySSemiBoldUtopiaStyles : bodySRegularUtopiaStyles)};
      color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
    }
  }
`;

const HeadingCol = styled(Col)`
  display: flex;
  align-items: center;

  @media (${bpWidth.tablet}) {
    h4.value-heading {
      display: none;
    }
  }
`;

const MobileHeadingCol = styled(Col)`
  margin-top: 10px;
`;

const StyledAnimateHeight = styled(AnimateHeight)`
  padding-top: 20px;
`;

const DescriptionRow = styled(Row)``;

function CTAArrow(isExpand, showLabel = false) {
  return (
    <>
      {showLabel && <span className="chevron-label">{isExpand ? 'Collapse' : 'Expand'}</span>}
      <Chevron
        style={{
          transitionDuration: '.1s',
          transform: isExpand ? 'rotate(180deg)' : 'none',
        }}
      />
    </>
  );
}

const AccordionItem = ({ blok, onExpand, darkMode = false }) => {
  const [hasHeadingIcon, setHasHeadingIcon] = useState(false);
  const [hasDescriptionImage, setHasDescriptionImage] = useState(false);

  useEffect(() => {
    setHasHeadingIcon(blok.headingIcon?.length > 0);
  }, [blok.headingIcon]);

  useEffect(() => {
    setHasDescriptionImage(blok.descriptionImage?.length > 0);
  }, [blok.descriptionImage]);

  return darkMode ? (
    <ValueCol
      {...storyblokEditable(blok)}
      xs={8}
      md={10}
      lg={12}
      darkMode={darkMode}
      hasHeadingIcon={hasHeadingIcon}
      hasDescriptionImage={hasDescriptionImage}
    >
      <Row className="value-heading-container" onClick={() => onExpand(blok._uid)}>
        <HeadingCol xs={4} md={8} lg={10}>
          {hasHeadingIcon > 0 && (
            <span className="value-heading-icon">
              {blok.headingIcon.map((nestedBlok) => (
                <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
              ))}
            </span>
          )}
          <h4 className="value-heading">{blok.heading}</h4>
        </HeadingCol>
        <Col xs={4} md={2}>
          <div className="value-cta-arrow">{CTAArrow(blok.isExpand, true)}</div>
        </Col>
        <MobileHeadingCol xs={8} md={false}>
          <h4 className="value-heading">{blok.heading}</h4>
        </MobileHeadingCol>
      </Row>
      <StyledAnimateHeight duration={250} height={blok.isExpand ? 'auto' : 0} animateOpacity>
        <DescriptionRow>
          <Col xs={8} md={hasDescriptionImage ? 5 : 10} lg={hasDescriptionImage ? 6 : 12}>
            <div className="value-description">{renderRichTextReact(blok.description)}</div>
          </Col>
          {hasDescriptionImage && (
            <Col xs={8} md={5} lg={6}>
              <div className="value-description-image">
                {blok.descriptionImage.map((nestedBlok) => (
                  <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
                ))}
              </div>
            </Col>
          )}
        </DescriptionRow>
      </StyledAnimateHeight>
    </ValueCol>
  ) : (
    <ValueCol {...storyblokEditable(blok)} xs={8} md={10} lg={12} darkMode={darkMode}>
      <div role="presentation" className="value-heading-container" onClick={() => onExpand && onExpand(blok._uid)}>
        <h4 className="value-heading">{blok.heading}</h4>
        <div className="value-cta-arrow">{CTAArrow(blok.isExpand)}</div>
      </div>
      <StyledAnimateHeight duration={250} height={blok.isExpand ? 'auto' : 0} animateOpacity>
        <div className="value-description">{renderRichTextReact(blok.description)}</div>
      </StyledAnimateHeight>
    </ValueCol>
  );
};

AccordionItem.propTypes = {
  blok: object.isRequired,
  onExpand: func.isRequired,
  darkMode: bool,
};

export default AccordionItem;
