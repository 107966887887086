import React, { useMemo } from 'react';
import { StoryblokComponent } from 'gatsby-source-storyblok';
import PropTypes from 'prop-types';
import { renderRichTextReact } from '../../../utils/storyblokRichText';

const Detail = ({ className, title, children }) => {
  return (
    <div className={className}>
      <h3
        className="font-brown font-bold text-[13px] tracking-[2.34px] text-center uppercase text-gray-300
        border-b-[1.5px] border-solid border-gray-500 mx-auto mb-10 pb-1.5 max-w-[150px]"
      >
        {title}
      </h3>
      {children}
    </div>
  );
};

Detail.propTypes = {
  className: PropTypes.string,
  title: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired,
};

const ProductDetailsSectionItem = ({ blok }) => {
  const cssClass = useMemo(() => {
    return ['overflow-hidden w-full product-details-section-component', blok.customCssClass].filter(Boolean).join(' ');
  }, [blok.customCssClass]);

  return (
    <Detail title={renderRichTextReact(blok.title)} className={cssClass}>
      {blok.contents?.map((nestedBlok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
    </Detail>
  );
};

ProductDetailsSectionItem.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default ProductDetailsSectionItem;
