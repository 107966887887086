import React from 'react';
import { storyblokEditable } from 'gatsby-source-storyblok';
import { object } from 'prop-types';
import { renderRichTextReact } from '../../../utils/storyblokRichText';

const TableDataHeaderCell = ({ blok }) => {
  return (
    <div {...storyblokEditable(blok)} className="th">
      {renderRichTextReact(blok.value)}
    </div>
  );
};

TableDataHeaderCell.propTypes = {
  blok: object.isRequired,
};

export default TableDataHeaderCell;
