import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { storyblokEditable } from 'gatsby-source-storyblok';
import styled from 'styled-components';
import { Col, Row } from 'react-styled-flexboxgrid';
import {
  bodyLRegularUtopiaStyles,
  bodyMRegularUtopiaStyles,
  bodySRegularUtopiaStyles,
  eyebrowLStyles,
  eyebrowMStyles,
  eyebrowSStyles,
} from '../../styles/Web3.0/typography';
import { bpWidth, sizes } from '../../styles/Web3.0/variables';
import { renderRichTextReact } from '../../utils/storyblokRichText';
import { useViewport } from '../../context/viewport.context';

const SectionRow = styled(Row)`
  margin-bottom: 150px;

  @media (${bpWidth.tablet}) {
    margin-bottom: 130px;
  }

  @media (${bpWidth.mobile}) {
    margin-bottom: 100px;
  }
`;

const AssetsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 70px;
  align-items: center;
  justify-content: space-between;

  > div {
    box-sizing: border-box;
    align-self: center;
  }

  > div.align-self-top {
    align-self: flex-start;
  }

  @media (${bpWidth.desktopSm}) {
    row-gap: 40px;
  }

  @media (${bpWidth.tablet}) {
    row-gap: 36px;
  }

  @media (${bpWidth.mobile}) {
    row-gap: 33px;
  }
`;

const CopyCol = styled(Col)`
  display: flex;
  flex-direction: column;

  @media (${bpWidth.tablet}) {
    margin-bottom: 50px;
  }
`;

const Heading = styled.h2`
  ${eyebrowLStyles};
  margin-bottom: 30px;

  @media (${bpWidth.desktopSm}) {
    ${eyebrowMStyles};
    margin-bottom: 20px;
  }

  @media (${bpWidth.mobile}) {
    ${eyebrowSStyles};
  }
`;

const Body = styled.div`
  ${bodyLRegularUtopiaStyles};

  @media (${bpWidth.desktopSm}) {
    ${bodyMRegularUtopiaStyles};
  }

  @media (${bpWidth.mobile}) {
    ${bodySRegularUtopiaStyles};
  }
`;

const AssetsCol = styled(Col)`
  @media (max-width: ${sizes.containerMaxWidth.desktopSm}px) and (min-width: ${sizes.containerMinWidth.desktopSm}px) {
    flex-basis: 45%;
    max-width: 45%;
  }
`;

const TwoColumnsTextWithManyImages = ({ blok }) => {
  const { heading, body } = blok;
  const [assets, setAssets] = useState([]);
  const { viewWidth } = useViewport();

  useEffect(() => {
    setAssets(blok.assets);
  }, [blok]);

  const renderAsset = useCallback(
    (nestedBlok) => {
      let imageUrl =
        nestedBlok.assetLg?.filename ||
        nestedBlok.assetMd?.filename ||
        nestedBlok.assetSm?.filename ||
        nestedBlok.assetXs?.filename;

      if (viewWidth < sizes.containerMinWidth.tablet) {
        if (nestedBlok.assetXs?.filename) {
          imageUrl = nestedBlok.assetXs.filename;
        }
      } else if (viewWidth < sizes.containerMinWidth.desktopSm) {
        if (nestedBlok.assetSm?.filename) {
          imageUrl = nestedBlok.assetSm.filename;
        }
      } else if (viewWidth < sizes.containerMinWidth.desktopLg) {
        if (nestedBlok.assetMd?.filename) {
          imageUrl = nestedBlok.assetMd.filename;
        }
      }

      return imageUrl ? (
        <div key={nestedBlok._uid} className={nestedBlok.customClass}>
          <img alt={nestedBlok.altText || nestedBlok.caption || ''} src={imageUrl} />
        </div>
      ) : undefined;
    },
    [viewWidth]
  );

  return (
    <Col {...storyblokEditable(blok)} xs={8} md={10} lg={12}>
      <SectionRow>
        <CopyCol xsOffset={1} xs={6} smOffset={1} sm={4} mdOffset={1} md={3}>
          {/* Heading */}
          {heading && <Heading>{renderRichTextReact(heading)}</Heading>}

          {/* Body */}
          {body && <Body>{renderRichTextReact(body)}</Body>}
        </CopyCol>
        <AssetsCol xsOffset={1} xs={6} mdOffset={1} md={4} lgOffset={1} lg={6}>
          <AssetsContainer>{assets?.map(renderAsset)}</AssetsContainer>
        </AssetsCol>
      </SectionRow>
    </Col>
  );
};

TwoColumnsTextWithManyImages.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default TwoColumnsTextWithManyImages;
