import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-styled-flexboxgrid';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import styled from 'styled-components';
import { renderRichTextReact } from '../../../utils/storyblokRichText';
import { bpWidthTailwind, colors } from '../../../styles/Web3.0/variables';
import { eyebrowMStyles, eyebrowXsStyles, headerXsStyles, headerXxsStyles } from '../../../styles/Web3.0/typography';

export const CaptionHeading = styled.h2`
  ${headerXxsStyles};
  color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
  font-size: 16px;
  margin-top: 25px;

  @media (${bpWidthTailwind.desktopSm}) {
    ${headerXsStyles};
    color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
    margin-top: 50px;
  }
`;

export const Caption = styled.div`
  ${eyebrowXsStyles};
  color: ${({ darkMode }) => (darkMode ? colors.orange200 : undefined)};
  margin-top: 12px;

  @media (${bpWidthTailwind.desktopSm}) {
    ${eyebrowMStyles};
    color: ${({ darkMode }) => (darkMode ? colors.orange200 : undefined)};
    margin-top: 20px;
  }
`;

const Slider4Item = ({ blok, darkMode }) => {
  const { captionHeading, caption, asset } = blok;

  const [captionHeadingRichText, setCaptionHeadingRichText] = useState(null);
  const [captionRichText, setCaptionRichText] = useState(null);

  useEffect(() => {
    setCaptionHeadingRichText(renderRichTextReact(captionHeading));
    setCaptionRichText(renderRichTextReact(caption));
  }, [blok]);

  return (
    <div {...storyblokEditable(blok)} className="w-[326px] sm:w-[450px] lg:w-[500px] mx-4 md:mx-5">
      {asset?.map((nestedBlok) => (
        <Row key={nestedBlok._uid}>
          <StoryblokComponent blok={nestedBlok} />
        </Row>
      ))}
      {(captionHeadingRichText || captionRichText) && (
        <Row>
          <Col xs={8} md={10} lg={12}>
            {captionHeadingRichText && <CaptionHeading darkMode={darkMode}>{captionHeadingRichText}</CaptionHeading>}
            {captionRichText && <Caption darkMode={darkMode}>{captionRichText}</Caption>}
          </Col>
        </Row>
      )}
    </div>
  );
};

Slider4Item.propTypes = {
  blok: PropTypes.object.isRequired,
  darkMode: PropTypes.bool,
};

export default Slider4Item;
