import React from 'react';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import { object } from 'prop-types';

const TableDataRow = ({ blok }) => {
  const { cells = [] } = blok;
  return (
    <div {...storyblokEditable(blok)} className="tr">
      {cells.map((td) => (
        <StoryblokComponent blok={td} key={td._uid} />
      ))}
    </div>
  );
};

TableDataRow.propTypes = {
  blok: object.isRequired,
};

export default TableDataRow;
