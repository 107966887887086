import React, { useMemo } from 'react';
import { string } from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { bodyMRegularBrownStyles, bodySRegularBrownStyles } from '../../../styles/Web3.0/typography';
import { bpWidth, colors } from '../../../styles/Web3.0/variables';

const StarRating = styled(Link)`
  text-decoration: none;

  .loox-rating {
    ${bodyMRegularBrownStyles};
    color: ${colors.cerulean400};
    padding-bottom: 30px;

    span {
      ${bodyMRegularBrownStyles};
      color: ${colors.cerulean400};
    }
  }

  @media (${bpWidth.desktopSm}) {
    .loox-rating {
      ${bodySRegularBrownStyles};
      color: ${colors.cerulean400};
      padding-bottom: 20px;

      span {
        ${bodySRegularBrownStyles};
        color: ${colors.cerulean400};
      }
    }
  }
`;

const LooxRating = (props) => {
  const { link, shopifyId } = props;

  const looxRating = useMemo(() => {
    return <div className="loox-rating" data-fetch data-id={shopifyId.replace('gid://shopify/Product/', '')} />;
  }, [shopifyId]);

  return <StarRating to={link}>{looxRating}</StarRating>;
};

LooxRating.propTypes = {
  link: string,
  shopifyId: string,
};

export default LooxRating;
