import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-styled-flexboxgrid';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import styled from 'styled-components';
import Slider from 'react-slick';
import { bpWidthTailwind } from '../../../styles/Web3.0/variables';

const PressSlickSlider = styled(Slider)`
  margin-top: 54px;

  .slick-list {
    .slick-track {
      .slick-slide {
        .gatsby-image-wrapper {
          height: 219px;
        }
        @media (${bpWidthTailwind.tablet}) {
          .gatsby-image-wrapper {
            height: 295px;
          }
        }
        @media (${bpWidthTailwind.desktopSm}) {
          .gatsby-image-wrapper {
            height: 329px;
          }
        }
      }
    }
  }
`;

const Slider4 = ({ blok }) => {
  const { assets: slideList = [], customClass = '', darkMode = false } = blok;

  const settings = {
    dots: false,
    infinite: true,
    speed: 12000,
    slidesToScroll: 1,
    arrows: false,
    fade: false,
    autoplay: true,
    autoplaySpeed: 0,
    pauseOnHover: false,
    swipe: false,
    touchMove: false,
    swipeToSlide: false,
    centerMode: false,
    cssEase: 'linear',
    variableWidth: true,
  };

  return (
    <Col {...storyblokEditable(blok)} xs={8} md={10} lg={12}>
      <PressSlickSlider {...settings} className={customClass}>
        {slideList?.map((nestedBlok) => {
          return <StoryblokComponent key={nestedBlok._uid} blok={nestedBlok} darkMode={darkMode} />;
        })}
      </PressSlickSlider>
    </Col>
  );
};

Slider4.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default Slider4;
