/*
  these product and variant IDs are copied from Shopify, where they never change.
  new product and variant IDs should be added here.
  alternatively these IDs can be retrieved via GraphQL query.
*/

export const SHOPIFY_PRODUCT_IDS = {
  BATTERY_CHARGER: '6664712519715',
  BATTERY_CHARGER_GITA: '2323665190971',
  CARGO_BIN: '2274628239419',
  GITA: '2267135410235',
  GITA_HOLIDAY_BUNDLE: '4831974916131',
  GITA_IGLOO_COOLER: '4465650106403',
  GITAMINI: '6791715815459',
  GITAMINI_V1: '6606532739107',
  GITAPLUS: '6707812532259',
  OPEN_ORGANIZER_BIN: '2320271278139',
};

export const SHOPIFY_VARIANT_IDS = {
  CARGO_BIN_SIGNAL_RED: '21143596531771',
  CARGO_BIN_THUNDER_GRAY: '21143596597307',
  CARGO_BIN_TWILIGHT_BLUE: '21322689937467',
  GITA_SIGNAL_RED: '21112142004283',
  GITA_THUNDER_GRAY: '21112161009723',
  GITA_TWILIGHT_BLUE: '21112161075259',
  GITA_HOLIDAY_BUNDLE_SIGNAL_RED: '32964178083875',
  GITA_HOLIDAY_BUNDLE_THUNDER_GRAY: '32964178018339',
  GITA_HOLIDAY_BUNDLE_TWILIGHT_BLUE: '32964178051107',
  GITAMINI_BOARDWALK_BEIGE: '39940853006371',
  GITAMINI_SHADOW_BLACK: '39940852940835',
  GITAMINI_SPARK_CITRON: '39940852973603',
  GITAMINI_V1_BOARDWALK_BEIGE: '39455222136867',
  GITAMINI_V1_SHADOW_BLACK: '39843543613475',
  GITAMINI_V1_SPARK_CITRON: '39455222104099',
  GITAPLUS_RAPID_BLUE: '39756227739683',
  GITAPLUS_ESPRESSO_BROWN: '39756227706915',
  OPEN_ORGANIZER_BIN_BLACK: '21322681090107',
  OPEN_ORGANIZER_BIN_GRAY: '21322682499131',
};
