import { css } from 'styled-components';

export const spacerXxxxlStyles = css`
  padding: 100px 0px;
`;

export const spacerXxxlStyles = css`
  padding: 90px 0px;
`;

export const spacerXxlStyles = css`
  padding: 80px 0px;
`;

export const spacerXlStyles = css`
  padding: 70px 0px;
`;

export const spacerLStyles = css`
  padding: 60px 0px;
`;

export const spacerMStyles = css`
  padding: 50px 0px;
`;

export const spacerSStyles = css`
  padding: 40px 0px;
`;

export const spacerXsStyles = css`
  padding: 30px 0px;
`;

export const spacerXxStyles = css`
  padding: 20px 0px;
`;

export const spacerXxxsStyles = css`
  padding: 10px 0px;
`;

export const spacerXxxxSStyles = css`
  padding: 5px 0px;
`;
