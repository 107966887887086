import React from 'react';
import { Col } from 'react-styled-flexboxgrid';
import { storyblokEditable } from 'gatsby-source-storyblok';
import { object } from 'prop-types';
import styled from 'styled-components';
import PressPostHeader from '../../Web3.0/PressPost/PressPostHeader';
import { renderRichTextReact } from '../../../utils/storyblokRichText';
import { bodyMItalicUtopiaStyles, bodySItalicUtopiaStyles, buttonSStyles } from '../../../styles/Web3.0/typography';
import { bpWidth, colors } from '../../../styles/Web3.0/variables';

const PressPostBody = styled.div`
  margin-bottom: 0;
  margin-top: 15px;
  ${bodyMItalicUtopiaStyles};

  @media (${bpWidth.desktopSm}) {
    ${bodySItalicUtopiaStyles}
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 30px;
`;

const ReadMoreBtn = styled.a`
  ${buttonSStyles};
  color: ${colors.cerulean400};
  text-decoration: none;

  &:hover {
    color: ${colors.cerulean700};
  }
`;

const Press = ({ blok }) => {
  let href = blok.article?.cached_url || blok.article?.url || '';
  if (blok.article?.anchor) {
    href += `#${blok.article.anchor}`;
  }
  const target = blok.article?.target;
  const rel = target === '_blank' ? 'noreferrer' : undefined;

  return (
    <Col {...storyblokEditable(blok)} xs={8} md={10} lg={12}>
      <PressPostHeader
        header={renderRichTextReact(blok.heading)}
        publishDate={blok.publishDate}
        publisher={blok.publisher}
      />
      <PressPostBody>{renderRichTextReact(blok.summary)}</PressPostBody>
      <ButtonWrapper>
        <ReadMoreBtn href={href} target={target} rel={rel}>
          read more&nbsp;&gt;
        </ReadMoreBtn>
      </ButtonWrapper>
    </Col>
  );
};

Press.propTypes = {
  blok: object.isRequired,
};

export default Press;
