import React, { useEffect } from 'react';
import { StoryblokComponent } from 'gatsby-source-storyblok';
import { useStoryblokStory } from '../../../hooks';
import { useAppState } from '../../../context/app-state.context';

const HeaderWrapper = () => {
  const { storyblokStoryData } = useStoryblokStory('header');

  const { hamburgerMenuIsOpen } = useAppState();

  // prevent scroll if menu is open
  useEffect(() => {
    const contentEl = document.getElementById('bodyContent');
    if (hamburgerMenuIsOpen) {
      contentEl.classList.add('overflow-hidden');
    } else {
      contentEl.classList.remove('overflow-hidden');
    }
  }, [hamburgerMenuIsOpen]);

  return storyblokStoryData?.content && <StoryblokComponent blok={storyblokStoryData.content} />;
};

export default HeaderWrapper;
