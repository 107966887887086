import { css } from 'styled-components';
import { colors, fontFamilies } from './variables.js';

// ========== Header typography ==========
// ===== Header XXL
export const headerXxlFillStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.brownFontFamily};
  font-size: 90px;
  font-weight: 700;
  letter-spacing: -0.9px;
  line-height: 1.2;
`;

export const headerXxlOutlineStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.brownFontFamily};
  font-size: 90px;
  font-weight: 700;
  letter-spacing: -0.9px;
  line-height: 1.2;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px ${colors.navy600};
`;

// ===== Header XL
export const headerXlFillStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.brownFontFamily};
  font-size: 60px;
  font-weight: 700;
  letter-spacing: -0.6px;
  line-height: 1.3;
`;

export const headerXlOutlineStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.brownFontFamily};
  font-size: 60px;
  font-weight: 700;
  letter-spacing: -0.6px;
  line-height: 1.3;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px ${colors.navy600};
`;

// ===== Header L
export const headerLFillStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.brownFontFamily};
  font-size: 45px;
  font-weight: 700;
  letter-spacing: -0.45px;
  line-height: 1.3;
`;

export const headerLOutlineStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.brownFontFamily};
  font-size: 45px;
  font-weight: 700;
  letter-spacing: -0.45px;
  line-height: 1.3;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px ${colors.navy600};
`;

// ===== Header M
export const headerMStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.brownFontFamily};
  font-size: 36px;
  font-weight: 700;
  letter-spacing: -0.36px;
  line-height: 1.4;
`;

export const headerMFillStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.brownFontFamily};
  font-size: 36px;
  font-weight: 700;
  letter-spacing: -0.36px;
  line-height: 1.1;
`;

export const headerMOutlineStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.brownFontFamily};
  font-size: 36px;
  font-weight: 700;
  letter-spacing: -0.36px;
  line-height: 1.1;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px ${colors.navy600};
`;

// ===== Header S
export const headerSStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.brownFontFamily};
  font-size: 28px;
  font-weight: 700;
  letter-spacing: -0.28px;
  line-height: 1.4;
`;

// ===== Header XS
export const headerXsStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.brownFontFamily};
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1.4;
`;

// ===== Header XXS
export const headerXxsStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.brownFontFamily};
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1.4;
`;

// ========== Body typography ==========
export const bodyLinkStyles = css`
  color: ${colors.cerulean400};
  text-decoration: none;
  &:hover {
    font-style: italic;
  }
`;

// ===== Body XXXL Utopia
export const bodyXxxlRegularUtopiaStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.utopiaStdCaptionFontFamily};
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.35px;
  line-height: 1.5;
`;

export const bodyXxxlSemiBoldUtopiaStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.utopiaStdCaptionFontFamily};
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.35px;
  line-height: 1.5;
`;

export const bodyXxxlItalicUtopiaStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.utopiaStdCaptionFontFamily};
  font-size: 35px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: -0.35px;
  line-height: 1.5;
`;

// Body XXXL Brown
export const bodyXxxlRegularBrownStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.brownFontFamily};
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.35px;
  line-height: 1.5;
`;

export const bodyXxxlBoldBrownStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.brownFontFamily};
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.35px;
  line-height: 1.5;
`;

export const bodyXxxlItalicBrownStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.brownFontFamily};
  font-size: 35px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: -0.35px;
  line-height: 1.5;
`;

// ===== Body XXL Utopia
export const bodyXxlRegularUtopiaStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.utopiaStdCaptionFontFamily};
  font-size: 27px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.27px;
  line-height: 1.6;
`;
export const bodyXxlSemiBoldUtopiaStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.utopiaStdCaptionFontFamily};
  font-size: 27px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.27px;
  line-height: 1.6;
`;
export const bodyXxlItalicUtopiaStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.utopiaStdCaptionFontFamily};
  font-size: 27px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: -0.27px;
  line-height: 1.6;
`;
// Body XXL Brown
export const bodyXxlRegularBrownStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.brownFontFamily};
  font-size: 27px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.27px;
  line-height: 1.6;
`;

export const bodyXxlBoldBrownStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.brownFontFamily};
  font-size: 27px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.27px;
  line-height: 1.6;
`;

export const bodyXxlItalicBrownStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.brownFontFamily};
  font-size: 27px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: -0.27px;
  line-height: 1.6;
`;

// ===== Body XL Utopia
export const bodyXlRegularUtopiaStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.utopiaStdCaptionFontFamily};
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.23px;
  line-height: 1.6;
`;

export const bodyXlSemiBoldUtopiaStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.utopiaStdCaptionFontFamily};
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.23px;
  line-height: 1.6;
`;

export const bodyXlItalicUtopiaStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.utopiaStdCaptionFontFamily};
  font-size: 23px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: -0.23px;
  line-height: 1.6;
`;

// Body XL Brown
export const bodyXlRegularBrownStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.brownFontFamily};
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.23px;
  line-height: 1.6;
`;

export const bodyXlBoldBrownStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.brownFontFamily};
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.23px;
  line-height: 1.6;
`;

export const bodyXlItalicBrownStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.brownFontFamily};
  font-size: 23px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: -0.23px;
  line-height: 1.6;
`;

// ===== Body L Utopia
export const bodyLRegularUtopiaStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.utopiaStdCaptionFontFamily};
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.19px;
  line-height: 1.7;
`;

export const bodyLSemiBoldUtopiaStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.utopiaStdCaptionFontFamily};
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.19px;
  line-height: 1.7;
`;

export const bodyLItalicUtopiaStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.utopiaStdCaptionFontFamily};
  font-size: 19px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: -0.19px;
  line-height: 1.7;
`;

// Body L Brown
export const bodyLRegularBrownStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.brownFontFamily};
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.19px;
  line-height: 1.7;
`;

export const bodyLBoldBrownStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.brownFontFamily};
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.19px;
  line-height: 1.7;
`;

export const bodyLItalicBrownStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.brownFontFamily};
  font-size: 19px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: -0.19px;
  line-height: 1.7;
`;

// ===== Body M Utopia
export const bodyMRegularUtopiaStyles = css`
  font-family: ${fontFamilies.utopiaStdCaptionFontFamily};
  color: ${colors.navy600};
  letter-spacing: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.7;
`;

export const bodyMSemiBoldUtopiaStyles = css`
  font-family: ${fontFamilies.utopiaStdCaptionFontFamily};
  color: ${colors.navy600};
  letter-spacing: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.7;
`;

export const bodyMItalicUtopiaStyles = css`
  font-family: ${fontFamilies.utopiaStdCaptionFontFamily};
  color: ${colors.navy600};
  letter-spacing: 0;
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.7;
`;

// Body M Brown
export const bodyMRegularBrownStyles = css`
  font-family: ${fontFamilies.brownFontFamily};
  color: ${colors.navy600};
  letter-spacing: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.7;
`;

export const bodyMBoldBrownStyles = css`
  font-family: ${fontFamilies.brownFontFamily};
  color: ${colors.navy600};
  letter-spacing: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.7;
  text-transform: none;
`;

export const bodyMItalicBrownStyles = css`
  font-family: ${fontFamilies.brownFontFamily};
  color: ${colors.navy600};
  letter-spacing: 0;
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.7;
`;

// ===== Body S Utopia
export const bodySRegularUtopiaStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.utopiaStdCaptionFontFamily};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.7;
`;

export const bodySSemiBoldUtopiaStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.utopiaStdCaptionFontFamily};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.7;
`;

export const bodySItalicUtopiaStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.utopiaStdCaptionFontFamily};
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.7;
`;

// Body S Brown
export const bodySRegularBrownStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.brownFontFamily};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.7;
`;

export const bodySBoldBrownStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.brownFontFamily};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1.7;
`;

export const bodySItalicBrownStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.brownFontFamily};
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.7;
`;

// ===== Body XS Utopia
export const bodyXsRegularUtopiaStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.utopiaStdCaptionFontFamily};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.7;
`;

export const bodyXsSemiBoldUtopiaStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.utopiaStdCaptionFontFamily};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.7;
`;

export const bodyXsItalicUtopiaStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.utopiaStdCaptionFontFamily};
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.7;
`;

// Body XS Brown
export const bodyXsRegularBrownStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.brownFontFamily};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.7;
`;

export const bodyXsBoldBrownStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.brownFontFamily};
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1.7;
`;
export const bodyXsItalicBrownStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.brownFontFamily};
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.7;
`;

// ===== Body XXS Utopia
export const bodyXxsRegularUtopiaStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.utopiaStdCaptionFontFamily};
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.7;
`;

export const bodyXxsSemiBoldUtopiaStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.utopiaStdCaptionFontFamily};
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.7;
`;

export const bodyXxsItalicUtopiaStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.utopiaStdCaptionFontFamily};
  font-size: 10px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.7;
`;

// Body XXS Brown
export const bodyXxsRegularBrownStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.brownFontFamily};
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.7;
`;

export const bodyXxsBoldBrownStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.brownFontFamily};
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1.7;
`;

export const bodyXxsItalicBrownStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.brownFontFamily};
  font-size: 10px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.7;
`;

// ===== Body XXXS Utopia
export const bodyXxxsRegularUtopiaStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.utopiaStdCaptionFontFamily};
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.8;
`;

export const bodyXxxsSemiBoldUtopiaStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.utopiaStdCaptionFontFamily};
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.8;
`;

export const bodyXxxsItalicUtopiaStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.utopiaStdCaptionFontFamily};
  font-size: 8px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.8;
`;

// Body XXS Brown
export const bodyXxxsRegularBrownStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.brownFontFamily};
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.8;
`;

export const bodyXxxsBoldBrownStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.brownFontFamily};
  font-size: 8px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1.8;
`;

export const bodyXxxsItalicBrownStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.brownFontFamily};
  font-size: 8px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.8;
`;

// ========== EyebrowSStyles typography ==========
// ===== Eyebrow L
export const eyebrowLStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.brownFontFamily};
  font-size: 19px;
  font-weight: 700;
  letter-spacing: 3.42px;
  line-height: 1.4;
  text-transform: uppercase;
`;

// ===== Eyebrow M
export const eyebrowMStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.brownFontFamily};
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 2.88px;
  line-height: 1.5;
  text-transform: uppercase;
`;

// ===== Eyebrow S
export const eyebrowSStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.brownFontFamily};
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 2.34px;
  line-height: 1.5;
  text-transform: uppercase;
`;

// ===== Eyebrow XS
export const eyebrowXsStyles = css`
  color: ${colors.navy600};
  font-family: ${fontFamilies.brownFontFamily};
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 1.8px;
  line-height: 1.6;
  text-transform: uppercase;
`;

// ========== ButtonSStyles typography ==========
// ===== Button L
export const buttonLStyles = css`
  border: none;
  color: ${colors.navy600};
  font-family: ${fontFamilies.brownFontFamily};
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 2.88px;
  line-height: 1.6;
  padding: 0;
  text-transform: uppercase;
`;

// ===== Button S
export const buttonSStyles = css`
  border: none;
  color: ${colors.navy600};
  font-family: ${fontFamilies.brownFontFamily};
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 2.16px;
  line-height: 1.7;
  padding: 0;
  text-transform: uppercase;
`;

// ===== Button S with !important
// Used in cases where it is necessary to override the inline styles of TrustArc or third-party
export const buttonSStylesImportant = `
  ${buttonSStyles
    .join('')
    .split(';')
    .filter(Boolean)
    .map((s) => `${s} !important`)
    .join(';')};
`;
