import screens from '../../tailwind.screens';

/**
 * @typedef Breakpoint
 * @property {number} xs
 * @property {number} sm
 * @property {number} md
 * @property {number} lg
 */

/**
 * @type Breakpoint
 */
export const breakpoint = {
  xs: 0,
  sm: Number.parseInt(screens.sm, 10),
  md: Number.parseInt(screens.md, 10),
  lg: Number.parseInt(screens.lg, 10),
};
