import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import styled, { css } from 'styled-components';
import { Col, Row } from 'react-styled-flexboxgrid';
import {
  bodyMRegularUtopiaStyles,
  bodySRegularUtopiaStyles,
  eyebrowLStyles,
  eyebrowSStyles,
  headerMFillStyles,
  headerXlFillStyles,
  headerXsStyles,
} from '../../styles/Web3.0/typography';
import { bpWidth, bpWidthTailwind, colors, sizes, bpWidthMobileFirst } from '../../styles/Web3.0/variables';
import { renderRichTextReact } from '../../utils/storyblokRichText';
import { isBoolean, isFloat, isInt } from '../../utils/helper';

const CustomCol = styled(Col)`
  ${({ xs }) => {
    if (isInt(xs) || isFloat(xs)) {
      return css`
        flex-basis: ${(100 / sizes.gridCount.mobile) * xs}%;
        max-width: ${(100 / sizes.gridCount.mobile) * xs}%;
        display: block;
      `;
    }

    if (isBoolean(xs)) {
      if (xs) {
        return css`
          flex-grow: 1;
          flex-basis: 0;
          max-width: 100%;
          display: block;
        `;
      }

      return css`
        display: none;
      `;
    }

    return undefined;
  }};

  @media only screen and (${bpWidthTailwind.tablet}) {
    ${({ sm }) => {
      if (isInt(sm) || isFloat(sm)) {
        return css`
          flex-basis: ${(100 / sizes.gridCount.tablet) * sm}%;
          max-width: ${(100 / sizes.gridCount.tablet) * sm}%;
          display: block;
        `;
      }

      if (isBoolean(sm)) {
        if (sm) {
          return css`
            flex-grow: 1;
            flex-basis: 0;
            max-width: 100%;
            display: block;
          `;
        }

        return css`
          display: none;
        `;
      }

      return undefined;
    }};
  }

  @media only screen and (${bpWidthTailwind.desktopSm}) {
    ${({ md }) => {
      if (isInt(md) || isFloat(md)) {
        return css`
          flex-basis: ${(100 / sizes.gridCount.desktopSm) * md}%;
          max-width: ${(100 / sizes.gridCount.desktopSm) * md}%;
          display: block;
        `;
      }

      if (isBoolean(md)) {
        if (md) {
          return css`
            flex-grow: 1;
            flex-basis: 0;
            max-width: 100%;
            display: block;
          `;
        }

        return css`
          display: none;
        `;
      }

      return undefined;
    }};
  }

  @media only screen and (${bpWidthTailwind.desktopLg}) {
    ${({ lg }) => {
      if (isInt(lg) || isFloat(lg)) {
        return css`
          flex-basis: ${(100 / sizes.gridCount.desktopLg) * lg}%;
          max-width: ${(100 / sizes.gridCount.desktopLg) * lg}%;
          display: block;
        `;
      }

      if (isBoolean(lg)) {
        if (lg) {
          return css`
            flex-grow: 1;
            flex-basis: 0;
            max-width: 100%;
            display: block;
          `;
        }

        return css`
          display: none;
        `;
      }

      return undefined;
    }};
  }
`;

const LeftCol = styled(CustomCol)`
  display: flex;
  flex-direction: column;

  @media (${bpWidth.tablet}) {
    margin-bottom: 30px;
  }
`;

const RightCol = styled(CustomCol)`
  display: flex;
  flex-direction: column;
`;

const SubHeading = styled.div`
  ${eyebrowLStyles};
  color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
  margin-bottom: 30px;

  @media (${bpWidth.desktopSm}) {
    ${eyebrowSStyles};
    color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
  }

  @media (${bpWidth.tablet}) {
    margin-bottom: 20px;
  }
`;

const Heading = styled.div`
  ${headerXlFillStyles};
  color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
  font-size: 45px;
  margin-bottom: 30px;

  @media (${bpWidth.desktopSm}) {
    ${headerMFillStyles};
    color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
  }

  @media (${bpWidth.mobile}) {
    ${headerXsStyles};
    color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
  }

  @media (${bpWidthMobileFirst.tablet}) {
    margin-top: ${({ cssClass }) => (cssClass === 'how-it-works-features' ? '50px' : '0')};
  }
`;

const Body = styled.div`
  ${bodyMRegularUtopiaStyles};
  color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
  margin-bottom: 30px;

  @media (${bpWidth.tablet}) {
    ${bodySRegularUtopiaStyles};
    color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
    margin-bottom: 20px;
  }
`;

const SectionRow = styled(Row)`
  &.about-our-first-of-their-kind {
    ${Heading} {
      font-size: 45px;
    }
  }
  &.how-it-works-features {
    @media only screen and (${bpWidthTailwind.desktopSm}) {
      ${Heading} {
        margin-top: 60px;
      }
    }
  }
`;

const TwoColumnsTextWithImage2 = ({ blok }) => {
  const [subHeadingRichText, setSubHeadingRichText] = useState(null);
  const [headingRichText, setHeadingRichText] = useState(null);
  const [bodyRichText, setBodyRichText] = useState(null);
  const [leftCols, setLeftCols] = useState(null);
  const [rightCols, setRightCols] = useState(null);

  useEffect(() => {
    setSubHeadingRichText(renderRichTextReact(blok.subHeading));
  }, [blok.subHeading]);

  useEffect(() => {
    setHeadingRichText(renderRichTextReact(blok.heading));
  }, [blok.heading]);

  useEffect(() => {
    setBodyRichText(renderRichTextReact(blok.body));
  }, [blok.body]);

  useEffect(() => {
    if (blok.cssClass === 'how-it-works-features') {
      if (blok.reverseColumnOrder) {
        setLeftCols({ xs: 8, md: 4, lg: 4.7 });
        setRightCols({ xs: 8, mdOffset: 1, md: 5, lg: 6.3 });
      } else {
        setLeftCols({ xs: 8, md: 5, lg: 6.3 });
        setRightCols({ xs: 8, mdOffset: 1, md: 4, lg: 4.7 });
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (blok.reverseColumnOrder) {
        setLeftCols({ xs: 8, md: 4, lg: 5 });
        setRightCols({ xs: 8, mdOffset: 1, md: 5, lg: 6 });
      } else {
        setLeftCols({ xs: 8, md: 5, lg: 6 });
        setRightCols({ xs: 8, mdOffset: 1, md: 4, lg: 5 });
      }
    }
  }, [blok.cssClass, blok.reverseColumnOrder]);

  return (
    <Col {...storyblokEditable(blok)} xs={8} md={10} lg={12}>
      <SectionRow className={blok.cssClass}>
        {blok.reverseColumnOrder ? (
          <>
            <LeftCol {...leftCols}>
              {blok.image?.map((nestedBlok) => (
                <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
              ))}
            </LeftCol>
            <RightCol {...rightCols}>
              {/* SubHeading */}
              {subHeadingRichText && <SubHeading darkMode={blok.darkMode}>{subHeadingRichText}</SubHeading>}

              {/* Heading */}
              {headingRichText && (
                <Heading cssClass={blok.cssClass} darkMode={blok.darkMode}>
                  {headingRichText}
                </Heading>
              )}

              {/* Body */}
              {bodyRichText && (
                <Body cssClass={blok.cssClass} darkMode={blok.darkMode}>
                  {bodyRichText}
                </Body>
              )}
            </RightCol>
          </>
        ) : (
          <>
            <LeftCol {...leftCols}>
              {/* SubHeading */}
              {subHeadingRichText && <SubHeading darkMode={blok.darkMode}>{subHeadingRichText}</SubHeading>}

              {/* Heading */}
              {headingRichText && (
                <Heading cssClass={blok.cssClass} darkMode={blok.darkMode}>
                  {headingRichText}
                </Heading>
              )}

              {/* Body */}
              {bodyRichText && (
                <Body cssClass={blok.cssClass} darkMode={blok.darkMode}>
                  {bodyRichText}
                </Body>
              )}
            </LeftCol>
            <RightCol {...rightCols}>
              {blok.image?.map((nestedBlok) => (
                <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
              ))}
            </RightCol>
          </>
        )}
      </SectionRow>
    </Col>
  );
};

TwoColumnsTextWithImage2.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default TwoColumnsTextWithImage2;
