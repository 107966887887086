import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-styled-flexboxgrid';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import styled from 'styled-components';
import Slider from 'react-slick';
import { bpWidth, colors } from '../../../styles/Web3.0/variables';

const ImageCol = styled(Col)`
  margin-bottom: 40px;

  @media (${bpWidth.desktopSm}) {
    margin-bottom: 30px;
  }
`;

const ContentCol = styled(Col)`
  margin-top: 24px;

  @media (${bpWidth.desktopSm}) {
    margin-top: 30px;
  }
`;

const CarouselSlider = styled(Slider)`
  .slick-dots {
    text-align: left;
    box-sizing: border-box;
    pointer-events: none;
  }

  .slick-dots li {
    pointer-events: auto;
  }

  .slick-dots li button {
    padding: 0;
  }

  .slick-dots li button:before {
    font-size: 7px;
    line-height: 7px;
    width: 7px;
    height: 7px;
    color: ${colors.gray100};
    opacity: 1;
  }

  .slick-dots li.slick-active button:before {
    color: ${colors.gray400};
    opacity: 1;
  }

  &.press-carousel {
    .slick-dots {
      bottom: -33px;
    }

    .slick-dots li {
      width: 7px;
      height: 7px;
      margin-right: 15px;
    }

    .slick-dots li button {
      width: 100%;
      height: 100%;
    }

    .slick-dots li button:before {
      font-size: 20px;
      width: 100%;
      height: 100%;
      color: ${colors.gray400};
      opacity: 0.25;
    }

    .slick-dots li.slick-active button:before {
      color: ${colors.black};
      opacity: 1;
    }
  }
`;

const Slider2 = ({ blok }) => {
  const { assets: slideList = [], twoColumns = false, customClass } = blok;

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [currentAsset, setCurrentAsset] = useState([]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplaySpeed: 4000,
    autoplay: true,
    beforeChange: (oldIndex, newIndex) => {
      setCurrentSlideIndex(newIndex);
    },
    onInit: () => {
      setCurrentSlideIndex(0);
    },
  };

  useEffect(() => {
    const asset = slideList[currentSlideIndex]?.asset;
    setCurrentAsset(asset);
  }, [currentSlideIndex]);

  const pressCarouselAssetCss = useMemo(
    () => (customClass === 'press-carousel' ? 'md:min-h-[18.75rem] lg:min-h-[31.25rem]' : undefined),
    [customClass]
  );

  const pressCarouselAssetMobileCss = useMemo(
    () => (customClass === 'press-carousel' ? 'min-h-[15rem] sm:min-h-[28.125rem] md:min-h-0' : undefined),
    [customClass]
  );

  return (
    <Col {...storyblokEditable(blok)} xs={8} md={10} lg={12}>
      <Row>
        {twoColumns ? (
          <>
            <Col xsOffset={1} xs={6} mdOffset={0} md={0} lg={0} className={pressCarouselAssetMobileCss}>
              <Row>
                {currentAsset?.map((nestedBlok) => (
                  <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
                ))}
              </Row>
            </Col>
            <ContentCol xsOffset={1} xs={6} smOffset={2} sm={4} mdOffset={2} md={3} lgOffset={2} lg={3}>
              <CarouselSlider {...settings} className={customClass}>
                {slideList?.map((nestedBlok) => (
                  <Row key={nestedBlok._uid}>
                    <StoryblokComponent blok={nestedBlok} />
                  </Row>
                ))}
              </CarouselSlider>
            </ContentCol>
            <Col xs={0} md={3} lgOffset={1} lg={4} className={pressCarouselAssetCss}>
              <Row>
                {currentAsset?.map((nestedBlok) => (
                  <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
                ))}
              </Row>
            </Col>
          </>
        ) : (
          <>
            <ImageCol xsOffset={1} xs={6} smOffset={1} sm={6} mdOffset={2} md={6} lgOffset={3} lg={6}>
              <Row>
                {currentAsset?.map((nestedBlok) => (
                  <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
                ))}
              </Row>
            </ImageCol>
            <Col xsOffset={1} xs={6} smOffset={2} sm={4} mdOffset={3} md={4} lgOffset={4} lg={4}>
              <CarouselSlider {...settings}>
                {slideList?.map((nestedBlok) => {
                  return (
                    <Row key={nestedBlok._uid}>
                      <StoryblokComponent blok={nestedBlok} />
                    </Row>
                  );
                })}
              </CarouselSlider>
            </Col>
          </>
        )}
      </Row>
    </Col>
  );
};

Slider2.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default Slider2;
