export const EXTERNAL_URL = {
  SHOP_URL: 'https://shop.piaggiofastforward.com/',
  MY_GITA_SHOP_URL: 'https://shop.mygita.com',
  MY_GITA_BLOG_URL: 'https://blog.mygita.com',
};

export const CANONICAL_URLS = {
  ABOUT: 'https://piaggiofastforward.com/about',
  BLOG: 'https://piaggiofastforward.com/blog',
  BUSINESS: 'https://piaggiofastforward.com/business',
  BUSINESS_CONTACT: 'https://piaggiofastforward.com/business/contact',
  BUSINESS_KILO: 'https://piaggiofastforward.com/business/kilo',
  BUSINESS_KILO_PRODUCT_SHEET: 'https://piaggiofastforward.com/business/kilo-product-sheet',
  BUSINESS_SUPPORT: 'https://piaggiofastforward.com/business/support',
  CAREERS: 'https://piaggiofastforward.com/careers',
  CONTACT: 'https://piaggiofastforward.com/contact',
  COOKIE_POLICY: 'https://piaggiofastforward.com/cookie-policy',
  DATA_POLICY: 'https://piaggiofastforward.com/data-policy',
  FAQS: 'https://piaggiofastforward.com/faqs',
  HOME: 'https://piaggiofastforward.com/',
  HOW_IT_WORKS: 'https://piaggiofastforward.com/how-it-works',
  OPEN_SOURCE: 'https://piaggiofastforward.com/open-source',
  PATENTS: 'https://piaggiofastforward.com/patents',
  PFF_FOLLOW_USER_MANUAL: 'https://piaggiofastforward.com/PFF-follow-user-manual',
  PRESS: 'https://piaggiofastforward.com/press',
  PRIVACY_POLICY: 'https://piaggiofastforward.com/privacy-policy',
  PROMOTION_TERMS: 'https://piaggiofastforward.com/promotion-terms',
  SHOP: 'https://piaggiofastforward.com/shop',
  SHOP_GITAMINI: 'https://piaggiofastforward.com/shop/gitamini',
  SHOP_GITAPLUS: 'https://piaggiofastforward.com/shop/gitaplus',
  SUPPORT: 'https://piaggiofastforward.com/support',
  TEAM: 'https://piaggiofastforward.com/team',
  TERMS_OF_SALE: 'https://piaggiofastforward.com/terms-of-sale',
  TERMS_OF_USE: 'https://piaggiofastforward.com/terms-of-use',
};
