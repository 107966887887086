import { css } from 'styled-components';
import { bodySBoldBrownStyles, bodyXxsBoldBrownStyles, bodyXxxsBoldBrownStyles } from './typography';
import { bpWidth, colors } from './variables.js';

// ===== Size options =====
export const textAlignment = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const sizeS = css`
  ${textAlignment};
  height: 23px;
  width: 78px;
`;
export const sizeM = css`
  ${textAlignment};
  height: 30px;
  width: 111px;
`;
export const sizeL = css`
  ${textAlignment};
  height: 42px;
  width: 148px;
`;

// ===== Color options =====
export const defaultColor = css`
  background: ${colors.gray300};
  color: ${colors.beige100};
`;
export const tesimonialColor = css`
  background: ${colors.navy100};
  color: ${colors.beige100};
`;
export const technologyColor = css`
  background: ${colors.cerulean100};
  color: ${colors.navy600};
`;
export const localLivingColor = css`
  background: ${colors.orange100};
  color: ${colors.navy600};
`;

// ===== Default tag =====
// Small
export const defaultS = css`
  ${bodyXxxsBoldBrownStyles};
  ${defaultColor};
  ${sizeS};
`;
// Medium
export const defaultM = css`
  ${bodyXxsBoldBrownStyles};
  ${defaultColor};
  ${sizeM};
`;
// Large
export const defaultL = css`
  ${bodySBoldBrownStyles};
  ${defaultColor};
  ${sizeL};
`;

// ===== Tesimonial tag =====
// Small
export const tesimonialS = css`
  ${bodyXxxsBoldBrownStyles};
  ${tesimonialColor};
  ${sizeS};
`;
// Medium
export const tesimonialM = css`
  ${bodyXxsBoldBrownStyles};
  ${tesimonialColor};
  ${sizeM};
`;
// Large
export const tesimonialL = css`
  ${bodySBoldBrownStyles};
  ${tesimonialColor};
  ${sizeL};
`;

// ===== Technology tag =====
// Small
export const technologyS = css`
  ${bodyXxxsBoldBrownStyles};
  ${technologyColor};
  ${sizeS};
`;
// Medium
export const technologyM = css`
  ${bodyXxsBoldBrownStyles};
  ${technologyColor};
  ${sizeM};
`;
// Large
export const technologyL = css`
  ${bodySBoldBrownStyles};
  ${technologyColor};
  ${sizeL};
`;

// ===== Local Living tag =====
// Small
export const localLivingS = css`
  ${bodyXxxsBoldBrownStyles};
  ${localLivingColor};
  ${sizeS};
`;
// Medium
export const localLivingM = css`
  ${bodyXxsBoldBrownStyles};
  ${localLivingColor};
  ${sizeM};
`;
// Large
export const localLivingL = css`
  ${bodySBoldBrownStyles};
  ${localLivingColor};
  ${sizeL};
`;

// ===== Standard setup =====
export const standardTags = css`
  &.localLiving {
    ${localLivingL};
  }
  &.technology {
    ${technologyL};
  }
  &.testimonials {
    ${tesimonialL};
  }
  @media (${bpWidth.desktopSm}) {
    &.localLiving {
      ${localLivingM};
    }
    &.technology {
      ${technologyM};
    }
    &.testimonials {
      ${tesimonialM};
    }
  }
  @media (${bpWidth.mobile}) {
    &.localLiving {
      ${localLivingS};
    }
    &.technology {
      ${technologyS};
    }
    &.testimonials {
      ${tesimonialS};
    }
  }
`;
