import React from 'react';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import { Col, Row } from 'react-styled-flexboxgrid';
import { func, object } from 'prop-types';
import AnimateHeight from 'react-animate-height';
import styled from 'styled-components';
import { bpWidth, colors } from '../../../styles/Web3.0/variables';
import {
  bodyMBoldBrownStyles,
  bodySBoldBrownStyles,
  buttonSStyles,
  eyebrowMStyles,
  eyebrowXsStyles,
} from '../../../styles/Web3.0/typography';
import Chevron from '../../../icons/Web3.0/arrow-DCerulean.svg';
import ArrowDown from '../../../icons/Web3.0/arrow-down.svg';

const ValueCol = styled(Col)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border-top: 1px solid ${colors.gray100};
  padding-top: 40px;
  padding-bottom: 40px;

  &:last-of-type {
    border-bottom: 1px solid ${colors.gray100};
  }

  @media (${bpWidth.desktopSm}) {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  @media (${bpWidth.tablet}) {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  @media (${bpWidth.mobile}) {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .value-heading-container {
    flex-grow: 1;
    display: flex;
    cursor: pointer;

    span.value-heading-icon {
      width: ${({ theme }) => `calc(100% * (1 / ${theme?.flexboxgrid?.gridSize || 12}))`};
      margin-right: ${({ theme }) => `calc(100% * (1 / ${theme?.flexboxgrid?.gridSize || 12}))`};
      text-align: center;
    }

    h4.value-heading {
      ${bodyMBoldBrownStyles};
      flex: 1 0;
    }

    .value-cta-arrow {
      width: auto;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      ${eyebrowMStyles};

      .chevron-label {
        margin-right: 15px;
      }

      svg {
        width: 13px;

        path {
          stroke: ${colors.navy600};
        }
      }

      @media (${bpWidth.mobile}) {
        ${eyebrowXsStyles};
      }
    }
  }

  @media (${bpWidth.mobile}) {
    .value-heading-container {
      h4.value-heading {
        ${bodySBoldBrownStyles};
      }
    }
  }
`;

const StyledAnimateHeight = styled(AnimateHeight)``;

function CTAArrow(isExpand, showLabel = false) {
  return (
    <>
      {showLabel && <span className="chevron-label">{isExpand ? 'Collapse' : 'Expand'}</span>}
      <Chevron
        style={{
          transitionDuration: '.1s',
          transform: isExpand ? 'rotate(180deg)' : 'none',
        }}
      />
    </>
  );
}

const DownloadButtonContainerRow = styled(Row)`
  color: ${colors.cerulean400};

  button {
    padding: 0;
    cursor: pointer;
    ${buttonSStyles};
    color: ${colors.cerulean400};
    background-color: ${colors.beige100};
    align-self: flex-start;
    margin-bottom: 40px;

    svg {
      padding-left: 5px;
      width: 100%;
      max-width: 13.31px;
      height: auto;
    }

    &:hover {
      color: ${colors.cerulean700};
    }
  }
`;

const MediaKitAccordionItemRow = styled(Row)`
  justify-content: stretch;
  row-gap: 30px;
`;

const MediaKitAccordionItem = ({ blok, onExpand }) => {
  return (
    <ValueCol {...storyblokEditable(blok)} xs={8} md={10} lg={12}>
      <div role="presentation" className="value-heading-container" onClick={() => onExpand(blok._uid)}>
        <h4 className="value-heading">{blok.heading}</h4>
        <div className="value-cta-arrow">{CTAArrow(blok.isExpand)}</div>
      </div>
      <StyledAnimateHeight duration={250} height={blok.isExpand ? 'auto' : 0} animateOpacity>
        <DownloadButtonContainerRow>
          <Col>
            <a href={blok.sectionAsset?.filename}>
              <button type="button">
                Download All
                <ArrowDown />
              </button>
            </a>
          </Col>
        </DownloadButtonContainerRow>
        <MediaKitAccordionItemRow>
          {blok.assets?.map((asset) => (
            <StoryblokComponent blok={asset} key={asset._uid} />
          ))}
        </MediaKitAccordionItemRow>
      </StyledAnimateHeight>
    </ValueCol>
  );
};

MediaKitAccordionItem.propTypes = {
  blok: object.isRequired,
  onExpand: func.isRequired,
};

export default MediaKitAccordionItem;
