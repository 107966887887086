import React from 'react';
import PropTypes from 'prop-types';
import { storyblokEditable } from 'gatsby-source-storyblok';
import styled from 'styled-components';
import { Col, Row } from 'react-styled-flexboxgrid';
import {
  bodyLItalicUtopiaStyles,
  bodyMRegularUtopiaStyles,
  bodySRegularUtopiaStyles,
  bodyXlItalicUtopiaStyles,
  bodyXxlItalicUtopiaStyles,
  bodyXxxlItalicUtopiaStyles,
} from '../../styles/Web3.0/typography';
import { bpWidth } from '../../styles/Web3.0/variables';
import { renderRichTextReact } from '../../utils/storyblokRichText';

const LeftCol = styled(Col)`
  padding-right: 20px;
  margin-top: -6px;

  @media (${bpWidth.mobile}) {
    padding-right: 0;
    margin-top: 0;
    margin-bottom: 20px;
  }
`;

const RightCol = styled(Col)``;

const Title = styled.div`
  ${bodyXxxlItalicUtopiaStyles};
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  height: 100%;

  @media (${bpWidth.desktopSm}) {
    ${bodyXxlItalicUtopiaStyles};
  }

  @media (${bpWidth.desktopSm}) {
    ${bodyXlItalicUtopiaStyles};
  }

  @media (${bpWidth.mobile}) {
    ${bodyLItalicUtopiaStyles};
    justify-content: flex-start;
  }
`;

const Text = styled.div`
  ${bodyMRegularUtopiaStyles};
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;

  @media (${bpWidth.desktopSm}) {
    ${bodySRegularUtopiaStyles};
  }
`;

const TwoColumnsTitleText = ({ blok }) => {
  const { title, text } = blok;
  return (
    <Col {...storyblokEditable(blok)} xs={8} md={10} lg={12} className="two-columns-title-text-component">
      <Row>
        <LeftCol xs={8} sm={4} md={5} lg={6} className="two-columns-title-text-component--left-col">
          <Title className="two-columns-title-text-component--title">{renderRichTextReact(title)}</Title>
        </LeftCol>
        <RightCol xs={8} sm={4} md={5} lg={6} className="two-columns-title-text-component--right-col">
          <Text className="two-columns-title-text-component--text">{renderRichTextReact(text)}</Text>
        </RightCol>
      </Row>
    </Col>
  );
};

TwoColumnsTitleText.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default TwoColumnsTitleText;
