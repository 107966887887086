import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';
import { MARK_LINK } from 'storyblok-rich-text-react-renderer';
import EnterIcon from '../../../img/PFF-brand-icons-thin-enter.svg';
import {
  eyebrowSStyles,
  eyebrowMStyles,
  bodyLinkStyles,
  bodyXxxsRegularBrownStyles,
  bodyXxsRegularBrownStyles,
  bodySRegularBrownStyles,
} from '../../../styles/Web3.0/typography';
import { bpWidth, colors } from '../../../styles/Web3.0/variables';
import { renderRichTextReact } from '../../../utils/storyblokRichText';

// elements
const FormBodyWrapper = styled.div`
  &.hide {
    display: none;
  }
`;

const InputSubmit = styled.div`
  border-bottom: 1px solid ${colors.gray100};
  cursor: pointer;
  display: flex;
`;

const Input = styled(Field).attrs({
  type: 'email',
})`
  ${eyebrowMStyles};
  background: transparent;
  border: none;

  width: 100%;

  &::placeholder {
    color: ${colors.navy600};
  }
  &::selection {
    background: pink;
  }

  @media (${bpWidth.desktopSm}) {
    ${eyebrowSStyles}
  }
`;

const Submit = styled.button.attrs({
  type: 'submit',
})`
  background: none;
  border: none;

  padding: 0;

  svg {
    height: 21px;
  }

  svg,
  svg * {
    color: ${colors.navy600};
    fill: ${colors.navy600};
  }

  @media (${bpWidth.desktopSm}) {
    svg {
      height: 19px;
    }
  }
`;

const Disclaimer = styled.div`
  ${bodyXxsRegularBrownStyles};
  color: ${colors.gray300};
  padding-top: 10px;

  @media (${bpWidth.desktopSm}) {
    ${bodyXxxsRegularBrownStyles};
    color: ${colors.gray300};
  }
`;

const ExternalLink = styled.a.attrs({
  target: '_blank',
})`
  ${bodyLinkStyles};
  color: ${colors.cerulean400} !important;

  &:hover {
    color: ${colors.cerulean700} !important;
  }
`;

const ErrorStyles = styled.div`
  ${bodySRegularBrownStyles};
  color: ${colors.orange700};

  @media (${bpWidth.desktopSm}) {
    ${bodyXxsRegularBrownStyles};
    color: ${colors.orange700};
  }
`;
/**
 * @type {RenderOptions}
 */
const renderOptions = {
  markResolvers: {
    [MARK_LINK]: (children, { href }) => {
      return <ExternalLink href={href}>{children}</ExternalLink>;
    },
  },
};

function EmailForm(props) {
  const { handleSubmit, privacyPolicyText } = props;

  return (
    <form onSubmit={handleSubmit}>
      <FormBodyWrapper>
        <InputSubmit>
          <Input name="email" placeholder="NEWSLETTER SIGN UP" />

          <Submit>
            <EnterIcon alt="Form enter icon" />
          </Submit>
        </InputSubmit>

        <ErrorStyles>
          <ErrorMessage name="email" />
        </ErrorStyles>

        {privacyPolicyText && <Disclaimer>{renderRichTextReact(privacyPolicyText, renderOptions)}</Disclaimer>}
      </FormBodyWrapper>
    </form>
  );
}

EmailForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  privacyPolicyText: PropTypes.object,
};
export default EmailForm;
