import React from 'react';
import { func } from 'prop-types';

// styles
import styled from 'styled-components';
import { Col, Row } from 'react-styled-flexboxgrid';
import { bpWidth, colors } from '../../../styles/Web3.0/variables';

// components
import SearchIcon from '../../../icons/Web3.0/PFF-brand-icons-thin-search.svg';
import CloseIcon from '../../../icons/Web3.0/PFF-brand-icons-thin-close.svg';

// Elements
const SearchHeaderRow = styled(Row)`
  flex: 0 1 auto;
  padding: 20px 0 79px 0;

  @media (${bpWidth.desktopSm}) {
    padding: 17px 0 71px 0;
  }
  @media (${bpWidth.tablet}) {
    padding-bottom: 65px;
  }
  @media (${bpWidth.tablet}) {
    padding-bottom: 54px;
  }
`;

const SearchIconCol = styled(Col)`
  display: flex;
  align-items: center;

  svg {
    cursor: pointer;
    width: 25px;

    @media (${bpWidth.tablet}) {
      width: 20px;
    }
    @media (${bpWidth.mobile}) {
      width: 17px;
    }
  }
`;

const CloseIconCol = styled(Col)`
  display: flex;
  align-items: center;

  @media (${bpWidth.tablet}) {
    justify-content: flex-end;
  }
`;

const CloseIconStyled = styled(CloseIcon)`
  cursor: pointer;
  width: 25px;
  height: auto;
  color: ${colors.navy600};
  fill: ${colors.navy600};

  * {
    color: ${colors.navy600}!important;
    fill: ${colors.navy600}!important;
  }

  @media (${bpWidth.tablet}) {
    width: 20px;
    margin-top: 0;
  }
  @media (${bpWidth.mobile}) {
    width: 17px;
    margin-top: 0;
  }
`;

const SearchHeader = (props) => {
  const { handleSearchToggle } = props;

  return (
    <SearchHeaderRow>
      <Col xsOffset={1} xs={6} md={8} lg={10}>
        <Row>
          {/* Search icon */}
          <SearchIconCol xs={1}>
            <SearchIcon onClick={handleSearchToggle} />
          </SearchIconCol>

          {/* Close icon */}
          <CloseIconCol xsOffset={6} mdOffset={8} lgOffset={10} xs={1}>
            <CloseIconStyled onClick={handleSearchToggle} />
          </CloseIconCol>
        </Row>
      </Col>
    </SearchHeaderRow>
  );
};

// props
const propTypes = {
  handleSearchToggle: func,
};

const defaultProps = {
  handleSearchToggle: () => {},
};

SearchHeader.propTypes = propTypes;
SearchHeader.defaultProps = defaultProps;
export default SearchHeader;
