import React from 'react';
import { bool, string } from 'prop-types';

// styles
import styled, { css } from 'styled-components';
import { bpWidth, colors } from '../../../styles/Web3.0/variables.js';

const transformDuration = '150ms';
const topDuration = '100ms';

const buildHamburger = (hitArea, lineWidth) => css`
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: relative;
  display: none;
  height: ${hitArea}px;
  width: ${lineWidth}px;

  &::before,
  &::after {
    background-color: currentColor;
    color: ${colors.navy600};
    content: '';
    display: block;
    height: 1.4px;
    position: absolute;
    transition: transform ${transformDuration} ease-in 0s, top ${topDuration} ease-out ${transformDuration};
    width: ${hitArea}px;

    @media (${bpWidth.mobile}) {
      height: 1.2px;
    }
  }

  &::before {
    top: ${hitArea * (1 / 4)}px;
    transform: translateY(-50%);
  }

  &::after {
    top: ${hitArea * (3 / 4)}px;
    transform: translateY(-50%);
  }

  @media (${bpWidth.tablet}) {
    display: block;
  }

  ${(props) =>
    props.isX &&
    css`
      &::before,
      &::after {
        height: 1px;
        top: 50%;
        transition: top ${topDuration} ease-in 0s, transform ${transformDuration} ease-out ${topDuration};
      }

      &::before {
        transform: translateY(-50%) rotate(-45deg);
      }

      &::after {
        transform: translateY(-50%) rotate(45deg);
      }
    `}
`;

const Container = styled.button`
  outline: 0;
  ${buildHamburger(16, 25)}

  @media (${bpWidth.mobile}) {
    ${buildHamburger(12, 20)}
  }
`;

function Hamburger(props) {
  const { isX, color, ...rest } = props;
  return <Container isX={isX} aria-label="Navigation menu" fontColor={color} {...rest} />;
}

const propTypes = {
  isX: bool,
  color: string,
};

const defaultProps = {
  isX: false,
  // eslint-disable-next-line react/default-props-match-prop-types
  color: colors.floralWhite,
};

Hamburger.propTypes = propTypes;
Hamburger.defaultProps = defaultProps;
export default Hamburger;
