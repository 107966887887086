import React from 'react';
import PropTypes from 'prop-types';
import { storyblokEditable } from 'gatsby-source-storyblok';
import styled from 'styled-components';
import { Col } from 'react-styled-flexboxgrid';
import {
  bodyMItalicUtopiaStyles,
  bodyXlItalicUtopiaStyles,
  eyebrowMStyles,
  eyebrowSStyles,
  headerSStyles,
  headerXsStyles,
} from '../../styles/Web3.0/typography';
import { bpWidth } from '../../styles/Web3.0/variables';
import { renderRichTextReact } from '../../utils/storyblokRichText';

const Title = styled.h3`
  ${headerSStyles};
  margin-bottom: 40px;

  @media (${bpWidth.desktopSm}) {
    ${headerXsStyles};
    margin-bottom: 30px;
  }
`;

const Logo = styled.img`
  max-width: 100%;
  object-fit: contain;
  margin-bottom: 40px;

  @media (${bpWidth.desktopSm}) {
    margin-bottom: 30px;
  }
`;

const Quote = styled.blockquote`
  quotes: '“' '”' '‘' '’';
  margin-bottom: 20px;

  &:before {
    content: open-quote;
  }
  &:after {
    content: close-quote;
  }

  p {
    display: unset;
  }

  ${bodyXlItalicUtopiaStyles};

  @media (${bpWidth.desktopSm}) {
    ${bodyMItalicUtopiaStyles};
  }
`;

const Quotee = styled.h4`
  ${eyebrowMStyles};
  margin-bottom: 40px;

  @media (${bpWidth.desktopSm}) {
    ${eyebrowSStyles};
    margin-bottom: 30px;
  }
`;

const SingleColumnQuote = ({ blok }) => {
  const { title, logo, quote, quotee, position } = blok;

  return (
    <Col {...storyblokEditable(blok)} xs={8} md={10} lg={12}>
      {title && <Title>{title}</Title>}
      {logo?.filename?.length > 0 && <Logo src={logo.filename} />}
      {quote && <Quote>{renderRichTextReact(quote)}</Quote>}
      {(quotee || position) && <Quotee>{[quotee.trim(), position.trim()].filter(Boolean).join(', ')}</Quotee>}
    </Col>
  );
};

SingleColumnQuote.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default SingleColumnQuote;
