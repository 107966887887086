import React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import { object } from 'prop-types';
import styled from 'styled-components';
import { headerLFillStyles, headerMStyles, headerSStyles } from '../../../styles/Web3.0/typography';
import { bpWidth, colors } from '../../../styles/Web3.0/variables';

const Heading = styled.h2`
  ${headerLFillStyles};
  color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};

  @media (${bpWidth.tablet}) {
    ${headerMStyles};
    color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
  }

  @media (${bpWidth.mobile}) {
    ${headerSStyles};
    color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
  }
`;

const ProductListItemRow = styled.div``;

const ProductListItemCol = styled.div``;

const BusinessProductList = ({ blok }) => {
  const { heading, list, darkMode } = blok;

  return (
    <Col {...storyblokEditable(blok)} xs={8} md={10} lg={12}>
      {heading && (
        <Row>
          <Col xs={8} md={10} lg={12}>
            <Heading darkMode={darkMode}>{heading}</Heading>
          </Col>
        </Row>
      )}

      {/* Products */}
      <ProductListItemRow className="flex flex-col sm:gap-12 md:flex-row mt-9 lg:mt-16 md:gap-10 lg:gap-14 lg:px-[3%]">
        {list?.map((nestedBlok, index) => (
          <ProductListItemCol
            key={nestedBlok._uid}
            className={`flex-1 ${index === 0 ? 'order-2 sm:order-1' : index === 1 ? 'order-1 sm:order-2' : 'order-3'}`}
          >
            <Row>
              <StoryblokComponent blok={nestedBlok} darkMode={darkMode} index={index} />
            </Row>
          </ProductListItemCol>
        ))}
      </ProductListItemRow>
    </Col>
  );
};

BusinessProductList.propTypes = {
  blok: object.isRequired,
};

export default BusinessProductList;
