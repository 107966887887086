/**
 * @typedef {Object} BusinessAppStateSettingKeys
 * @property {boolean} showContactButton
 */

export const initialSettings = {
  showContactButton: false,
};

/**
 * @type {BusinessAppStateSettingKeys}
 */
export const inputLabels = (() => {
  const target = {};
  for (const setting in initialSettings) {
    if (Object.hasOwn(initialSettings, setting)) {
      target[setting] = setting;
    }
  }
  return target;
})();

export function settingsReducer(state, action) {
  return {
    ...state,
    [action.name]: action.value === 'default' ? undefined : action.value,
  };
}
