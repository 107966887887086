import React, { useContext, useEffect, useState } from 'react';
import fetch from 'isomorphic-fetch';
import Client from 'shopify-buy';
import _ from 'lodash';
import { any } from 'prop-types';
import { useSsr } from '../hooks';
import useLocation from '../hooks/useLocation';
import { tiktokAddToCartGitas, tiktokAddToCartGitamini, tiktokAddToCartGitaplus } from '../utils/tiktokEvents.js';
import { redditAddToCartGitas, redditAddToCartGitaplus, redditAddToCartGitamini } from '../utils/redditEvents.js';
import { metaAddToCartGitas, metaAddToCartGitamini, metaAddToCartGitaplus } from '../utils/metaEvents.js';
import { PRODUCT_IDS } from '../constants';
import { dataLayerAddToCart } from '../utils/dataLayerEvents.js';

const client = Client.buildClient(
  {
    domain: process.env.GATSBY_SHOPIFY_STORE_URL,
    storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
  },
  fetch
);

const defaultValues = {
  cart: [],
  isOpen: false,
  loading: false,
  onOpen: () => {},
  onClose: () => {},
  onToggle: () => {},
  addVariantToCart: () => {},
  removeLineItem: () => {},
  updateLineItem: () => {},
  client,
  checkout: {
    lineItems: [],
  },
  didJustAddToCart: false,
};

export const StoreContext = React.createContext(defaultValues);

export const useShopifyStore = () => {
  const context = useContext(StoreContext);
  if (!context) {
    throw new Error('useShopifyStore must be used within the StoreProvider');
  }
  return context;
};

const localStorageKey = `shopify_checkout_id`;

export const StoreProvider = ({ children }) => {
  const { pathname } = useLocation();

  const [checkout, setCheckout] = useState(defaultValues.checkout);
  const [loading, setLoading] = useState(false);
  const [didJustAddToCart, setDidJustAddToCart] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { isBrowser } = useSsr();

  const ttq = isBrowser ? window.ttq || {} : {};
  const rdt = isBrowser ? window.rdt || {} : {};
  const fbq = isBrowser ? window.fbq || {} : {};

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = (force = true) => {
    if (force || pathname.startsWith('/shop')) {
      setIsOpen(false);
    } else {
      window.location.href = '/shop';
    }
  };

  const onToggle = (forceClose = true) => {
    if (isOpen) {
      onClose(forceClose);
    } else {
      onOpen();
    }
  };

  // 1) set the checkout ID in local storage
  // 2) set checkout data into checkout state var
  // eslint-disable-next-line no-shadow
  const setCheckoutItem = (checkout) => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout.id);
    }

    setCheckout(checkout);
    setLoading(false);
  };

  // this effect initializes the checkout:
  // 1) get existing checkout ID from local storage if it exists
  // 2) if it exists, fetch the checkout's data from Shopify
  // 3) if this checkout isn't already completed, set the existing checkout's data into checkout state var
  // 4) if no existing checkout or already-completed checkout, create a new checkout with Shopify and set it into checkout state var
  useEffect(() => {
    const initializeCheckout = async () => {
      setLoading(true);

      const existingCheckoutID = isBrowser ? localStorage.getItem(localStorageKey) : null;

      if (existingCheckoutID && existingCheckoutID !== `null`) {
        try {
          const existingCheckout = await client.checkout.fetch(existingCheckoutID);
          if (!existingCheckout.completedAt) {
            setCheckoutItem(existingCheckout);
            return;
          }
        } catch (e) {
          localStorage.setItem(localStorageKey, null);
        }
      }

      const newCheckout = await client.checkout.create();
      setCheckoutItem(newCheckout);
    };

    initializeCheckout();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      document.documentElement.classList.toggle('is-locked', isOpen);
    }, 300);
  }, [isOpen]);

  const handleOutsideCartClick = ($event) => {
    if (isOpen && $event.target.id === 'outerCartEl') {
      onClose(true);
    }
  };

  // close cart when clicking outside of it
  useEffect(() => {
    document.addEventListener('click', handleOutsideCartClick);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('click', handleOutsideCartClick);
    };
  }, [isOpen]);

  // this function takes a variant ID and the quantity to be added, and adds them to the checkout object with Shopify,
  // which in turns gets set into the checkout state var
  const addVariantToCart = (variant, quantity) => {
    const { storefrontId: variantId } = variant;

    setLoading(true);
    if (!isOpen) {
      onOpen();
    }

    const checkoutID = checkout.id;

    const lineItemsToUpdate = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
      },
    ];

    // tiktok events
    if (_.has(ttq, 'track')) {
      if (
        _.includes(variantId, PRODUCT_IDS.SHOPIFY_VARIANT_IDS?.GITAMINI_BOARDWALK_BEIGE) ||
        _.includes(variantId, PRODUCT_IDS.SHOPIFY_VARIANT_IDS?.GITAMINI_SHADOW_BLACK) ||
        _.includes(variantId, PRODUCT_IDS.SHOPIFY_VARIANT_IDS?.GITAMINI_SPARK_CITRON)
      ) {
        tiktokAddToCartGitamini(ttq);
        tiktokAddToCartGitas(ttq);
      }

      if (
        _.includes(variantId, PRODUCT_IDS.SHOPIFY_VARIANT_IDS?.GITAPLUS_RAPID_BLUE) ||
        _.includes(variantId, PRODUCT_IDS.SHOPIFY_VARIANT_IDS?.GITAPLUS_ESPRESSO_BROWN)
      ) {
        tiktokAddToCartGitaplus(ttq);
        tiktokAddToCartGitas(ttq);
      }
    }

    // reddit events
    if (_.isFunction(rdt)) {
      if (
        _.includes(variantId, PRODUCT_IDS.SHOPIFY_VARIANT_IDS?.GITAMINI_BOARDWALK_BEIGE) ||
        _.includes(variantId, PRODUCT_IDS.SHOPIFY_VARIANT_IDS?.GITAMINI_SHADOW_BLACK) ||
        _.includes(variantId, PRODUCT_IDS.SHOPIFY_VARIANT_IDS?.GITAMINI_SPARK_CITRON)
      ) {
        redditAddToCartGitamini(rdt);
        redditAddToCartGitas(rdt);
      }

      if (
        _.includes(variantId, PRODUCT_IDS.SHOPIFY_VARIANT_IDS?.GITAPLUS_RAPID_BLUE) ||
        _.includes(variantId, PRODUCT_IDS.SHOPIFY_VARIANT_IDS?.GITAPLUS_ESPRESSO_BROWN)
      ) {
        redditAddToCartGitaplus(rdt);
        redditAddToCartGitas(rdt);
      }
    }

    // meta events
    if (_.isFunction(fbq)) {
      if (
        _.includes(variantId, PRODUCT_IDS.SHOPIFY_VARIANT_IDS?.GITAMINI_BOARDWALK_BEIGE) ||
        _.includes(variantId, PRODUCT_IDS.SHOPIFY_VARIANT_IDS?.GITAMINI_SHADOW_BLACK) ||
        _.includes(variantId, PRODUCT_IDS.SHOPIFY_VARIANT_IDS?.GITAMINI_SPARK_CITRON)
      ) {
        metaAddToCartGitamini(fbq);
        metaAddToCartGitas(fbq);
      }

      if (
        _.includes(variantId, PRODUCT_IDS.SHOPIFY_VARIANT_IDS?.GITAPLUS_RAPID_BLUE) ||
        _.includes(variantId, PRODUCT_IDS.SHOPIFY_VARIANT_IDS?.GITAPLUS_ESPRESSO_BROWN)
      ) {
        metaAddToCartGitaplus(fbq);
        metaAddToCartGitas(fbq);
      }
    }

    // dataLayer event
    dataLayerAddToCart(variant);

    return client.checkout.addLineItems(checkoutID, lineItemsToUpdate).then((res) => {
      setCheckout(res);
      setLoading(false);
      setDidJustAddToCart(true);
      setTimeout(() => setDidJustAddToCart(false), 2000);
    });
  };

  // this function takes the checkout ID and the line item ID and removes it from the checkout object with Shopify,
  // which in turn gets set into the checkout state var
  const removeLineItem = (checkoutID, lineItemID) => {
    setLoading(true);

    return client.checkout.removeLineItems(checkoutID, [lineItemID]).then((res) => {
      setCheckout(res);
      setLoading(false);
    });
  };

  // this function takes the checkout ID, line item ID, and the quantity to update it to,
  // and sets it in the checkout object with Shopify, which in turn gets set into the checkout state var
  const updateLineItem = (checkoutID, lineItemID, quantity) => {
    setLoading(true);

    const lineItemsToUpdate = [{ id: lineItemID, quantity: parseInt(quantity, 10) }];

    return client.checkout.updateLineItems(checkoutID, lineItemsToUpdate).then((res) => {
      setCheckout(res);
      setLoading(false);
    });
  };

  return (
    <StoreContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        ...defaultValues,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        checkout,
        loading,
        didJustAddToCart,
        isOpen,
        onToggle,
        onOpen,
        onClose,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

StoreProvider.propTypes = {
  children: any,
};
