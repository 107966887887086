import React, { useState } from 'react';
import { Col } from 'react-styled-flexboxgrid';
import { object } from 'prop-types';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import FixedScrollArrow from '../../Web3.0/FixedShopCta/FixedScrollArrow';
import BioPopUp from './BioPopUp';

const Team = ({ blok }) => {
  const [biosOpen, setBiosOpen] = useState(false);
  const [bioIndexClicked, setBioIndexClicked] = useState(0);
  const [carouselMembers, setCarouselMembers] = useState([]);

  // Close profile popup
  const handleBioClose = (closeBtnClicked) => {
    if (closeBtnClicked) {
      setBiosOpen(false);
    }
  };

  // Trigger profile popup on click
  const onProfileClick = (member, members) => {
    const enabledList = members?.filter((m) => m.enableBio) || [];
    const idx = enabledList.findIndex((m) => m._uid === member._uid);
    if (!member.enableBio || idx < 0) {
      return;
    }

    setBiosOpen(true);
    setBioIndexClicked(idx);
    setCarouselMembers(enabledList);
  };

  return (
    <Col {...storyblokEditable(blok)} xs={8} md={10} lg={12}>
      {biosOpen ? (
        <BioPopUp
          members={carouselMembers}
          bioIndexClicked={bioIndexClicked}
          biosOpen={biosOpen}
          handleBioClose={handleBioClose}
        />
      ) : (
        blok.profiles?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} onProfileClick={onProfileClick} />
        ))
      )}

      <FixedScrollArrow float={false} />
      <FixedScrollArrow float />
    </Col>
  );
};

Team.propTypes = {
  blok: object.isRequired,
};

export default Team;
