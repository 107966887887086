import React, { useContext } from 'react';
import { IconContext } from 'react-icons';

// styles
import styled from 'styled-components';
import { Col, Row } from 'react-styled-flexboxgrid';
import { bpWidth, colors } from '../../../styles/Web3.0/variables.js';

// components
import CartIcon from './CartIcon.jsx';
import CloseIcon from '../../../img/PFF-brand-icons-thin-close.svg';
import { StoreContext } from '../../../context/store-context';

// elements
const CartHeaderRow = styled(Row)`
  padding-top: 20px;

  @media (${bpWidth.tablet}) {
    padding-top: 17px;
  }
`;

const CartIconCol = styled(Col)`
  padding-left: 4.5%;

  @media (${bpWidth.desktopSm}) {
    padding-left: 17%;
  }

  @media (${bpWidth.tablet}) {
    padding-left: 0;
  }

  img {
    cursor: pointer;
    width: 26px;

    @media (${bpWidth.desktopSm}) {
      width: 19px;
    }
  }
`;

const CloseIconCol = styled(Col)`
  padding-right: 4.5%;

  @media (${bpWidth.desktopSm}) {
    padding-right: 17%;
    text-align: right;
  }

  @media (${bpWidth.tablet}) {
    padding-right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  @media (${bpWidth.mobile}) {
  }
`;

const CloseIconStyled = styled(CloseIcon)`
  cursor: pointer;
  width: 25px;
  height: auto;
  color: ${colors.navy600};
  fill: ${colors.navy600};

  * {
    color: ${colors.navy600} !important;
    fill: ${colors.navy600} !important;
  }

  .st0 {
    display: inherit;
  }

  @media (${bpWidth.tablet}) {
    width: 20px;
    margin-top: 0;
  }

  @media (${bpWidth.mobile}) {
    width: 17px;
    margin-top: 0;
  }
`;

const CartHeader = () => {
  const { onToggle: handleCartToggle } = useContext(StoreContext);

  const iconContext = React.useMemo(() => ({ color: colors.navy600, size: '25px' }), []);

  return (
    <CartHeaderRow>
      <IconContext.Provider value={iconContext}>
        <Col xsOffset={1} mdOffset={0} xs={6} sm={6} md={10} lg={12}>
          <Row>
            {/* cart icon */}
            <CartIconCol xsOffset={0} xs={1} sm={1} md={5} lgOffset={1} lg={9}>
              <CartIcon />
            </CartIconCol>

            {/* close icon */}
            <CloseIconCol xsOffset={6} xs={1} smOffset={6} sm={1} mdOffset={0} md={5} lg={1}>
              <CloseIconStyled onClick={handleCartToggle} />
            </CloseIconCol>
          </Row>
        </Col>
      </IconContext.Provider>
    </CartHeaderRow>
  );
};

export default CartHeader;
