import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-styled-flexboxgrid';

function FixedBottom({ children, fixedBottomSetting }) {
  const fixedBottomRowRef = useRef(null);
  const [isVisible, setIsVisible] = useState(fixedBottomSetting === 'show' && window.scrollY > 100);
  const [lastScrollY, setLastScrollY] = useState(window.scrollY);

  const handleScrollDirection = () => {
    const currentScrollY = window.scrollY;

    if (fixedBottomSetting === 'showHide') {
      if (currentScrollY > lastScrollY) {
        // Scrolling down
        setIsVisible(false);
      } else {
        // Scrolling up
        setIsVisible(true);
      }
      setLastScrollY(currentScrollY);
    }
  };

  const handleScrollVisibility = () => {
    if (window.scrollY > 100) {
      setIsVisible(true); // slide up whn scroll past 100px
    } else {
      setIsVisible(false); // hide when scrolling up.
    }
  };

  useEffect(() => {
    const handleScroll = fixedBottomSetting === 'showHide' ? handleScrollDirection : handleScrollVisibility;

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScrollDirection, handleScrollVisibility, fixedBottomSetting]);

  return (
    <Row
      ref={fixedBottomRowRef}
      className={`fixed w-[100vw] bottom-0 bg-beige-100 py-[20px] px-0 z-[15] transition-transform duration-300 ease-in-out ${
        isVisible ? 'translate-y-0' : 'translate-y-full'
      }`}
      style={{
        transform: isVisible ? 'translateY(0)' : 'translateY(100%)',
      }}
    >
      <Col xsOffset={1} mdOffset={0} xs={6} sm={6} md={10} lg={12}>
        <Row>
          <Col xsOffset={0} xs={8} smOffset={0} sm={8} mdOffset={6} md={3} lgOffset={7} lg={4}>
            {children}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

const propTypes = {
  children: PropTypes.node.isRequired,
  fixedBottomSetting: PropTypes.oneOf(['show', 'showHide']).isRequired,
};

FixedBottom.propTypes = propTypes;
export default FixedBottom;
