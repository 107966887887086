import React, { useContext, useEffect, useState } from 'react';
import { bool, number } from 'prop-types';

// styles
import styled from 'styled-components';
import { Col, Row } from 'react-styled-flexboxgrid';
import { bpWidth } from '../../../styles/Web3.0/variables.js';
import {
  eyebrowMStyles,
  eyebrowSStyles,
  bodyLItalicUtopiaStyles,
  bodyMItalicUtopiaStyles,
} from '../../../styles/Web3.0/typography.js';

// components
import { StoreContext } from '../../../context/store-context';
import LineItem from './LineItem.jsx';

// props
const propTypes = {
  accessoriesExpanded: bool,
  lastLineItemBottomPadding: number,
};

const defaultProps = {
  accessoriesExpanded: false,
  lastLineItemBottomPadding: 140,
};

// elements
const CartBodyRow = styled(Row)`
  flex: 1;
  padding-top: 75px;
  overflow-y: scroll;
  position: relative;

  @media (${bpWidth.desktopSm}) {
    padding-top: 65px;
  }

  @media (${bpWidth.tablet}) {
    padding-top: 55px;
  }

  @media (${bpWidth.mobile}) {
    padding-top: 50px;
  }
`;

const CartBodyCol = styled(Col)`
  padding-right: 4.5%;
  padding-left: 4.5%;
  pointer-events: ${(props) => (props.accessoriesExpanded ? 'none' : 'auto')};

  @media (${bpWidth.desktopSm}) {
    padding-right: 17%;
    padding-left: 17%;
  }

  @media (${bpWidth.tablet}) {
    padding-right: 0;
    padding-left: 0;
  }

  img {
    width: 100%;
    max-width: 100%;
  }
`;

const CartTitle = styled.h2`
  ${eyebrowMStyles};

  @media (${bpWidth.mobile}) {
    ${eyebrowSStyles};
  }
`;

const CartEmptyStateText = styled.p`
  ${bodyLItalicUtopiaStyles};
  font-weight: normal;
  padding-top: 40px;

  @media (${bpWidth.desktopSm}) {
    ${bodyMItalicUtopiaStyles};
  }

  @media (${bpWidth.mobile}) {
    letter-spacing: -0.16px;
  }
`;

const CartBody = React.forwardRef((props, ref) => {
  const { accessoriesExpanded, lastLineItemBottomPadding } = props;

  const { checkout, didJustAddToCart } = useContext(StoreContext);

  const emptyCart = checkout.lineItems.length === 0;

  const accessoriesContainerEl = ref;

  const [cartTitle, setCartTitle] = useState('YOUR CART');

  useEffect(() => {
    if (didJustAddToCart) {
      setCartTitle('Added to your cart!');
    } else {
      setCartTitle('YOUR CART');
    }
  }, [didJustAddToCart]);

  return (
    <CartBodyRow>
      <CartBodyCol
        xsOffset={1}
        xs={6}
        smOffset={1}
        sm={6}
        mdOffset={0}
        md={10}
        lgOffset={1}
        lg={10}
        accessoriesExpanded={accessoriesExpanded}
      >
        <CartTitle>{cartTitle}</CartTitle>
        {emptyCart ? (
          <CartEmptyStateText>Looks like your cart is empty!</CartEmptyStateText>
        ) : (
          checkout.lineItems.map((lineItem, index) => (
            <LineItem
              lineItemData={lineItem}
              index={index}
              arrayLength={checkout.lineItems.length}
              key={lineItem.id}
              ref={accessoriesContainerEl}
              accessoriesExpanded={accessoriesExpanded}
              lastLineItemBottomPadding={lastLineItemBottomPadding}
            />
          ))
        )}
      </CartBodyCol>
    </CartBodyRow>
  );
});

CartBody.propTypes = propTypes;
CartBody.defaultProps = defaultProps;
export default CartBody;
