import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { storyblokEditable } from 'gatsby-source-storyblok';
import styled from 'styled-components';
import { getGatsbyImage } from 'gatsby-plugin-storyblok-image';
import { GatsbyImage } from 'gatsby-plugin-image';
import { eyebrowLStyles, eyebrowSStyles } from '../../../styles/Web3.0/typography';
import { bpWidth, colors } from '../../../styles/Web3.0/variables';

const Wrapper = styled.div`
  position: relative;

  a {
    display: flex;
    height: 110px;
    background-color: ${colors.navy600};
    border-radius: 8px;

    @media (${bpWidth.desktopSm}) {
      height: 80px;
    }

    .thumbnail {
      opacity: 0.6;
      border-radius: 8px;
    }

    &:hover {
      background-color: ${colors.navy300};

      .thumbnail {
        opacity: 0.2;
        transition: -webkit-opacity 0.3s ease-out 0s;
      }
    }

    span.title {
      ${eyebrowLStyles};
      color: ${colors.beige100};
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 1;

      @media (${bpWidth.desktopSm}) {
        ${eyebrowSStyles};
        color: ${colors.beige100};
      }
    }
  }
`;

const PromoteCategoryItem = ({ blok }) => {
  const { title, image, link } = blok;
  const options = { layout: 'constrained', quality: 50 };
  const [imageData, setImageData] = useState(null);

  useEffect(() => {
    if (image?.filename) {
      setImageData(getGatsbyImage(image.filename, options));
    }
  }, []);

  return (
    <Wrapper {...storyblokEditable(blok)}>
      <a href={link?.cached_url || link?.url} target={link?.target} rel={link?.target ? 'noreferrer' : undefined}>
        {imageData && <GatsbyImage image={imageData} alt={image.alt || ''} loading="lazy" className="thumbnail" />}
        <span className="title">{title}</span>
      </a>
    </Wrapper>
  );
};

PromoteCategoryItem.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default PromoteCategoryItem;
