import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, useFormik, FormikProvider, Form, ErrorMessage, useFormikContext } from 'formik';
import { CountryRegionData } from 'react-country-region-selector';
import * as Yup from 'yup';
import styled from 'styled-components';
import { StoryblokComponent } from 'gatsby-source-storyblok';

import RotatedArrow from '../../../icons/Web3.0/arrow-rotated.svg';
import { STATUS_FAILURE, STATUS_INIT, STATUS_SUCCESS } from '../../../common/constants/formikStatus.constant';
import { consentChoiceValue } from '../../../common/constants/privacyPolicyPopup.constant';
import PrivacyPolicyConfirmationPopup from '../../Web3.0/PrivacyPolicy/PrivacyPolicyConfirmationPopup';
import PdfIcon from '../../../icons/Web3.0/pdf-icon.svg';
import ArrowRightIcon from '../../../icons/Web3.0/arrow-RCerulean.svg';
import { useBoolean } from '../../../hooks';
import { renderRichTextReact } from '../../../utils/storyblokRichText';
import { bpWidthTailwind, colors } from '../../../styles/Web3.0/variables';
import {
  bodyLRegularBrownStyles,
  bodyMRegularBrownStyles,
  bodySRegularBrownStyles,
  bodyXsRegularBrownStyles,
  headerSStyles,
  headerXsStyles,
  headerXxsStyles,
} from '../../../styles/Web3.0/typography';
import { btnLgPrimaryThinOrangeStyles, btnSmPrimaryThinOrangeStyles } from '../../../styles/Web3.0/buttons';

const Label = ({ labelText, htmlFor }) => {
  return (
    <label htmlFor={htmlFor} className="text-cerulean-400 font-bold tracking-[2.88px] uppercase">
      {labelText}
    </label>
  );
};
Label.propTypes = {
  labelText: PropTypes.string.isRequired,
  htmlFor: PropTypes.string,
};

const TextField = ({ name, type, asControl, placeholder }) => {
  return (
    <Field
      id={name}
      name={name}
      type={type}
      as={asControl}
      placeholder={placeholder}
      autoComplete="off"
      className={`w-full ${
        asControl === 'textarea' ? 'h-72' : 'h-11 sm:max-w-[392px] md:max-w-none'
      } bg-beige-300/50 text-sm text-beige-100 placeholder:text-gray-200 p-4 rounded outline-none resize-none`}
    />
  );
};
TextField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  asControl: PropTypes.string,
  placeholder: PropTypes.string,
};
TextField.defaultProps = {
  type: 'text',
  asControl: 'input',
};

const CheckboxField = ({ name, label, value, onChange }) => {
  const formik = useFormikContext();

  useEffect(() => {
    if (onChange) {
      onChange(formik.values[name]);
    }
  }, [formik.values[name]]);

  return (
    <label
      htmlFor={name}
      className="text-beige-100 text-xs leading-[1.7] font-brown font-normal inline-flex items-center gap-x-2"
    >
      <div className="relative w-3.5 h-3.5">
        <Field
          id={name}
          name={name}
          type="checkbox"
          value={value}
          className="peer w-3.5 h-3.5 cursor-pointer appearance-none rounded-sm border border-beige-10
          checked:border-cerulean-400 checked:bg-cerulean-400"
        />
        <div
          className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-white
          opacity-0 transition-opacity peer-checked:opacity-100"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-2.5 w-2.5"
            viewBox="0 0 20 20"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="1"
          >
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
        </div>
      </div>

      {label}
    </label>
  );
};
CheckboxField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};
CheckboxField.defaultProps = {};

const ValidationMessage = ({ name }) => {
  return <ErrorMessage name={name} component="p" className="text-orange-600 text-sm mt-2 mx-4" />;
};
ValidationMessage.propTypes = {
  name: PropTypes.string.isRequired,
};

const FormHeading = styled.h2`
  br {
    display: hidden;
  }

  @media (${bpWidthTailwind.desktopSm}) {
    br {
      display: block;
    }
  }
`;

const ThankYouOrErrorContent = styled.div`
  ${bodySRegularBrownStyles};
  color: ${colors.white};

  h3 {
    ${headerXxsStyles};
    color: ${colors.white};
    margin-bottom: 20px;
  }

  @media (${bpWidthTailwind.tablet}) {
    h3 {
      margin-bottom: 34px;
    }
  }

  @media (${bpWidthTailwind.desktopSm}) {
    ${bodyMRegularBrownStyles};
    color: ${colors.white};

    h3 {
      ${headerXsStyles};
      color: ${colors.white};
      margin-bottom: 28px;
    }
  }

  @media (${bpWidthTailwind.desktopLg}) {
    ${bodyLRegularBrownStyles};
    color: ${colors.white};

    h3 {
      ${headerSStyles};
      color: ${colors.white};
      margin-bottom: 31px;
    }
  }
`;

const BackButton = styled.div`
  margin-top: 20px;

  a {
    ${btnSmPrimaryThinOrangeStyles};
    width: auto;
    height: 36px;
    padding: 0 18px;
  }

  @media (${bpWidthTailwind.tablet}) {
    margin-bottom: 31px;
  }

  @media (${bpWidthTailwind.desktopSm}) {
    margin-top: 28px;

    a {
      ${btnLgPrimaryThinOrangeStyles};
      width: auto;
      height: 40px;
      padding: 0 20px;
    }
  }

  @media (${bpWidthTailwind.desktopLg}) {
    margin-bottom: 31px;
  }
`;

const Terms = styled.p`
  ${bodyXsRegularBrownStyles};
  color: ${colors.beige100};

  a {
    text-decoration: underline;
  }
`;

const BusinessContact = (props) => {
  const { blok, gated = false } = props;

  const [countryData, setCountryData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [openPrivacyPolicyPopup, setOpenPrivacyPolicyPopup] = useState(false);
  const {
    value: isShowCountryDropdownList,
    setFalse: hideCountryDropdownList,
    setTrue: showCountryDropdownList,
  } = useBoolean(false);
  const {
    value: isShowRegionDropdownList,
    setFalse: hideRegionDropdownList,
    setTrue: showRegionDropdownList,
  } = useBoolean(false);
  const formHeadingRichText = useMemo(() => renderRichTextReact(blok.formHeading), [blok.formHeading]);
  const thankYouContentRichText = useMemo(() => renderRichTextReact(blok.thankYouContent), [blok.thankYouContent]);
  const errorContentRichText = useMemo(() => renderRichTextReact(blok.errorContent), [blok.errorContent]);

  const formValidationSchema = Yup.object().shape({
    email: Yup.string().email('This field is invalid').required('This field is required'),
    phoneNumber: Yup.string().required('This field is required'),
    preferredEmail: Yup.bool(),
    preferredPhone: Yup.bool(),
    preferredContactMethod: Yup.string(),
    firstName: Yup.string().required('This field is required'),
    lastName: Yup.string().required('This field is required'),
    jobtitle: Yup.string().required('This field is required'),
    industry: Yup.string().required('This field is required'),
    country: Yup.string().required('This field is required'),
    state: Yup.string().when('country', {
      is: (val) => !val || val === '',
      then: Yup.string().required('Cannot load region from "empty"'),
      otherwise: Yup.string().required('This field is required'),
    }),
    additionalInformation: Yup.string().required('This field is required'),
  });

  const getPreferredContactMethod = (values) => {
    if (values.preferredEmail) {
      return 'email';
    }

    if (values.preferredPhone) {
      return 'phone';
    }

    return '';
  };

  const formik = useFormik({
    initialStatus: STATUS_INIT,
    initialValues: {
      email: '',
      phoneNumber: '',
      preferredEmail: false,
      preferredPhone: false,
      preferredContactMethod: '',
      firstName: '',
      lastName: '',
      jobtitle: '',
      industry: '',
      country: '',
      state: '',
      additionalInformation: '',
      consent: 0,
    },
    validationSchema: formValidationSchema,
    onSubmit: (values, actions) => {
      if (!values.consent) {
        setOpenPrivacyPolicyPopup(true);
        return;
      }

      setOpenPrivacyPolicyPopup(false);

      const { setSubmitting, setStatus } = actions;

      const data = {
        fields: [
          {
            name: 'email',
            value: values.email,
          },
          {
            name: 'phone',
            value: values.phoneNumber,
          },
          {
            name: 'preferred_contact_method',
            value: getPreferredContactMethod(values),
          },
          {
            name: 'firstname',
            value: values.firstName,
          },
          {
            name: 'lastname',
            value: values.lastName,
          },
          {
            name: 'jobtitle',
            value: values.jobtitle,
          },
          {
            name: 'industry',
            value: values.industry,
          },
          {
            name: 'country',
            value: values.country,
          },
          {
            name: 'state',
            value: values.state,
          },
          {
            name: 'website_inbound_message',
            value: values.additionalInformation,
          },
        ],
        context: {
          pageName: document.title ?? '',
          pageUri: window.location.href,
        },
      };

      // Add hubspot tracking cookie if available
      const hubspotTrackingCookie = document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*=\s*([^;]*).*$)|^.*$/, '$1');

      if (hubspotTrackingCookie !== '') data.context.hutk = hubspotTrackingCookie;

      // submission URL
      const url = gated
        ? 'https://api.hsforms.com/submissions/v3/integration/submit/6286664/a3ae9415-4afc-4048-9b4f-1547cb06dc7b'
        : 'https://api.hsforms.com/submissions/v3/integration/submit/6286664/64f1ab85-e449-42a7-a483-ddac8811f5d5';

      fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      })
        .then((response) => {
          // response.ok checks if the response.status is in the range 200-299, inclusive
          if (response.ok) {
            setStatus(STATUS_SUCCESS);
          } else {
            setStatus(STATUS_FAILURE);
          }
        })
        .catch(() => {
          setStatus(STATUS_FAILURE);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const isInitial = useMemo(() => formik.status === STATUS_INIT, [formik.status]);
  const isSuccess = useMemo(() => formik.status === STATUS_SUCCESS, [formik.status]);
  const isFailure = useMemo(() => formik.status === STATUS_FAILURE, [formik.status]);

  useEffect(() => {
    const countryFullNames = [];
    const priorityOption = 'US';
    CountryRegionData.forEach((countryRegionCompactData) => {
      const [countryFullName, countryShortName] = countryRegionCompactData;
      if (priorityOption === countryShortName) {
        countryFullNames.unshift(countryFullName);
      } else {
        countryFullNames.push(countryFullName);
      }
    });

    setCountryData(countryFullNames);
  }, []);

  useEffect(() => {
    const regionFullNames = [];
    for (const countryRegionCompactData of CountryRegionData) {
      // eslint-disable-next-line no-unused-vars
      const [countryFullName, countryShortName, regionCompactedData] = countryRegionCompactData;

      if (countryFullName === formik.values.country) {
        const regionRawData = regionCompactedData.split('|');
        regionRawData.forEach((regionCompactData) => {
          const [regionFullName] = regionCompactData.split('~');
          regionFullNames.push(regionFullName);
        });

        break;
      }
    }

    setRegionData(regionFullNames);
  }, [formik.values.country]);

  const onChoosingConsent = (choice) => {
    if (choice === consentChoiceValue.consent) {
      formik.values.consent = choice;
      formik.handleSubmit();
    } else {
      setOpenPrivacyPolicyPopup(false);
      formik.setSubmitting(false);
    }
  };

  const onBlurCountryDropdownList = () => {
    setTimeout(() => {
      hideCountryDropdownList();
      formik.validateField('country');
    }, 300);
  };

  const onBlurRegionDropdownList = () => {
    setTimeout(() => {
      hideRegionDropdownList();
      formik.validateField('state');
    }, 300);
  };

  const onSelectCountry = ($event, country) => {
    formik.setFieldValue('country', country);
    formik.setFieldTouched('country', true);
    formik.setFieldValue('state', '');
    formik.setFieldTouched('state', false);
    formik.setFieldError('state', undefined);
  };

  const onClickCountryDropdownList = useCallback(
    ($event) => {
      if (isShowCountryDropdownList) {
        onSelectCountry($event, formik.values.country);
        onBlurCountryDropdownList();
      } else {
        showCountryDropdownList();
      }
    },
    [isShowCountryDropdownList, showCountryDropdownList, hideCountryDropdownList, formik.values.country]
  );

  const onSelectRegion = ($event, region) => {
    formik.setFieldValue('state', region);
    formik.setFieldTouched('state', true);
  };

  const onClickRegionDropdownList = useCallback(
    ($event) => {
      if (isShowRegionDropdownList) {
        onSelectRegion($event, formik.values.state);
        onBlurRegionDropdownList();
      } else if (regionData?.length > 0) {
        showRegionDropdownList();
      } else {
        onSelectRegion($event, '');
      }
    },
    [regionData, isShowRegionDropdownList, showRegionDropdownList, hideRegionDropdownList, formik.values.state]
  );

  return (
    <div className="flex-1 flex flex-col">
      <div
        className="flex-1 flex items-end px-[12.5%] md:px-[10%] lg:px-[8.333333%]
        pt-[119px] sm:pt-[188px] md:pt-[170px] pb-[51px] sm:pb-[64px] md:pb-[80px] lg:pb-[64px]
        bg-gradient-to-b from-[#032137] from-0% via-[#023E58] via-45% to-[#032137] to-100%"
      >
        <FormHeading
          className="text-[22px] sm:text-[28px] md:text-[36px] lg:text-[45px] leading-[1.4] lg:leading-[1.3]
          font-brown font-bold text-cerulean-400
          w-[272px] sm:w-[460px] md:w-[788px]"
        >
          {formHeadingRichText}
        </FormHeading>
      </div>
      {isInitial && (
        <div className="flex-1 bg-[#032137] px-[12.5%] md:px-[10%] lg:px-[8.333333%]">
          <FormikProvider value={formik}>
            <Form
              className="grid grid-cols-1 md:grid-cols-2 gap-y-12 gap-x-28 lg:gap-x-32
              font-brown text-base max-w-[944px] py-20"
            >
              <div className="flex flex-col col-span-2 md:col-span-1">
                <Label labelText="First Name*" htmlFor="firstName" />
                <div className="mt-3">
                  <TextField name="firstName" />
                </div>
                <ValidationMessage name="firstName" />
              </div>

              <div className="flex flex-col col-span-2 md:col-span-1">
                <Label labelText="Last Name*" htmlFor="lastName" />
                <div className="mt-3">
                  <TextField name="lastName" />
                </div>
                <ValidationMessage name="lastName" />
              </div>

              <div className="flex flex-col col-span-2 md:col-span-1">
                <div className="inline-flex items-end gap-x-5">
                  <Label labelText="Email*" htmlFor="email" />
                  <CheckboxField
                    name="preferredEmail"
                    label="Preferred"
                    onChange={($event) => {
                      if ($event) {
                        formik.setFieldValue('preferredPhone', false);
                      }
                    }}
                  />
                </div>
                <div className="mt-3">
                  <TextField name="email" type="email" />
                </div>
                <ValidationMessage name="email" />
              </div>

              <div className="flex flex-col col-span-2 md:col-span-1">
                <div className="inline-flex items-end gap-x-5">
                  <Label labelText="Phone number*" htmlFor="phoneNumber" />
                  <CheckboxField
                    name="preferredPhone"
                    label="Preferred"
                    onChange={($event) => {
                      if ($event) {
                        formik.setFieldValue('preferredEmail', false);
                      }
                    }}
                  />
                </div>
                <div className="mt-3">
                  <TextField name="phoneNumber" type="tel" />
                </div>
                <ValidationMessage name="phoneNumber" />
              </div>

              <div className="flex flex-col col-span-2 md:col-span-1">
                <Label labelText="Title*" htmlFor="jobtitle" />
                <div className="mt-3">
                  <TextField name="jobtitle" />
                </div>
                <ValidationMessage name="jobtitle" />
              </div>

              <div className="flex flex-col col-span-2 md:col-span-1">
                <Label labelText="Industry*" htmlFor="industry" />
                <div className="mt-3">
                  <TextField name="industry" placeholder="Type in your industry" />
                </div>
                <ValidationMessage name="industry" />
              </div>

              <div className="flex flex-col col-span-2 md:col-span-1">
                <Label labelText="Country*" htmlFor="country" />
                <div className="mt-3 relative">
                  <div className="w-full sm:max-w-[392px] md:max-w-none h-11 relative">
                    <div
                      className={`w-full left-0 top-10 absolute bg-beige-300 shadow-[0_-10px_25px_#dfdcd2_inset]
                      max-h-40 overflow-y-auto ${isShowCountryDropdownList ? 'block z-[1]' : 'hidden'}`}
                    >
                      <div className="w-full py-2.5">
                        {(countryData || []).map((country) => (
                          <button
                            key={country}
                            type="button"
                            className="w-full h-8 relative appearance-none text-left"
                            onClick={($event) => onSelectCountry($event, country)}
                          >
                            <div className="top-[50%] right-2 left-2 -translate-y-[50%] absolute text-navy-700 text-xs">
                              {country}
                            </div>
                          </button>
                        ))}
                      </div>
                    </div>
                    <button
                      type="button"
                      className={`w-full sm:max-w-[392px] md:max-w-none h-full left-0 top-0 absolute appearance-none text-left ${
                        isShowCountryDropdownList ? 'z-[2]' : ''
                      }`}
                      onClick={($event) => onClickCountryDropdownList($event)}
                      onBlur={($event) => onBlurCountryDropdownList($event)}
                    >
                      {isShowCountryDropdownList && (
                        <div className="w-full h-full left-0 top-0 absolute bg-navy-600 rounded" />
                      )}
                      <div className="w-full h-full left-0 top-0 absolute bg-beige-300/50 rounded" />
                      <div
                        className={`w-full pl-4 pr-20 top-[50%] -translate-y-[50%] absolute ${
                          formik.values.country ? 'text-beige-100' : 'text-gray-200'
                        } text-sm appearance-none outline-none cursor-pointer bg-transparent`}
                      >
                        {formik.values.country || 'Select a country'}
                      </div>
                      <div className="w-auto h-full top-0 right-0 absolute">
                        {isShowCountryDropdownList ? (
                          <svg
                            width="60"
                            height="44"
                            viewBox="0 0 60 44"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              opacity="0.5"
                              d="M0 0H56C58.2091 0 60 1.79086 60 4V40C60 42.2091 58.2091 44 56 44H0V0Z"
                              fill="#B2AFA4"
                            />
                            <path
                              opacity="0.5"
                              d="M30.0052 19.25L30.0081 19.25C30.1126 19.2494 30.2107 19.2859 30.2832 19.3479L38.6405 27.1427C38.713 27.2103 38.7501 27.2981 38.7501 27.3857C38.7501 27.4733 38.713 27.5611 38.6405 27.6288C38.5674 27.697 38.4647 27.738 38.3544 27.738C38.244 27.738 38.1414 27.697 38.0683 27.6288C38.0683 27.6288 38.0683 27.6288 38.0683 27.6288L30.1771 20.2629L29.836 20.2629L21.932 27.6408C21.932 27.6408 21.9319 27.6408 21.9319 27.6408C21.8959 27.6744 21.8522 27.7019 21.8029 27.721C21.7536 27.74 21.7002 27.75 21.6459 27.75C21.5916 27.75 21.5382 27.74 21.4889 27.721C21.4395 27.7019 21.3958 27.6744 21.3598 27.6408C21.3238 27.6072 21.2962 27.5683 21.2778 27.5267L21.0493 27.6282L21.2778 27.5267C21.2593 27.4852 21.2501 27.4415 21.2501 27.3977C21.2501 27.354 21.2593 27.3102 21.2778 27.2687L21.0493 27.1672L21.2778 27.2687C21.2962 27.2272 21.3238 27.1882 21.3598 27.1547L21.1893 26.9718L21.3598 27.1547L29.7197 19.3575C29.7198 19.3574 29.72 19.3573 29.7201 19.3571C29.7561 19.3239 29.7996 19.2968 29.8487 19.2782C29.8979 19.2594 29.9511 19.2497 30.0052 19.25Z"
                              fill="#DFDCD2"
                              stroke="#DFDCD2"
                              strokeWidth="0.5"
                              strokeLinejoin="bevel"
                            />
                          </svg>
                        ) : (
                          <svg
                            width="60"
                            height="44"
                            viewBox="0 0 60 44"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              opacity="0.5"
                              d="M0 0H56C58.2091 0 60 1.79086 60 4V40C60 42.2091 58.2091 44 56 44H0V0Z"
                              fill="#DFDCD2"
                            />
                            <path
                              d="M29.9949 27.75L29.992 27.75C29.8875 27.7506 29.7894 27.7141 29.717 27.6521L21.3597 19.8573C21.2871 19.7897 21.25 19.7019 21.25 19.6143C21.25 19.5267 21.2871 19.4389 21.3597 19.3712C21.4328 19.303 21.5354 19.262 21.6458 19.262C21.7561 19.262 21.8587 19.303 21.9318 19.3712C21.9318 19.3712 21.9318 19.3712 21.9319 19.3712L29.823 26.7371L30.1642 26.7371L38.0681 19.3592C38.0682 19.3592 38.0682 19.3592 38.0682 19.3592C38.1042 19.3256 38.1479 19.2981 38.1972 19.279C38.2465 19.26 38.2999 19.25 38.3542 19.25C38.4085 19.25 38.4619 19.26 38.5113 19.279C38.5606 19.2981 38.6043 19.3256 38.6403 19.3592C38.6764 19.3928 38.7039 19.4317 38.7224 19.4733L38.9508 19.3718L38.7224 19.4733C38.7408 19.5148 38.75 19.5585 38.75 19.6023C38.75 19.646 38.7408 19.6898 38.7224 19.7313L38.9508 19.8328L38.7224 19.7313C38.7039 19.7728 38.6764 19.8118 38.6403 19.8453L38.8109 20.0282L38.6403 19.8453L30.2804 27.6425C30.2803 27.6426 30.2802 27.6427 30.28 27.6429C30.2441 27.6761 30.2005 27.7032 30.1515 27.7218C30.1022 27.7406 30.049 27.7503 29.9949 27.75Z"
                              fill="#DFDCD2"
                              stroke="#DFDCD2"
                              strokeWidth="0.5"
                              strokeLinejoin="bevel"
                            />
                          </svg>
                        )}
                      </div>
                    </button>
                  </div>
                </div>
                <ValidationMessage name="country" />
              </div>

              <div className="flex flex-col col-span-2 md:col-span-1">
                <Label labelText="State / Province / Territory*" htmlFor="state" />
                <div className="mt-3 relative">
                  <div className="w-full sm:max-w-[392px] md:max-w-none h-11 relative">
                    <div
                      className={`w-full left-0 top-10 absolute bg-beige-300 shadow-[0_-10px_25px_#dfdcd2_inset]
                      max-h-40 overflow-y-auto ${isShowRegionDropdownList ? 'block z-[1]' : 'hidden'}`}
                    >
                      <div className="w-full py-2.5">
                        {(regionData || []).map((region) => (
                          <button
                            type="button"
                            key={region}
                            className="w-full h-8 relative appearance-none text-left"
                            onClick={($event) => onSelectRegion($event, region)}
                          >
                            <div className="top-[50%] right-2 left-2 -translate-y-[50%] absolute text-navy-700 text-xs">
                              {region}
                            </div>
                          </button>
                        ))}
                      </div>
                    </div>
                    <button
                      type="button"
                      className={`w-full sm:max-w-[392px] md:max-w-none h-full left-0 top-0 absolute appearance-none text-left ${
                        isShowRegionDropdownList ? 'z-[2]' : ''
                      }`}
                      onClick={($event) => onClickRegionDropdownList($event)}
                      onBlur={($event) => onBlurRegionDropdownList($event)}
                    >
                      {isShowRegionDropdownList && (
                        <div className="w-full h-full left-0 top-0 absolute bg-navy-600 rounded" />
                      )}
                      <div className="w-full h-full left-0 top-0 absolute bg-beige-300/50 rounded" />
                      <div
                        className={`w-full pl-4 pr-20 top-[50%] -translate-y-[50%] absolute ${
                          formik.values.state ? 'text-beige-100' : 'text-gray-200'
                        } text-sm appearance-none outline-none cursor-pointer bg-transparent`}
                      >
                        {formik.values.state || 'Select a state/province/territory'}
                      </div>
                      <div className="w-auto h-full top-0 right-0 absolute">
                        {isShowRegionDropdownList ? (
                          <svg
                            width="60"
                            height="44"
                            viewBox="0 0 60 44"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              opacity="0.5"
                              d="M0 0H56C58.2091 0 60 1.79086 60 4V40C60 42.2091 58.2091 44 56 44H0V0Z"
                              fill="#B2AFA4"
                            />
                            <path
                              opacity="0.5"
                              d="M30.0052 19.25L30.0081 19.25C30.1126 19.2494 30.2107 19.2859 30.2832 19.3479L38.6405 27.1427C38.713 27.2103 38.7501 27.2981 38.7501 27.3857C38.7501 27.4733 38.713 27.5611 38.6405 27.6288C38.5674 27.697 38.4647 27.738 38.3544 27.738C38.244 27.738 38.1414 27.697 38.0683 27.6288C38.0683 27.6288 38.0683 27.6288 38.0683 27.6288L30.1771 20.2629L29.836 20.2629L21.932 27.6408C21.932 27.6408 21.9319 27.6408 21.9319 27.6408C21.8959 27.6744 21.8522 27.7019 21.8029 27.721C21.7536 27.74 21.7002 27.75 21.6459 27.75C21.5916 27.75 21.5382 27.74 21.4889 27.721C21.4395 27.7019 21.3958 27.6744 21.3598 27.6408C21.3238 27.6072 21.2962 27.5683 21.2778 27.5267L21.0493 27.6282L21.2778 27.5267C21.2593 27.4852 21.2501 27.4415 21.2501 27.3977C21.2501 27.354 21.2593 27.3102 21.2778 27.2687L21.0493 27.1672L21.2778 27.2687C21.2962 27.2272 21.3238 27.1882 21.3598 27.1547L21.1893 26.9718L21.3598 27.1547L29.7197 19.3575C29.7198 19.3574 29.72 19.3573 29.7201 19.3571C29.7561 19.3239 29.7996 19.2968 29.8487 19.2782C29.8979 19.2594 29.9511 19.2497 30.0052 19.25Z"
                              fill="#DFDCD2"
                              stroke="#DFDCD2"
                              strokeWidth="0.5"
                              strokeLinejoin="bevel"
                            />
                          </svg>
                        ) : (
                          <svg
                            width="60"
                            height="44"
                            viewBox="0 0 60 44"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              opacity="0.5"
                              d="M0 0H56C58.2091 0 60 1.79086 60 4V40C60 42.2091 58.2091 44 56 44H0V0Z"
                              fill="#DFDCD2"
                            />
                            <path
                              d="M29.9949 27.75L29.992 27.75C29.8875 27.7506 29.7894 27.7141 29.717 27.6521L21.3597 19.8573C21.2871 19.7897 21.25 19.7019 21.25 19.6143C21.25 19.5267 21.2871 19.4389 21.3597 19.3712C21.4328 19.303 21.5354 19.262 21.6458 19.262C21.7561 19.262 21.8587 19.303 21.9318 19.3712C21.9318 19.3712 21.9318 19.3712 21.9319 19.3712L29.823 26.7371L30.1642 26.7371L38.0681 19.3592C38.0682 19.3592 38.0682 19.3592 38.0682 19.3592C38.1042 19.3256 38.1479 19.2981 38.1972 19.279C38.2465 19.26 38.2999 19.25 38.3542 19.25C38.4085 19.25 38.4619 19.26 38.5113 19.279C38.5606 19.2981 38.6043 19.3256 38.6403 19.3592C38.6764 19.3928 38.7039 19.4317 38.7224 19.4733L38.9508 19.3718L38.7224 19.4733C38.7408 19.5148 38.75 19.5585 38.75 19.6023C38.75 19.646 38.7408 19.6898 38.7224 19.7313L38.9508 19.8328L38.7224 19.7313C38.7039 19.7728 38.6764 19.8118 38.6403 19.8453L38.8109 20.0282L38.6403 19.8453L30.2804 27.6425C30.2803 27.6426 30.2802 27.6427 30.28 27.6429C30.2441 27.6761 30.2005 27.7032 30.1515 27.7218C30.1022 27.7406 30.049 27.7503 29.9949 27.75Z"
                              fill="#DFDCD2"
                              stroke="#DFDCD2"
                              strokeWidth="0.5"
                              strokeLinejoin="bevel"
                            />
                          </svg>
                        )}
                      </div>
                    </button>
                  </div>
                </div>
                <ValidationMessage name="state" />
              </div>

              <div className="flex flex-col col-span-2">
                <Label labelText="Additional Information*" htmlFor="additionalInformation" />
                <div className="mt-3">
                  <TextField
                    name="additionalInformation"
                    asControl="textarea"
                    placeholder="Share more details about your business, goals, requirements, etc. This will help us provide the most relevant information for your needs."
                  />
                </div>
                <ValidationMessage name="additionalInformation" />
              </div>

              <Terms className="col-span-2">
                By connecting with us, you agree to Piaggio Fast Forward’s{' '}
                <a href="/privacy-policy" target="_blank" rel="noreferrer">
                  Privacy Policy
                </a>{' '}
                and{' '}
                <a href="/terms-of-use" target="_blank" rel="noreferrer">
                  Terms of Use
                </a>
                .
              </Terms>
              <div className="col-span-2">
                <button
                  type="submit"
                  className=" bg-orange-400 hover:bg-orange-700
                  text-[12px] text-beige-100 tracking-[2.16px] leading-[1.7] rounded-[18px] uppercase
                  px-[18px] h-9 w-40 flex gap-2.5 items-center justify-center"
                >
                  Submit
                  <span className="-mt-0.5">
                    <RotatedArrow />
                  </span>
                </button>

                {formik.submitCount > 0 && !formik.isValid && (
                  <p className="text-orange-600 text-sm mt-3">Please fill out all the required fields.</p>
                )}
              </div>
            </Form>
          </FormikProvider>
        </div>
      )}

      {openPrivacyPolicyPopup && <PrivacyPolicyConfirmationPopup onPopUpClose={onChoosingConsent} lightMode={false} />}

      {/* form success state, ungated contact form */}
      {(isSuccess || isFailure) && (
        <div className="pt-10 pb-60 md:pb-96 bg-[#032137] px-[12.5%] md:px-[10%] lg:px-[8.333333%]">
          {isSuccess && !gated && <ThankYouOrErrorContent>{thankYouContentRichText}</ThankYouOrErrorContent>}

          {/* form success state, for gated pdf form */}
          {isSuccess && gated && (
            <>
              <ThankYouOrErrorContent>{thankYouContentRichText}</ThankYouOrErrorContent>
              <p className="pt-4">
                <a
                  className="decoration-0 text-cerulean-400 text-[10px] uppercase tracking-[2.16px]"
                  href="https://6286664.fs1.hubspotusercontent-na1.net/hubfs/6286664/PFF%20site/kilo_product-sheet.pdf"
                >
                  <PdfIcon className="mr-3 inline-block" />
                  <span className="font-bold">download product sheet</span>
                  <ArrowRightIcon className="ml-3 inline-block" />
                </a>
              </p>
            </>
          )}

          {isFailure && !gated && <ThankYouOrErrorContent>{errorContentRichText}</ThankYouOrErrorContent>}

          {(isSuccess || isFailure) &&
            (blok.backButton || []).map((nestedBlok) => (
              <BackButton key={nestedBlok._uid}>
                <StoryblokComponent blok={nestedBlok} />
              </BackButton>
            ))}
        </div>
      )}
    </div>
  );
};

BusinessContact.propTypes = {
  blok: PropTypes.object,
  gated: PropTypes.bool,
};
BusinessContact.defaultProps = {};

export default BusinessContact;
