import React, { useState } from 'react';
import PropTypes, { any, object } from 'prop-types';
import { ErrorMessage, Field, useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import Select, { components } from 'react-select';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';

import styled from 'styled-components';
import { Col, Row } from 'react-styled-flexboxgrid';
import { colors, bpWidth, fontFamilies } from '../../../styles/Web3.0/variables';
import {
  bodyLSemiBoldUtopiaStyles,
  bodyMRegularBrownStyles,
  bodySSemiBoldUtopiaStyles,
  bodyXxsRegularBrownStyles,
  bodyXxxsRegularBrownStyles,
  headerLFillStyles,
  headerMStyles,
  headerSStyles,
} from '../../../styles/Web3.0/typography';
import '../../../styles/Web3.0/formStyles.css';

import Chevron from '../../../img/d-arrow-CTA.svg';
import { STATUS_FAILURE, STATUS_INIT, STATUS_SUCCESS } from '../../../common/constants/formikStatus.constant';
import { consentChoiceValue } from '../../../common/constants/privacyPolicyPopup.constant';
import PrivacyPolicyConfirmationPopup from '../../Web3.0/PrivacyPolicy/PrivacyPolicyConfirmationPopup';
import { renderRichTextReact } from '../../../utils/storyblokRichText';

// elements
const SupportRow = styled(Row)`
  margin-top: 20vh;
  margin-bottom: 110px;

  @media (${bpWidth.desktopSm}) {
    margin-bottom: 90px;
  }

  @media (${bpWidth.tablet}) {
    margin-bottom: 0;
  }

  @media (${bpWidth.mobile}) {
    margin-top: 15vh;
  }
`;

const SupportCol = styled(Col)`
  h2 {
    ${headerLFillStyles};
    color: ${colors.beige100};
    margin-bottom: 20px;
  }

  p {
    ${bodyLSemiBoldUtopiaStyles};
    color: ${colors.beige100};
    margin-bottom: 0;
  }

  @media (${bpWidth.tablet}) {
    h2 {
      ${headerMStyles};
      color: ${colors.beige100};
    }

    p {
      ${bodySSemiBoldUtopiaStyles};
      color: ${colors.beige100};
      margin-bottom: 0;
    }
  }

  @media (${bpWidth.mobile}) {
    h2 {
      ${headerSStyles};
      color: ${colors.beige100};
    }
  }
`;

const SupportHeader = styled.h2`
  p {
    ${headerLFillStyles};
    color: ${colors.beige100};
    margin-bottom: 20px;
  }

  @media (${bpWidth.tablet}) {
    p {
      ${headerMStyles};
      color: ${colors.beige100};
    }
  }

  @media (${bpWidth.mobile}) {
    p {
      ${headerSStyles};
      color: ${colors.beige100};
    }
  }
`;

const TextContainer = styled.div`
  max-width: 460px;
  margin: 1em 0 2.5em;

  p {
    ${bodyLSemiBoldUtopiaStyles};
    color: ${colors.beige100};
    margin-bottom: 30px;
  }

  @media (${bpWidth.tablet}) {
    p {
      ${bodySSemiBoldUtopiaStyles};
      color: ${colors.beige100};
    }
  }
`;

const SupportButton = styled.div`
  a {
    ${bodyMRegularBrownStyles};
    color: ${colors.beige100};
    text-decoration: none;
    width: auto;
    height: auto;
    padding: 12px 45px;
    border-radius: 50px;

    &:hover {
      background: ${colors.cerulean700};
      border: none;
      color: ${colors.beige100};
    }
  }
`;

const Form = styled.form`
  margin-top: 40px;
  width: 100%;
  max-width: 625px;

  @media (${bpWidth.tablet}) {
    margin-top: 105px;
    margin-bottom: 50px;
  }
`;

const ResultMessage = styled.div`
  padding: 0;
  font-size: 18px;
  display: ${(props) => props.display};
  width: 100%;
  font-family: ${fontFamilies.brownNormalFamily};
  font-weight: normal;
  margin-top: 0;
  color: #032f54;
`;

const FormBodyWrapper = styled.div`
  width: 100%;
  display: ${(props) => props.display};
  flex-flow: column;
  justify-content: space-between;
`;

const FormField = styled.div`
  max-width: 300px;

  &:nth-child(n + 2) {
    margin-top: 3em;
  }

  @media (${bpWidth.tablet}) {
    max-width: 375px;
  }
`;

const TextField = styled(Field).attrs({
  type: 'text',
})`
  width: 100%;
  min-width: 100px;
  max-width: 375px;
  padding: 0 0 5px 0;
  line-height: 1.7;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  border-bottom: 1px solid ${colors.beige100};
  color: ${colors.beige100};
  font-family: ${fontFamilies.brownNormalFamily};
  font-size: 14px;

  @media (${bpWidth.tablet}) {
    font-size: calc(12px + (14 - 12) * ((100vw - 421px) / (1025 - 421)));
  }

  @media (${bpWidth.mobile}) {
    font-size: 12px;
  }

  &::placeholder {
    color: ${colors.beige100};
    font-size: 14px;

    @media (${bpWidth.tablet}) {
      font-size: calc(12px + (14 - 12) * ((100vw - 421px) / (1025 - 421)));
    }

    @media (${bpWidth.mobile}) {
      font-size: 12px;
    }
  }
`;

const MessageLabel = styled.div`
  color: ${colors.beige100};
  font-family: ${fontFamilies.brownNormalFamily};
  font-size: 14px;
  line-height: 1.7;
  margin-top: 3em;

  @media (${bpWidth.tablet}) {
    font-size: calc(12px + (14 - 12) * ((100vw - 421px) / (1025 - 421)));
  }

  @media (${bpWidth.mobile}) {
    font-size: 12px;
  }
`;

const Message = styled(Field)`
  box-sizing: border-box;
  width: 100%;
  max-width: 300px;
  min-height: 125px;
  padding: 14px;
  color: ${colors.beige100};
  font-family: ${fontFamilies.brownNormalFamily};
  font-size: 14px;
  margin-top: 5px;
  border: 1px solid ${colors.beige100};
  background-color: transparent;
  line-height: 1.7;

  @media (${bpWidth.tablet}) {
    font-size: calc(12px + (14 - 12) * ((100vw - 421px) / (1025 - 421)));
    max-width: 375px;
  }

  @media (${bpWidth.mobile}) {
    font-size: 12px;
  }
`;

const FirstName = styled(TextField)``;
const LastName = styled(TextField)``;
const Email = styled(TextField)``;
const Subject = styled(TextField)``;

const ErrorStyles = styled.div`
  font-size: 12px;
  line-height: 2;
  color: #d94c1a;
  font-family: ${fontFamilies.brownNormalFamily};
  margin-top: 5px;
`;

const selectOptions = [
  { value: 'your-gita', label: 'gita product support' },
  { value: 'App', label: 'App support' },
  { value: 'purchasing', label: 'Purchasing support' },
  { value: 'general-question', label: 'General support' },
];

const DropdownIndicator = (props) => {
  const { selectProps } = props;
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <Chevron
          className="chevron"
          style={{
            transitionDuration: '.1s',
            transform: selectProps.menuIsOpen ? 'rotate(180deg)' : 'none',
          }}
        />
      </components.DropdownIndicator>
    )
  );
};

DropdownIndicator.propTypes = {
  selectProps: PropTypes.shape({
    menuIsOpen: PropTypes.bool,
  }).isRequired,
};

const selectStyles = {
  option: (provided) => ({
    color: colors.beige100,
    marginTop: '1em',
    fontFamily: fontFamilies.brownNormalFamily,
    fontWeight: 'normal',
    cursor: 'pointer',
    fontSize: '14px',
    '@media(max-width: 1024px)': {
      ...provided['@media(max-width: 1024px)'],
      fontSize: 'calc(12px + (14 - 12) * ((100vw - 421px) / (1025 - 421)))',
    },
    '@media(max-width: 420px)': {
      ...provided['@media(max-width: 420px)'],
      fontSize: '12px',
    },
  }),
  control: () => ({
    width: '100%',
    cursor: 'pointer',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${colors.beige100}`,
    height: '27.8px',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: colors.beige100,
    fontFamily: fontFamilies.brownNormalFamily,
    fontWeight: 'normal',
    fontSize: '14px !important',
    lineHeight: 1.7,
    paddingBottom: '5px',
    '@media(max-width: 1024px)': {
      ...provided['@media(max-width: 1024px)'],
      fontSize: 'calc(12px + (14 - 12) * ((100vw - 421px) / (1025 - 421))) !important',
    },
    '@media(max-width: 420px)': {
      ...provided['@media(max-width: 420px)'],
      fontSize: '12px !important',
    },
  }),
  menu: () => ({
    backgroundColor: colors.navy600,
    boxShadow: 'none',
    position: 'absolute',
    height: '235px',
    width: 'calc(100% + 4px)',
    left: '-2px',
  }),
  menuList: () => ({
    backgroundColor: colors.navy600,
    position: 'absolute',
    top: '0',
    left: '0',
    maxHeight: '235px',
    overflow: 'scroll',
    paddingLeft: '2px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: '0',
    paddingTop: '0',
    paddingBottom: '5px',
  }),
  container: (provided) => ({
    ...provided,
    width: '100%',
    boxSizing: 'content-box',
    position: 'relative',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: colors.beige100,
    fontFamily: fontFamilies.brownNormalFamily,
    fontWeight: 'normal',
    fontSize: '14px !important',
    lineHeight: '1.7',
    marginLeft: 0,
    marginRight: 0,
    paddingBottom: '6px',
    '@media(max-width: 1024px)': {
      ...provided['@media(max-width: 1024px)'],
      fontSize: 'calc(12px + (14 - 12) * ((100vw - 421px) / (1025 - 421))) !important',
    },
    '@media(max-width: 420px)': {
      ...provided['@media(max-width: 420px)'],
      fontSize: '12px !important',
    },
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
    paddingTop: '0',
    paddingBottom: '5px',
    lineHeight: '1.7',
  }),
  indicatorsContainer: () => ({
    // height: "18px",
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: () => ({
    marginRight: '5px',
    width: '10px',
    height: 'auto',
  }),
};

const SelectInput = (props) => {
  const { setFieldValue } = props;
  return (
    <Select
      components={{ DropdownIndicator }}
      options={selectOptions}
      styles={selectStyles}
      placeholder="Topic*"
      onChange={(selectedValue) => {
        setFieldValue('hs_ticket_category', selectedValue.value);
      }}
      classNamePrefix="react-select"
      // you can set this to true for debugging menu styling
      defaultMenuIsOpen={false}
    />
  );
};

SelectInput.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
};

const FormFooter = styled.div`
  padding-top: 20px;
  display: ${(props) => props.display};
  flex-direction: column;

  p,
  a {
    margin: 0;
    ${bodyXxsRegularBrownStyles};
    color: ${colors.beige100};
    cursor: pointer;

    @media (${bpWidth.mobile}) {
      ${bodyXxxsRegularBrownStyles};
      color: ${colors.beige100};
    }
  }

  a {
    text-decoration: underline;
  }
`;

const FormSubmitWrapper = styled.div`
  margin-top: 3em;
  display: ${(props) => props.display};
`;

const SubmitButton = styled.button.attrs({
  type: 'submit',
})`
  border-radius: 50px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  transition: all 250ms;
  width: auto;
  max-width: 250px;
  padding: 12px 45px;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 2.88px;
  display: inline-block;
  border: 0;
  ${bodyMRegularBrownStyles};
  color: ${colors.beige100};
  background: ${colors.cerulean400};

  // prevent 2nd line
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    color: ${colors.beige100};
    background: ${colors.cerulean700};
  }

  @media (${bpWidth.desktopSm}) {
    padding: 10px 38px;
    font-size: 14px;
  }

  @media (${bpWidth.tablet}) {
    padding: 11px 35px 10px;
    font-size: 16px;
  }

  @media (${bpWidth.mobile}) {
    padding: 11px 25px 10px;
    font-size: 12px;
    line-height: 1.7;
    letter-spacing: 2.16px;
  }
`;

const MessageHeader = styled.h1`
  p {
    font-size: 27px;
    line-height: 1.55;
    letter-spacing: -0.27px;
    font-family: ${fontFamilies.utopiaStdCaptionNormalBoldFontFamily};
    color: ${colors.beige100};
    width: 100%;
    margin: 0;
  }

  @media (${bpWidth.tablet}) {
    p {
      font-size: calc(19px + (27 - 19) * ((100vw - 421px) / (1024 - 421)));
    }
  }

  @media (${bpWidth.mobile}) {
    p {
      font-size: 19px;
    }
  }
`;

const MessageBody = styled.p`
  p {
    font-size: 19px;
    line-height: 1.68;
    letter-spacing: -0.19px;
    font-family: ${fontFamilies.utopiaStdCaptionNormalBoldFontFamily};
    color: ${colors.beige100};
    width: 100%;
    margin: 10px 0 0;
  }

  @media (${bpWidth.tablet}) {
    p {
      font-size: calc(14px + (19 - 14) * ((100vw - 421px) / (1024 - 421)));
    }
  }

  @media (${bpWidth.mobile}) {
    p {
      font-size: 14px;
      width: 82%;
    }
  }
`;

const SuccessMessage = ({ heading, body }) => (
  <>
    <MessageHeader>{heading}</MessageHeader>
    <MessageBody>{body}</MessageBody>
  </>
);
SuccessMessage.propTypes = {
  heading: any,
  body: any,
};

const FailureMessage = () => (
  <>
    <MessageHeader>
      <p>We're sorry.</p>
    </MessageHeader>
    <MessageBody>
      <p>There’s been an error in submitting your form. Please try again later.</p>
    </MessageBody>
  </>
);

const SupportForm = ({ blok }) => {
  const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';

  const [openPrivacyPolicyPopup, setOpenPrivacyPolicyPopup] = useState(false);

  const formik = useFormik({
    initialStatus: STATUS_INIT,
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      hs_ticket_category: '',
      subject: '',
      content: '',
      consent: 0,
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Invalid email address').required('Email is required'),
      firstname: Yup.string().required('First name is required'),
      lastname: Yup.string().required('Last name is required'),
      hs_ticket_category: Yup.string().required('Please choose a topic'),
      subject: Yup.string().required('Please enter a subject'),
    }),
    onSubmit: (values) => {
      if (!values.consent) {
        setOpenPrivacyPolicyPopup(true);
        return;
      }

      setOpenPrivacyPolicyPopup(false);

      const data = {
        fields: [
          {
            name: 'firstname',
            value: values.firstname,
          },
          {
            name: 'lastname',
            value: values.lastname,
          },
          {
            name: 'email',
            value: values.email,
          },
          {
            name: 'TICKET.hs_ticket_category',
            value: values.hs_ticket_category,
          },
          {
            name: 'TICKET.subject',
            value: values.subject,
          },
          {
            name: 'TICKET.content',
            value: values.content,
          },
        ],
        context: {
          pageName: 'B2B website support page',
          pageUri: window.location.href,
        },
      };

      // Add hubspot tracking cookie if available
      // eslint-disable-next-line no-useless-escape
      const hubspotTrackingCookie = document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/, '$1');
      if (hubspotTrackingCookie !== '') data.context.hutk = hubspotTrackingCookie;

      // staging
      let url =
        'https://api.hsforms.com/submissions/v3/integration/submit/6286664/0ea3ea8e-99ac-4100-a570-e6d07cc3e7fe';

      if (activeEnv === 'production') {
        // prod
        url = 'https://api.hsforms.com/submissions/v3/integration/submit/6286664/ec6f548e-3f01-46ae-a07a-82c96c8dd881';
      }

      fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      })
        .then((response) => {
          // response.ok checks if the response.status is in the range 200-299, inclusive
          formik.resetForm();
          formik.setStatus(response.ok ? STATUS_SUCCESS : STATUS_FAILURE);
        })
        .catch(() => {
          formik.setStatus(STATUS_FAILURE);
        })
        .finally(() => {
          formik.setSubmitting(false);
        });
    },
  });

  const onChoosingConsent = (choice) => {
    if (choice === consentChoiceValue.consent) {
      formik.values.consent = choice;
      formik.handleSubmit();
    } else {
      setOpenPrivacyPolicyPopup(false);
      formik.setSubmitting(false);
    }
  };

  return (
    <Col {...storyblokEditable(blok)} xs={8} md={10} lg={12}>
      <SupportRow>
        <SupportCol xsOffset={1} lgOffset={3} xs={6} md={3} lg={2}>
          <SupportHeader>{renderRichTextReact(blok.formHeading)}</SupportHeader>
          <TextContainer>{renderRichTextReact(blok.formBody)}</TextContainer>
          {blok.cta?.map((nestedBlok) => (
            <SupportButton key={nestedBlok._uid}>
              <StoryblokComponent blok={nestedBlok} />
            </SupportButton>
          ))}
        </SupportCol>
        <Col xsOffset={1} xs={6} md={4} lg={3}>
          <Row>
            <Col xs={8} md={10} lg={12}>
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit}>
                  <ResultMessage display={formik.status === STATUS_INIT ? 'none' : 'block'}>
                    {formik.status === STATUS_SUCCESS ? (
                      <SuccessMessage
                        heading={renderRichTextReact(blok.thankYouHeading)}
                        body={renderRichTextReact(blok.thankYouBody)}
                      />
                    ) : undefined}
                    {formik.status === STATUS_FAILURE ? <FailureMessage /> : undefined}
                  </ResultMessage>

                  <FormBodyWrapper display={formik.status === STATUS_INIT ? 'flex' : 'none'}>
                    <FormField>
                      <FirstName name="firstname" placeholder="First name*" />
                      <ErrorStyles>
                        <ErrorMessage name="firstname" />
                      </ErrorStyles>
                    </FormField>

                    <FormField>
                      <LastName name="lastname" placeholder="Last name*" />
                      <ErrorStyles>
                        <ErrorMessage name="lastname" />
                      </ErrorStyles>
                    </FormField>

                    <FormField>
                      <Email name="email" placeholder="Email*" />
                      <ErrorStyles>
                        <ErrorMessage name="email" />
                      </ErrorStyles>
                    </FormField>

                    <FormField>
                      <Field name="hs_ticket_category">{({ form }) => <SelectInput {...form} />}</Field>
                      <ErrorStyles>
                        <ErrorMessage name="hs_ticket_category" />
                      </ErrorStyles>
                    </FormField>

                    <FormField>
                      <Subject name="subject" placeholder="Subject*" />
                      <ErrorStyles>
                        <ErrorMessage name="subject" />
                      </ErrorStyles>
                    </FormField>

                    <MessageLabel>Tell us about your questions/requests/comments</MessageLabel>
                    <Message name="content" component="textarea" />
                    <ErrorStyles>
                      <ErrorMessage name="content" />
                    </ErrorStyles>
                  </FormBodyWrapper>
                  <FormSubmitWrapper display={formik.status === STATUS_INIT ? 'block' : 'none'}>
                    <SubmitButton>SUBMIT</SubmitButton>
                  </FormSubmitWrapper>
                  <FormFooter display={formik.status === STATUS_INIT ? 'block' : 'none'}>
                    {renderRichTextReact(blok.terms)}
                  </FormFooter>
                </Form>
              </FormikProvider>
            </Col>
          </Row>
          {openPrivacyPolicyPopup && (
            <PrivacyPolicyConfirmationPopup onPopUpClose={onChoosingConsent} lightMode={false} />
          )}
        </Col>
      </SupportRow>
    </Col>
  );
};

SupportForm.propTypes = {
  blok: object.isRequired,
};

export default SupportForm;
