import React, { useEffect, useState } from 'react';
import { bool, func, string } from 'prop-types';

// Styles
import styled from 'styled-components';
import { Col, Row } from 'react-styled-flexboxgrid';
import { bpWidth, colors } from '../../../styles/Web3.0/variables';
import { bodyLRegularBrownStyles, bodyMRegularBrownStyles } from '../../../styles/Web3.0/typography';

// Components

// Elements
const SearchBarRow = styled(Row)`
  flex: 0 1 auto;
`;

const SearchBarCol = styled(Col)`
  display: flex;
  flex-direction: column;
`;

const Section = styled.div`
  margin-bottom: 40px;

  input.searchInput {
    ${bodyLRegularBrownStyles};
    line-height: 1;
    color: ${colors.navy600};
    width: 100%;
    border: none;
    border-bottom: 0.75px solid ${colors.navy600};
    background: no-repeat;
    padding: 0 0 5px 0;

    &:focus,
    textarea {
      outline: none !important;
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${colors.navy600};
      opacity: 1; /* Firefox */
    }
    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: ${colors.navy600};
    }
    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: ${colors.navy600};
    }

    @media (${bpWidth.desktopSm}) {
      ${bodyMRegularBrownStyles};
      line-height: 1;
    }
  }
`;

// Props
const propTypes = {
  searchIsOpen: bool,
  searchQuery: string,
  onSearchSubmit: func,
};

const defaultProps = {
  searchIsOpen: false,
  searchQuery: '',
  onSearchSubmit: () => {},
};

// const SearchBar = ({onSearchSubmit}) => {
function SearchBar(props) {
  const { searchIsOpen, searchQuery, onSearchSubmit } = props;

  const [searchTerm, setSearchTerm] = useState('');
  const [searchValue, setSearchValue] = useState('');

  // Update search value if a suggested search is clicked
  useEffect(() => {
    if (searchQuery !== searchValue) {
      setSearchValue(searchQuery);
    }
  }, [searchQuery]);

  // Clear input value when search bar is closed
  useEffect(() => {
    if (!searchIsOpen) {
      setSearchValue(searchQuery);
    }
  }, [searchIsOpen]);

  // Pass through the searched term to parent component (Search.jsx)
  useEffect(() => {
    onSearchSubmit(searchTerm);
  }, [searchTerm]);

  return (
    <SearchBarRow>
      {/* Search bar */}
      <SearchBarCol xsOffset={1} xs={6} md={8} lg={10}>
        <Section>
          <input
            id="searchBarInput"
            className="searchInput"
            type="text"
            placeholder="What are you looking for?"
            value={searchValue}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Section>
      </SearchBarCol>
    </SearchBarRow>
  );
}

SearchBar.propTypes = propTypes;
SearchBar.defaultProps = defaultProps;
export default SearchBar;
