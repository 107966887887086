// eslint-disable-next-line no-unused-vars
import { ISbStoriesParams, ISbStoryParams, StoryblokBridgeConfigV2 } from 'gatsby-source-storyblok';

const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';

/**
 * @type ISbStoryParams
 */
export const defaultStoryblokStoryParams = {
  version: activeEnv === 'production' ? 'published' : 'draft',
};

/**
 * @type {ISbStoriesParams}
 */
export const defaultStoryblokStoriesParams = {
  version: activeEnv === 'production' ? 'published' : 'draft',
};

/**
 * @type ISbStoryParams
 */
export const defaultStoryblokParams = {
  version: activeEnv === 'production' ? 'published' : 'draft',
};

/**
 * @type {StoryblokBridgeConfigV2}
 */
export const defaultStoryblokBridgeConfig = {};
