import React, { useEffect, useState } from 'react';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import { Col, Row } from 'react-styled-flexboxgrid';
import { object } from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';
import AnimateHeight from 'react-animate-height';
import { useViewport } from '../../../context/viewport.context';
import { bpWidth, colors, sizes } from '../../../styles/Web3.0/variables';
import { btnSmTertiaryBlueStyles } from '../../../styles/Web3.0/buttons';
import Chevron from '../../../icons/Web3.0/arrow-DCerulean.svg';

const ItemRow = styled(Row)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 30px;
  column-gap: 25px;

  @media (${bpWidth.tablet}) {
    row-gap: 25px;
    column-gap: 15px;
    padding-left: 40px;
    padding-right: 40px;
  }

  @media (${bpWidth.mobile}) {
    grid-template-columns: repeat(1, 1fr);
    padding-left: 50px;
    padding-right: 50px;
  }
`;

const ItemRowAnimateHeight = styled(AnimateHeight)`
  > div {
    margin-top: 30px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 30px;
    column-gap: 25px;
  }

  @media (${bpWidth.tablet}) {
    > div {
      margin-top: 25px;
      row-gap: 25px;
      column-gap: 15px;
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  @media (${bpWidth.mobile}) {
    > div {
      grid-template-columns: repeat(1, 1fr);
      padding-left: 50px;
      padding-right: 50px;
    }
  }
`;

const ButtonWrapper = styled.div`
  text-align: center;
  margin-top: 40px;

  button {
    ${btnSmTertiaryBlueStyles};
    position: relative;

    .value-cta-arrow {
      width: 8px;
      position: absolute;
      top: calc(50% - 12px);
      left: calc(100% + 8px);

      svg {
        width: inherit;
        max-width: 8px;

        path {
          stroke: ${colors.cerulean400};
        }
      }
    }

    @media (${bpWidth.mobile}) {
      &:hover {
        color: ${colors.cerulean400};
      }
    }
  }
`;

function CTAArrow(isExpand) {
  return (
    <Chevron
      style={{
        transitionDuration: '.1s',
        transform: isExpand ? 'rotate(180deg)' : 'none',
      }}
    />
  );
}

const IconTextList = ({ blok }) => {
  const lessItems = _.cloneDeep(blok.items);
  const moreItems = lessItems.splice(6);
  const { viewWidth } = useViewport();

  const [isExpand, setIsExpand] = useState(false);
  const [expandText, setExpandText] = useState('See all');

  const toggleExpansion = () => {
    setIsExpand(!isExpand);
  };

  useEffect(() => {
    if (viewWidth < sizes.containerMinWidth.tablet) {
      setIsExpand(false);
    } else {
      setIsExpand(true);
    }
  }, [viewWidth]);

  useEffect(() => {
    if (isExpand) {
      setExpandText('See less');
    } else {
      setExpandText('See all');
    }
  }, [isExpand]);

  return (
    <Col {...storyblokEditable(blok)} xs={8} md={10} lg={12}>
      <ItemRow>
        {lessItems.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </ItemRow>
      <ItemRowAnimateHeight duration={250} height={isExpand ? 'auto' : 0} animateOpacity>
        {moreItems.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </ItemRowAnimateHeight>
      <Row center="xs">
        <Col xs={6} sm={false}>
          <ButtonWrapper>
            <button type="button" onClick={toggleExpansion}>
              {expandText}
              <div className="value-cta-arrow">{CTAArrow(isExpand)}</div>
            </button>
          </ButtonWrapper>
        </Col>
      </Row>
    </Col>
  );
};

IconTextList.propTypes = {
  blok: object.isRequired,
};

export default IconTextList;
