// exports for TikTok Events API events
// https://ads.tiktok.com/help/article/web-attribution?lang=en
import { PRODUCT_IDS } from '../constants';

export const tiktokViewPageAny = (ttq) => {
  ttq.track('ViewContent', {
    value: '1',
    currency: 'USD',
    content_type: 'product',
    contents: [
      {
        brand: 'Piaggio Fast Forward',
        content_id: '1',
      },
    ],
  });
};

export const tiktokViewPageGitamini = (ttq) => {
  ttq.track('ViewGitaminiContent', {
    value: '3',
    currency: 'USD',
    content_type: 'product',
    contents: [
      {
        content_id: PRODUCT_IDS.SHOPIFY_PRODUCT_IDS?.GITAMINI,
        content_name: 'gitamini',
      },
    ],
  });
};

export const tiktokViewPageGitaplus = (ttq) => {
  ttq.track('ViewGitaplusContent', {
    value: '3',
    currency: 'USD',
    content_type: 'product',
    contents: [
      {
        content_id: PRODUCT_IDS.SHOPIFY_PRODUCT_IDS?.GITAPLUS,
        content_name: 'gitaplus',
      },
    ],
  });
};

export const tiktokViewPageShop = (ttq) => {
  ttq.track('ViewShopContent', {
    value: '1',
    currency: 'USD',
    content_type: 'product',
    contents: [
      {
        content_name: 'shop',
        content_id: '2',
      },
    ],
  });
};

export const tiktokViewPageHowItWorks = (ttq) => {
  ttq.track('ViewHowItWorksContent', {
    value: '2',
    currency: 'USD',
    content_type: 'product',
    contents: [
      {
        content_name: 'how-it-works',
        content_id: '3',
      },
    ],
  });
};

export const tiktokViewPageBlog = (ttq) => {
  ttq.track('ViewBlogContent', {
    value: '2',
    currency: 'USD',
    content_type: 'product',
    contents: [
      {
        content_name: 'blog',
        content_id: '4',
      },
    ],
  });
};

export const tiktokViewPageFAQs = (ttq) => {
  ttq.track('ViewFaqContent', {
    value: '2',
    currency: 'USD',
    content_type: 'product',
    contents: [
      {
        content_name: 'faqs',
        content_id: '5',
      },
    ],
  });
};

export const tiktokClickButton = (ttq) => {
  ttq.track('ClickButton', {
    content_type: 'product',
    contents: [
      {
        content_name: 'Shop',
        content_id: '6',
      },
    ],
  });
};

export const tiktokSubscribe = (ttq) => {
  ttq.track('Subscribe', {
    content_type: 'product',
    content_id: '7',
  });
};

// standard 'AddToCart' event
export const tiktokAddToCartGitas = (ttq) => {
  ttq.track('AddToCart', {
    value: '5',
    currency: 'USD',
    content_type: 'product',
    contents: [
      {
        content_id: PRODUCT_IDS.SHOPIFY_PRODUCT_IDS?.GITAPLUS,
        content_name: 'gitaplus',
      },
      {
        content_id: PRODUCT_IDS.SHOPIFY_PRODUCT_IDS?.GITAMINI,
        content_name: 'gitamini',
      },
    ],
  });
};

export const tiktokAddToCartGitamini = (ttq) => {
  ttq.track('AddToCartGitamini', {
    value: '5',
    currency: 'USD',
    content_type: 'product',
    contents: [
      {
        content_id: PRODUCT_IDS.SHOPIFY_PRODUCT_IDS?.GITAMINI,
        content_name: 'gitamini',
        price: '2475',
      },
    ],
  });
};

export const tiktokAddToCartGitaplus = (ttq) => {
  ttq.track('AddToCartGitaplus', {
    value: '5',
    currency: 'USD',
    content_type: 'product',
    contents: [
      {
        content_id: PRODUCT_IDS.SHOPIFY_PRODUCT_IDS?.GITAPLUS,
        content_name: 'gitaplus',
        price: '3475',
      },
    ],
  });
};
