import React from 'react';
import { array, object, func } from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

// Styles
import { bpWidth, colors } from '../../../styles/Web3.0/variables';

// Elements
const VariantSelectionContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  @media (${bpWidth.desktopSm}) {
    padding-bottom: 15px;
  }
`;

const VariantSelection = styled.div`
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 20px;
  margin-bottom: 10px;
  height: 26px;
  width: 26px;
  box-sizing: border-box;

  &.active {
    border: 1px solid ${colors.gray200};
  }

  @media (${bpWidth.desktopSm}) {
    margin-right: 10px;
    margin-bottom: 0;
    height: 21px;
    width: 21px;
  }
`;

const VariantOption = styled.div`
  &.boardwalkBeige,
  &.red,
  &.signalRed,
  &.sparkCitron,
  &.shadowBlack,
  &.twilightBlue,
  &.thunderGray,
  &.gray,
  &.black,
  &.rapidBlue,
  &.espressoBrown {
    border-radius: 50%;
    height: 20px;
    width: 20px;
  }

  &.boardwalkBeige {
    background-color: ${colors.boardwalkBeige};
  }
  &.red {
    background-color: ${colors.red};
  }
  &.signalRed {
    background-color: ${colors.signalRed};
  }
  &.sparkCitron {
    background-color: ${colors.sparkCitron};
  }
  &.shadowBlack {
    background-color: ${colors.black};
  }
  &.twilightBlue {
    background-color: ${colors.twilightBlue};
  }
  &.thunderGray,
  &.gray {
    background-color: ${colors.thunderGray};
  }
  &.black {
    background-color: ${colors.black};
  }
  &.rapidBlue {
    background-color: ${colors.rapidBlue};
  }
  &.espressoBrown {
    background-color: ${colors.espressoBrown};
  }

  @media (${bpWidth.desktopSm}) {
    &.boardwalkBeige,
    &.red,
    &.signalRed,
    &.sparkCitron,
    &.shadowBlack,
    &.twilightBlue,
    &.thunderGray,
    &.rapidBlue,
    &.espressoBrown {
      height: 15px;
      width: 15px;
    }
  }
`;

// Props
const propTypes = {
  productVariants: array,
  currentVariant: object,
  onVariantChanged: func,
};

const defaultProps = {
  productVariants: [],
  onVariantChanged: () => {},
};

const ProductVariantSelector = (props) => {
  const { productVariants = [], currentVariant = null, onVariantChanged } = props;

  return (
    <VariantSelectionContainer>
      {productVariants.map((variant) => (
        <VariantSelection
          key={variant.storefrontId}
          className={currentVariant?.storefrontId === variant.storefrontId ? 'active' : undefined}
        >
          <VariantOption
            className={_.camelCase(variant.title)}
            style={{
              backgroundColor: variant.color,
            }}
            onClick={() => {
              onVariantChanged(variant);
            }}
          />
        </VariantSelection>
      ))}
    </VariantSelectionContainer>
  );
};

ProductVariantSelector.propTypes = propTypes;
ProductVariantSelector.defaultProps = defaultProps;
export default ProductVariantSelector;
