import React from 'react';
import PropTypes from 'prop-types';
import { storyblokEditable } from 'gatsby-source-storyblok';
import styled from 'styled-components';
import { Col, Row } from 'react-styled-flexboxgrid';
import {
  bodyLRegularBrownStyles,
  bodyMRegularBrownStyles,
  bodyMRegularUtopiaStyles,
  bodySRegularBrownStyles,
  bodySRegularUtopiaStyles,
  eyebrowLStyles,
  eyebrowMStyles,
  eyebrowSStyles,
  headerLFillStyles,
  headerMFillStyles,
  headerSStyles,
  headerXsStyles,
} from '../../styles/Web3.0/typography';
import { bpWidth, bpWidthTailwind, colors } from '../../styles/Web3.0/variables';
import { renderRichTextReact } from '../../utils/storyblokRichText';

const Grid = styled(Row)`
  @media (${bpWidth.mobile}) {
    gap: 20px 0;
  }
`;

const Text = styled.div`
  ${bodyMRegularUtopiaStyles};
  color: ${({ darkMode }) => (darkMode ? colors.cerulean100 : undefined)};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;

  i {
    font-style: italic;
  }

  @media (${bpWidth.desktopSm}) {
    ${bodySRegularUtopiaStyles};
  }
`;

const LeftCol = styled(Col)`
  padding-right: 20px;

  @media (${bpWidth.tablet}) {
    padding-right: 0;
    padding-bottom: 20px;
  }

  &.business-product-overview {
    padding: 0 0 2.125rem;

    ${Text} {
      ${headerXsStyles};
      color: ${({ darkMode }) => (darkMode ? colors.cerulean400 : undefined)};
      align-items: flex-start;
    }

    @media (${bpWidthTailwind.tablet}) {
      padding: 0 8rem 5rem 0;

      ${Text} {
        ${headerSStyles};
        color: ${({ darkMode }) => (darkMode ? colors.cerulean400 : undefined)};
        align-items: flex-start;
      }
    }

    @media (${bpWidthTailwind.desktopSm}) {
      padding: 0 5.5rem 1.25rem 0;

      ${Text} {
        ${headerMFillStyles};
        color: ${({ darkMode }) => (darkMode ? colors.cerulean400 : undefined)};
        align-items: flex-start;
      }
    }

    @media (${bpWidthTailwind.desktopLg}) {
      padding: 0 5.5rem 1.25rem 0;

      ${Text} {
        ${headerLFillStyles};
        color: ${({ darkMode }) => (darkMode ? colors.cerulean400 : undefined)};
        align-items: flex-start;
      }
    }
  }

  &.business-product-overview-mobile {
    display: none;
  }
`;

const RightCol = styled(Col)`
  padding-left: 20px;

  @media (${bpWidth.tablet}) {
    padding-left: 0;
  }

  &.business-product-overview {
    display: none;

    @media (${bpWidthTailwind.tablet}) {
      display: block;
      padding: 0 0 0 30%;
      margin: 0;

      ${Text} {
        ${bodySRegularBrownStyles};
        color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
        align-items: flex-start;
      }
    }

    @media (${bpWidthTailwind.desktopSm}) {
      display: block;
      padding: 0;
      margin: 0;
      flex-basis: 50%;
      max-width: 50%;

      ${Text} {
        ${bodyMRegularBrownStyles};
        color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
        align-items: flex-start;
      }
    }

    @media (${bpWidthTailwind.desktopLg}) {
      display: block;
      padding: 0 0 0 5.5rem;
      margin: 0;
      flex-basis: 50%;
      max-width: 50%;

      ${Text} {
        ${bodyLRegularBrownStyles};
        color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
        align-items: flex-start;
      }
    }
  }

  &.business-product-overview-mobile {
    ${Text} {
      ${bodySRegularBrownStyles};
      color: ${({ darkMode }) => (darkMode ? colors.navy400 : undefined)};
      align-items: flex-start;
    }
  }
`;

const Title = styled.div`
  ${eyebrowMStyles};
  color: ${({ darkMode }) => (darkMode ? colors.cerulean100 : undefined)};
  margin-bottom: 15px;

  @media (${bpWidth.desktopSm}) {
    ${eyebrowSStyles};
    color: ${({ darkMode }) => (darkMode ? colors.cerulean100 : undefined)};
  }

  &.business-product-overview {
    border-bottom: 3px solid ${colors.navy200};
    ${eyebrowSStyles};
    color: ${({ darkMode }) => (darkMode ? colors.cerulean100 : undefined)};
    width: 14.8rem;
    padding-bottom: 10px;
    margin-bottom: 42px;

    @media (${bpWidthTailwind.tablet}) {
      ${eyebrowLStyles};
      color: ${({ darkMode }) => (darkMode ? colors.cerulean100 : undefined)};
      width: 18.9rem;
      padding-bottom: 20px;
      margin-bottom: 63px;
    }
  }

  &.business-product-overview-mobile {
    display: none;
  }
`;

const TwoColumnsTitleTwoTexts = ({ blok }) => {
  const { title, textLeft, textRight } = blok;

  return (
    <Col {...storyblokEditable(blok)} xs={8} md={10} lg={12}>
      <Grid>
        <Col xs={8} md={10} lg={12}>
          <Title darkMode={blok.darkMode} className={blok.customCssClass}>
            {title}
          </Title>
        </Col>
        <Col xs={8} md={10} lg={12}>
          <Row>
            <LeftCol xs={8} md={5} lg={6} darkMode={blok.darkMode} className={blok.customCssClass}>
              <Text>{renderRichTextReact(textLeft)}</Text>
            </LeftCol>
            <RightCol xs={8} md={5} lg={6} darkMode={blok.darkMode} className={blok.customCssClass}>
              <Text>{renderRichTextReact(textRight)}</Text>
            </RightCol>
          </Row>
        </Col>
      </Grid>
    </Col>
  );
};

TwoColumnsTitleTwoTexts.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default TwoColumnsTitleTwoTexts;
