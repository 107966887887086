export * as MEDIA_TYPES from './press';
export * as BLOG from './blog';
export * as PAGE_URLS from './URL';
export * as PRODUCT_IDS from './products';
export * as REGEX from './regex';
export * as THEME from './theme';

export const SHOP_URL = '/shop';

export const FORM_STATUS = {
  STATUS_INIT: 'STATUS_INIT',
  STATUS_SUCCESS: 'STATUS_SUCCESS',
  STATUS_FAILURE: 'STATUS_FAILURE',
};

export const variants = {
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'tertiary',
};

export const DYNAMIC_HEADER_INDICATOR = 'dynamic-header-indicator';
export const SCROLL_UP = 'scroll-up';
export const SCROLL_DOWN = 'scroll-down';
