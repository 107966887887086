import React from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
import { isBrowser } from '../utils/helper';
import { useIsomorphicLayoutEffect } from '../hooks';

function getWindowDimensions() {
  let viewWidth = 1280;
  let viewHeight = 800;

  if (isBrowser) {
    viewWidth = window.document.body.clientWidth;
    viewHeight = window.document.body.clientHeight;
  }

  return {
    viewWidth,
    viewHeight,
  };
}

const ViewportContext = React.createContext(getWindowDimensions());

export const useViewport = () => React.useContext(ViewportContext);

const ViewportProvider = ({ children }) => {
  const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());

  const handleWindowResize = throttle(() => {
    setWindowDimensions(getWindowDimensions());
  }, 500);

  useIsomorphicLayoutEffect(() => {
    setWindowDimensions(getWindowDimensions());

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  // lock body scroll while cart is open
  useIsomorphicLayoutEffect(() => {
    const { viewHeight } = windowDimensions;
    document.documentElement.style.setProperty('--window-inner-height', `${viewHeight}px`);
  }, [windowDimensions]);

  return <ViewportContext.Provider value={windowDimensions}>{children}</ViewportContext.Provider>;
};

ViewportProvider.propTypes = {
  children: PropTypes.any,
};

export default ViewportProvider;
