import { REGEX } from '../constants';

const { SHOPIFY_ID_REGEX } = REGEX;

/**
 * @param  {Object} product Shopify product object
 * @param  {string} title product name
 * @param  {string} shopifyId gid string for product ID
 * @param  {Object} priceRangeV2 product variant price range
 */
export const dataLayerViewItem = (product) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'view_item',
    ecommerce: {
      items: [
        {
          item_name: product.title,
          item_id: product.shopifyId.substring(SHOPIFY_ID_REGEX.exec(product.shopifyId).index),
          price: product.priceRangeV2?.maxVariantPrice?.amount,
          item_brand: 'PIAGGIO',
          quantity: '1',
        },
      ],
    },
  });
};

/**
 * @param  {Object} variant Shopify variant object
 * @param  {string} productTitle name of variant's product
 * @param  {string} title variant name
 * @param  {string} storefrontId gid string for variant ID
 * @param  {number} price variant price
 */
export const dataLayerAddToCart = (variant) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'add_to_cart',
    ecommerce: {
      items: [
        {
          item_name: variant.productTitle,
          item_id: variant.storefrontId.substring(SHOPIFY_ID_REGEX.exec(variant.storefrontId).index),
          price: variant.price,
          item_brand: 'PIAGGIO',
          item_variant: variant.title,
          quantity: '1',
        },
      ],
    },
  });
};

/**
 * @typedef {object} LineItem
 * @property {string} title
 * @property {number} quantity
 * @property {variant} variant
 *
 * @typedef {object} variant
 * @property {number} id
 * @property {number} price
 * @property {string} title
 */
export const dataLayerCheckout = (lineItems) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'begin_checkout',
    ecommerce: {
      items: lineItems.map(({ title, quantity, variant }) => {
        return {
          item_name: title,
          item_id: variant.id.substring(SHOPIFY_ID_REGEX.exec(variant.id).index),
          price: variant.price.amount,
          item_brand: 'PIAGGIO',
          item_variant: variant.title,
          quantity,
        };
      }),
    },
  });
};

export const dataLayerSubscribe = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'newsletter-signup',
  });
};

export const dataLayerConsumerContactPage = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'contact_form',
  });
};

export const dataLayerBusinessContactPage = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'business_contact_form',
  });
};
