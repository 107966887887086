// exports for Reddit Pixel events
// https://reddit.my.site.com/helpcenter/s/article/Install-the-Reddit-Pixel-on-your-website
import { PRODUCT_IDS } from '../constants';

export const redditAddToCartGitas = (rdt) => {
  rdt('track', 'AddToCart', {
    currency: 'USD',
    value: '5',
    products: [
      {
        id: PRODUCT_IDS.SHOPIFY_PRODUCT_IDS?.GITAPLUS,
        name: 'gitaplus',
      },
      {
        id: PRODUCT_IDS.SHOPIFY_PRODUCT_IDS?.GITAMINI,
        name: 'gitamini',
      },
    ],
  });
};

export const redditAddToCartGitaplus = (rdt) => {
  rdt('track', 'AddToCart', {
    currency: 'USD',
    value: '5',
    products: [
      {
        id: PRODUCT_IDS.SHOPIFY_PRODUCT_IDS?.GITAPLUS,
        name: 'gitaplus',
      },
    ],
  });
};

export const redditAddToCartGitamini = (rdt) => {
  rdt('track', 'AddToCart', {
    currency: 'USD',
    value: '5',
    products: [
      {
        id: PRODUCT_IDS.SHOPIFY_PRODUCT_IDS?.GITAMINI,
        name: 'gitamini',
      },
    ],
  });
};

export const redditViewPageShop = (rdt) => {
  rdt('track', 'Custom', {
    customEventName: 'shop',
    currency: 'USD',
    value: '2',
  });
};

export const redditViewPageShopGitamini = (rdt) => {
  rdt('track', 'Custom', {
    customEventName: 'shop gitamini',
    currency: 'USD',
    value: '3',
  });
};

export const redditViewPageShopGitaplus = (rdt) => {
  rdt('track', 'Custom', {
    customEventName: 'shop gitaplus',
    currency: 'USD',
    value: '3',
  });
};

export const redditViewPageHowItWorks = (rdt) => {
  rdt('track', 'Custom', {
    customEventName: 'how-it-works',
    currency: 'USD',
    value: '1',
  });
};

export const redditViewPageBlog = (rdt) => {
  rdt('track', 'Custom', {
    customEventName: 'blog',
    currency: 'USD',
    value: '1',
  });
};

export const redditViewPageFAQs = (rdt) => {
  rdt('track', 'Custom', {
    customEventName: 'faqs',
    currency: 'USD',
    value: '1',
  });
};
