import React from 'react';
import PropTypes from 'prop-types';
import GlobalStyles from '../../styles/Web3.0/globalStyles';
import '../../styles/Web3.0/fonts/brown.css';
import '../../styles/Web3.0/utopia.css';
import AppStateProvider from '../../context/app-state.context';
import Layout from './layouts/Layout';

const DefaultPage = ({ blok }) => {
  return (
    <AppStateProvider>
      <GlobalStyles />
      <Layout blok={blok} />
    </AppStateProvider>
  );
};

DefaultPage.propTypes = {
  blok: PropTypes.any.isRequired,
};

DefaultPage.defaultProps = {};

export default DefaultPage;
