import React, { useEffect, useMemo, useState } from 'react';
import { Col } from 'react-styled-flexboxgrid';
import { object } from 'prop-types';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import _ from 'lodash';
import styled from 'styled-components';
import Slider from 'react-slick';
import { useViewport } from '../../../context/viewport.context';
import {
  bodyMBoldBrownStyles,
  bodySBoldBrownStyles,
  eyebrowSStyles,
  eyebrowXsStyles,
  headerMStyles,
} from '../../../styles/Web3.0/typography';
import { bpWidthTailwind, colors } from '../../../styles/Web3.0/variables';
import { renderRichTextReact } from '../../../utils/storyblokRichText';
import { FadeInOnView } from '../../Animation/FadeInOnView';
import { breakpoint } from '../../../constants/breakpoint';

const TeamName = styled.div`
  ${eyebrowSStyles};
  color: ${({ color }) => color};
`;

const Name = styled.div`
  ${bodySBoldBrownStyles};
  color: ${({ color }) => color};
  font-style: italic;
`;

const Position = styled.div`
  ${headerMStyles};
  font-size: 16px;
  color: ${colors.gray100};
`;

const Department = styled.div`
  ${bodyMBoldBrownStyles};
  color: ${({ color }) => color};
`;

const Division = styled.div`
  ${eyebrowXsStyles};
  color: ${colors.gray100};
`;

const TeamCardSlider = styled(Slider)`
  .slick-arrow {
    bottom: -60px;
    top: unset;
    transform: unset;
    left: unset;
    width: 34px;
    height: 34px;
    background: ${colors.beige700};
    border-radius: 50%;
  }

  .slick-prev {
    right: 82px;
  }

  .slick-next {
    right: 34px;
  }

  .slick-prev.slick-disabled,
  .slick-next.slick-disabled {
    opacity: 0.5;
  }

  .slick-prev:before,
  .slick-next:before {
    font-family: ui-sans-serif, system-ui, sans-serif;
    font-size: 30px;
    color: #000;
    opacity: 1;
  }

  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    background: ${colors.beige700};
  }

  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 1;
  }

  .slick-slide {
    padding-left: 0.5625rem;
    padding-right: 0.5625rem;
  }

  @media (${bpWidthTailwind.tablet}) {
    .slick-prev {
      right: 127px;
    }

    .slick-next {
      right: 79px;
    }

    .slick-slide {
      padding-left: 0.8125rem;
      padding-right: 0.8125rem;
    }
  }
`;

const TeamCardList = ({ blok }) => {
  const noTeam = 'NO_TEAM';
  const { viewWidth } = useViewport();
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [dataByRow, setDataByRow] = useState([]);
  const [dataForMobile, setDataForMobile] = useState([]);
  const chunkSize = useMemo(() => {
    let numOfItems = 4;
    if (viewWidth < breakpoint.lg) {
      numOfItems = 3;
    }
    if (viewWidth < breakpoint.md) {
      numOfItems = 0;
    }

    return numOfItems;
  }, [viewWidth]);
  const name = useMemo(() => renderRichTextReact(blok.teamCard[currentSlideIndex]?.name), [currentSlideIndex]);
  const position = useMemo(() => renderRichTextReact(blok.teamCard[currentSlideIndex]?.position), [currentSlideIndex]);
  const department = useMemo(
    () => renderRichTextReact(blok.teamCard[currentSlideIndex]?.department),
    [currentSlideIndex]
  );
  const division = useMemo(() => renderRichTextReact(blok.teamCard[currentSlideIndex]?.division), [currentSlideIndex]);

  const settings = useMemo(
    () => ({
      dots: false,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      fade: false,
      swipe: true,
      touchMove: true,
      swipeToSlide: true,
      focusOnSelect: true,
      variableWidth: true,
      centerMode: true,
      centerPadding: 0,
      beforeChange: (oldIndex, newIndex) => {
        setCurrentSlideIndex(newIndex);
      },
      onInit: () => {
        setCurrentSlideIndex(0);
      },
    }),
    [viewWidth]
  );

  useEffect(() => {
    setDataByRow(_.chunk(blok.teamCard || [], chunkSize));
    setDataForMobile(chunkSize === 0 ? blok.teamCard || [] : []);
  }, [chunkSize, blok.teamCard]);

  const groupByTeam = (data) => _.groupBy(data || [], (teamCard) => teamCard.team || noTeam);

  return (
    <Col {...storyblokEditable(blok)} xs={8} md={10} lg={12}>
      {dataByRow?.length > 0 && (
        <div className="hidden md:flex flex-col mt-3 lg:mt-9 mx-[7%] lg:mx-0">
          {dataByRow.map((data, rowIdx) => {
            const teams = groupByTeam(data);
            const teamKeys = Object.keys(teams);
            const teamVals = Object.values(teams);
            const [first] = teamVals[0];
            const rowKey1 = [first._uid, rowIdx, 1].join('-');
            const rowKey2 = [first._uid, rowIdx, 2].join('-');

            return (
              <>
                <div
                  key={rowKey1}
                  className="grid gap-x-[1%] justify-items-center mt-12 lg:mt-11"
                  style={{ gridTemplateColumns: `repeat(${chunkSize}, minmax(0, 1fr))` }}
                >
                  {data.length <= 2 && chunkSize === 4 && <div></div>}
                  {data.length === 1 && chunkSize === 3 && <div></div>}
                  {teamKeys.map((teamName) =>
                    teams[teamName].map((nestedBlok) => {
                      const teamCardIndexKey = [rowKey1, teamName, nestedBlok._uid].join('-');
                      return <StoryblokComponent blok={nestedBlok} key={teamCardIndexKey} />;
                    })
                  )}
                  {data.length === 2 && <div></div>}
                  {data.length === 1 && <div></div>}
                </div>
                <div
                  key={rowKey2}
                  className="grid gap-x-[1%] justify-items-center mt-[1.875rem] lg:mt-[1.375rem] text-center"
                  style={{ gridTemplateColumns: `repeat(${chunkSize}, minmax(0, 1fr))` }}
                >
                  {data.length <= 2 && chunkSize === 4 && <div></div>}
                  {data.length === 1 && chunkSize === 3 && <div></div>}
                  {teamKeys.map((teamName) => {
                    const teamNameIndexKey = [rowKey2, teamName].join('-');
                    return (
                      <TeamName
                        key={teamNameIndexKey}
                        color={teams[teamName][0].glowColor}
                        style={{ gridColumn: `span  ${teams[teamName].length}/ span ${teams[teamName].length}` }}
                      >
                        {teamName === noTeam ? undefined : teamName}
                      </TeamName>
                    );
                  })}
                  {data.length === 2 && <div></div>}
                  {data.length === 1 && <div></div>}
                </div>
              </>
            );
          })}
        </div>
      )}
      {dataForMobile?.length > 0 && (
        <>
          <div className="team-card-slider-container md:hidden mt-9 mb-16">
            <TeamCardSlider {...settings}>
              {dataForMobile.map((nestedBlok) => (
                <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
              ))}
            </TeamCardSlider>
          </div>
          <div className="team-card-slider-info-container md:hidden ml-14 sm:ml-56">
            <div className="inline-flex gap-[10px] pr-10 pb-7 border-b border-solid border-cerulean-100/50">
              <FadeInOnView>
                {name && <Name color={colors.sparkCitron}>{name}</Name>}
                {position && <Position>{position}</Position>}
              </FadeInOnView>
            </div>
            {(department || division) && (
              <div className="flex gap-[2px] pt-7">
                <FadeInOnView>
                  {department && <Department color={colors.sparkCitron}>{department}</Department>}
                  {division && <Division>{division}</Division>}
                </FadeInOnView>
              </div>
            )}
          </div>
        </>
      )}
    </Col>
  );
};

TeamCardList.propTypes = {
  blok: object.isRequired,
};

export default TeamCardList;
