import { useState, useEffect } from 'react';
import { isBrowser, off, on } from '../utils/helper';

const patchHistoryMethod = (method) => {
  const original = window.history[method];

  window.history[method] = (state) => {
    // eslint-disable-next-line no-undef
    const result = original.apply(this, arguments);
    const event = new Event(method.toLowerCase());

    event.state = state;

    window.dispatchEvent(event);

    return result;
  };
};

if (isBrowser) {
  patchHistoryMethod('pushState');
  patchHistoryMethod('replaceState');
}

const useLocation = () => {
  const buildState = (trigger) => {
    const { state, length } = window.history;

    const { hash, host, hostname, href, origin, pathname, port, protocol, search } = window.location;

    return {
      trigger,
      state,
      length,
      hash,
      host,
      hostname,
      href,
      origin,
      pathname,
      port,
      protocol,
      search,
    };
  };

  const [state, setState] = useState(
    isBrowser
      ? buildState('load')
      : {
          trigger: 'load',
          length: 1,
        }
  );

  const onChange = (trigger) => setState(buildState(trigger));

  const onPopstate = () => onChange('popstate');
  const onPushstate = () => onChange('pushstate');
  const onReplacestate = () => onChange('replacestate');

  useEffect(() => {
    on(window, 'popstate', onPopstate);
    on(window, 'pushstate', onPushstate);
    on(window, 'replacestate', onReplacestate);

    return () => {
      off(window, 'popstate', onPopstate);
      off(window, 'pushstate', onPushstate);
      off(window, 'replacestate', onReplacestate);
    };
  }, []);

  return state;
};

export default useLocation;
