import React, { useRef } from 'react';
import { object } from 'prop-types';
import styled from 'styled-components';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import { Row } from 'react-styled-flexboxgrid';
import HeaderWrapper from '../Header/HeaderWrapper';
import CategoryPosts from '../blogs/CategoryPosts';
import FooterWrapper from '../Footer/FooterWrapper';
import Search from '../search/Search';
import Cart from '../../Web3.0/Cart/Cart';
import { useIsomorphicLayoutEffect } from '../../../hooks';
import { inputLabels } from '../../../state/settings/settingsReducer';
import { useRootState } from '../../../context/root-state.context';
import { useAppState } from '../../../context/app-state.context';
import FixedBottom from '../fixed-bottom/FixedBottom';
import FixedAffirm from '../fixed-bottom/FixedAffirm';
import FixedShopCta from '../fixed-bottom/FixedShopCta';

const Main = styled.main`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
`;

const BodyWrapper = styled.div`
  flex: 1;
`;

const Layout = ({ blok }) => {
  const cartColInnerEl = useRef(null);
  const { categoryPostsSbStories, fixedBottomSetting } = useRootState();
  const { dispatchSetting, searchIsOpen, toggleSearch } = useAppState();

  useIsomorphicLayoutEffect(() => {
    dispatchSetting({
      name: inputLabels.showCartIcon,
      value: blok.showCartIcon || false,
    });
  }, [blok.showCartIcon]);

  useIsomorphicLayoutEffect(() => {
    dispatchSetting({
      name: inputLabels.showSearchIcon,
      value: blok.showSearchIcon || false,
    });
  }, [blok.showSearchIcon]);

  useIsomorphicLayoutEffect(() => {
    dispatchSetting({
      name: inputLabels.navLightMode,
      value: blok.navLightMode || false,
    });
    dispatchSetting({
      name: inputLabels.navFontColorLight,
      value: blok.navLightMode || false,
    });
  }, [blok.navLightMode]);

  return (
    <Main {...storyblokEditable(blok)} id="bodyContent">
      <HeaderWrapper />

      <BodyWrapper>
        {blok.body?.map((nestedBlok) => (
          <Row key={nestedBlok._uid}>
            <StoryblokComponent blok={nestedBlok} />
          </Row>
        ))}

        {/* Category posts */}
        {categoryPostsSbStories?.total > 0 && <CategoryPosts sbStories={categoryPostsSbStories} />}
      </BodyWrapper>

      {(fixedBottomSetting === 'show' || fixedBottomSetting === 'showHide') && (
        <FixedBottom fixedBottomSetting={fixedBottomSetting}>
          <div className="text-right">
            <FixedAffirm />
            <FixedShopCta />
          </div>
        </FixedBottom>
      )}

      <FooterWrapper />

      <Search searchIsOpen={searchIsOpen} handleSearchToggle={toggleSearch} />

      <Cart ref={cartColInnerEl} />
    </Main>
  );
};

Layout.propTypes = {
  blok: object.isRequired,
};

export default Layout;
