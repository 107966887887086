import React from 'react';

// styles
import styled from 'styled-components';

// components
import { bodySBoldBrownStyles, bodyXsBoldBrownStyles, bodyXxsBoldBrownStyles } from '../../../styles/Web3.0/typography';
import { bpWidthTailwind, colors } from '../../../styles/Web3.0/variables';

// props
const propTypes = {};
const defaultProps = {};

// elements
const AffirmText = styled.p`
  ${bodyXxsBoldBrownStyles};
  color: ${colors.beige700};
  padding-right: 20px;
  text-align: right;
  display: inline-block;
  vertical-align: middle;

  @media (${bpWidthTailwind.tablet}) {
    ${bodyXsBoldBrownStyles};
    color: ${colors.beige700};
  }

  @media (${bpWidthTailwind.desktopLg}) {
    ${bodySBoldBrownStyles};
    color: ${colors.beige700};
  }
`;

function FixedAffirm() {
  return (
    <AffirmText>
      Starting at $2475<br></br> or $88/month
    </AffirmText>
  );
}

FixedAffirm.propTypes = propTypes;
FixedAffirm.defaultProps = defaultProps;
export default FixedAffirm;
