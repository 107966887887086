/**
 * @param {string} name
 * @return {string}
 */
export const getCookie = (name) => {
  if (document.cookie.length > 0) {
    const cookieStart = document.cookie.indexOf(`${name}=`);

    if (cookieStart !== -1) {
      const valueStart = cookieStart + name.length + 1;
      let cookieEnd = document.cookie.indexOf(';', valueStart);

      if (cookieEnd === -1) {
        cookieEnd = document.cookie.length;
      }
      return unescape(document.cookie.substring(valueStart, cookieEnd));
    }
  }

  return '';
};
