import React, { useEffect, useRef, useState, useContext } from 'react';

// styles
import styled from 'styled-components';
import { Col, Row } from 'react-styled-flexboxgrid';
import { bpWidth, colors } from '../../../styles/Web3.0/variables';

// components
import { StoreContext } from '../../../context/store-context';
import CartHeader from './CartHeader';
import CartBody from './CartBody';
import CartFooter from './CartFooter';
import { useViewport } from '../../../context/viewport.context';

// elements
const CartRowOuter = styled(Row)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 22;
  width: 100vw;
  height: ${(props) => props.height}px;

  display: flex;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0s linear;
  transition-delay: 250ms;

  ${(props) =>
    props.cartIsOpen === true
      ? `
      visibility: visible;
      opacity: 1;
      transition-delay: 0s;
    `
      : ``};
`;
const CartColOuter = styled(Col)`
  background: none;
  height: ${(props) => props.height}px;
  transition: background-color 100ms ease;

  ${(props) =>
    props.cartIsOpen === true
      ? `
      background-color: rgba(243, 241, 237, .7);
    `
      : ``};
`;
const CartRowInner = styled(Row)`
  height: 100%;
`;
const CartColInner = styled(Col)`
  background-color: ${colors.beige100};
  opacity: 1;
  height: 100%;
  width: 28%;
  max-width: 28%;
  flex-basis: 28%;

  transform: ${(props) => (props.cartIsOpen === true ? `translateX(0%)` : `translateX(100%)`)};
  transition: transform 250ms ease;

  @media (${bpWidth.desktopSm}) {
    width: 35%;
    max-width: 35%;
    flex-basis: 35%;
  }

  @media (${bpWidth.tablet}) {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
`;

// should refactor these component names, but I needed them in order to sticky the CartFooter to the bottom of the Cart
const CarRowInnerInner = styled(Row)`
  height: 100%;
`;
const CartColInnerInner = styled(Col)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Cart = React.forwardRef((props, ref) => {
  const { checkout, isOpen: cartIsOpen } = useContext(StoreContext);
  const emptyCart = checkout.lineItems.length === 0;
  const { viewHeight } = useViewport();

  const cartRowOuterEl = useRef(null);
  const cartColOuterEl = useRef(null);
  const cartColInnerEl = ref;
  const accessoriesContainerEl = useRef(null);
  const accessoriesHeaderRowEl = useRef(null);
  const accessoriesBodyRowEl = useRef(null);

  const [accessoriesExpanded, setAccessoriesExpanded] = useState(false);
  const [lastLineItemBottomPadding, setLastLineItemBottomPadding] = useState(140);
  const [showAccessories, setShowAccessories] = useState(true);
  const [height100vh, setHeight100vh] = useState(null);

  const handleAccessoriesClick = () => {
    setAccessoriesExpanded(!accessoriesExpanded);
  };

  // an array containing only the main product titles currently in cart as strings
  // so if a gitaplus is in cart, this array will contain a string "gitaPLUS"
  // if a gitamini is in cart, this array will contain a string "gita mini" (this is how the handle is written in Shopify)
  const mainProductsInCart = !emptyCart
    ? checkout.lineItems
        .filter((lineItem) => {
          return lineItem.title === 'gitaplus' || lineItem.title === 'gitamini';
        })
        .map((lineItem) => {
          return lineItem.title;
        })
        .reduce((a, b) => {
          if (a.indexOf(b) < 0) a.push(b);
          return a;
        }, [])
    : [];
  // set setShowAccessories(false) when there's neither a gita or gitamini in the cart
  useEffect(() => {
    if (mainProductsInCart.length > 0) {
      setShowAccessories(true);
    } else {
      setShowAccessories(false);
    }
  }, [mainProductsInCart]);

  // set padding for last line item in cart
  // this is to account for the accessories container, which overlays the cart body
  // when the accessories container displays, add extra padding to allow the last line item to be visible
  useEffect(() => {
    if (accessoriesContainerEl.current && accessoriesHeaderRowEl.current) {
      const accessoriesContainerElOffsetHeight = accessoriesContainerEl.current.offsetHeight;
      const accessoriesHeaderRowElOffsetHeight = accessoriesHeaderRowEl.current.offsetHeight;

      if (!accessoriesExpanded) {
        setLastLineItemBottomPadding(accessoriesHeaderRowElOffsetHeight + 40);
      } else {
        setLastLineItemBottomPadding(accessoriesContainerElOffsetHeight + 40);
      }
    }
  }, [accessoriesContainerEl, accessoriesHeaderRowEl, accessoriesExpanded]);

  // reset accessoriesExpanded when cart is collapsed
  useEffect(() => {
    if (!cartIsOpen) {
      setAccessoriesExpanded(false);
    }
  }, [cartIsOpen]);

  // close accessories container when clicking outside of it, but still within the cart
  function useHandleOutsideAccessoriesContainerClick(_ref) {
    useEffect(() => {
      function handleOutsideAccessoriesContainerClick(event) {
        if (_ref.current && accessoriesExpanded && !_ref.current.contains(event.target)) {
          // pointer-events CSS property is set via props on the CartBody,
          // preventing cart body interaction until accessories container is collapsed
          handleAccessoriesClick();
        }
      }

      document.addEventListener('click', handleOutsideAccessoriesContainerClick);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('click', handleOutsideAccessoriesContainerClick);
      };
    }, [ref, accessoriesExpanded]);
  }

  useHandleOutsideAccessoriesContainerClick(accessoriesContainerEl);

  // lock body scroll while cart is open
  useEffect(() => {
    // helper function
    const preventDefault = (e) => {
      e.preventDefault();
    };

    if (cartIsOpen) {
      if (cartColOuterEl && cartRowOuterEl.current) {
        cartRowOuterEl.current.addEventListener('pointermove', preventDefault);
      }
    }
    return () => {
      if (cartColOuterEl && cartRowOuterEl.current) {
        cartRowOuterEl.current.removeEventListener('pointermove', preventDefault);
      }
    };
  }, [cartIsOpen]);

  useEffect(() => {
    setHeight100vh(viewHeight);
  }, [viewHeight]);

  return (
    <CartRowOuter cartIsOpen={cartIsOpen} ref={cartRowOuterEl} height={height100vh}>
      <CartColOuter xs={8} sm={8} md={10} lg={12} cartIsOpen={cartIsOpen} ref={cartColOuterEl} height={height100vh}>
        <CartRowInner end="md" id="outerCartEl">
          <CartColInner xs={8} sm={8} md={4} lg={4} cartIsOpen={cartIsOpen} ref={cartColInnerEl}>
            <CarRowInnerInner>
              <CartColInnerInner xs={8} sm={8} md={10} lg={12}>
                <CartHeader />
                <CartBody
                  accessoriesExpanded={accessoriesExpanded}
                  ref={accessoriesContainerEl}
                  lastLineItemBottomPadding={lastLineItemBottomPadding}
                />
                <CartFooter
                  ref={{ accessoriesContainerEl, accessoriesHeaderRowEl, accessoriesBodyRowEl }}
                  showAccessories={showAccessories}
                  accessoriesExpanded={accessoriesExpanded}
                  handleAccessoriesClick={handleAccessoriesClick}
                  mainProductsInCart={mainProductsInCart}
                />
              </CartColInnerInner>
            </CarRowInnerInner>
          </CartColInner>
        </CartRowInner>
      </CartColOuter>
    </CartRowOuter>
  );
});

export default Cart;
