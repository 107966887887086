import React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { func, object } from 'prop-types';
import styled from 'styled-components';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import { bpWidth } from '../../../styles/Web3.0/variables';
import { headerLFillStyles, headerSStyles } from '../../../styles/Web3.0/typography';

const SectionCol = styled(Col)`
  margin-top: 150px;

  @media (${bpWidth.desktopSm}) {
    margin-top: 110px;
  }

  @media (${bpWidth.mobile}) {
    margin-top: 100px;
  }
`;

const TeamRow = styled(Row)`
  justify-content: center;
  margin-bottom: 150px;

  &:last-of-type {
    margin-bottom: 30px;
  }

  @media (${bpWidth.desktopSm}) {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const TitleCol = styled(Col)`
  text-align: center;
  margin-bottom: 70px;

  h2 {
    ${headerLFillStyles};
  }

  @media (${bpWidth.desktopSm}) {
    margin-bottom: 40px;

    h2 {
      ${headerSStyles};
    }
  }

  @media (${bpWidth.tablet}) {
    margin-bottom: 30px;
  }

  @media (${bpWidth.mobile}) {
    margin-bottom: 40px;
  }
`;

const ProfileList = ({ blok, onProfileClick }) => {
  let colWidth = {
    xsOffset: 1,
    xs: 6,
    mdOffset: 2,
    md: 6,
    lgOffset: 1,
    lg: 10,
  };
  if (blok.narrowWidth) {
    colWidth = {
      xsOffset: 1,
      xs: 6,
      mdOffset: 2,
      md: 6,
      lgOffset: 3,
      lg: 6,
    };
  }
  return (
    <Col {...storyblokEditable(blok)} xs={8} md={10} lg={12}>
      <Row>
        <SectionCol {...colWidth}>
          <TeamRow>
            <TitleCol xs={8} md={10} lg={12}>
              <h2>{blok.heading}</h2>
            </TitleCol>

            {blok.members?.map((nestedBlok, idx) => (
              <StoryblokComponent
                blok={nestedBlok}
                key={nestedBlok._uid}
                narrowWidth={blok.narrowWidth}
                idx={idx}
                onProfileClick={() =>
                  nestedBlok.enableBio && onProfileClick && onProfileClick(nestedBlok, blok.members)
                }
              />
            ))}
          </TeamRow>
        </SectionCol>
      </Row>
    </Col>
  );
};

ProfileList.propTypes = {
  blok: object.isRequired,
  onProfileClick: func,
};

export default ProfileList;
