// exports for Meta pixel conversion events
// https://developers.facebook.com/docs/meta-pixel/advanced/
import { PRODUCT_IDS } from '../constants';

export const metaViewPageShop = (fbq) => {
  fbq('track', 'ViewContent', {
    content_name: 'View shop Content',
  });
};

export const metaViewPageGitamini = (fbq) => {
  fbq('track', 'ViewContent', {
    content_name: 'View gitamini Content',
    content_ids: [PRODUCT_IDS.SHOPIFY_PRODUCT_IDS?.GITAMINI],
    value: 2475,
    currency: 'USD',
  });
};

export const metaViewPageGitaplus = (fbq) => {
  fbq('track', 'ViewContent', {
    content_name: 'View gitaplus Content',
    content_ids: [PRODUCT_IDS.SHOPIFY_PRODUCT_IDS?.GITAPLUS],
    value: 3475,
    currency: 'USD',
  });
};

export const metaViewPageHowItWorks = (fbq) => {
  fbq('track', 'ViewContent', {
    content_name: 'View how it works Content',
  });
};

export const metaViewPageBlog = (fbq) => {
  fbq('track', 'ViewContent', {
    content_name: 'View Blog Content',
  });
};

export const metaViewPageFAQs = (fbq) => {
  fbq('track', 'ViewContent', {
    content_name: 'View FAQ Content',
  });
};

// standard 'AddToCart' event
export const metaAddToCartGitas = (fbq) => {
  fbq('track', 'AddToCart', {
    content_name: 'Add to Cart any gita',
    content_ids: [PRODUCT_IDS.SHOPIFY_PRODUCT_IDS?.GITAMINI, PRODUCT_IDS.SHOPIFY_PRODUCT_IDS?.GITAPLUS],
  });
};

export const metaAddToCartGitamini = (fbq) => {
  fbq('track', 'AddToCart', {
    content_name: 'Add to Cart gitamini',
    content_ids: [PRODUCT_IDS.SHOPIFY_PRODUCT_IDS?.GITAMINI],
    value: 2475,
    currency: 'USD',
  });
};

export const metaAddToCartGitaplus = (fbq) => {
  fbq('track', 'AddToCart', {
    content_name: 'Add to Cart gitaplus',
    content_ids: [PRODUCT_IDS.SHOPIFY_PRODUCT_IDS?.GITAPLUS],
    value: 3475,
    currency: 'USD',
  });
};
