import React, { useEffect, useState } from 'react';
import { object } from 'prop-types';
import { storyblokEditable } from 'gatsby-source-storyblok';
import styled from 'styled-components';
import { useViewport } from '../../../context/viewport.context';
import { useIsClient } from '../../../hooks';

const EmbeddedImageContainer = styled.div`
  margin-bottom: 32px;

  &.medium-width {
    width: 50%;
  }

  &.full-width {
    width: 100%;
  }
`;

const EmbeddedImage = ({ blok }) => {
  const { viewWidth } = useViewport();
  const isClient = useIsClient();

  const [src, setSrc] = useState('');
  const [alt, setAlt] = useState('');
  const [avifSrc, setAvifSrc] = useState('');
  const [webpSrc, setWebpSrc] = useState('');
  const [styleClassName, setStyleClassName] = useState('');

  useEffect(() => {
    const { source, customClassName } = blok;
    const imageWidth = viewWidth > 800 ? 800 : viewWidth;
    const qualitySrc = `${source.filename}/m/${imageWidth}x0/smart/filters:quality(50)`;

    setAlt(source.alt);
    setSrc(qualitySrc);
    setAvifSrc(`${qualitySrc}:format(avif)`);
    setWebpSrc(`${qualitySrc}:format(webp)`);
    setStyleClassName(['embedded-image', customClassName].filter(Boolean).join(' '));
  }, [blok, isClient, viewWidth]);

  return (
    <EmbeddedImageContainer {...storyblokEditable(blok)} className={styleClassName}>
      {blok?.source?.filename && (
        <picture>
          {avifSrc && <source type="image/avif" srcSet={avifSrc} />}
          {webpSrc && <source type="image/webp" srcSet={webpSrc} />}
          <img alt={alt} loading="lazy" src={src} />
        </picture>
      )}
    </EmbeddedImageContainer>
  );
};

EmbeddedImage.propTypes = {
  blok: object.isRequired,
};

export default EmbeddedImage;
