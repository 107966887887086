import React from 'react';
import { Link } from 'gatsby';
import { func } from 'prop-types';

// Styles
import styled from 'styled-components';
import { Col, Row } from 'react-styled-flexboxgrid';
import { bpWidth, colors } from '../../../styles/Web3.0/variables';
import {
  eyebrowSStyles,
  eyebrowXsStyles,
  bodyLRegularUtopiaStyles,
  bodyMRegularUtopiaStyles,
} from '../../../styles/Web3.0/typography';

// Components
import { useStoryblokDatasourceEntries } from '../../../hooks';
import { useAppState } from '../../../context/app-state.context';
import { renderPlainText, renderRichTextReact } from '../../../utils/storyblokRichText';

// Elements
const SuggestionsRow = styled(Row)`
  flex: 0 1 auto;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;

  :first-child {
    padding-bottom: 40px;
  }

  h4 {
    ${eyebrowSStyles};
    line-height: 1;
    color: ${colors.gray700};
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  a,
  h5 {
    ${bodyLRegularUtopiaStyles};
    line-height: 1;
    color: ${colors.gray500};
    margin-bottom: 15px;
    text-decoration: none;
    width: fit-content;
    cursor: pointer;

    :last-child {
      margin-bottom: 0;
    }

    &:hover {
      font-style: italic;
    }
  }

  @media (${bpWidth.desktopSm}) {
    h4 {
      ${eyebrowXsStyles};
      line-height: 1;
      color: ${colors.gray700};
      margin-bottom: 15px;
    }

    a,
    h5 {
      ${bodyMRegularUtopiaStyles};
      line-height: 1;
      color: ${colors.gray500};
      margin-bottom: 10px;

      :last-child {
        margin-bottom: 0;
      }
    }
  }
`;

function SearchSuggestions({ onSuggestionClick }) {
  const { popularSearches } = useAppState();
  const categories = useStoryblokDatasourceEntries('blog-category');

  // Change search term when a suggested search is clicked
  const handleSuggestionClick = (term) => {
    const searchSuggestionPlainText = renderPlainText(term);
    onSuggestionClick(searchSuggestionPlainText);
  };

  return (
    <SuggestionsRow>
      <Col xsOffset={1} xs={6} md={8} lg={10}>
        {/* Categories */}
        <Section>
          <h4>Categories</h4>
          {categories.map((category) => (
            <Link to={'/blog/' + category.value} key={category.id}>
              <span>{category.name}</span>
            </Link>
          ))}
        </Section>

        {/* Popular Searches */}
        <Section>
          <h4>Popular searches</h4>
          {popularSearches.map((popularSearch) => (
            <h5 role="presentation" key={popularSearch._uid} onClick={() => handleSuggestionClick(popularSearch.body)}>
              {renderRichTextReact(popularSearch.body)}
            </h5>
          ))}
        </Section>
      </Col>
    </SuggestionsRow>
  );
}

// Props
const propTypes = {
  onSuggestionClick: func,
};

const defaultProps = {
  onSuggestionClick: () => {},
};

SearchSuggestions.propTypes = propTypes;
SearchSuggestions.defaultProps = defaultProps;
export default SearchSuggestions;
