import { useCallback, useState } from 'react';

/**
 * @typedef UseBooleanOutput
 * @property {boolean} value
 * @property {(value: boolean) => void} setValue
 * @property {() => void} setTrue
 * @property {() => void} setFalse
 * @property {() => void} toggle
 */

/**
 * @param {boolean} [defaultValue]
 * @return {UseBooleanOutput}
 */
export function useBoolean(defaultValue) {
  const [value, setValue] = useState(!!defaultValue);

  const setTrue = useCallback(() => setValue(true), []);
  const setFalse = useCallback(() => setValue(false), []);
  const toggle = useCallback(() => setValue((x) => !x), []);

  return { value, setValue, setTrue, setFalse, toggle };
}
