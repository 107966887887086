import React from 'react';
import { Col } from 'react-styled-flexboxgrid';
import { bool, func, number, object } from 'prop-types';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import styled from 'styled-components';
import { bpWidth, colors } from '../../../styles/Web3.0/variables';
import {
  bodyMRegularUtopiaStyles,
  bodySRegularUtopiaStyles,
  eyebrowMStyles,
  eyebrowSStyles,
} from '../../../styles/Web3.0/typography';
import { renderRichTextReact } from '../../../utils/storyblokRichText';

const MemberCopy = styled.div`
  margin-top: 22px;

  h3.member-name {
    ${eyebrowMStyles};
    margin-bottom: 10px;
    display: inline-flex;
    align-items: center;

    i.n-e-arrow {
      display: inline-flex;
      align-items: center;
      justify-content: flex-end;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background: ${colors.beige200};
      margin-left: 7px;
      font-size: 0.5em;

      &:before {
        content: '\\2197';
      }
    }
  }

  div.member-title {
    ${bodyMRegularUtopiaStyles};
  }

  @media (${bpWidth.desktopSm}) {
    h3.member-name {
      ${eyebrowSStyles};
    }

    div.member-title {
      ${bodySRegularUtopiaStyles};
    }
  }
`;

const MemberCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 70px;
  width: ${({ narrowWidth }) => {
    if (narrowWidth) {
      return 'calc(100% / 3)';
    }

    return 'calc(100% / 5)';
  }};

  .member-col-inner {
    cursor: ${({ member }) => {
      if (member.enableBio) {
        return 'pointer';
      }
      return 'inherit';
    }};
  }

  &:hover {
    ${MemberCopy} {
      h3.member-name {
        color: ${({ member }) => {
          if (member.enableBio) {
            return colors.cerulean400;
          }
          return 'inherit';
        }};

        i.n-e-arrow {
          background: ${({ member }) => {
            if (member.enableBio) {
              return colors.cerulean400;
            }
            return 'inherit';
          }};

          &:before {
            color: ${({ member }) => {
              if (member.enableBio) {
                return colors.beige100;
              }
              return 'inherit';
            }};
          }
        }
      }
    }
  }

  @media (${bpWidth.desktopSm}) {
    width: ${({ idx, narrowWidth }) => {
      if (!narrowWidth) {
        if ([3, 4].includes(idx)) {
          return 'calc(100% / 2)';
        }
      }

      return 'calc(100% / 3)';
    }};
  }
  @media (${bpWidth.tablet}) {
    width: ${({ idx, narrowWidth }) => {
      if (!narrowWidth) {
        if (idx === 4) {
          return '100%';
        }
      }

      return 'calc(100% / 2)';
    }};
  }
  @media (${bpWidth.mobile}) {
    width: 100%;
  }
`;

const ProfileItem = ({ blok, narrowWidth = false, onProfileClick, idx = 0 }) => {
  return (
    <MemberCol {...storyblokEditable(blok)} idx={idx} narrowWidth={narrowWidth} member={blok}>
      <div className="member-col-inner" role="presentation" onClick={onProfileClick}>
        {blok.image?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
        <MemberCopy>
          <h3 className="member-name">
            {renderRichTextReact(blok.name)}
            {blok.enableBio && <i className="n-e-arrow" />}
          </h3>
          <div className="member-title">{renderRichTextReact(blok.title)}</div>
        </MemberCopy>
      </div>
    </MemberCol>
  );
};

ProfileItem.propTypes = {
  blok: object.isRequired,
  narrowWidth: bool,
  onProfileClick: func,
  idx: number,
};

ProfileItem.defaultProps = {
  narrowWidth: false,
  idx: 0,
};

export default ProfileItem;
