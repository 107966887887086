// hook to return the discount status of a line item
export default function useCheckoutDiscountStatus(checkout) {
  const emptyCart = checkout.lineItems.length === 0;

  // this returns whether a discount is currently applied
  const checkoutDiscountStatus = emptyCart ? false : checkout.discountApplications.length > 0;
  /*
    returns whether an applied discount is:
      - manual: CODE
      - automatic: AUTO
      - if it doesn't match the above: OTHER
      - null if no discount
  */
  const checkoutDiscountType = checkoutDiscountStatus
    ? checkout.discountApplications[0].type.name === 'DiscountCodeApplication'
      ? 'CODE'
      : checkout.discountApplications[0].type.name === 'AutomaticDiscountApplication'
      ? 'AUTO'
      : 'OTHER'
    : null;
  /*
    returns the currently applied discount code if applicable.
    only 1 discount can be applied at one time.
    returns null if no discount code is applied.
  */
  const checkoutDiscountCode = checkoutDiscountType === 'CODE' ? checkout.discountApplications[0].code : null;
  /*
    this will return the amount of the checkout's discount. if no disount is applied, it returns 0.
  */
  const checkoutDiscountAmount = checkoutDiscountStatus
    ? checkout.lineItemsSubtotalPrice.amount - checkout.totalPrice.amount
    : 0;
  // this will return the name of an applied automatic discount
  const checkoutDiscountName = checkoutDiscountType === 'AUTO' ? checkout.discountApplications[0].title : null;
  return {
    checkoutDiscountStatus,
    checkoutDiscountType,
    checkoutDiscountCode,
    checkoutDiscountAmount,
    checkoutDiscountName,
  };
}
