import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { StoryblokComponent } from 'gatsby-source-storyblok';
import { bpWidth, colors } from '../../../styles/Web3.0/variables.js';

const SocialContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  width: 100%;

  a {
    line-height: unset;

    img {
      width: 25px;
      height: 25px;
      color: ${colors.gray300};
      margin: 0;

      &:hover {
        color: ${colors.gray700};
      }
    }
  }

  @media (${bpWidth.tablet}) {
    a {
      img {
        width: 29px;
        height: 29px;
        margin: 0;
      }
    }
  }

  @media (${bpWidth.mobile}) {
    padding-top: 40px;
  }
`;

function Social({ links }) {
  return (
    <SocialContainer>
      {links.map((nestedBlok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
    </SocialContainer>
  );
}

Social.propTypes = {
  links: PropTypes.array.isRequired,
};

export default Social;
