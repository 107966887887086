import React from 'react';
import { storyblokInit, apiPlugin } from 'gatsby-source-storyblok';
import PropTypes from 'prop-types';
import DefaultPage from '../components/Storyblok/DefaultPage';
import Header from '../components/Storyblok/Header/Header';
import Footer from '../components/Storyblok/Footer/Footer';
import Section from '../components/Storyblok/layouts/Section';
import Button from '../components/Storyblok/singleElements/Button';
import Link from '../components/Storyblok/singleElements/Link';
import RichText from '../components/Storyblok/singleElements/RichText';
import Video from '../components/Storyblok/singleElements/Video';
import Slider1 from '../components/Storyblok/Carousels/Slider1';
import Slider1Item from '../components/Storyblok/Carousels/Slider1Item';
import Media from '../components/Storyblok/singleElements/Media';
import TwoImagesLarge from '../components/Storyblok/TwoImagesLarge';
import TwoColumnsTextWithImage from '../components/Storyblok/TwoColumnsTextWithImage';
import TwoColumnsTitleText from '../components/Storyblok/TwoColumnsTitleText';
import TwoColumnsTitleTwoTexts from '../components/Storyblok/TwoColumnsTitleTwoTexts';
import Hero from '../components/Storyblok/Hero';
import FAQ from '../components/Storyblok/FAQ/FAQ';
import Questions from '../components/Storyblok/FAQ/Questions';
import PromoteCategories from '../components/Storyblok/promoteCategory/PromoteCategories';
import PromoteCategoryItem from '../components/Storyblok/promoteCategory/PromoteCategoryItem';
import Newspapers from '../components/Storyblok/newspapers/Newspapers';
import Newspaper from '../components/Storyblok/newspapers/Newspaper';
import Testimonials from '../components/Storyblok/testimonials/Testimonials';
import Testimonial from '../components/Storyblok/testimonials/Testimonial';
import FeaturedPost from '../components/Storyblok/blogs/FeaturedPost';
import Post from '../components/Storyblok/blogs/Post';
import CarouselPost from '../components/Storyblok/blogs/CarouselPost';
import Support from '../components/Storyblok/support/Support';
import Contact from '../components/Storyblok/contact/Contact';
import TwoColumnsTextWithImage2 from '../components/Storyblok/TwoColumnsTextWithImage2';
import VideoWithTitleAndCta from '../components/Storyblok/VideoWithTitleAndCta';
import LargeImageWithTitleAndCta from '../components/Storyblok/LargeImageWithTitleAndCta';
import TwoColumnsTextWithManyImages from '../components/Storyblok/TwoColumnsTextWithManyImages';
import ProfileList from '../components/Storyblok/team/ProfileList';
import ProfileItem from '../components/Storyblok/team/ProfileItem';
import Team from '../components/Storyblok/team/Team';
import SingleColumnTitleTextCta from '../components/Storyblok/SingleColumnTitleTextCta';
import SingleColumnQuote from '../components/Storyblok/SingleColumnQuote';
import Slider2 from '../components/Storyblok/Carousels/Slider2';
import Slider2Item from '../components/Storyblok/Carousels/Slider2Item';
import IconTextListItem from '../components/Storyblok/iconTextList/IconTextListItem';
import IconTextList from '../components/Storyblok/iconTextList/IconTextList';
import SingleColumnTitleTextAccordion from '../components/Storyblok/SingleColumnTitleTextAccordion';
import AccordionList from '../components/Storyblok/accordion/AccordionList';
import AccordionItem from '../components/Storyblok/accordion/AccordionItem';
import Vacancies from '../components/Storyblok/vacancies/Vacancies';
import Policy from '../components/Storyblok/policy/Policy';
import PolicyItem from '../components/Storyblok/policy/PolicyItem';
import TableData from '../components/Storyblok/table/TableData';
import TableDataHeaderCell from '../components/Storyblok/table/TableDataHeaderCell';
import TableDataRow from '../components/Storyblok/table/TableDataRow';
import TableDataCell from '../components/Storyblok/table/TableDataCell';
import BusinessPage from '../components/Storyblok/BusinessPage';
import BusinessHeader from '../components/Storyblok/Header/BusinessHeader';
import BusinessFooter from '../components/Storyblok/Footer/BusinessFooter';
import TwoColumnsTitleTextCta from '../components/Storyblok/TwoColumnsTitleTextCta';
import BusinessProductList from '../components/Storyblok/Product/BusinessProductList';
import BusinessProductListItem from '../components/Storyblok/Product/BusinessProductListItem';
import BusinessSupport from '../components/Storyblok/support/BusinessSupport';
import Slider3 from '../components/Storyblok/Carousels/Slider3';
import Slider3Item from '../components/Storyblok/Carousels/Slider3Item';
import Tabs from '../components/Storyblok/tabber/Tabs';
import TabItem from '../components/Storyblok/tabber/TabItem';
import PostGrid from '../components/Storyblok/press/PostGrid';
import Press from '../components/Storyblok/press/Press';
import MediaKitAccordionItem from '../components/Storyblok/accordion/MediaKitAccordionItem';
import MediaKit from '../components/Storyblok/accordion/MediaKit';
import ShopProduct from '../components/Storyblok/Product/ShopProduct';
import ShopProductList from '../components/Storyblok/Product/ShopProductList';
import PDFViewer from '../components/Storyblok/PDFViewer';
import EmbeddedVideo from '../components/Storyblok/singleElements/EmbeddedVideo';
import EmbeddedImage from '../components/Storyblok/singleElements/EmbeddedImage';
import SectionHeader from '../components/Storyblok/layouts/SectionHeader';
import Tiles from '../components/Storyblok/tiles/Tiles';
import TileItem from '../components/Storyblok/tiles/TileItem';
import Slider4 from '../components/Storyblok/Carousels/Slider4';
import Slider4Item from '../components/Storyblok/Carousels/Slider4Item';
import TeamCardList from '../components/Storyblok/teamCard/TeamCardList';
import TeamCard from '../components/Storyblok/teamCard/TeamCard';
import TwoColumnsBlok from '../components/Storyblok/TwoColumnsBlok';
import EmbeddedEventTime from '../components/Storyblok/singleElements/EmbeddedEventTime';
import ThreeColumnsBlok from '../components/Storyblok/ThreeColumnsBlok';
import ProductDetailsSection from '../components/Storyblok/Product/ProductDetailsSection';
import ProductDetailSlider from '../components/Storyblok/Carousels/ProductDetailSlider';
import BusinessHero from '../components/Storyblok/BusinessHero';
import BusinessContact from '../components/Storyblok/contact/BusinessContact';

const components = {
  defaultPage: DefaultPage,
  header: Header,
  footer: Footer,
  page: DefaultPage,
  section: Section,
  media: Media,
  button: Button,
  link: Link,
  richText: RichText,
  video: Video,
  slider1: Slider1,
  slider1Item: Slider1Item,
  twoImagesLarge: TwoImagesLarge,
  twoColumnsTextWithImage: TwoColumnsTextWithImage,
  twoColumnsTitleText: TwoColumnsTitleText,
  twoColumnsTitleTwoTexts: TwoColumnsTitleTwoTexts,
  hero: Hero,
  FAQ,
  questions: Questions,
  promoteCategories: PromoteCategories,
  promoteCategoryItem: PromoteCategoryItem,
  newspapers: Newspapers,
  newspaper: Newspaper,
  testimonials: Testimonials,
  testimonial: Testimonial,
  featuredPost: FeaturedPost,
  post: Post,
  carouselPost: CarouselPost,
  support: Support,
  contact: Contact,
  twoColumnsTextWithImage2: TwoColumnsTextWithImage2,
  videoWithTitleAndCta: VideoWithTitleAndCta,
  largeImageWithTitleAndCta: LargeImageWithTitleAndCta,
  twoColumnsTextWithManyImages: TwoColumnsTextWithManyImages,
  team: Team,
  profileList: ProfileList,
  profileItem: ProfileItem,
  singleColumnTitleTextCta: SingleColumnTitleTextCta,
  singleColumnQuote: SingleColumnQuote,
  slider2: Slider2,
  slider2Item: Slider2Item,
  iconTextList: IconTextList,
  iconTextListItem: IconTextListItem,
  singleColumnTitleTextAccordion: SingleColumnTitleTextAccordion,
  accordionList: AccordionList,
  accordionItem: AccordionItem,
  vacancies: Vacancies,
  policy: Policy,
  policyItem: PolicyItem,
  tableData: TableData,
  tableDataHeaderCell: TableDataHeaderCell,
  tableDataRow: TableDataRow,
  tableDataCell: TableDataCell,
  businessPage: BusinessPage,
  businessHeader: BusinessHeader,
  businessFooter: BusinessFooter,
  twoColumnsTitleTextCta: TwoColumnsTitleTextCta,
  businessProductList: BusinessProductList,
  businessProductListItem: BusinessProductListItem,
  businessSupport: BusinessSupport,
  slider3: Slider3,
  slider3Item: Slider3Item,
  tabs: Tabs,
  tabItem: TabItem,
  postGrid: PostGrid,
  press: Press,
  mediaKitAccordionItem: MediaKitAccordionItem,
  mediaKit: MediaKit,
  shopProduct: ShopProduct,
  shopProductList: ShopProductList,
  pdfViewer: PDFViewer,
  embeddedVideo: EmbeddedVideo,
  embeddedImage: EmbeddedImage,
  sectionHeader: SectionHeader,
  tiles: Tiles,
  tileItem: TileItem,
  slider4: Slider4,
  slider4Item: Slider4Item,
  teamCardList: TeamCardList,
  teamCard: TeamCard,
  twoColumnsBlok: TwoColumnsBlok,
  embeddedEventTime: EmbeddedEventTime,
  threeColumnsBlok: ThreeColumnsBlok,
  productDetailsSection: ProductDetailsSection,
  productDetailSlider: ProductDetailSlider,
  businessHero: BusinessHero,
  businessContact: BusinessContact,
};

const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';

storyblokInit({
  accessToken: process.env.GATSBY_STORYBLOK_ACCESSTOKEN,
  bridge: activeEnv !== 'production',
  use: [apiPlugin],
  components,
});

const StoryblokContext = React.createContext(null);

const StoryblokProvider = ({ children }) => {
  return <StoryblokContext.Provider value={null}>{children}</StoryblokContext.Provider>;
};

StoryblokProvider.propTypes = {
  children: PropTypes.any,
};

export default StoryblokProvider;
