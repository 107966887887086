import { css } from 'styled-components';

// CSS for elements that have a 'fadedText' className
export const fadeTextStyle = css`
  -webkit-mask-image: linear-gradient(to bottom, black 90%, transparent 100%);
  mask-image: linear-gradient(to bottom, black 90%, transparent 100%);
`;

// Add or remove 'fadedText' className to copy
export function toggleFadeClass(stateChange, copyContainer) {
  if (stateChange === 'add') {
    if (!copyContainer.classList.contains('fadedText')) {
      copyContainer.classList.add('fadedText');
    }
  } else if (copyContainer.classList.contains('fadedText')) {
    copyContainer.classList.remove('fadedText');
  }
}

// Remove fade ('fadedText' className) if user scrolls down the copy
export function onScrollFadeClassChange(copyContainer) {
  if (copyContainer.scrollTop > 0) {
    toggleFadeClass('remove', copyContainer);
  } else if (copyContainer.scrollTop === 0) {
    toggleFadeClass('add', copyContainer);
  }
}
