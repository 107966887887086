/**
 * Variables
 */

/**
 * Colors
 */
export const colors = {
  beige700: '#B2AFA4',
  beige600: '#BEBBAF',
  beige500: '#CAC6B9',
  beige400: '#D6D2C4',
  beige300: '#DFDCD2',
  beige200: '#E9E7E0',
  beige100: '#F3F1ED',

  cerulean700: '#008399',
  cerulean600: '#0093AF',
  cerulean500: '#00A4C6',
  cerulean400: '#00B4DC',
  cerulean300: '#2EC0DF',
  cerulean200: '#5BCCE2',
  cerulean100: '#89D8E5',

  gray700: '#595959',
  gray600: '#686869',
  gray500: '#777779',
  gray400: '#868689',
  gray300: '#9D9D9F',
  gray200: '#B5B5B6',
  gray100: '#CCCCCC',

  navy700: '#001219',
  navy600: '#021A26',
  navy500: '#032F54',
  navy400: '#063D65',
  navy300: '#0A4C77',
  navy200: '#0D5A88',
  navy100: '#106899',

  orange700: '#B23210',
  orange600: '#C13911',
  orange500: '#D13F12',
  orange400: '#E04613',
  orange300: '#EA5D33',
  orange200: '#F57353',
  orange100: '#FF8A73',

  white: '#ffffff',
  black: '#000000',

  // Product Variants
  boardwalkBeige: '#D6D2C4',
  signalRed: '#DF1F26',
  sparkCitron: '#DDDE1F',
  thunderGray: '#8E8E89',
  twilightBlue: '#0C1F2C',
  red: '#A21C21',
  rapidBlue: '#0BACC6',
  espressoBrown: '#4E2B18',
};

/**
 * breakpoints
 */
export const bpWidth = {
  xsMobile: 'max-width: 395px',
  mobile: 'max-width: 414px',
  tablet: 'max-width: 768px',
  desktopSm: 'max-width: 1280px',
};

/**
 * breakpoints mobile first
 */
export const bpWidthMobileFirst = {
  xsMobile: 'max-width: 395px',
  mobile: 'min-width: 414px',
  tablet: 'min-width: 768px',
  desktopSm: 'min-width: 1280px',
  desktopLg: 'min-width: 1920px',
};

export const bpWidthTailwind = {
  tablet: 'min-width: 415px',
  desktopSm: 'min-width: 769px',
  desktopLg: 'min-width: 1281px',
}

/**`
 * container sizes
 */
export const sizes = {
  containerMaxWidth: {
    // Window max-widths in px
    mobile: 414, // Mobile large   (0-414px)
    tablet: 768, // Tablet         (415-768px)
    desktopSm: 1280, // Desktop small  (769-1280px)
  },
  containerMinWidth: {
    // Window min-widths in px
    mobile: 0, // Mobile large   (0-414px)
    tablet: 415, // Tablet         (415-768px)
    desktopSm: 769, // Desktop small  (769-1280px)
    desktopLg: 1281, // Desktop large  (1281+px)
  },
  breakpoint: {
    // Breakpoints use ems and use min-widths
    mobile: 0, // 0em == min 0px
    tablet: 25.9375, // 25.9375em == 415px
    desktopSm: 48.0625, // 48.0625em == 769px
    desktopLg: 80.0625, // 80.0625em == 1281px
  },
  gridCount: {
    // Grid counts to different window widths
    mobile: 8,
    tablet: 8,
    desktopSm: 10,
    desktopLg: 12,
  },
};

/**
 * font-family
 */
export const fontFamilies = {
  brownFontFamily: `"Brown", Helvetica, san-serif`,
  utopiaStdCaptionFontFamily: `"utopia-std-caption", "Brown", Helvetica, sans-serif`,
  brownNormalFamily: `"brownNormal", Helvetica, san-serif`,
  brownNormalBoldFamily: `"brownNormalBold", Helvetica, san-serif`,
  utopiaStdCaptionNormalBoldFontFamily: `"utopia-std-caption", "brownNormalBold", Helvetica, sans-serif`,
};
