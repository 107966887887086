import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-styled-flexboxgrid';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';

const Slider3Item = ({ blok }) => {
  const { asset } = blok;

  return (
    <Col {...storyblokEditable(blok)} xs={8} md={10} lg={12}>
      {asset?.map((nestedBlok) => (
        <Row key={nestedBlok._uid}>
          <StoryblokComponent blok={nestedBlok} />
        </Row>
      ))}
    </Col>
  );
};

Slider3Item.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default Slider3Item;
