import { useState } from 'react';
import { getStoryblokApi, registerStoryblokBridge } from 'gatsby-source-storyblok';
import { useIsMounted } from './useIsMounted';
import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect';
import {
  defaultStoryblokBridgeConfig,
  defaultStoryblokParams,
  defaultStoryblokStoryParams,
} from '../common/constants/storyblok.constant';

/**
 * @param {string} slug
 * @param {ISbStoryParams} [params]
 * @param {StoryblokBridgeConfigV2} [bridgeConfig]
 * @return {{storyblokStory: ISbStory, storyblokStoryData: ISbStoryData}}
 */
export function useStoryblokStory(slug, params, bridgeConfig) {
  const sbStoryParams = {
    ...defaultStoryblokStoryParams,
    ...params,
  };
  const sbBridgeConfig = {
    ...defaultStoryblokBridgeConfig,
    ...bridgeConfig,
  };

  /**
   * @type {ISbStory | null}
   */
  const initialStory = null;

  /**
   * @type {ISbStoryData | null}
   */
  const initialStoryData = null;

  const [storyblokStory, setStoryblokStory] = useState(initialStory);
  const [storyblokStoryData, setStoryblokStoryData] = useState(initialStoryData);
  const isMounted = useIsMounted();

  useIsomorphicLayoutEffect(() => {
    getStoryblokApi()
      .getStory(slug, sbStoryParams)
      .then((sbStory) => {
        if (isMounted()) {
          setStoryblokStory(sbStory);
          setStoryblokStoryData(sbStory.data.story);
          registerStoryblokBridge(sbStory.data.story.id, setStoryblokStoryData, sbBridgeConfig);
        }
      })
      .catch(console.error);
  }, [isMounted]);

  return { storyblokStory, storyblokStoryData };
}

/**
 * @param {string} slug
 * @param {ISbStoryParams} [params]
 * @return {Promise<ISbStory>}
 */
export async function fetchStoryblokStory(slug, params) {
  const sbStoryParams = {
    ...defaultStoryblokStoryParams,
    ...params,
  };

  return getStoryblokApi().getStory(slug, sbStoryParams);
}

/**
 * @param {ISbStoriesParams} [params]
 * @return {ISbStories}
 */
export function useStoryblokStories(params) {
  const sbStoryParams = {
    ...defaultStoryblokStoryParams,
    ...params,
  };

  /**
   * @type {ISbStories | null}
   */
  const initialStories = null;

  const [storyblokStories, setStoryblokStories] = useState(initialStories);
  const isMounted = useIsMounted();

  useIsomorphicLayoutEffect(() => {
    getStoryblokApi()
      .getStories(sbStoryParams)
      .then((sbStories) => {
        if (isMounted()) {
          setStoryblokStories(sbStories);
        }
      })
      .catch(console.error);
  }, [isMounted]);

  return storyblokStories;
}

/**
 * @param {ISbStoriesParams} [params]
 * @return {Promise<ISbStories>}
 */
export async function fetchStoryblokStories(params) {
  const sbStoryParams = {
    ...defaultStoryblokStoryParams,
    ...params,
  };

  return getStoryblokApi().getStories(sbStoryParams);
}

/**
 * @typedef DatasourceEntry
 * @property {number | string} id
 * @property {string} name
 * @property {string} value
 * @property {string | null} dimension_value
 */

/**
 * @param {string} datasource
 * @param {ISbStoryParams} [params]
 * @return {DatasourceEntry[]}
 */
export function useStoryblokDatasourceEntries(datasource, params) {
  const sbParams = {
    ...defaultStoryblokParams,
    ...params,
    datasource,
  };

  /**
   * @type {DatasourceEntry[]}
   */
  const initialDatasourceEntries = [];

  const [storyblokDatasourceEntries, setStoryblokDatasourceEntries] = useState(initialDatasourceEntries);
  const isMounted = useIsMounted();

  useIsomorphicLayoutEffect(() => {
    getStoryblokApi()
      .getAll('cdn/datasource_entries', sbParams)
      .then((data) => {
        if (isMounted()) {
          setStoryblokDatasourceEntries(data);
        }
      })
      .catch(console.error);
  }, [isMounted]);

  return storyblokDatasourceEntries;
}

/**
 * @param {SbGatsbyStory} originalStory
 * @param {StoryblokBridgeConfigV2} [bridgeOptions={}]
 * @return {SbGatsbyStory}
 */
export function useStoryblokState(originalStory, bridgeOptions) {
  if (typeof originalStory.content === 'string') originalStory.content = JSON.parse(originalStory.content);

  const [story, setStory] = useState(originalStory);

  useIsomorphicLayoutEffect(() => {
    registerStoryblokBridge(story.id, setStory, bridgeOptions);
  }, []);

  return story;
}
