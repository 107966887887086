import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import styled from 'styled-components';
import { Col, Row } from 'react-styled-flexboxgrid';
import {
  bodyLRegularUtopiaStyles,
  bodyLSemiBoldUtopiaStyles,
  bodyMRegularUtopiaStyles,
  bodyMSemiBoldUtopiaStyles,
  bodySRegularUtopiaStyles,
  headerLFillStyles,
  headerSStyles,
  headerXlFillStyles,
} from '../../styles/Web3.0/typography';
import { bpWidth, colors } from '../../styles/Web3.0/variables';
import { renderRichTextReact } from '../../utils/storyblokRichText';

const InnerRow = styled(Row)`
  justify-content: space-between;
`;

const Title = styled.h2`
  ${headerXlFillStyles};
  color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
  margin: -15px 0 35px;

  @media (${bpWidth.tablet}) {
    ${headerLFillStyles};
    color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
    margin: 0 0 25px;
  }

  @media (${bpWidth.mobile}) {
    ${headerSStyles};
    color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
    margin: 0 0 20px;
  }
`;

const Text = styled.div`
  ${({ darkMode }) => (darkMode ? bodyLSemiBoldUtopiaStyles : bodyLRegularUtopiaStyles)};
  color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
  margin-bottom: 40px;

  @media (${bpWidth.desktopSm}) {
    ${({ darkMode }) => (darkMode ? bodyMSemiBoldUtopiaStyles : bodyMRegularUtopiaStyles)};
    color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
    margin-bottom: 30px;
  }

  @media (${bpWidth.tablet}) {
    margin-bottom: 0;
  }

  @media (${bpWidth.mobile}) {
    ${bodySRegularUtopiaStyles};
    color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
  }
`;

const CtaCol = styled(Col)`
  > div {
    align-items: flex-start;
  }

  @media (${bpWidth.tablet}) {
    margin-top: 30px;
  }
`;

const TwoColumnsTitleTextCta = ({ blok }) => {
  const {
    primaryTitle,
    secondaryTitle,
    primaryText,
    secondaryText,
    primaryCta = [],
    secondaryCta = [],
    darkMode = false,
  } = blok;
  const [richTextPrimaryText, setRichTextPrimaryText] = useState(null);
  const [richTextSecondaryText, setRichTextSecondaryText] = useState(null);

  useEffect(() => {
    setRichTextPrimaryText(renderRichTextReact(primaryText));
  }, [primaryText]);

  useEffect(() => {
    setRichTextSecondaryText(renderRichTextReact(secondaryText));
  }, [secondaryText]);

  return (
    <Col {...storyblokEditable(blok)} xs={8} md={10} lg={12}>
      <InnerRow>
        <Col xs={6} sm={8} md={5}>
          {primaryTitle && (
            <Row>
              <Col xs={false} sm={8} md={10} lg={12}>
                <Title darkMode={darkMode}>{primaryTitle}</Title>
              </Col>
            </Row>
          )}
          {secondaryTitle && (
            <Row>
              <Col xs={8} sm={false}>
                <Title darkMode={darkMode}>{secondaryTitle}</Title>
              </Col>
            </Row>
          )}
          {primaryCta?.length > 0 && (
            <Row>
              <CtaCol xs={false} md={10} lg={12}>
                {primaryCta?.map((nestedBlok) => (
                  <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
                ))}
              </CtaCol>
            </Row>
          )}
        </Col>
        {richTextPrimaryText && (
          <Col xs={false} sm={8} md={5} lg={6}>
            <Text darkMode={darkMode}>{richTextPrimaryText}</Text>
          </Col>
        )}
        {richTextSecondaryText && (
          <Col xs={8} sm={false}>
            <Text darkMode={darkMode}>{richTextSecondaryText}</Text>
          </Col>
        )}
        {primaryCta?.length > 0 && (
          <CtaCol xs={false} sm={8} md={false}>
            {primaryCta.map((nestedBlok) => (
              <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
          </CtaCol>
        )}
        {secondaryCta?.length > 0 && (
          <CtaCol xs={8} sm={false}>
            {secondaryCta.map((nestedBlok) => (
              <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
          </CtaCol>
        )}
      </InnerRow>
    </Col>
  );
};

TwoColumnsTitleTextCta.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default TwoColumnsTitleTextCta;
