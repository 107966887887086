import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link, navigate } from 'gatsby';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';

// Styles
import styled from 'styled-components';
import { Col, Row } from 'react-styled-flexboxgrid';
import { bodySRegularBrownStyles } from '../../../styles/Web3.0/typography.js';
import { bpWidth, colors } from '../../../styles/Web3.0/variables.js';
import { useBusinessAppState } from '../../../context/business-app-state.context';
import { updateStoryblokMediaUrl } from '../../../utils/helper';

// Components
import Hamburger from '../../Web3.0/Header/BusinessHamburger';
import Menu from './BusinessMenu';
import { DYNAMIC_HEADER_INDICATOR } from '../../../constants';
import { useViewport } from '../../../context/viewport.context';

const HeaderContainerRow = styled(Row)`
  background: ${colors.navy400};
  display: flex;
  justify-content: space-between;
  min-height: 60px;
  position: fixed;
  top: 0;
  transition: transform 0.5s;
  width: 100%;
  z-index: 22;

  @media (${bpWidth.mobile}) {
    min-height: 50px;
  }
`;

const InnerHeaderRow = styled(Row)`
  align-items: center;
  margin: 15px 0;

  a {
    ${bodySRegularBrownStyles};
    color: ${colors.beige100};
    text-decoration: none;

    &:hover {
      font-style: italic;
    }
  }

  .button-col {
    @media (max-width: 1000px) and (min-width: 769px) {
      flex-basis: 25%;
      max-width: 25%;
    }
  }
`;

const LogoCol = styled(Col)`
  z-index: 3;

  @media (max-width: 1000px) and (min-width: 769px) {
    flex-basis: 25%;
    max-width: 25%;
  }
`;

const ImgSvg = styled.span`
  display: inline-block;
  mask: ${({ src }) => {
    return `url(${src})`;
  }};
  background: ${colors.beige100};
  width: 125px;
  height: ${`${(125 * 9) / 25}px`};

  @media (${bpWidth.desktopSm}) {
    width: 110px;
    height: ${`${(110 * 9) / 25}px`};
  }

  @media (${bpWidth.mobile}) {
    width: 80px;
    height: ${`${(80 * 9) / 25}px`};
  }
`;

const NavItemCol = styled(Col)`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;

  @media (${bpWidth.tablet}) {
    display: none;
  }

  @media (max-width: 1000px) and (min-width: 769px) {
    flex-basis: 50%;
    max-width: 50%;
  }
`;

const HamburgerCol = styled(Col)`
  display: none;
  z-index: 3;

  @media (${bpWidth.tablet}) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

const MenuWrapperCol = styled(Col)`
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
`;

const BusinessHeader = ({ blok }) => {
  const { links: primaryLinks = [], logo } = blok;
  const { hamburgerMenuIsOpen, toggleHamburgerMenu, settings, setHeaderHeight } = useBusinessAppState();
  const { viewWidth } = useViewport();

  const headerContainerRowRef = useRef(null);

  useEffect(() => {
    if (headerContainerRowRef.current) {
      setTimeout(() => setHeaderHeight(headerContainerRowRef.current.clientHeight));
    }
  }, [viewWidth, headerContainerRowRef.current]);

  const logoClicked = () => {};

  const onContactUsClick = () => {
    return navigate('/business/contact');
  };

  return (
    <div {...storyblokEditable(blok)}>
      <HeaderContainerRow ref={headerContainerRowRef} className={DYNAMIC_HEADER_INDICATOR}>
        <Col xsOffset={0.5} xs={7} smOffset={1} sm={6} md={8} lg={10}>
          <InnerHeaderRow>
            <LogoCol xs={2}>
              {logo?.filename && (
                <Link to="/business" onClick={hamburgerMenuIsOpen ? toggleHamburgerMenu : logoClicked}>
                  <ImgSvg src={updateStoryblokMediaUrl(logo.filename)} alt="logo" />
                </Link>
              )}
            </LogoCol>
            <NavItemCol xs={false} md={6}>
              {primaryLinks.map((nestedBlok) => (
                <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
              ))}
            </NavItemCol>
            <Col xs={false} lg={2} />
            <Col xs={false} md={2} className="button-col">
              <Link
                className="flex flex-row justify-center items-center p-2.5 gap-2.5
                w-[131px] w-min-[96px] h-[36px] bg-orange-400 rounded-[18px] mr-0 ml-auto
                !font-brown !text-xs !font-bold !leading-[20.4px] !tracking-[2.16px] text-center !text-beige-100"
                to="/business/contact"
              >
                CONTACT US
              </Link>
            </Col>
            <HamburgerCol xsOffset={5} xs={1} md={false}>
              <Hamburger isX={hamburgerMenuIsOpen} onClick={toggleHamburgerMenu} />
            </HamburgerCol>
          </InnerHeaderRow>

          {hamburgerMenuIsOpen ? (
            <Row>
              <MenuWrapperCol xs={8} md={false}>
                <Menu
                  includeContactUsCta={settings.showContactButton}
                  isOpen={hamburgerMenuIsOpen}
                  primaryLinks={primaryLinks}
                  onContactUsClick={onContactUsClick}
                  onHamburgerClick={toggleHamburgerMenu}
                />
              </MenuWrapperCol>
            </Row>
          ) : undefined}
        </Col>
      </HeaderContainerRow>
    </div>
  );
};

BusinessHeader.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default BusinessHeader;
