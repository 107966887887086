/**
 * @typedef {Object} AppStateSettingKeys
 * @property {boolean} navFontColorLight
 * @property {boolean} navLightMode
 * @property {boolean} showCartIcon
 * @property {boolean} showSearchIcon
 */

export const initialSettings = {
  navFontColorLight: false,
  navLightMode: false,
  showCartIcon: false,
  showSearchIcon: false,
};

/**
 * @type {AppStateSettingKeys}
 */
export const inputLabels = (() => {
  const target = {};
  for (const setting in initialSettings) {
    if (Object.hasOwn(initialSettings, setting)) {
      target[setting] = setting;
    }
  }
  return target;
})();

export function settingsReducer(state, action) {
  return {
    ...state,
    [action.name]: action.value === 'default' ? undefined : action.value,
  };
}
