import React from 'react';
import { any, string } from 'prop-types';

// Styles
import styled from 'styled-components';
import { Row, Col } from 'react-styled-flexboxgrid';

import { bpWidth, colors } from '../../../styles/Web3.0/variables.js';
import {
  bodySBoldBrownStyles,
  bodyXsRegularBrownStyles,
  bodyXxsRegularBrownStyles,
} from '../../../styles/Web3.0/typography.js';

// Components
import { formatPublishDate } from '../../../utils';

// Elements
const Header = styled.div`
  margin-bottom: 0;
  margin-top: 10px;
  width: 100%;
  overflow-wrap: break-word;
  ${bodySBoldBrownStyles};

  @media (${bpWidth.desktopSm}) {
    width: calc((80vw - 2vw * 2) / 3);
  }

  @media (${bpWidth.tablet}) {
    width: calc((100vw - 2vw * 2) / 3);
  }

  @media (${bpWidth.mobile}) {
    width: 100%;
  }
`;

const PostData = styled.p`
  ${bodyXsRegularBrownStyles};
  color: ${colors.gray300};

  @media (${bpWidth.desktopSm}) {
    ${bodyXxsRegularBrownStyles};
    color: ${colors.gray300};
  }
`;

// Props
const propTypes = {
  publishDate: string,
  publisher: string,
  header: any,
};

const defaultProps = {
  publishDate: '',
  publisher: '',
};

const PressPostHeader = (props) => {
  const { header, publishDate, publisher } = props;

  return (
    <Col xs={8} md={12}>
      <PostData>
        {formatPublishDate(publishDate)}&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;{publisher}
      </PostData>
      <Row>{header && <Header>{header}</Header>}</Row>
    </Col>
  );
};

PressPostHeader.propTypes = propTypes;
PressPostHeader.defaultProps = defaultProps;
export default PressPostHeader;
