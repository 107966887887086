import React, { useContext, useCallback } from 'react';
import { bool, number } from 'prop-types';
import debounce from 'lodash.debounce';

// styles
import styled from 'styled-components';
import { Col, Row } from 'react-styled-flexboxgrid';
import { bpWidth, colors } from '../../../styles/Web3.0/variables.js';
import {
  eyebrowSStyles,
  eyebrowXsStyles,
  bodyXsRegularBrownStyles,
  bodyXxsRegularBrownStyles,
  bodyMBoldBrownStyles,
  bodySBoldBrownStyles,
} from '../../../styles/Web3.0/typography.js';

// components
import { StoreContext } from '../../../context/store-context.jsx';
import { currencyFormatter } from '../../../utils/currencyFormatter';
import MinusIcon from '../../../img/minus.svg';
import PlusIcon from '../../../img/plus.svg';

// props
const propTypes = {
  accessoriesExpanded: bool,
  lastLineItemBottomPadding: number,
};

const defaultProps = {
  accessoriesExpanded: false,
  lastLineItemBottomPadding: 140,
};

// elements
const LineItemRow = styled(Row)`
  padding-top: ${(props) => (props.firstItem ? '50px' : '40px')};

  ${(props) =>
    props.lastItem
      ? `
      border-bottom: 0;
      padding-bottom: ${props.lastLineItemBottomPadding}px;

      @media(${bpWidth.mobile}) {
        padding-bottom: calc(${props.lastLineItemBottomPadding}px - 20px);
      }
    `
      : `
      border-bottom: 1px solid ${colors.gray100};
      padding-bottom: 40px;

      @media(${bpWidth.mobile}) {
        padding-bottom: 30px;
      }
    `};

  @media (${bpWidth.mobile}) {
    padding-top: 30px;
  }
`;

const LineItemCol = styled(Col)``;

const ProductDetailsRow = styled(Row)``;

const QuantityAndSubtotalRow = styled(Row)`
  padding-top: 30px;

  @media (${bpWidth.mobile}) {
    padding-top: 20px;
  }
`;

const ProductName = styled.h2`
  ${eyebrowSStyles};

  i {
    font-style: italic;
  }

  @media (${bpWidth.mobile}) {
    ${eyebrowXsStyles};

    i {
      font-style: italic;
    }
  }
`;

const VariantName = styled.p`
  padding-top: 10px;
  ${bodyXsRegularBrownStyles};

  @media (${bpWidth.mobile}) {
    ${bodyXxsRegularBrownStyles};
  }
`;

const ShippingText = styled.p`
  ${bodyXsRegularBrownStyles};
  padding-top: 30px;
  color: ${colors.gray300};

  @media (${bpWidth.mobile}) {
    ${bodyXxsRegularBrownStyles};
    padding-top: 20px;
    color: ${colors.gray300};
  }
`;

const IncrementAndDecrementRow = styled(Row)`
  max-width: 125px;
`;

const IncrementAndDecrementContainer = styled.div``;

const LineItemQuantityText = styled.p`
  ${bodyXsRegularBrownStyles};
  display: inline;
  padding: 0 5px 0;

  @media (${bpWidth.mobile}) {
    ${bodyXxsRegularBrownStyles};
  }
`;

const RemoveText = styled.p`
  ${bodyXsRegularBrownStyles};
  color: ${colors.gray300};
  cursor: pointer;
  display: inline-block;
  text-align: right;

  @media (${bpWidth.mobile}) {
    ${bodyXxsRegularBrownStyles};
    color: ${colors.gray300};
  }
`;

const LineItemSubtotal = styled.p`
  ${bodyMBoldBrownStyles};
  letter-spacing: -0.16px;
  text-align: right;

  @media (${bpWidth.mobile}) {
    ${bodySBoldBrownStyles};
  }
`;

const LineItemImage = styled.img`
  width: 100%;
  max-width: 100%;
`;

const LineItem = React.forwardRef((props) => {
  const { lineItemData, index, arrayLength, accessoriesExpanded, lastLineItemBottomPadding } = props;

  const { checkout, removeLineItem, updateLineItem } = useContext(StoreContext);

  const [quantity, setQuantity] = React.useState(lineItemData.quantity);

  const firstItem = index === 0;
  const lastItem = index === arrayLength - 1;
  const isgitaProduct = !!(lineItemData.title === 'gitaPLUS' || lineItemData.title === 'gita mini');

  const handleRemove = () => {
    removeLineItem(checkout.id, lineItemData.id);
  };

  const uli = debounce((value) => updateLineItem(checkout.id, lineItemData.id, value), 300);
  const debouncedUli = useCallback((value) => uli(value), [uli]);

  const handleQuantityChange = (value) => {
    if (value !== '' && Number(value) < 1) {
      return;
    }
    setQuantity(value);
    if (Number(value) >= 1) {
      debouncedUli(value);
    }
  };

  const doIncrement = () => {
    handleQuantityChange(Number(quantity || 0) + 1);
  };

  const doDecrement = () => {
    handleQuantityChange(Number(quantity || 0) - 1);
  };

  return (
    <LineItemRow
      firstItem={firstItem}
      lastItem={lastItem}
      accessoriesExpanded={accessoriesExpanded}
      lastLineItemBottomPadding={lastLineItemBottomPadding}
    >
      <LineItemCol xs={8} sm={8} md={10} lg={12}>
        <ProductDetailsRow>
          <Col xs={4} sm={4} md={6} lg={7}>
            <ProductName>
              {lineItemData.title === 'gita mini' ? (
                <span>
                  gita<i>mini</i>
                </span>
              ) : (
                <span>{lineItemData.title}</span>
              )}
            </ProductName>
            {lineItemData.variant.title !== 'Default Title' && <VariantName>{lineItemData.variant.title}</VariantName>}
            <ShippingText>
              {isgitaProduct ? 'Includes free shipping and charger.' : 'Includes free shipping.'}
            </ShippingText>
          </Col>
          <Col xs={4} sm={4} md={4} lg={5}>
            <Row>
              <Col xsOffset={3} xs={5} smOffset={3} sm={5} mdOffset={1} md={9} lgOffset={2} lg={10}>
                <LineItemImage src={lineItemData.variant.image.src} />
              </Col>
            </Row>
          </Col>
        </ProductDetailsRow>
        <QuantityAndSubtotalRow>
          <Col xs={4} sm={4} md={6} lg={7}>
            <IncrementAndDecrementRow between="xs">
              <IncrementAndDecrementContainer>
                <MinusIcon
                  style={{
                    cursor: 'pointer',
                    color: '#022137',
                    verticalAlign: 'middle',
                    width: '7px',
                    height: 'auto',
                    paddingTop: '5px',
                    paddingRight: '5px',
                    paddingBottom: '5px',
                  }}
                  onClick={doDecrement}
                />
                <LineItemQuantityText>{lineItemData.quantity}</LineItemQuantityText>
                <PlusIcon
                  style={{
                    cursor: 'pointer',
                    color: '#022137',
                    verticalAlign: 'middle',
                    width: '7px',
                    height: 'auto',
                    padding: '5px',
                  }}
                  onClick={doIncrement}
                />
              </IncrementAndDecrementContainer>
              <RemoveText onClick={handleRemove}>Remove</RemoveText>
            </IncrementAndDecrementRow>
          </Col>
          <Col xs={4} sm={4} md={4} lg={5}>
            <Row>
              <Col xsOffset={3} xs={5} smOffset={3} sm={5} mdOffset={1} md={9} lgOffset={2} lg={10}>
                <LineItemSubtotal>
                  {currencyFormatter(lineItemData.variant.priceV2.amount * lineItemData.quantity, {
                    currency: lineItemData.variant.priceV2.currencyCode,
                  })}
                </LineItemSubtotal>
              </Col>
            </Row>
          </Col>
        </QuantityAndSubtotalRow>
      </LineItemCol>
    </LineItemRow>
  );
});

LineItem.propTypes = propTypes;
LineItem.defaultProps = defaultProps;
export default LineItem;
