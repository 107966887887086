import React, { useState } from 'react';
import { object } from 'prop-types';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import styled from 'styled-components';
import { Col, Row } from 'react-styled-flexboxgrid';
import { bpWidth, colors } from '../../../styles/Web3.0/variables';
import { STATUS_INIT, STATUS_SUCCESS } from '../../../common/constants/formikStatus.constant';
import FormikWrapper from '../../Web3.0/Contact/FormikWrapper';
import {
  bodyMRegularUtopiaStyles,
  bodyMSemiBoldUtopiaStyles,
  bodySRegularUtopiaStyles,
  bodySSemiBoldUtopiaStyles,
  headerLFillStyles,
  headerLOutlineStyles,
  headerMStyles,
  headerXlFillStyles,
  headerXlOutlineStyles,
} from '../../../styles/Web3.0/typography';
import { renderRichTextReact } from '../../../utils/storyblokRichText';
import { btnLgTertiaryBlueStyles, btnSmTertiaryBlueStyles } from '../../../styles/Web3.0/buttons';

const ContactCol = styled(Col)`
  margin-top: 130px;
  margin-bottom: auto;
  padding-bottom: 50px;

  @media (${bpWidth.desktopSm}) {
    margin-top: 110px;
  }

  @media (${bpWidth.tablet}) {
    margin-top: 100px;
  }

  @media (${bpWidth.mobile}) {
    margin-top: 90px;
  }
`;

const FormHeadingCol = styled(Col)`
  h1 {
    margin-bottom: 20px;

    p {
      ${headerXlOutlineStyles};
      line-height: 1;
      -webkit-text-stroke: 1px ${colors.cerulean400};

      u {
        ${headerXlFillStyles};
        text-decoration: none;
        line-height: 1;
        color: ${colors.cerulean400};
        -webkit-text-fill-color: ${colors.cerulean400};
        -webkit-text-stroke: unset;
      }
    }
  }

  @media (${bpWidth.desktopSm}) {
    h1 {
      p {
        ${headerLOutlineStyles};
        line-height: 1;
        -webkit-text-stroke: 1px ${colors.cerulean400};

        u {
          ${headerLFillStyles};
          line-height: 1;
          color: ${colors.cerulean400};
        }
      }
    }
  }

  @media (${bpWidth.tablet}) {
  }

  @media (${bpWidth.mobile}) {
    h1 {
      p {
        ${headerMStyles};
        line-height: 1;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke: 0.75px ${colors.cerulean400};

        u {
          ${headerMStyles};
          line-height: 1;
          color: ${colors.cerulean400};
        }
      }
    }
  }
`;

const FormBodyCol = styled(Col)`
  p {
    ${bodyMRegularUtopiaStyles}

    a {
      font-style: italic;
      text-decoration: none;
      color: ${colors.cerulean400};
    }

    b {
      ${bodyMSemiBoldUtopiaStyles}
    }
  }

  @media (${bpWidth.desktopSm}) {
    p {
      ${bodySRegularUtopiaStyles}

      b {
        ${bodySSemiBoldUtopiaStyles}
      }
    }
  }
`;

const SubmittedHeadingCol = styled(Col)`
  h1 {
    margin-bottom: 30px;

    p {
      ${headerXlFillStyles};
      line-height: 1;
      color: ${colors.cerulean400};

      u {
        ${headerXlOutlineStyles};
        text-decoration: none;
        -webkit-text-stroke: 1px ${colors.cerulean400};
        line-height: 1;
      }
    }
  }

  @media (${bpWidth.desktopSm}) {
    h1 {
      p {
        ${headerLFillStyles};
        line-height: 1;
        color: ${colors.cerulean400};

        u {
          ${headerLOutlineStyles};
          text-decoration: none;
          -webkit-text-stroke: 1px ${colors.cerulean400};
          line-height: 1;
        }
      }
    }
  }

  @media (${bpWidth.tablet}) {
  }

  @media (${bpWidth.mobile}) {
    h1 {
      p {
        ${headerMStyles};
        line-height: 1;
        color: ${colors.cerulean400};

        u {
          ${headerMStyles};
          text-decoration: none;
          line-height: 1;
          -webkit-text-fill-color: transparent;
          -webkit-text-stroke: 0.75px ${colors.cerulean400};
        }
      }
    }
  }
`;

const SubmittedBodyCol = styled(Col)`
  p {
    ${bodyMRegularUtopiaStyles};

    a {
      text-decoration: none;
      color: ${colors.cerulean400};
    }

    b {
      ${bodyMSemiBoldUtopiaStyles};
    }
  }

  @media (${bpWidth.desktopSm}) {
    p {
      ${bodySRegularUtopiaStyles};

      b {
        ${bodySSemiBoldUtopiaStyles};
      }
    }
  }
`;

const CtaCol = styled(Col)`
  padding-top: 40px;

  button {
    ${btnLgTertiaryBlueStyles};
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    color: ${colors.cerulean400};

    svg {
      margin-left: 15px;
      width: 7px;
    }

    &:hover {
      path#Side {
        stroke: ${colors.cerulean700};
      }
    }
  }

  @media (${bpWidth.desktopSm}) {
    padding-top: 30px;

    button {
      ${btnSmTertiaryBlueStyles};
      color: ${colors.cerulean400};

      svg {
        margin-left: 10px;
        width: 5px;
      }
    }
  }
`;

const Contact = ({ blok }) => {
  const [formStatusType, setFormStatusType] = useState(STATUS_INIT);

  // Trigger success copy if form submits successfully
  const formSucceededCheck = React.useCallback(
    (formStatus) => {
      setFormStatusType(formStatus);
      return formStatusType;
    },
    [setFormStatusType]
  );

  // Trigger resetting form if user returns to form from success copy
  const resetContactForm = React.useCallback(() => {
    setFormStatusType(STATUS_INIT);
    return formStatusType;
  }, [setFormStatusType]);

  const ctaClicked = () => {
    resetContactForm();
  };

  return (
    <ContactCol {...storyblokEditable(blok)} xs={8} md={10} lg={12}>
      {formStatusType !== STATUS_SUCCESS && (
        <>
          {/* Heading */}
          <Row>
            <FormHeadingCol xsOffset={1} xs={6} mdOffset={3} md={4} lg={5}>
              <h1>{renderRichTextReact(blok.formHeading)}</h1>
            </FormHeadingCol>
          </Row>

          {/* Body */}
          <Row>
            <FormBodyCol xsOffset={1} xs={6} sm={5} mdOffset={3} md={4} lg={3}>
              {renderRichTextReact(blok.formBody)}
            </FormBodyCol>
          </Row>

          {/* Contact form */}
          {!blok.hideForm && (
            <Row>
              <Col xsOffset={1} xs={6} sm={4} mdOffset={3} md={3}>
                <FormikWrapper formStatus={formSucceededCheck} />
              </Col>
            </Row>
          )}

          {/* Address */}
          <Row>
            <FormBodyCol xsOffset={1} xs={6} sm={4} mdOffset={3} md={3}>
              {renderRichTextReact(blok.formAddress)}
            </FormBodyCol>
          </Row>
        </>
      )}

      {/* Form successfully submitted view */}
      {formStatusType === STATUS_SUCCESS && (
        <>
          {/* Heading */}
          <Row>
            <SubmittedHeadingCol xsOffset={1} xs={6} mdOffset={2} md={5} lgOffset={3}>
              <h1>{renderRichTextReact(blok.thankYouHeading)}</h1>
            </SubmittedHeadingCol>
          </Row>

          {/* Body */}
          <Row>
            <SubmittedBodyCol xsOffset={1} xs={6} sm={4} mdOffset={2} md={3} lgOffset={3}>
              {renderRichTextReact(blok.thankYouBody)}
            </SubmittedBodyCol>
          </Row>

          {/* CTA */}
          <Row>
            <CtaCol xsOffset={1} xs={6} sm={4} mdOffset={2} md={3} lgOffset={3}>
              {blok.backButton?.map((nestedBlok) => (
                <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} onClick={ctaClicked} />
              ))}
            </CtaCol>
          </Row>
        </>
      )}
    </ContactCol>
  );
};

Contact.propTypes = {
  blok: object.isRequired,
};

export default Contact;
