/* eslint-disable no-script-url */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { storyblokEditable } from 'gatsby-source-storyblok';
import styled from 'styled-components';
import {
  btnLgPrimaryMediumBlueStyles,
  btnLgPrimaryMediumDisabledBlueStyles,
  btnLgSecondaryMediumBlueStyles,
  btnLgSecondaryMediumDisabledBlueStyles,
  btnLgTertiaryBlueStyles,
  btnLgTertiaryDisabledBlueStyles,
  btnSmPrimaryMediumBlueStyles,
  btnSmPrimaryMediumDisabledOrangeStyles,
  btnSmSecondaryMediumBlueStyles,
  btnSmSecondaryMediumDisabledBlueStyles,
  btnSmTertiaryBlueStyles,
  btnSmTertiaryDisabledBlueStyles,
} from '../../../styles/Web3.0/buttons';
import { variants } from '../../../constants';
import CTAArrow from '../../../icons/Web3.0/arrow-LCerulean.svg';
import { bpWidth, colors } from '../../../styles/Web3.0/variables';

const ButtonWrapper = styled.div`
  display: inline-flex;
  margin-top: ${({ marginTop }) => (marginTop?.toString()?.length || 0 ? marginTop : undefined)};
`;

ButtonWrapper.propTypes = {
  marginTop: PropTypes.string,
};

const BaseButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
`;

const PrimaryButton = styled(BaseButton)`
  ${btnLgPrimaryMediumBlueStyles};
  font-size: 14px;
  line-height: 1;
  width: auto;
  height: auto;
  padding: 10px 20px;

  &:disabled {
    ${btnLgPrimaryMediumDisabledBlueStyles};
    font-size: 14px;
    line-height: 1;
    width: auto;
    height: auto;
    padding: 10px 20px;
  }

  @media (${bpWidth.mobile}) {
    ${btnSmPrimaryMediumBlueStyles};
    font-size: 10px;
    line-height: 1;
    width: auto;
    height: auto;
    padding: 10px 20px;

    &:disabled {
      ${btnSmPrimaryMediumDisabledOrangeStyles};
      font-size: 10px;
      line-height: 1;
      width: auto;
      height: auto;
      padding: 10px 20px;
    }
  }
`;

const SecondaryButton = styled(BaseButton)`
  ${btnLgSecondaryMediumBlueStyles};
  font-size: 14px;
  line-height: 1;
  width: auto;
  height: auto;
  padding: 10px 20px;

  &:disabled {
    ${btnLgSecondaryMediumDisabledBlueStyles};
    font-size: 14px;
    line-height: 1;
    width: auto;
    height: auto;
    padding: 10px 20px;
  }

  @media (${bpWidth.tablet}) {
    ${btnSmSecondaryMediumBlueStyles};
    font-size: 10px;
    line-height: 1;
    width: auto;
    height: auto;
    padding: 10px 20px;

    &:disabled {
      ${btnSmSecondaryMediumDisabledBlueStyles};
      font-size: 10px;
      line-height: 1;
      width: auto;
      height: auto;
      padding: 10px 20px;
    }
  }
`;

const TertiaryButton = styled(BaseButton)`
  ${btnLgTertiaryBlueStyles};
  font-size: 14px;
  line-height: 1;
  width: auto;
  height: auto;
  padding: 0;

  &:disabled {
    ${btnLgTertiaryDisabledBlueStyles};
    font-size: 14px;
    line-height: 1;
    width: auto;
    height: auto;
    padding: 0;
  }

  .icon {
    align-self: normal;

    svg {
      margin-left: 15px;
      width: 5px;
    }
  }

  &:hover {
    svg path#Side {
      stroke: ${colors.cerulean700};
    }
  }

  @media (${bpWidth.tablet}) {
    ${btnSmTertiaryBlueStyles};
    font-size: 10px;
    line-height: 1;
    width: auto;
    height: auto;
    padding: 0;

    &:disabled {
      ${btnSmTertiaryDisabledBlueStyles};
      font-size: 10px;
      line-height: 1;
      width: auto;
      height: auto;
      padding: 0;
    }

    .icon {
      svg {
        margin-left: 10px;
        width: 5px;
      }
    }
  }
`;

const Button = ({ blok }) => {
  let href = blok.link?.cached_url || blok.link?.url || '';
  if (blok.link?.anchor) {
    href += `#${blok.link.anchor}`;
  }
  const target = blok.link?.target;
  const rel = target === '_blank' ? 'noreferrer' : undefined;

  const [customActionHandler, setCustomActionHandler] = useState(null);

  const watchTheVideo = (selectors) => {
    const videoContainerEl = document.querySelector(selectors);
    if (videoContainerEl) {
      const videoEl = videoContainerEl.querySelector('video');
      if (videoEl) {
        if (videoEl.webkitRequestFullscreen) {
          videoEl.webkitRequestFullscreen();
        } else {
          videoEl.requestFullscreen().catch(() => null);
        }

        videoEl.play().catch(() => null);
      }
    }
  };

  useEffect(() => {
    const link = blok.link?.cached_url || blok.link?.url || '';
    if (link === 'javascript:watchTheBusinessCaseStudy') {
      setCustomActionHandler(() =>
        watchTheVideo.bind(this, '.video-with-title-and-cta-component--container.business-case-study')
      );
    } else if (link === 'javascript:watchThePFFFollowTestimonial') {
      setCustomActionHandler(() =>
        watchTheVideo.bind(this, '.video-with-title-and-cta-component--container.business-pff-follow-testimonial')
      );
    } else {
      setCustomActionHandler(null);
    }
  }, [blok.link]);

  const renderButton = useCallback(() => {
    switch (blok.variant) {
      case variants.secondary:
        if (customActionHandler) {
          return (
            <SecondaryButton onClick={customActionHandler} className="button-component--secondary">
              {blok.text}
            </SecondaryButton>
          );
        }

        return (
          <SecondaryButton href={href} target={target} rel={rel} className="button-component--secondary">
            {blok.text}
          </SecondaryButton>
        );
      case variants.tertiary:
        if (customActionHandler) {
          return (
            <TertiaryButton onClick={customActionHandler} className="button-component--tertiary">
              {blok.text}
              <div className="icon">
                <CTAArrow />
              </div>
            </TertiaryButton>
          );
        }

        return (
          <TertiaryButton href={href} target={target} rel={rel} className="button-component--tertiary">
            {blok.text}
            <div className="icon">
              <CTAArrow />
            </div>
          </TertiaryButton>
        );
      default:
        if (customActionHandler) {
          return (
            <PrimaryButton
              onClick={customActionHandler}
              style={{
                backgroundColor: blok.customBackgroundColor ? blok.customBackgroundColor : undefined,
                borderColor: blok.customBackgroundColor ? blok.customBackgroundColor : undefined,
                color: blok.customTextColor ? blok.customTextColor : undefined,
              }}
              className="button-component--primary"
            >
              {blok.text}
            </PrimaryButton>
          );
        }

        return (
          <PrimaryButton
            href={href}
            target={target}
            rel={rel}
            style={{
              backgroundColor: blok.customBackgroundColor ? blok.customBackgroundColor : undefined,
              borderColor: blok.customBackgroundColor ? blok.customBackgroundColor : undefined,
              color: blok.customTextColor ? blok.customTextColor : undefined,
            }}
            className="button-component--primary"
          >
            {blok.text}
          </PrimaryButton>
        );
    }
  }, [blok, customActionHandler]);

  return (
    <ButtonWrapper {...storyblokEditable(blok)} marginTop={blok.marginTop} className="button-component--container">
      {renderButton()}
    </ButtonWrapper>
  );
};

Button.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default Button;
