import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import styled from 'styled-components';
import { Col, Row } from 'react-styled-flexboxgrid';
import _ from 'lodash';
import { Link, withPrefix } from 'gatsby';
import { fetchStoryblokStories, useIsMounted, useIsomorphicLayoutEffect } from '../../../hooks';
import { bpWidth, colors } from '../../../styles/Web3.0/variables';
import {
  bodyLItalicUtopiaStyles,
  bodyLRegularUtopiaStyles,
  bodyMItalicUtopiaStyles,
  bodyMRegularUtopiaStyles,
  bodySBoldBrownStyles,
  bodyXlBoldBrownStyles,
  bodyXlItalicUtopiaStyles,
  bodyXlRegularUtopiaStyles,
  bodyXxlBoldBrownStyles,
  bodyXxsBoldBrownStyles,
  bodyXxxlBoldBrownStyles,
} from '../../../styles/Web3.0/typography';
import { standardTags } from '../../../styles/Web3.0/categoryTags';
import { calculateReadTime } from '../../../utils/calculateReadTime';
import { renderPlainText, renderRichTextReact } from '../../../utils/storyblokRichText';
import { defaultStoryblokStoriesParams } from '../../../common/constants/storyblok.constant';

const FeaturedPostRow = styled(Row)``;

const FeaturedPostCol = styled(Col)`
  height: 600px;
  position: relative;

  @media (${bpWidth.desktopSm}) {
    height: 540px;
  }

  @media (${bpWidth.tablet}) {
    height: 580px;
  }

  @media (${bpWidth.mobile}) {
    height: 370px;
  }
`;

const CopyRow = styled(Row)`
  background-image: linear-gradient(180deg, transparent, ${colors.navy700});
  bottom: 0;
  cursor: pointer;
  left: 0;
  margin-top: 70px;
  position: absolute;
  width: 100%;
  height: 300px;
  transition: height 0.3s linear;
  z-index: 2;
  display: flex;
  align-items: flex-end;

  a {
    display: flex;
    display: -webkit-flex;
    align-items: flex-end;
    -webkit-align-items: flex-end;
    height: 100%;
    text-decoration: unset;
    width: 100%;
  }

  .showOnHover {
    p {
      display: none;
    }

    height: 0;
    opacity: 0;
  }

  &:hover {
    display: flex;
    align-items: flex-end;
    height: 100%;

    h2 {
      margin-bottom: 15px;
    }

    .showOnHover {
      p {
        display: unset;
      }
      height: fit-content;
      opacity: 1;
      transition: opacity 0.3s ease-out;
    }
  }

  @media (${bpWidth.desktopSm}) {
    &:hover h2 {
      margin-bottom: 10px;
    }
  }

  @media (${bpWidth.tablet}) {
    height: fit-content;
    min-height: 20vh;

    &:hover {
      height: fit-content;

      h2 {
        margin-bottom: 0;
      }

      .showOnHover {
        p {
          display: none;
        }

        height: 0;
        opacity: 0;
      }
    }
  }
  @media (${bpWidth.mobile}) {
    padding-top: 70px;
  }
`;

const CopyCol = styled(Col)`
  display: flex;
  flex-direction: column;
`;

const InnerCopyCol = styled(Col)`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  span {
    ${bodySBoldBrownStyles};
    margin-bottom: 15px;
  }

  h1 {
    ${bodyXxxlBoldBrownStyles};
    margin-bottom: 15px;
  }

  h2 {
    ${bodyXlRegularUtopiaStyles};
  }

  div {
    ${bodyXlItalicUtopiaStyles};
  }

  span,
  h1,
  h2,
  div {
    color: ${colors.beige100};
  }

  @media (${bpWidth.desktopSm}) {
    margin-bottom: 20px;

    span {
      ${bodyXxsBoldBrownStyles};
      margin-bottom: 10px;
    }

    h1 {
      ${bodyXxlBoldBrownStyles};
      margin-bottom: 10px;
    }

    h2 {
      ${bodyLRegularUtopiaStyles};
    }

    div {
      ${bodyLItalicUtopiaStyles};
    }

    span,
    h1,
    h2,
    div {
      color: ${colors.beige100};
    }
  }

  @media (${bpWidth.mobile}) {
    margin-bottom: 10px;

    span {
      margin-bottom: 5px;
    }

    h1 {
      ${bodyXlBoldBrownStyles};
      margin-bottom: 5px;
    }

    h2 {
      ${bodyMRegularUtopiaStyles};
    }

    div {
      ${bodyMItalicUtopiaStyles};
    }

    h1,
    h2,
    div {
      color: ${colors.beige100};
    }
  }
`;

const Tag = styled.div`
  ${standardTags};
  text-transform: capitalize;
`;

const MediaRow = styled(Row)`
  height: 100%;

  .media-col {
    height: 100%;

    .media-wrapper {
      height: 100%;

      .gatsby-image-wrapper.gatsby-image-wrapper-constrained {
        height: 100%;
        width: 100%;
      }
    }
  }
`;

const FeaturedPost = ({ blok }) => {
  const { targetPost, hideCategory } = blok;
  const [story, setStory] = useState(null);
  const [featuredPost, setFeaturedPost] = useState(null);
  const [featuredPostSubHeading, setFeaturedPostSubHeading] = useState(null);
  const [featuredPostSummary, setFeaturedPostSummary] = useState(null);
  const isMounted = useIsMounted();

  useIsomorphicLayoutEffect(() => {
    if (typeof targetPost === 'string') {
      fetchStoryblokStories({
        ...defaultStoryblokStoriesParams,
        by_uuids: targetPost,
        is_startpage: false,
      })
        .then((resp) => {
          if (isMounted() && resp?.data?.stories?.length > 0) {
            setStory(resp.data.stories[0]);
          }
        })
        .catch(console.error);
    } else {
      setStory(targetPost);
    }
  }, [targetPost, isMounted]);

  useIsomorphicLayoutEffect(() => {
    const foundPost = story?.content?.body?.find((item) => item.component === 'post');
    if (foundPost) {
      setFeaturedPost(foundPost);
      setFeaturedPostSubHeading(renderRichTextReact(foundPost.subHeading));
      setFeaturedPostSummary(renderRichTextReact(foundPost.summary));
    }
  }, [story]);

  return (
    <Col {...storyblokEditable(blok)} xs={8} md={10} lg={12}>
      {featuredPost && (
        <FeaturedPostRow>
          <FeaturedPostCol xsOffset={0} xs={8} md={10} lg={12} id="FeaturedPostCol">
            {featuredPost.featuredImage?.map((nestedBlok) => (
              <MediaRow key={nestedBlok._uid}>
                <StoryblokComponent blok={nestedBlok} />
              </MediaRow>
            ))}
            <CopyRow>
              <Link to={withPrefix(story.path || story.full_slug)}>
                <CopyCol xsOffset={0} xs={8} md={10} lg={12}>
                  <Row>
                    <InnerCopyCol xsOffset={1} lgOffset={2} xs={6} sm={5} md={4}>
                      <span>{calculateReadTime({ raw: renderPlainText(featuredPost.article) })}</span>
                      <h1>{renderRichTextReact(featuredPost.heading)}</h1>
                      {featuredPostSubHeading && <h2>{featuredPostSubHeading}</h2>}
                      {featuredPostSummary && <div className="showOnHover">{featuredPostSummary}</div>}
                    </InnerCopyCol>

                    {!hideCategory && featuredPost.category && (
                      <Col xsOffset={1} lgOffset={2} xs={7} md={9} lg={10}>
                        <Tag id="categoryTag" className={_.camelCase(featuredPost.category)}>
                          {featuredPost.category}
                        </Tag>
                      </Col>
                    )}
                  </Row>
                </CopyCol>
              </Link>
            </CopyRow>
          </FeaturedPostCol>
        </FeaturedPostRow>
      )}
    </Col>
  );
};

FeaturedPost.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default FeaturedPost;
