import React, { useContext } from 'react';
import { bool } from 'prop-types';

// styles
import styled, { css } from 'styled-components';
import { bpWidth, colors } from '../../../styles/Web3.0/variables';
import { bodyXxsBoldBrownStyles, bodyXxxsRegularBrownStyles } from '../../../styles/Web3.0/typography';

// components
import { StoreContext } from '../../../context/store-context';
import EmptyCartIcon from '../../../img/PFF-brand-icons-thin-mktg-cart-empty.svg';
import FullCartIcon from '../../../img/PFF-brand-icons-thin-mktg-cart-full.svg';

const EmptyCartContainer = styled.div`
  * {
    color: ${({ darkMode }) => (darkMode ? colors.beige100 : colors.navy600)} !important;
    fill: ${({ darkMode }) => (darkMode ? colors.beige100 : colors.navy600)} !important;
  }
`;

const cartIconStyled = css`
  cursor: pointer;
  width: 24px;
  max-width: 100%;

  .st0 {
    display: inherit;
  }

  @media (${bpWidth.mobile}) {
    width: 18px;
  }
`;

const EmptyCartIconStyled = styled(EmptyCartIcon)`
  ${cartIconStyled};
`;

const FullCartContainer = styled.div`
  * {
    color: ${({ darkMode }) => (darkMode ? colors.beige100 : colors.navy600)} !important;
    fill: ${({ darkMode }) => (darkMode ? colors.beige100 : colors.navy600)} !important;
  }

  position: relative;
`;

const FullCartIconStyled = styled(FullCartIcon)`
  ${cartIconStyled};
`;

const LineItemNumber = styled.div`
  ${bodyXxsBoldBrownStyles};

  line-height: 1;
  position: absolute;
  top: 1px;
  left: 16px;
  pointer-events: none;

  @media (${bpWidth.mobile}) {
    ${bodyXxxsRegularBrownStyles};
    line-height: 1;
    left: 12px;
  }
`;

const CartIcon = (props) => {
  const { darkMode = false } = props;
  const { checkout, onToggle: handleCartToggle } = useContext(StoreContext);
  const emptyCart = checkout.lineItems.length === 0;
  // this maps each line item's quantity into a new array, which then gets reduced.
  // it returns the sum of line item quantities.
  // since this component will rerender every time StoreContext updates, this number should stay accurate
  const cartItemsQuantity = emptyCart
    ? 0
    : checkout.lineItems
        .map((lineItem) => lineItem.quantity)
        .reduce((accumulator, currentValue) => {
          return accumulator + currentValue;
        });

  return emptyCart ? (
    <EmptyCartContainer darkMode={darkMode}>
      <EmptyCartIconStyled alt="empty cart icon" onClick={handleCartToggle} />
    </EmptyCartContainer>
  ) : (
    <FullCartContainer darkMode={darkMode}>
      <FullCartIconStyled alt="full cart icon" onClick={handleCartToggle} />
      <LineItemNumber>{cartItemsQuantity}</LineItemNumber>
    </FullCartContainer>
  );
};

CartIcon.propTypes = {
  darkMode: bool,
};
CartIcon.defaultProps = {
  darkMode: false,
};
export default CartIcon;
