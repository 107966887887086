export const useSsr = () => {
  const isDOM = typeof window !== 'undefined' && !!window.document && !!window.document.documentElement;

  return {
    isBrowser: isDOM,
    isServer: !isDOM,
  };
};

// Use checkSsr to avoid eslint call use hook
export { useSsr as checkSsr };
