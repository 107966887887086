import React from 'react';
import { Script } from 'gatsby';
import TrustArcCookieBanner from './src/components/Web3.0/TrustArcCookieBanner';
import { StoreProvider } from './src/context/store-context';
import ViewportProvider from './src/context/viewport.context';
import StoryblokProvider from './src/context/storyblok.context';
import RootStateProvider from './src/context/root-state.context';

export const wrapPageElement = ({ element }) => {
  const hasEnabledTrustArc = !!process.env.GATSBY_TRUST_ARC_CM_ID;
  const trustArcUrl = `https://consent.trustarc.com/v2/notice/${process.env.GATSBY_TRUST_ARC_CM_ID}?c=teconsent`;

  return (
    <>
      <StoryblokProvider>
        <ViewportProvider>
          <RootStateProvider>{element}</RootStateProvider>
        </ViewportProvider>
      </StoryblokProvider>
      <Script
        id="storyblokBridge"
        type="text/javascript"
        referrerPolicy="strict-origin-when-cross-origin"
        src="//app.storyblok.com/f/storyblok-v2-latest.js"
      />
      {hasEnabledTrustArc && (
        <>
          <Script
            id="trustArc"
            type="text/javascript"
            referrerpolicy="strict-origin-when-cross-origin"
            src={trustArcUrl}
          />
          <TrustArcCookieBanner />
        </>
      )}
      <Script id={'tiktok'}>
        {`!function (w, d, t) {
          w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
          ttq.load('CM0QOCJC77U43IB7IP80');
          ttq.page();
        }(window, document, 'ttq');`}
      </Script>
      <Script id={'reddit'}>
        {`!function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);rdt('init','t2_axeupa6x', {"optOut":false,"useDecimalCurrencyValues":true,"aaid":"<AAID-HERE>","email":"<EMAIL-HERE>","externalId":"<EXTERNAL-ID-HERE>","idfa":"<IDFA-HERE>"});rdt('track', 'PageVisit');`}
      </Script>
      <Script id={'meta'}>
        {`!function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '931507771772699');
        fbq('track', 'PageView');`}
      </Script>
    </>
  );
};

export const wrapRootElement = ({ element }) => <StoreProvider>{element}</StoreProvider>;
