import React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import { bool, number, object } from 'prop-types';
import styled from 'styled-components';
import { bpWidth, colors } from '../../../styles/Web3.0/variables';
import {
  bodyLRegularUtopiaStyles,
  bodyMBoldBrownStyles,
  bodyMRegularUtopiaStyles,
  bodySBoldBrownStyles,
  eyebrowMStyles,
  eyebrowSStyles,
} from '../../../styles/Web3.0/typography';

const PolicyItemCol = styled(Col)`
  .section {
    margin-bottom: 80px;
  }

  h6 {
    ${bodyLRegularUtopiaStyles};

    @media (${bpWidth.desktopSm}) {
      ${bodyMRegularUtopiaStyles};
    }
  }

  .policy-section {
    h2 {
      ${eyebrowMStyles};
      margin-bottom: 20px;

      @media (${bpWidth.tablet}) {
        ${eyebrowSStyles};
      }
    }

    h3 {
      ${bodyMBoldBrownStyles};

      @media (${bpWidth.tablet}) {
        ${bodySBoldBrownStyles};
      }

      margin-bottom: 20px;
    }

    h4 {
      font-style: italic;
      font-weight: 600;
    }

    h5 {
      font-weight: 600;
    }

    p {
      margin-bottom: 40px;

      & + ul {
        margin-top: -30px;
      }
    }

    ul {
      margin: 0;

      p {
        margin-bottom: 10px;
      }
    }
  }

  a {
    text-decoration: none;
    color: ${colors.cerulean400};
  }
`;

const PolicyItem = ({ blok, idx = 0, hideOrderedListNumber = false }) => {
  const { _uid, heading, content = [] } = blok;

  return (
    <PolicyItemCol {...storyblokEditable(blok)} xs={8} md={10} lg={12}>
      <div className="section policy-section" id={`${_uid}`}>
        <h2>
          {hideOrderedListNumber ? '' : idx + 1 + '. '}
          {heading}
        </h2>
        <div>
          {content?.map((nestedBlok) => (
            <Row key={nestedBlok._uid}>
              <StoryblokComponent blok={nestedBlok} />
            </Row>
          ))}
        </div>
      </div>
    </PolicyItemCol>
  );
};

PolicyItem.propTypes = {
  blok: object.isRequired,
  idx: number,
  hideOrderedListNumber: bool,
};

export default PolicyItem;
