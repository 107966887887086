import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import { Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import {
  bodyLItalicUtopiaStyles,
  bodyXxlItalicUtopiaStyles,
  bodyXxxlItalicUtopiaStyles,
  headerMStyles,
  headerXlOutlineStyles,
  headerXxlOutlineStyles,
} from '../../../styles/Web3.0/typography';
import { bpWidth, colors } from '../../../styles/Web3.0/variables';
import { btnLgTertiaryBlueStyles, btnSmTertiaryBlueStyles } from '../../../styles/Web3.0/buttons';
import { renderRichTextReact } from '../../../utils/storyblokRichText';

const FaqCol = styled(Col)`
  margin-top: 130px;
  margin-bottom: auto;
  padding-bottom: 50px;

  @media (${bpWidth.desktopSm}) {
    margin-top: 110px;
  }

  @media (${bpWidth.tablet}) {
    margin-top: 100px;
  }

  @media (${bpWidth.mobile}) {
    margin-top: 90px;
  }
`;

const HeadingRow = styled(Row)`
  margin-bottom: 90px;

  h1.title,
  h1.subtitle {
    p {
      white-space: nowrap;
      ${bodyXxxlItalicUtopiaStyles};
      line-height: 1;
    }

    u {
      font-style: normal;
      text-decoration: none;
      ${headerXxlOutlineStyles};
      line-height: 1;
    }
  }

  h1.title {
    p {
      padding-left: 3rem;
    }

    u {
      -webkit-text-stroke: 0.75px ${colors.cerulean400};
    }
  }

  h1.subtitle {
    margin-bottom: 50px;

    u {
      -webkit-text-stroke: 0.75px ${colors.orange400};
    }
  }

  @media (${bpWidth.desktopSm}) {
    margin-top: 90px;
    margin-bottom: 60px;

    h1.title,
    h1.subtitle {
      p {
        ${bodyXxlItalicUtopiaStyles};
        line-height: 1;
      }

      u {
        font-style: normal;
        text-decoration: none;
        ${headerXlOutlineStyles};
        line-height: 1;
      }
    }

    h1.title {
      p {
        padding-left: 3rem;
      }

      u {
        -webkit-text-stroke: 0.75px ${colors.cerulean400};
      }
    }

    h1.subtitle {
      u {
        -webkit-text-stroke: 0.75px ${colors.orange400};
      }
    }
  }

  @media (${bpWidth.tablet}) {
    h1.title,
    h1.subtitle {
      p {
        padding-left: 0;
        white-space: unset;
      }
    }

    h1.title {
      p {
        text-align: right;
      }
    }

    h1.subtitle {
      padding-bottom: 15px;

      p {
        text-align: left;
      }
    }
  }

  @media (${bpWidth.mobile}) {
    h1.title,
    h1.subtitle {
      p {
        ${bodyLItalicUtopiaStyles};
        line-height: 1;

        u {
          font-style: normal;
          text-decoration: none;

          ${headerMStyles};
          line-height: 1;
          -webkit-text-fill-color: transparent;
        }
      }
    }

    h1.title {
      u {
        -webkit-text-stroke: 0.75px ${colors.cerulean400};
      }
    }

    h1.subtitle {
      margin-bottom: 30px;

      u {
        -webkit-text-stroke: 0.75px ${colors.orange600};
      }
    }
  }
`;

const CTACol = styled(Col)`
  margin-top: 50px;

  a {
    ${btnLgTertiaryBlueStyles};
    display: flex;
    align-items: center;
    text-decoration: none;

    .icon {
      align-self: normal;

      svg {
        margin-left: 15px;
        width: 5px;
      }
    }

    &:hover {
      svg path#Side {
        stroke: ${colors.cerulean700};
      }
    }
  }

  @media (${bpWidth.desktopSm}) {
    a {
      ${btnSmTertiaryBlueStyles};
      max-width: 100%;

      .icon {
        svg {
          margin-left: 10px;
          width: 5px;
        }
      }
    }
  }

  @media (${bpWidth.mobile}) {
    margin-top: 40px;
  }
`;

const FAQ = ({ blok }) => {
  const { title, subtitle, questions } = blok;
  const [selectedId, setSelectedId] = useState(null);

  return (
    <FaqCol {...storyblokEditable(blok)} xs={8} md={10} lg={12}>
      {/* Heading */}
      <HeadingRow>
        {title && (
          <Col xsOffset={1} xs={6} mdOffset={3} md={4} lgOffset={4}>
            <h1 className="title">{renderRichTextReact(title)}</h1>
          </Col>
        )}
        {subtitle && (
          <Col xsOffset={1} xs={6} mdOffset={3} md={4} lgOffset={4}>
            <h1 className="subtitle">{renderRichTextReact(subtitle)}</h1>
          </Col>
        )}
      </HeadingRow>

      {/* FAQs */}
      <Row>
        {questions?.map((question) => {
          return (
            <StoryblokComponent
              blok={question}
              isOpen={question._uid === selectedId}
              onToggleItem={() => setSelectedId(question._uid === selectedId ? null : question._uid)}
              key={question._uid}
            />
          );
        })}
      </Row>

      {/* Learn more CTA */}
      <Row>
        <CTACol xsOffset={1} mdOffset={2} lgOffset={3} xs={6} md={4}>
          {blok.cta?.map((nestedBlok) => (
            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
          ))}
        </CTACol>
      </Row>
    </FaqCol>
  );
};

FAQ.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default FAQ;
