export const CHECK_EVERY_MILLISECONDS = 1000;
export const MAXIMUM_TIMES_CHECKED = 100;
export const TRUST_ARC_COOKIE_NAME = 'notice_preferences';
export const PRIVACY_MANAGER_ACTION = {
  getConsent: 'getConsent',
  submitPreferences: 'submit_preferences',
};
export const PRIVACY_MANAGER_API_NAME = {
  getConsent: 'getConsent',
  getConsentDecision: 'getConsentDecision',
  getGDPRConsentDecision: 'getGDPRConsentDecision',
  getConsentCategories: 'getConsentCategories',
};
export const SOURCE_DECISION = {
  implied: 'implied',
  asserted: 'asserted',
};
