import React from 'react';
import PropTypes from 'prop-types';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import { Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import { headerLFillStyles, headerMStyles, headerSStyles } from '../../../styles/Web3.0/typography';
import { bpWidth } from '../../../styles/Web3.0/variables';

const TestimonialsHeadingRow = styled(Row)`
  justify-content: space-around;
`;

const TestimonialsHeading = styled.h2`
  ${headerLFillStyles};

  @media (${bpWidth.desktopSm}) {
    ${headerMStyles};
  }

  @media (${bpWidth.tablet}) {
    text-align: center;
  }

  @media (${bpWidth.mobile}) {
    ${headerSStyles};
    text-align: center;
  }
`;

const Testimonials = ({ blok }) => {
  const { title, testimonial } = blok;

  return (
    <Col {...storyblokEditable(blok)} xsOffset={1} xs={6} smOffset={1} sm={6} mdOffset={2} md={6} lgOffset={3} lg={6}>
      <TestimonialsHeadingRow>
        <TestimonialsHeading>{title}</TestimonialsHeading>
      </TestimonialsHeadingRow>
      <Row>
        {testimonial?.map((nestedBlok, index) => {
          return <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} index={index} />;
        })}
      </Row>
    </Col>
  );
};

Testimonials.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default Testimonials;
