import React, { useState } from 'react';
import { Link, withPrefix } from 'gatsby';
import { array, object } from 'prop-types';
import _ from 'lodash';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';

// Styles
import styled from 'styled-components';
import { Row, Col } from 'react-styled-flexboxgrid';
import {
  headerMStyles,
  headerSStyles,
  headerXsStyles,
  bodyLBoldBrownStyles,
  bodySBoldBrownStyles,
  bodyXsBoldBrownStyles,
  bodyXxsBoldBrownStyles,
  bodyXxxsBoldBrownStyles,
  bodyLRegularUtopiaStyles,
  bodySRegularUtopiaStyles,
  bodyXsRegularUtopiaStyles,
} from '../../../styles/Web3.0/typography';
import { bpWidth, colors } from '../../../styles/Web3.0/variables';
import { standardTags } from '../../../styles/Web3.0/categoryTags';

// Components
import { calculateReadTime } from '../../../utils/calculateReadTime';
import { fetchStoryblokStories, useIsMounted, useIsomorphicLayoutEffect } from '../../../hooks';
import { renderPlainText, renderRichTextReact } from '../../../utils/storyblokRichText';
import { defaultStoryblokStoriesParams } from '../../../common/constants/storyblok.constant';
import { BLOG_POST_SORT_BY } from '../../../constants/blog';
import { findNestedObject } from '../../../utils/helper';

// Elements
const CarouselPostCol = styled(Col)`
  padding-top: 95px;
  margin-bottom: 182px;

  i {
    font-style: italic;
  }

  h2 {
    ${headerMStyles};
    color: ${colors.navy600};
    padding-bottom: 50px;
  }

  div {
    ${bodyLRegularUtopiaStyles};
    color: ${colors.navy600};
  }

  @media (${bpWidth.desktopSm}) {
    padding-top: 80px;
    margin-bottom: 139px;

    h2 {
      ${headerSStyles};
      color: ${colors.navy600};
      padding-bottom: 30px;
    }

    div {
      ${bodySRegularUtopiaStyles};
      color: ${colors.navy600};
    }
  }

  @media (${bpWidth.tablet}) {
    padding-top: 70px;
    margin-bottom: 50px;
  }

  @media (${bpWidth.mobile}) {
    h2 {
      ${headerXsStyles};
      color: ${colors.navy600};
    }

    div {
      ${bodyXsRegularUtopiaStyles};
      color: ${colors.navy600};
    }
  }
`;

const SlidesCol = styled(Col)`
  display: flex;
  flex-direction: row;
  max-width: 100%;
  overflow: hidden;
  overflow-x: scroll;
`;

const Slides = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 50px;

  @media (${bpWidth.desktopSm}) {
    padding-bottom: 30px;
  }

  @media (${bpWidth.tablet}) {
    padding-bottom: 50px;
  }

  @media (${bpWidth.mobile}) {
    padding-bottom: 30px;
  }
`;

const Thumbnail = styled.div`
  height: 462px !important;
  width: 462px !important;

  @media (${bpWidth.desktopSm}) {
    height: 326px !important;
    width: 326px !important;
  }

  @media (${bpWidth.tablet}) {
    height: 278px !important;
    width: 278px !important;
  }

  @media (${bpWidth.mobile}) {
    height: 202px !important;
    width: 202px !important;
  }
`;

const Slide = styled.div`
  margin-right: 24px;
  width: 462px;

  &:hover {
    .gatsby-image-wrapper.gatsby-image-wrapper-constrained {
      opacity: 0.4;
      -webkit-transition: -webkit-opacity 0.3s ease-out;
    }
  }
  &:first-child {
    margin-left: 8.333333333333334vw;
  }
  &:last-child {
    margin-right: 8.333333333333334vw;
  }
  a {
    cursor: pointer;
    text-decoration: none;
  }

  @media (${bpWidth.desktopSm}) {
    margin-right: 20px;
    width: 326px;

    &:first-child {
      margin-left: 10vw;
    }
    &:last-child {
      margin-right: 10vw;
    }
  }

  @media (${bpWidth.tablet}) {
    margin-right: 16px;
    width: 278px;

    &:hover {
      .gatsby-image-wrapper.gatsby-image-wrapper-constrained {
        opacity: 1;
      }
    }
    &:first-child {
      margin-left: 12.5vw;
    }
    &:last-child {
      margin-right: 12.5vw;
    }
  }

  @media (${bpWidth.mobile}) {
    margin-right: 10px;
    width: 202px;
  }
`;

const ImageContainer = styled.div`
  background: ${colors.navy700};
  margin-bottom: 10px;
  position: relative;
`;

const Tag = styled.div`
  margin-left: 20px;
  padding-bottom: 0;
  position: absolute;
  z-index: 2;
  ${standardTags}
`;

const Copy = styled.div`
  display: flex;
  flex-direction: column;

  span {
    ${bodySBoldBrownStyles};
    color: ${colors.gray300};
    margin-bottom: 15px;
  }
  h3 {
    ${bodyLBoldBrownStyles};
    color: ${colors.navy600};
    margin-bottom: 10px;
  }
  @media (${bpWidth.desktopSm}) {
    span {
      ${bodyXxsBoldBrownStyles};
      color: ${colors.gray300};
    }
    h3 {
      ${bodySBoldBrownStyles};
      color: ${colors.navy600};
    }
  }
  @media (${bpWidth.mobile}) {
    span {
      ${bodyXxxsBoldBrownStyles};
      margin-bottom: 5px;
      color: ${colors.gray300};
    }
    h3 {
      ${bodyXsBoldBrownStyles};
      color: ${colors.navy600};
      margin-bottom: 5px;
    }
  }
`;

function CarouselPost({ blok, relatedPosts = [] }) {
  const { heading, posts = [] } = blok;
  const isMounted = useIsMounted();
  const [stories, setStories] = useState([]);

  useIsomorphicLayoutEffect(() => {
    if (posts.length > 0) {
      fetchStoryblokStories({
        ...defaultStoryblokStoriesParams,
        by_uuids: posts.join(','),
        is_startpage: false,
        sort_by: BLOG_POST_SORT_BY,
      })
        .then((resp) => {
          if (isMounted()) {
            setStories(resp?.data?.stories || []);
          }
        })
        .catch(console.error);
    }
  }, [posts, isMounted]);

  useIsomorphicLayoutEffect(() => {
    if (posts.length === 0) {
      setStories(relatedPosts);
    }
  }, [posts, relatedPosts]);

  return (
    <CarouselPostCol {...storyblokEditable(blok)} xsOffset={0} xs={8} md={10} lg={12}>
      {/* Section heading */}
      {heading && (
        <Row>
          <Col xsOffset={1} xs={4} md={3}>
            <h2>{heading}</h2>
          </Col>
        </Row>
      )}

      {/* Carousel */}
      <Row>
        <SlidesCol xs={8} md={10} lg={12}>
          <Slides>
            {stories.map((story) => {
              const post = findNestedObject(story.content, 'component', 'post');
              if (!post) {
                return undefined;
              }

              const article = renderPlainText(post.article);
              const articleHeading = renderRichTextReact(post.heading);
              const articleSubHeading = renderRichTextReact(post.subHeading);
              return (
                post && (
                  <Slide key={post._uid}>
                    <Link to={withPrefix(story.path || story.full_slug)}>
                      <ImageContainer>
                        <Tag className={_.camelCase(post.category)}>{post.category}</Tag>
                        <Thumbnail>
                          {post.thumbnail?.map((nestedBlok) => (
                            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
                          ))}
                        </Thumbnail>
                      </ImageContainer>
                      <Copy>
                        {article && <span>{calculateReadTime({ raw: article })}</span>}
                        {articleHeading && <h3>{articleHeading}</h3>}
                        {articleSubHeading && <div>{articleSubHeading}</div>}
                      </Copy>
                    </Link>
                  </Slide>
                )
              );
            })}
          </Slides>
        </SlidesCol>
      </Row>
    </CarouselPostCol>
  );
}
// Props
const propTypes = {
  blok: object.isRequired,
  relatedPosts: array,
};
const defaultProps = {
  relatedPosts: [],
};

CarouselPost.propTypes = propTypes;
CarouselPost.defaultProps = defaultProps;
export default CarouselPost;
