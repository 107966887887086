/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { any, func } from 'prop-types';

// Styles
import { Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import {
  eyebrowMStyles,
  eyebrowSStyles,
  eyebrowXsStyles,
  bodySRegularBrownStyles,
  bodyXsRegularBrownStyles,
} from '../../../styles/Web3.0/typography.js';
import { bpWidth, colors } from '../../../styles/Web3.0/variables.js';

// Components
import CloseIcon from '../../../icons/Web3.0/close-icon.svg';
import { renderRichTextReact } from '../../../utils/storyblokRichText';
import { useSsr, useStoryblokStories } from '../../../hooks';

const PopUpContainer = styled.div`
  color: ${colors.navy600};

  h3 {
    ${eyebrowMStyles};
    line-height: 1;
  }

  h4 {
    ${eyebrowSStyles};
    padding-bottom: 20px;
  }

  p {
    ${bodySRegularBrownStyles};
    padding-bottom: 30px;

    &:last-child {
      padding-bottom: 0;
    }
  }

  @media (${bpWidth.desktopSm}) {
    h3 {
      ${eyebrowSStyles};
      line-height: 1;
    }

    h4 {
      ${eyebrowXsStyles};
      padding-bottom: 10px;
    }

    p {
      ${bodyXsRegularBrownStyles};
    }
  }
  @media (${bpWidth.mobile}) {
    h3 {
      overflow-wrap: break-word;
    }
  }
`;

const PopUpBackgroundRow = styled(Row)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  z-index: 23;
`;

const PopUpCol = styled(Col)`
  background: ${colors.beige100};
  display: flex;
  flex-direction: column;
  height: fit-content;
  max-height: 700px;
  padding: 50px;
  width: 38.9vw;

  @media (${bpWidth.desktopSm}) {
    max-height: 600px;
    padding: 40px;
    width: 46.7vw;
  }
  @media (${bpWidth.tablet}) {
    width: 58.3vw;
  }
  @media (max-height: 700px) {
    height: 75%;
  }
`;

const HeadingCol = styled(Col)`
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
`;

const PopUpCopyRow = styled(Row)`
  overflow-x: hidden;
  overflow-y: scroll;

  &.fadedText {
    -webkit-mask-image: linear-gradient(to bottom, black 90%, transparent 100%);
    mask-image: linear-gradient(to bottom, black 90%, transparent 100%);
  }

  #popUpCopy {
    padding-right: 60px;
  }

  @media (${bpWidth.desktopSm}) {
    #popUpCopy {
      padding-right: 50px;
    }
  }
  @media (${bpWidth.mobile}) {
    #popUpCopy {
      padding-right: 30px;
    }
  }
`;

const CloseButton = styled.button`
  display: flex;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;

  svg {
    background: ${colors.beige100};
    width: 12px;
    z-index: 1;

    polygon.st0 {
      fill: ${colors.navy600};
    }
  }

  @media (${bpWidth.desktopSm}) {
    svg {
      width: 9px;
    }
  }
`;

function DetailsPopUp(props) {
  const { isBrowser } = useSsr();
  // State
  const { onPopUpClose, bannerClicked } = props;

  const [popUpClass, setPopUpClass] = useState('');
  const [includeCtaButton, setIncludeCtaButton] = useState(false);
  const [buttonText, setButtonText] = useState('');
  const [buttonHref, setButtonHref] = useState('');
  const [show, setShow] = useState(false);
  const [closeOutsideX, setCloseOutsideX] = useState(false);
  const [lockScrolling, setLockScrolling] = useState(false);

  // Elements
  const [popUpBlur, setModalBlur] = useState('');
  const popUpStyle = {
    backgroundColor: popUpBlur,
  };

  // Modal entries from Storyblok, only 1st one is pulled
  const popUps = useStoryblokStories({
    content_type: 'detailPopup',
    sort_by: 'content.startDate:desc,content.endDate:desc',
    resolve_relations: ['detailPopup.exemptPages'],
    per_page: 1,
  });
  const stories = popUps?.data?.stories || [];
  const [popUp] = stories;

  // Freeze scrolling when popUp opens
  const popUpFreezeScroll = () => {
    if (show && lockScrolling) {
      document.body.classList.add('popUpFreezeScroll');
    }
  };

  const isExemptPages = (story) => {
    return (
      window.location.pathname.startsWith(`/${story.path || story.full_slug}`) ||
      `${window.location.pathname}/`.startsWith(`/${story.path || story.full_slug}`)
    );
  };

  useEffect(() => {
    // Show popUp if there are popUp entries in Contenful
    if (popUp) {
      setCloseOutsideX(popUp.content.closeOutsideX);
      setLockScrolling(popUp.content.lockScrolling);

      // Show if start & end dates are within range or popUp is permanent
      const currentDate = new Date();
      if (
        popUp.content.permanent ||
        (new Date(popUp.content.startDate) <= currentDate && new Date(popUp.content.endDate) >= currentDate)
      ) {
        // Show popUp if url is NOT exempt
        setShow(true);

        if (isBrowser && popUp.content.exemptPages?.some(isExemptPages)) {
          setShow(false);
        }

        popUpFreezeScroll();

        // Setting popUp values
        if (typeof window !== `undefined` && window.location.href.includes('/')) {
          setModalBlur('rgba(0, 18, 25, .6)');
        } else {
          setModalBlur('unset');
        }
      }
    }
  }, [popUp]);

  // Close popUp functions
  const closeOnX = () => {
    document.body.classList.remove('popUpFreezeScroll');
    setShow(false);

    if (bannerClicked) {
      return onPopUpClose(true);
    }
    return null;
  };

  const closeOnAnyClick = () => {
    if (closeOutsideX) {
      closeOnX();
    }
  };

  const popUpButtonClick = (e) => {
    e.preventDefault();
    if (typeof window !== `undefined`) {
      window.location.href = buttonHref;
    }
  };

  // Fade popup text if there's an overflow
  const fadeClassChange = (changeType) => {
    const popUpCopyContainer = document.getElementById('popUpCopyContainer');
    if (changeType !== 'addFade' && popUpCopyContainer.classList.contains('fadedText')) {
      popUpCopyContainer.classList.remove('fadedText');
    } else if (changeType === 'addFade' && !popUpCopyContainer.classList.contains('fadedText')) {
      popUpCopyContainer.classList.add('fadedText');
    }
  };

  const onScrollFadeClassChange = () => {
    const popUpCopyContainer = document.getElementById('popUpCopyContainer');
    if (popUpCopyContainer.scrollTop > 0) {
      fadeClassChange('removeFade');
    } else if (popUpCopyContainer.scrollTop === 0) {
      fadeClassChange('addFade');
    }
  };

  useEffect(() => {
    const popUpCopyContainer = document.getElementById('popUpCopyContainer');
    const popUpCopy = document.getElementById('popUpCopy');

    if (show) {
      if (popUpCopyContainer.offsetHeight < popUpCopy.offsetHeight) {
        // On initial popup open
        fadeClassChange('addFade');

        // Remove fade once user scrolls down
        popUpCopyContainer.addEventListener('scroll', onScrollFadeClassChange);
      } else {
        fadeClassChange('removeFade');
      }
    }

    return () => {
      popUpCopyContainer.removeEventListener('scroll', onScrollFadeClassChange);
    };
  }, [show]);

  useEffect(() => {
    if (typeof window !== `undefined` && typeof document !== `undefined`) {
      const popUpCopyContainer = document.getElementById('popUpCopyContainer');
      const popUpCopy = document.getElementById('popUpCopy');
      // If window width is resized
      const handleWidthResize = () => {
        if (popUpCopyContainer.offsetHeight < popUpCopy.offsetHeight) {
          fadeClassChange('addFade');
        } else {
          fadeClassChange('removeFade');
        }
      };
      window.addEventListener('resize', handleWidthResize);

      // Clean up listening events
      return () => {
        window.removeEventListener('resize', handleWidthResize);
      };
    }
    return null;
  }, []);

  return (
    <PopUpContainer>
      {show && (
        <PopUpBackgroundRow style={popUpStyle} onClick={closeOnAnyClick}>
          <PopUpCol className={popUpClass}>
            <Row>
              <HeadingCol xs={8} md={10} lg={12}>
                <h3>{popUp.content.heading}</h3>

                <CloseButton onClick={closeOnX}>
                  <CloseIcon />
                </CloseButton>
              </HeadingCol>
            </Row>
            <PopUpCopyRow id="popUpCopyContainer">
              <Col id="popUpCopy" xs={8} md={10} lg={12}>
                <div>{renderRichTextReact(popUp.content.body)}</div>
                {includeCtaButton && (
                  <a className="popUpButton" href={buttonHref} onClick={popUpButtonClick}>
                    {buttonText}
                  </a>
                )}
              </Col>
            </PopUpCopyRow>
          </PopUpCol>
        </PopUpBackgroundRow>
      )}
    </PopUpContainer>
  );
}

DetailsPopUp.propTypes = {
  onPopUpClose: func,
  bannerClicked: any,
};

export default DetailsPopUp;
