import React, { useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { throttle } from 'lodash';
import { initialSettings, settingsReducer } from '../state/settings/settingsReducer';
import { useBoolean, useIsomorphicLayoutEffect } from '../hooks';
import { SCROLL_DOWN, SCROLL_UP } from '../constants';

/**
 * @type {React.Context<AppState>}
 */
const AppStateContext = React.createContext({});

/**
 * @typedef {Object} AppStateSettings
 * @property {boolean} navFontColorLight
 * @property {boolean} navLightMode
 * @property {boolean} showCartIcon
 * @property {boolean} showSearchIcon
 */

/**
 * @typedef {Object} AppState
 * @property {AppStateSettings} settings
 * @property {({ name: string, value: any }) => void} dispatchSetting
 * @property {any[]} primaryNavLinks
 * @property {(value: any[]) => void} setPrimaryNavLinks
 * @property {any[]} popularSearches
 * @property {(value: any[]) => void} setPopularSearches
 * @property {*} footerBlok
 * @property {(value: any) => void} setFooterBlok
 * @property {boolean} searchIsOpen
 * @property {() => void} toggleSearch
 * @property {boolean} hamburgerMenuIsOpen
 * @property {() => void} toggleHamburgerMenu
 * @property {number} headerHeight
 * @property {(number) => void} setHeaderHeight
 * @property {number} headerTranslateY
 * @property {(number) => void} setHeaderTranslateY
 */

/**
 * @returns {AppState}
 */
export const useAppState = () => {
  /**
   * @type {AppState}
   */
  const context = useContext(AppStateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
};

const AppStateProvider = ({ children }) => {
  const [settings, dispatchSetting] = useReducer(settingsReducer, initialSettings);
  const [primaryNavLinks, setPrimaryNavLinks] = useState([]);
  const [popularSearches, setPopularSearches] = useState([]);
  const [footerBlok, setFooterBlok] = useState(null);
  const { value: searchIsOpen, toggle: toggleSearch, setFalse: closeSearch } = useBoolean(false);
  const { value: hamburgerMenuIsOpen, toggle: toggleHamburgerMenu } = useBoolean(false);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [headerTranslateY, setHeaderTranslateY] = useState(0);

  useEffect(() => {
    if (searchIsOpen) {
      setTimeout(() => {
        document.documentElement.classList.add('is-locked');
      }, 300);
    } else {
      setTimeout(() => {
        document.documentElement.classList.remove('is-locked');
      }, 300);
    }
  }, [searchIsOpen]);

  const handleOutsideSearchClick = (event) => {
    if (searchIsOpen && event.target.id === 'outerSearchEl') {
      closeSearch();
    }
  };

  // Search closed on outside click
  useEffect(() => {
    document.addEventListener('click', handleOutsideSearchClick);

    // Unbind the event listener on clean up
    return () => {
      document.removeEventListener('click', handleOutsideSearchClick);
    };
  }, [searchIsOpen]);

  useEffect(() => {
    if (searchIsOpen) {
      setTimeout(() => {
        document.documentElement.classList.add('is-locked');
      }, 300);
    } else {
      setTimeout(() => {
        document.documentElement.classList.remove('is-locked');
      }, 300);
    }
  }, [searchIsOpen]);

  let lastScroll = 0;
  let lastPosition = 0;
  const handleDynamicHeader = useCallback(() => {
    const { body } = document;
    const currentScroll = window.scrollY;
    if (currentScroll <= 0) {
      body.classList.remove(SCROLL_UP);
      setHeaderTranslateY(0);
      return;
    }
    if (currentScroll > lastScroll && !body.classList.contains(SCROLL_DOWN)) {
      // down
      body.classList.remove(SCROLL_UP);
      body.classList.add(SCROLL_DOWN);
      lastPosition = currentScroll;
    } else if (currentScroll < lastScroll && body.classList.contains(SCROLL_DOWN)) {
      // up
      body.classList.remove(SCROLL_DOWN);
      body.classList.add(SCROLL_UP);
      lastPosition = currentScroll;
    }

    lastScroll = currentScroll;
    let scrollDeltaY;
    if (body.classList.contains(SCROLL_DOWN)) {
      scrollDeltaY = lastScroll - lastPosition;
      if (scrollDeltaY > headerHeight) {
        scrollDeltaY = headerHeight;
      }
    } else {
      scrollDeltaY = lastPosition - lastScroll;
      if (scrollDeltaY > headerHeight) {
        scrollDeltaY = headerHeight;
      }

      scrollDeltaY = headerHeight - scrollDeltaY;
    }
    setHeaderTranslateY(scrollDeltaY / headerHeight);
  }, [headerHeight]);

  const throttledHandleDynamicHeader = throttle(handleDynamicHeader, 100);

  useIsomorphicLayoutEffect(() => {
    handleDynamicHeader();

    window.addEventListener('scroll', throttledHandleDynamicHeader);

    return () => {
      window.removeEventListener('scroll', throttledHandleDynamicHeader);
    };
  }, [headerHeight]);

  const contextValue = useMemo(
    () => ({
      settings,
      dispatchSetting,
      primaryNavLinks,
      setPrimaryNavLinks,
      popularSearches,
      setPopularSearches,
      footerBlok,
      setFooterBlok,
      searchIsOpen,
      toggleSearch,
      hamburgerMenuIsOpen,
      toggleHamburgerMenu,
      headerHeight,
      setHeaderHeight,
      headerTranslateY,
      setHeaderTranslateY,
    }),
    [
      settings,
      primaryNavLinks,
      popularSearches,
      footerBlok,
      searchIsOpen,
      hamburgerMenuIsOpen,
      headerHeight,
      headerTranslateY,
    ]
  );

  return <AppStateContext.Provider value={contextValue}>{children}</AppStateContext.Provider>;
};

AppStateProvider.propTypes = {
  children: PropTypes.any,
};

export default AppStateProvider;
