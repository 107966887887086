/* eslint-disable no-param-reassign */
/**
 * @param {string | number} value
 * @param {NumberFormatOptions} [options]
 * @param {string} locales
 * @return {string}
 */
export function currencyFormatter(value, options, locales = 'en-US') {
  let formattedValue;

  if (typeof value === 'string') {
    formattedValue = Number(value);
  } else {
    formattedValue = value;
  }

  if (options) {
    options.style = 'currency';

    if (!options.currency) {
      options.currency = 'USD';
    }
  }

  formattedValue = new Intl.NumberFormat(locales, options).format(formattedValue);

  return formattedValue;
}
