import React from 'react';
import PropTypes from 'prop-types';

const TabContentRow = ({ activeTab, uid, className, children }) => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return activeTab === uid ? <div className={className}>{children}</div> : undefined;
};

TabContentRow.propTypes = {
  uid: PropTypes.string.isRequired,
  activeTab: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default TabContentRow;
