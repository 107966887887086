import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import { Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import { withPrefix } from 'gatsby';
import { useIsomorphicLayoutEffect } from '../../../hooks';
import { bpWidth } from '../../../styles/Web3.0/variables';
import PostHeader from './PostHeader';
import PostBody from './PostBody';
import SharingLinks from './SharingLinks';
import { renderPlainText } from '../../../utils/storyblokRichText';
import PostComments from './PostComments';
import { useRootState } from '../../../context/root-state.context';
import CarouselPost from './CarouselPost';

const FeaturedImageRow = styled(Row)`
  .gatsby-image-wrapper.gatsby-image-wrapper-constrained {
    height: 287px;
    object-position: top;
    width: 100%;
  }

  @media (${bpWidth.mobile}) {
    .gatsby-image-wrapper.gatsby-image-wrapper-constrained {
      height: 350px;
    }
  }
`;

const Post = ({ blok }) => {
  const [bannerImage, setBannerImage] = useState();
  const { post, relatedPosts } = useRootState();

  useIsomorphicLayoutEffect(() => {
    setBannerImage(blok.bannerImage?.[0]);
  }, [blok.bannerImage]);

  return (
    <Col {...storyblokEditable(blok)} xs={8} md={10} lg={12}>
      {/* Banner image */}
      {bannerImage && (
        <FeaturedImageRow>
          <Col xs={8} md={10} lg={12}>
            <StoryblokComponent blok={bannerImage} />
          </Col>
        </FeaturedImageRow>
      )}

      {/* Post heading */}
      <PostHeader
        publishDate={blok.publishDate}
        heading={blok.heading}
        subHeading={blok.subHeading}
        article={blok.article}
      />

      {/* Post body */}
      <PostBody postBodyData={blok.article} />

      {/* Social media links */}
      {blok.showSocialLinks && <SharingLinks postHeading={renderPlainText(blok.heading)} />}

      {/* Blog comments */}
      {post && <PostComments postUrl={withPrefix(post.path || post.full_slug)} postTitle={`${post.title}`} />}

      {/* Related stories */}
      {relatedPosts?.length > 0 && blok.showRelatedStories && (
        <CarouselPost blok={{ heading: 'Related Stories' }} relatedPosts={relatedPosts} />
      )}
    </Col>
  );
};

Post.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default Post;
