import React, { useEffect, useMemo } from 'react';
import { string } from 'prop-types';
import _ from 'lodash';
import useLocation from '../../../hooks/useLocation';

const LooxReviews = (props) => {
  const { shopifyId } = props;
  const { href } = useLocation();

  // this is a fix for the Loox review modal iframe.
  // on modal close, it was navigating the current tab back.
  useEffect(() => {
    const preventMessageNavigation = (e) => {
      const messageData = e.data;
      const closeQuickViewModal = _.includes(messageData, 'quickview') && _.includes(messageData, 'close');

      if (closeQuickViewModal) {
        window.location.href = `${href}#`;
      }
    };

    window.addEventListener('message', preventMessageNavigation);

    return () => window.removeEventListener('message', preventMessageNavigation);
  }, [href]);

  const looxReviews = useMemo(() => {
    return shopifyId ? (
      <div id="looxReviews" data-product-id={shopifyId.replace('gid://shopify/Product/', '')} />
    ) : undefined;
  }, [shopifyId]);

  return looxReviews;
};

LooxReviews.propTypes = {
  shopifyId: string,
};

export default LooxReviews;
