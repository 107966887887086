import React, { useEffect, useMemo, useState } from 'react';
import { storyblokEditable } from 'gatsby-source-storyblok';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import { bpWidthTailwind, colors } from '../../../styles/Web3.0/variables';
import {
  headerLFillStyles,
  headerMStyles,
  headerSStyles,
  headerXsStyles,
  headerXxsStyles,
} from '../../../styles/Web3.0/typography';
import { renderRichTextReact } from '../../../utils/storyblokRichText';

const SectionHeaderCol = styled(Col)``;
const SectionHeaderInnerRow = styled(Row)`
  gap: 20px;
`;
const TitleCol = styled(Col)`
  ${headerXxsStyles};
  color: ${({ darkMode }) => (darkMode ? colors.orange400 : undefined)};

  @media (${bpWidthTailwind.tablet}) {
    ${headerXsStyles};
    color: ${({ darkMode }) => (darkMode ? colors.orange400 : undefined)};
  }
`;
const ContentCol = styled(Col)`
  ${headerXsStyles};
  color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};

  @media (${bpWidthTailwind.tablet}) {
    ${headerSStyles};
    color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
  }

  @media (${bpWidthTailwind.desktopSm}) {
    ${headerMStyles};
    color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
  }

  @media (${bpWidthTailwind.desktopLg}) {
    ${headerLFillStyles};
    color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
  }
`;

const SectionHeader = ({ blok }) => {
  const { title, content } = blok;
  const [titleRichText, setTitleRichText] = useState(null);
  const [contentRichText, setContentRichText] = useState(null);

  const cssClass = useMemo(
    () => ['section-header-component', blok.customCssClass].filter(Boolean),
    [blok.customCssClass]
  );

  useEffect(() => {
    setTitleRichText(renderRichTextReact(title));
  }, [blok.title]);

  useEffect(() => {
    setContentRichText(renderRichTextReact(content));
  }, [blok.content]);

  return (
    <SectionHeaderCol {...storyblokEditable(blok)} xs={8} md={10} lg={12} className={cssClass}>
      <SectionHeaderInnerRow className="section-header-component--container">
        {titleRichText && (
          <TitleCol xs={8} md={10} lg={12} darkMode={blok.darkMode} className="section-header-component--title">
            {titleRichText}
          </TitleCol>
        )}
        {contentRichText && (
          <ContentCol xs={8} md={10} lg={12} darkMode={blok.darkMode} className="section-header-component--content">
            {contentRichText}
          </ContentCol>
        )}
      </SectionHeaderInnerRow>
    </SectionHeaderCol>
  );
};

SectionHeader.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default SectionHeader;
