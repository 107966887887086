import React, { useState, useEffect, useRef } from 'react';
import { array, bool, func } from 'prop-types';

// styles
import styled from 'styled-components';
import { Col, Row } from 'react-styled-flexboxgrid';
import { bpWidth, colors } from '../../../styles/Web3.0/variables.js';
import { bodyMRegularBrownStyles, bodySRegularBrownStyles } from '../../../styles/Web3.0/typography.js';

// components
import Chevron from '../../../icons/Web3.0/arrow-DCerulean.svg';
import { formatSectionLabel } from '../../../utils';

// elements
const FilterContainerCol = styled(Col)`
  margin-top: 30px;
`;

const FilterLabel = styled.label`
  ${bodySRegularBrownStyles};
  color: ${colors.gray400};
`;

const DropDownContainer = styled.div`
  width: 15vw;
  height: 3em;

  @media (${bpWidth.tablet}) {
    width: 25vw;
  }

  @media (${bpWidth.mobile}) {
    width: 37.5vw;
  }
`;

const DropDownHeader = styled(Row)`
  padding: 0.6em;
  width: 100%;
  cursor: pointer;
  ${bodyMRegularBrownStyles};
  color: ${colors.gray200};
  background-color: ${colors.beige200};
  border-radius: 6px;
  position: relative;
  z-index: ${({ stickyTop }) => (stickyTop ? 122 : undefined)};

  svg {
    max-width: 13.31px;
    height: 6.65px;
    margin-right: 1em;

    path#Path_2 {
      stroke: ${colors.navy600};
    }
  }

  ${Col} {
    overflow: hidden;
    white-space: nowrap;
  }
`;

const DropDownListContainer = styled.div`
  width: 15vw;

  @media (${bpWidth.tablet}) {
    width: 25vw;
  }

  @media (${bpWidth.mobile}) {
    width: 37.5vw;
  }

  ul {
    ${bodyMRegularBrownStyles};
    padding: 0 0.5em;
    margin: 0;
    background: ${colors.beige300};
    border-radius: 6px;
    box-sizing: border-box;
    color: ${colors.black};
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
    height: 150px;
    overflow: scroll;
    position: relative;
    top: -8px;
    z-index: 120;

    &:first-child {
      padding-top: 1em;
    }

    &:last-child {
      padding-bottom: 0.8em;
    }

    li {
      padding-left: 1em;
      cursor: pointer;
      list-style: none;
      margin-bottom: 0.8em;

      &:hover {
        background-color: ${colors.beige200};
      }
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 6em;
    width: 14vw;
    height: 150px;
    z-index: 121;
    background: linear-gradient(rgba(255, 255, 255, 0) 70%, rgba(223, 220, 210, 1) 100%);
    pointer-events: none;

    @media (${bpWidth.tablet}) {
      width: 24vw;
    }

    @media (${bpWidth.mobile}) {
      width: 36.5vw;
    }
  }
`;

const ArrowSvg = (isOpen) => {
  return (
    <Chevron
      className={isOpen ? 'ctaExpanded' : 'ctaCollapsed'}
      style={{
        transitionDuration: '.1s',
        transform: isOpen ? 'rotate(180deg)' : 'none',
      }}
    />
  );
};

const FilterDropdown = (props) => {
  const { optionList = [], onFilter, stickyTop } = props;
  const dropdownRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    const allOptions = [
      {
        id: 0,
        name: 'All',
        value: 'all',
        dimension_value: null,
      },
      ...optionList,
    ];
    setOptions(allOptions);
  }, [optionList]);

  const toggle = () => setIsOpen(!isOpen);

  const onOptionClicked = (option) => () => {
    setSelected(option);
    setIsOpen(false);
    onFilter(option.value);
  };

  const useHandleOutsideDropdownClick = (ref) => {
    useEffect(() => {
      const handleOutsideDropdownClick = (event) => {
        event.stopPropagation();
        if (ref.current && !ref.current.contains(event.target)) {
          setIsOpen(false);
        }
      };

      document.addEventListener('click', handleOutsideDropdownClick);
      return () => {
        document.removeEventListener('click', handleOutsideDropdownClick);
      };
    }, []);
  };

  useHandleOutsideDropdownClick(dropdownRef);

  return (
    <FilterContainerCol xs={4} sm={2} md={3}>
      <FilterLabel htmlFor="sections">Filters</FilterLabel>
      <DropDownContainer ref={dropdownRef}>
        <DropDownHeader onClick={toggle} stickyTop={stickyTop}>
          <Col xs>{selected?.name || 'Select'}</Col>
          <Col lgOffset={2} lg={1}>
            {ArrowSvg(isOpen)}
          </Col>
        </DropDownHeader>
        {isOpen && (
          <DropDownListContainer>
            <ul role="menu">
              {options.map((option) => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <li key={option.id} role="menuitem" onClick={onOptionClicked(option)}>
                  {formatSectionLabel(option.name)}
                </li>
              ))}
            </ul>
          </DropDownListContainer>
        )}
      </DropDownContainer>
    </FilterContainerCol>
  );
};

FilterDropdown.propTypes = {
  optionList: array,
  onFilter: func,
  stickyTop: bool,
};

FilterDropdown.defaultProps = {
  optionList: [],
};

export default FilterDropdown;
