import React from 'react';
import PropTypes from 'prop-types';
import { storyblokEditable } from 'gatsby-source-storyblok';
import styled from 'styled-components';
import { Col } from 'react-styled-flexboxgrid';
import { renderRichTextReact } from '../../../utils/storyblokRichText';
import Link from './Link';

const RichtextCol = styled(Col)``;

const RichtextContent = styled.div`
  text-align: ${({ alignment }) => alignment};
`;

RichtextContent.propTypes = {
  alignment: PropTypes.string,
};

const Richtext = ({ blok }) => {
  const handleEmbeddedLink = (embeddedLink) => <Link blok={embeddedLink} />;

  /** @type {RenderOptions} */
  const renderOptions = {
    blokResolvers: {
      link: handleEmbeddedLink,
    },
  };

  return (
    <RichtextCol {...storyblokEditable(blok)} xs={8} md={10} lg={12} className="rictext-component--container">
      <RichtextContent alignment={blok.alignment} className="rictext-component--content">
        {renderRichTextReact(blok.body, renderOptions)}
      </RichtextContent>
    </RichtextCol>
  );
};

Richtext.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default Richtext;
