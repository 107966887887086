import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

// styles
import styled from 'styled-components';
import { Col } from 'react-styled-flexboxgrid';
import { bodyMRegularBrownStyles, bodySRegularBrownStyles } from '../../../styles/Web3.0/typography';
import { bpWidth, colors } from '../../../styles/Web3.0/variables';

// libs
import { renderRichTextReact } from '../../../utils/storyblokRichText';

// components

const WrapperCol = styled(Col)`
  ${bodyMRegularBrownStyles};
  padding: 15px 0 12px 0;
  line-height: 1;

  @media (${bpWidth.desktopSm}) {
    ${bodySRegularBrownStyles};
    padding: 13px 0 10px 0;
  }

  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ isActive }) => (isActive ? colors.beige100 : colors.beige700)} !important;
  background-color: ${({ isActive }) => (isActive ? colors.beige700 : colors.beige200)};
  border-bottom: 1px solid ${colors.beige700};

  :not(:last-of-type) {
    border-right: 1px solid ${colors.beige700};
  }
`;

const MobileTitle = styled.div`
  display: none;

  @media (${bpWidth.mobile}) {
    display: block;
  }
`;

const BiggerThanMobileTitle = styled.div`
  display: block;

  @media (${bpWidth.mobile}) {
    display: none;
  }
`;

const TabHeader = ({ uid, activeTab, setActiveTab, title, mobileTitle }) => {
  const isActive = useMemo(() => activeTab === uid, [activeTab, uid]);
  const xsCol = useMemo(() => (isActive ? 5 : 3), [isActive]);
  const cssClass = useMemo(() => {
    return ['tabs-component--header', isActive ? 'tabs-component--header-active' : undefined].filter(Boolean).join(' ');
  }, [isActive]);
  const [titleRichText, setTitleRichText] = useState(null);
  const [mobileTitleRichText, setMobileTitleRichText] = useState(null);

  const handleTabClick = () => {
    setActiveTab(uid);
  };

  useEffect(() => {
    setTitleRichText(renderRichTextReact(title));
  }, [title]);

  useEffect(() => {
    setMobileTitleRichText(renderRichTextReact(mobileTitle));
  }, [mobileTitle]);

  return (
    <WrapperCol
      isActive={activeTab === uid}
      xs={xsCol}
      sm={4}
      md={5}
      lg={6}
      onClick={handleTabClick}
      className={cssClass}
    >
      <BiggerThanMobileTitle>{titleRichText}</BiggerThanMobileTitle>
      <MobileTitle>{activeTab === uid ? titleRichText : mobileTitleRichText || titleRichText}</MobileTitle>
    </WrapperCol>
  );
};

TabHeader.propTypes = {
  uid: PropTypes.string.isRequired,
  title: PropTypes.object.isRequired,
  mobileTitle: PropTypes.object,
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func.isRequired,
};

export default TabHeader;
