import React, { useMemo } from 'react';
import { object } from 'prop-types';
import { storyblokEditable } from 'gatsby-source-storyblok';
import styled from 'styled-components';
import CalendarIcon from '../../../img/calendar-icon.svg';
import { bodyMRegularBrownStyles } from '../../../styles/Web3.0/typography';

const EmbeddedEventTimeParagraph = styled.p`
  ${bodyMRegularBrownStyles}
  margin-bottom: 10px;

  &:last-of-type {
    margin-bottom: inherit;
  }
`;

const EmbeddedEventTime = ({ blok }) => {
  const { content, customCssClass } = blok;
  const cssClass = useMemo(() => {
    return ['embedded-event-time', customCssClass].filter(Boolean).join(' ');
  }, [customCssClass]);

  return content ? (
    <EmbeddedEventTimeParagraph {...storyblokEditable(blok)} className={cssClass}>
      <span className="mr-4">
        <CalendarIcon />
      </span>
      <span className="text-gray-300">{content}</span>
    </EmbeddedEventTimeParagraph>
  ) : undefined;
};

EmbeddedEventTime.propTypes = {
  blok: object.isRequired,
};

export default EmbeddedEventTime;
